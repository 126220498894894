/*------------------------------------
  Info Block v5-6
------------------------------------*/
.info-v5-6:hover .info-v5-6__rating {
	color: $g-color-gray-dark-v2;
}
.info-v5-6:hover .info-v5-6__price {
	background-color: $g-color-gray-dark-v2;
}

.info-v5-6 .g-color-gray-light-v5--hover .g-rating,
.info-v5-6 .g-bg-white--hover,
.info-v5-6 .g-color-primary--hover {
	transition-property: all;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-duration: .3s;
}

.info-v5-6:hover .g-color-gray-light-v5--hover .g-rating {
	color: $g-color-gray-light-v5;
}

.info-v5-6:hover .g-bg-white--hover {
	background: $g-color-white;
}

.info-v5-6:hover .g-color-primary--hover {
	color: $g-color-primary;
}