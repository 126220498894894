/*------------------------------------
  Social Background Colors
------------------------------------*/
/* Facebook */
.g-bg-facebook {
  background-color: $g-color-facebook;

  .u-block-hover:hover &--hover,
  &--hover:hover {
    background-color: $g-color-facebook-dark-v1 !important;
  }
}

/* Twitter */
.g-bg-twitter {
  background-color: $g-color-twitter;

  .u-block-hover:hover &--hover,
  &--hover:hover {
    background-color: $g-color-twitter-dark-v1 !important;
  }
}

/* Skype */
.g-bg-skype {
  background-color: $g-color-skype;

  .u-block-hover:hover &--hover,
  &--hover:hover {
    background-color: $g-color-skype-dark-v1 !important;
  }
}

/* Pinterest */
.g-bg-pinterest {
  background-color: $g-color-pinterest;

  .u-block-hover:hover &--hover,
  &--hover:hover {
    background-color: $g-color-pinterest-dark-v1 !important;
  }
}

/* Vine */
.g-bg-vine {
  background-color: $g-color-vine;

  .u-block-hover:hover &--hover,
  &--hover:hover {
    background-color: $g-color-vine-dark-v1 !important;
  }
}

/* Youtube */
.g-bg-youtube {
  background-color: $g-color-youtube;

  .u-block-hover:hover &--hover,
  &--hover:hover {
    background-color: $g-color-youtube-dark-v1 !important;
  }
}

/* Google plus */
.g-bg-google-plus {
  background-color: $g-color-google-plus;

  .u-block-hover:hover &--hover,
  &--hover:hover {
    background-color: $g-color-google-plus-dark-v1 !important;
  }
}

/* Dribbble */
.g-bg-dribbble {
  background-color: $g-color-dribbble;

  .u-block-hover:hover &--hover,
  &--hover:hover {
    background-color: $g-color-dribbble-dark-v1 !important;
  }
}

/* VK */
.g-bg-vk {
  background-color: $g-color-vk;

  .u-block-hover:hover &--hover,
  &--hover:hover {
    background-color: $g-color-vk-dark-v1 !important;
  }
}

/* Linkedin */
.g-bg-linkedin {
  background-color: $g-color-linkedin;

  .u-block-hover:hover &--hover,
  &--hover:hover {
    background-color: $g-color-linkedin-dark-v1 !important;
  }
}

/* Instagram */
.g-bg-instagram {
  background-color: $g-color-instagram;

  .u-block-hover:hover &--hover,
  &--hover:hover {
    background-color: $g-color-instagram-dark-v1 !important;
  }
}