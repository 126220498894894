/*------------------------------------
  Tabs v1
------------------------------------*/

/* Tabs v1
------------------------------------*/
@media(min-width: $g-md) {
  [class*="u-nav-v1"] {
    &.u-nav-rounded-3 .nav-link {
      border-radius: 3px;
    }
    &.u-nav-rounded-5 .nav-link {
      border-radius: 5px;
    }
    &.u-nav-rounded-7 .nav-link {
      border-radius: 7px;
    }
    &.u-nav-rounded-10 .nav-link {
      border-radius: 10px;
    }
  }

  .u-nav-v1-1 {
    & .nav-link.active {
      background-color: $g-color-gray-light-v4;
    }

    &.u-nav-primary {
      & .nav-link.active {
        color: $g-color-white;
        background-color: $g-color-primary;
      }
    }

    &.u-nav-dark {
      & .nav-link.active {
        color: $g-color-white;
        background-color: $g-color-gray-dark-v2;
      }
    }

    &.u-nav-light {
      & .nav-link {
        color: $g-color-white;
      }

      & .nav-link.active {
        color: $g-color-gray-dark-v2;
        background-color: $g-color-white;
      }
    }
  }

  .u-nav-v1-2 {
    & .nav-link {
      border: solid 1px transparent;
    }

    & .nav-link.active {
      border-color: $g-color-gray-light-v4;
    }

    &.u-nav-primary {
      & .nav-link.active {
        border-color: $g-color-primary;
      }
    }

    &.u-nav-dark {
      & .nav-link.active {
        border-color: $g-color-gray-dark-v2;
      }
    }

    &.u-nav-light {
      & .nav-link {
        color: $g-color-white;
      }

      & .nav-link.active {
        border-color: $g-color-white;
      }
    }
  }
}
