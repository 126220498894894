/*------------------------------------
  Carousel indicators v12
------------------------------------*/

.u-carousel-indicators-v12,
.u-carousel-indicators-v12--white {
  white-space: nowrap;

  li {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 5px;

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: transparent;
      border-radius: 50%;
      text-indent: -9999px;
      transition: background .3s ease;

      &::before,
      &::after {
        content: "";
        display: none;
      }

      &::before {
        display: block;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        transition: height .3s ease;
      }

      &:hover,
      &:focus {
        background-color: rgba($g-color-black, .2);
      }
    }

    &.slick-active {
      span {
        &::before {
          height: 100%;
        }
      }
    }
  }
}

.u-carousel-indicators-v12--white {
  li {
    span {
      box-shadow: inset 0 0 0 2px $g-color-white;

      &::before {
        background-color: $g-color-white;
        box-shadow: 0 0 1px $g-color-white;
      }
    }
  }
}
