/*------------------------------------
  Checkboxes
------------------------------------*/

.u-check {
  position: relative;
  cursor: pointer;

  &-icon {
    // Icon Font
    &-font {
      display: inline-block;
      font-size: 0;
      padding-left: 1px;
      padding-right: 1px;

      i {
        font-size: 22px;
        color: $g-color-gray-light-v2;

        &::before {
          content: attr(data-uncheck-icon);
        }
      }
    }
    // End Icon Font

    &-checkbox,
    &-radio {
      display: inline-block;
    }
  }

  // Control
  input[type="checkbox"],
  input[type="radio"] {
    &:checked {
      & +,
      & + * {
        // Icon Font
        .u-check-icon-font {
          i {
            color: $g-color-primary;

            &::before {
              content: attr(data-check-icon);
            }
          }
        }
        // End Icon Font
      }
    }
  }
}

.g-hide-check {
  display: block !important;

  .g-checked > * &,
  input[type="checkbox"]:checked + &,
  input[type="radio"]:checked + &,
  input[type="checkbox"]:checked + * &,
  input[type="radio"]:checked + * & {
    display: none !important;
  }
}

.g-show-check {
  display: none !important;

  .g-checked > * &,
  input[type="checkbox"]:checked + &,
  input[type="radio"]:checked + &,
  input[type="checkbox"]:checked + * &,
  input[type="radio"]:checked + * & {
    display: block !important;
  }
}

//
// Imports
//
@import "checkboxes-v1";
@import "checkboxes-v2";
@import "checkboxes-v3";
@import "checkboxes-v4";
@import "checkboxes-v5";
@import "checkboxes-v6";
@import "checkboxes-v7";
@import "checkboxes-v8";

/* P */
[class*="u-checkbox-v1"] {
  display: none;
}

[class*="u-checkbox-v1"] + label {
  cursor: pointer;
}

.u-checkbox-v1--checked-color-primary:checked + label {
  color: $g-color-primary !important;
}

.u-checkbox-v1--checked-brd-primary:checked + label {
  border-color: $g-color-primary !important;
}
