/*------------------------------------
  Go To v2
------------------------------------*/

[class*="u-go-to"] {
  display: none;
}

.u-go-to-v2 {
  display: block;
  background-color: rgba($g-color-black, .3);
  color: $g-color-white;
  border-radius: 3px;
  transition: .3s ease-out;
  z-index: 11;

  i {
    @include px-to-rem(width, 40px);
    @include px-to-rem(height, 40px);
    display: block;
    text-align: center;
    @include px-to-rem(line-height, 40px);
  }

  &:hover,
  &:focus:hover {
    text-decoration: none;
    color: $g-color-white;
    background-color: $g-color-primary;
  }

  &:focus {
    text-decoration: none;
    color: $g-color-white;
    background-color: rgba($g-color-black, .3);
  }
}

@media (max-width: #{$g-sm}-1) {
  .u-go-to-v2 {
    transform: scale(.8, .8);
  }
}