/*--------------------------------------------------
  Fancybox
----------------------------------------------------*/

//.u-fancybox-theme .fancybox-button {
//	@include px-to-rem(font-size, 20px);
//	@include px-to-rem(line-height, 41px);
//	@include px-to-rem(width, 44px);
//	@include px-to-rem(height, 44px);
//	text-align: center;
//	display: inline-block;
//	background-color: transparent;
//	@include px-to-rem(border-width, 1px);
//	border-style: solid;
//	border-color: $g-color-white;
//	outline: none;
//	border-radius: 50%;
//	opacity: .8;
//	transition: opacity .3s ease;
//
//	&::before {
//		font-family: "hs-icons";
//		position: static;
//		opacity: 1;
//		background-color: transparent;
//		border: none;
//		box-shadow: none;
//		transform: none;
//		width: auto;
//		height: auto;
//	}
//
//	&--left,
//	&--right {
//		@include px-to-rem(margin-top, -22px);
//		position: absolute;
//		top: 50%;
//		z-index: 99994;
//		display: none;
//	}
//
//	&--left {
//		@include px-to-rem(left, 20px);
//		&::before {
//			content: "\e901";
//		}
//	}
//
//	&--right {
//		@include px-to-rem(right, 20px);
//		&::before {
//			content: "\e902";
//		}
//	}
//
//	&--close {
//		&::before {
//			content: "\e904";
//		}
//	}
//
//	&--play {
//		&::before {
//			content: "\e90c";
//		}
//	}
//
//	&--fullscreen {
//		&::before {
//			content: "\e909";
//		}
//	}
//
//	&--thumbs {
//		&::before {
//			content: "\e906";
//		}
//	}
//
//	&::after {
//		display: none;
//	}
//
//	&:hover,
//	&:focus {
//		opacity: 1;
//	}
//
//}
//
//.u-fancybox-theme .fancybox-buttons {
//	@include px-to-rem(top, 20px);
//	@include px-to-rem(right, 20px);
//	@include px-to-rem(margin-left, -5px);
//	@include px-to-rem(margin-right, -5px);
//
//	.fancybox-button {
//		@include px-to-rem(margin-left, 5px);
//		@include px-to-rem(margin-right, 5px);
//	}
//}
//
//.u-fancybox-theme .fancybox-thumbs {
//	background-color: $g-color-black;
//
//	> ul > li {
//		border-color: $g-color-black;
//
//		&::before {
//			border-color: $g-color-primary;
//		}
//	}
//}

/* Blur bg container */
//.u-fancybox-blur-bg-container {
//	filter: blur(2px);
//}
//
//.fancybox-controls {
//	&--canzoomIn .fancybox-placeholder,
//	&--canzoomOut .fancybox-placeholder {
//		cursor: inherit;
//	}
//}

/*--------------------------------------------------
     Fancybox
     ----------------------------------------------------*/
.u-fancybox-theme .fancybox-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.u-fancybox-theme .fancybox-slide--iframe .fancybox-content {
  position: static;
}

.u-fancybox-theme.fancybox-show-thumbs .fancybox-content {
  right: 220px;
}

.u-fancybox-theme .fancybox-button,
.u-fancybox-theme .fancybox-arrow {
  font-size: 1.42857rem;
  line-height: 2.92857rem;
  width: 3.14286rem;
  height: 3.14286rem;
  text-align: center;
  background-color: transparent;
  border-width: 0.07143rem;
  border-style: solid;
  border-color: #fff;
  color: #fff;
  outline: none;
  border-radius: 50%;
  opacity: .8;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

.u-fancybox-theme .fancybox-button::before,
.u-fancybox-theme .fancybox-arrow::before {
  font-family: "hs-icons";
  position: static;
  opacity: 1;
  background-color: transparent;
  border: none;
  box-shadow: none;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  width: auto;
  height: auto;
}

.u-fancybox-theme .fancybox-button::after,
.u-fancybox-theme .fancybox-arrow::after {
  display: none;
}

.u-fancybox-theme .fancybox-button:hover, .u-fancybox-theme .fancybox-button:focus,
.u-fancybox-theme .fancybox-arrow:hover,
.u-fancybox-theme .fancybox-arrow:focus {
  opacity: 1;
}

.u-fancybox-theme .fancybox-button {
  display: inline-block;
}

.u-fancybox-theme .fancybox-button--close::before {
  content: "\e904";
}

.u-fancybox-theme .fancybox-button--play::before {
  content: "\e90c";
}

.u-fancybox-theme .fancybox-button--fullscreen::before {
  content: "\e909";
}

.u-fancybox-theme .fancybox-button--thumbs::before {
  content: "\e906";
}

.u-fancybox-theme .fancybox-arrow--left, .u-fancybox-theme .fancybox-arrow--right {
  margin-top: -1.57143rem;
  position: absolute;
  top: 50%;
  z-index: 100000;
  display: none;
}

.u-fancybox-theme.fancybox-show-nav .fancybox-arrow--left, .u-fancybox-theme.fancybox-show-nav .fancybox-arrow--right {
  display: block;
}

.u-fancybox-theme .fancybox-arrow--left::before, .u-fancybox-theme .fancybox-arrow--right::before {
  content: "\e902";
}

.u-fancybox-theme .fancybox-arrow--left {
  left: 1.42857rem;
}

.u-fancybox-theme .fancybox-arrow--right {
  right: 1.42857rem;
}

.u-fancybox-theme .fancybox-toolbar {
  top: 1.42857rem;
  right: 1.42857rem;
  margin-left: -0.35714rem;
  margin-right: -0.35714rem;
}

.u-fancybox-theme .fancybox-toolbar .fancybox-button {
  margin-left: 0.35714rem;
  margin-right: 0.35714rem;
}

.u-fancybox-theme .fancybox-thumbs {
  background-color: #000;
}

.u-fancybox-theme .fancybox-thumbs > ul > li {
  border-color: #000;
}

.u-fancybox-theme .fancybox-thumbs > ul > li::before {
  border-color: #72c02c;
}

.fancybox-controls--canzoomIn .fancybox-placeholder,
.fancybox-controls--canzoomOut .fancybox-placeholder {
  cursor: inherit;
}

.fancybox-slide.has-animation {
  display: block;
}

.fancybox-is-sliding .fancybox-slide.has-animation,
.fancybox-slide--current.has-animation,
.fancybox-slide--next.has-animation,
.fancybox-slide--previous.has-animation {
  display: none;
}

.fancybox-is-sliding .fancybox-slide.has-animation.animated,
.fancybox-slide--current.has-animation.animated,
.fancybox-slide--next.has-animation.animated,
.fancybox-slide--previous.has-animation.animated {
  display: block;
}