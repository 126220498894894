//
// Colors
//

//
// Basic Colors

// Main Colors
$g-color-main: #999;

// Primary Color
$g-color-primary: #9900cc;

// Custom Color
$g-theme-color-gray-light-v1: #dedede;
$g-theme-color-gray-light-v2: #ededed;
$g-theme-color-gray-light-v3: #e6e6e6;
$g-theme-color-gray-dark-v1: #1a1a1a;