/*------------------------------------
  Carousel indicators v19
------------------------------------*/

.u-carousel-indicators-v19,
.u-carousel-indicators-v19--white {
  white-space: nowrap;

  li {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: 0 5px;
    transition: box-shadow .3s ease;

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      text-indent: -9999px;
      transition: background-color .3s ease, transform .3s ease;

      &::before,
      &::after {
        content: "";
        display: none;
      }
    }

    &.slick-active {
      span {
        background-color: $g-color-white;
        transform: scale(.4);
      }
    }
  }
}

.u-carousel-indicators-v19--white {
  li {
    box-shadow: 0 0 0 2px rgba($g-color-white, 0);

    span {
      background-color: rgba($g-color-white, .7);

      &:hover,
      &:focus {
        background-color: $g-color-white;
      }
    }

    &.slick-active {
      box-shadow: 0 0 0 2px $g-color-white;
    }
  }
}
