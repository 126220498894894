/*------------------------------------
  Checkboxes v3
------------------------------------*/

.u-check {
  &-icon {
    &-checkbox,
    &-radio {
      // v3
      &-v3 {
        width: 40px;
        height: 40px;
        border: solid 1px $g-color-primary;
        border-radius: 50%;
      }
      // End v3
    }
  }
}
