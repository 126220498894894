//
// Icon Sizes (u-icon-v2, u-icon-v3, u-icon-v4)
//
// Default (md)
%u-icon-size {
  @include px-to-rem(width, 55px);
  @include px-to-rem(height, 55px);
  @include px-to-rem(font-size, 22px);
}

%u-icon-size--xs {
  @include px-to-rem(width, 30px);
  @include px-to-rem(height, 30px);
  @include px-to-rem(font-size, 13px);
}

%u-icon-size--sm {
  @include px-to-rem(width, 40px);
  @include px-to-rem(height, 40px);
  @include px-to-rem(font-size, 18px);
}

%u-icon-size--lg {
  @include px-to-rem(width, 70px);
  @include px-to-rem(height, 70px);
  @include px-to-rem(font-size, 30px);
}

%u-icon-size--xl {
  @include px-to-rem(width, 90px);
  @include px-to-rem(height, 90px);
  @include px-to-rem(font-size, 40px);
}

%u-icon-size--2xl {
  @include px-to-rem(width, 100px);
  @include px-to-rem(height, 100px);
  @include px-to-rem(font-size, 45px);
}

%u-icon-size--3xl {
  @include px-to-rem(width, 130px);
  @include px-to-rem(height, 130px);
  @include px-to-rem(font-size, 50px);
}