/*------------------------------------
  Heading-v2-1
------------------------------------*/
%u-heading-v2-1 {
  @include px-to-rem(width, 44px);
  border-top-width: 2px;
}
.u-heading-v2-1 {
  &--bottom::after {
    @extend %u-heading-v2;
    @extend %u-heading-v2-1;
    @include px-to-rem(margin-top, 25px);
  }
  &--top::before {
    @extend %u-heading-v2;
    @extend %u-heading-v2-1;
    @include px-to-rem(margin-bottom, 25px);
  }
}