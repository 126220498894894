/*------------------------------------
  Carousel indicators v34
------------------------------------*/

.u-carousel-indicators-v34 {
  li {
  	display: block;
    margin: 10px 0;

    &.slick-active {
      span {
        background-color: $g-color-primary;
        transform: scale(1);
      }
    }
  }

  span {
  	width: 13px;
  	height: 13px;
    border: 2px solid $g-color-white;
    border-radius: 50%;
    transform: scale(.7);
    transition: all .3s ease;
  }
}