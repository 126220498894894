/*------------------------------------
  Checked state box shadow
------------------------------------*/

.u-shadow-v1 {
  &-5 {
    &--checked {
      input[type="checkbox"]:checked + &,
      input[type="radio"]:checked + &,
      input[type="checkbox"]:checked + * &,
      input[type="radio"]:checked + * & {
        box-shadow: 0 0 20px rgba($g-color-black, .2);
      }

      &.g-checked,
      .g-checked & {
        box-shadow: 0 0 20px rgba($g-color-black, .2);
      }
    }
  }

  &-v6 {
    &--checked {
      input[type="checkbox"]:checked + &,
      input[type="radio"]:checked + &,
      input[type="checkbox"]:checked + * &,
      input[type="radio"]:checked + * & {
        box-shadow: 0 0 10px rgba($g-color-black, .2);
      }

      &.g-checked,
      .g-checked & {
        box-shadow: 0 0 10px rgba($g-color-black, .2);
      }
    }
  }
}
