/*------------------------------------
  Checkboxes v5
------------------------------------*/

.u-check {
  &-icon {
    &-checkbox,
    &-radio {
      // v5
      &-v5 {
        width: 18px;
        height: 18px;

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 100%;
          height: 100%;
          background-color: $g-color-white;
          border: solid 1px $g-color-gray-light-v2;
          transform: translate(-50%, -50%);
        }
      }
      // End v5
    }

    &-checkbox {
      // v5
      &-v5 {
        border-radius: 1px;
      }
      // End v5
    }

    &-radio {
      // v5
      &-v5 {
        border-radius: 50%;

        i {
          border-radius: 50%;
        }
      }
      // End v5
    }
  }

  // Control
  input[type="checkbox"],
  input[type="radio"] {
    &:checked {
      & +,
      & + * {
        // v5
        .u-check-icon-checkbox-v5,
        .u-check-icon-radio-v5 {
          i {
            border: {
              width: 4px;
              color: $g-color-primary;
            }
          }
        }
        // End v5
      }
    }
  }
}