/*------------------------------------
  Success state v1-2
------------------------------------*/

.u-has-success-v1-2 {
  position: relative;

  .form-control,
  [class*="input-group-"],
  [class*="u-select"] {
    background-color: #f0fff0;
    border-color: $g-color-primary;

    &[readonly] {
      background-color: #f0fff0;
    }
  }

  .form-control-feedback {
    color: $g-color-primary;
  }

  [class*="u-check-icon"] {
    color: #f0fff0;
    background-color: #f0fff0;
    border-radius: 50%;
  }

  [class*="u-check-icon"] i {
    color: rgba($g-color-primary, .2);
  }

  [class*="input-group-"],
  [class*="input-group-"]:last-child,
  .chosen-single div b {
    color: $g-color-primary;
  }

  [class*="input-group-"],
  [class*="input-group-"]:last-child {
    color: $g-color-white;
    background-color: $g-color-primary;
  }

  .form-control-feedback {
    &::before {
      content: "";
      position: absolute;
      bottom: -3px;
      right: 18px;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 3px 3px 0 3px;
      border-color: $g-color-primary transparent transparent transparent;
    }
  }
}
