/*------------------------------------
  Go To v1
------------------------------------*/

[class*="u-go-to"] {
  display: none;
}

.u-go-to-v1 {
  @include px-to-rem(width, 50px);
  @include px-to-rem(height, 50px);
  display: block;
  background-color: rgba($g-color-white, .7);
  color: $g-color-main;
  border-radius: 50%;
  box-shadow: 0 0 20px 0 rgba($g-color-black, .15);
  transition: .3s ease-out;
  z-index: 11;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover,
  &:focus:hover {
    text-decoration: none;
    color: $g-color-white;
    background-color: $g-color-primary;
  }

  &:focus {
    text-decoration: none;
    color: $g-color-main;
    background-color: rgba($g-color-white, .7);
  }
}

@media (max-width: #{$g-sm}-1) {
  .u-go-to-v1 {
    transform: scale(.8, .8);
  }
}