.plyr--video {
  .embed-responsive & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.plyr--full-ui input[type=range] {
  color: $g-color-primary;
}