/*------------------------------------
  Headings
------------------------------------*/

/* Massive Underline */
.u-heading-massive-underline {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: .2em;
    height: .3em;
    background-color: $g-color-primary-light-v2;
    z-index: -1;
  }
}

/* Heading With Dot */
.u-header-title-dot::after {
  content: ".";
  color: $g-color-primary;
  display: inline-block;
  font-size: 35px;
}