//
// Links
//

/*------------------------------------
  Link Styles
------------------------------------*/

.u-link {
  // Link Style v1
  &-v1 {
    border-bottom: solid 1px;

    &:hover,
    &:focus {
      border-bottom: none;
      text-decoration: none;
    }
  }

  // Link Style v2
  &-v2 {
    @include absolute(top 0 right 0 bottom 0 left 0);
    text-indent: -100%;
    white-space: nowrap;
    overflow: hidden;
    z-index: 2;
  }

  // Link Style v3
  &-v3 {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  // Link Style v4
  &-v4 {
    transition: all .3s;

    &:hover {
      opacity: .8;
    }
  }

  // Link Style v5
  &-v5 {
    text-decoration: none;
    transition: all .2s;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  // Link Style v5
  &-v6 {
    display: block;
    overflow: hidden;

    &-arrow {
      margin-left: -50px;
      visibility: hidden;
      opacity: 0;
      transition: all .4s;
    }
  }

  .u-block-hover:hover,
  &-v6:hover {
    &-v6-arrow {
      margin-left: 5px;
      visibility: visible;
      opacity: 1;
    }
  }

  &-v7 {
    margin-right: 30px;
    transition: all .4s;

    &-arrow {
      margin-left: -50px;
      visibility: hidden;
      opacity: 0;
      transition: all .4s;
    }
  }

  .u-block-hover:hover,
  &-v7:hover {
    margin-right: 0;

    &-v7-arrow {
      margin-left: 10px;
      visibility: visible;
      opacity: 1;
    }
  }
}