/*------------------------------------
  Searchform v3
------------------------------------*/

.u-searchform-v3 {

	@include absolute(top 0 right 0 bottom 0 left 0);
	z-index: 3;

	.container {
		height: 100%;
	}

	.input-group {
		align-items: center;
		height: 100%;
	}

	.form-control {
		font-family: $g-font-primary;
		font-weight: 300;
		font-size: inherit;
		color: inherit;
		text-transform: uppercase;

		@include px-to-rem(height, 66px);
		@include px-to-rem(padding, 12px 0);

		background-color: transparent;
		border: none;

		&::placeholder {
			color: g-color-white;
			opacity: .85;
		}

		&::-webkit-input-placeholder {
			color: g-color-white;
			opacity: .85;
		}

		&::-moz-placeholder {
			color: g-color-white;
			opacity: .85;
		}
	}

	.input-group-addon {
		border: 0;

		button {
			cursor: pointer;
			height: 100%;

			border: none;
			outline: none;
			background-color: transparent;
		}
	}
}