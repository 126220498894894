/*------------------------------------
  Badges v2
------------------------------------*/

[class*="u-badge-v2"] {
  background-color: $g-color-primary;
  border-radius: 50%;
}

.u-badge-v2 {
  width: 14px;
  height: 14px;

  &--xs {
    width: 8px;
    height: 8px;
  }

  &--sm {
    width: 12px;
    height: 12px;
  }

  &--md {
    width: 14px;
    height: 14px;
  }

  &--lg {
    width: 16px;
    height: 16px;
  }

  &--xl {
    width: 18px;
    height: 18px;
  }
}
