/*------------------------------------
  Box-shadows-v8
------------------------------------*/
.u-shadow-v8 {
  box-shadow: 2px 2px 1px rgba($g-color-black, .05);

  &--hover:hover {
    box-shadow: 2px 2px 1px rgba($g-color-black, .05);
  }

  &-1 {
    box-shadow: 0 0 15px rgba($g-color-black, .05);
  }
}