/*------------------------------------
  Typography Letter Spacing
------------------------------------*/
.g-letter-spacing {
  &-minus-2 {
    @include px-to-rem(letter-spacing, -2px);
  }
  &-0_5 {
    @include px-to-rem(letter-spacing, .5px);
  }
  &-1_5 {
    @include px-to-rem(letter-spacing, 1.5px);
  }
  @for $i from 0 through 12 {
    &-#{$i} {
      @include px-to-rem(letter-spacing, $i*1px);
    }
  }
}
