// Font Families
// $g-font-primary: "Encode Sans", sans-serif;
$g-font-primary: "Encode Sans", "verdana";

// Font Sizes
$g-font-primary-size: 16px;

//
// Colors
//

// Primary Color
$g-color-primary: #9900cc;
$g-color-primary-light-v1: #2f2d27;

// Main Colors
$g-color-text: #6f7274;
$g-color-secondary: white;
$g-color-dark: #13181d;
$g-color-dark-light-v1: #0e151b;
$g-color-main: #9900cc;
$g-color-main-light-v1: #9900cc;
