/*------------------------------------
  Social Colors
------------------------------------*/
/* Facebook */
.g-color-facebook {
  color: $g-color-facebook;

  &:hover {
    color: $g-color-facebook-dark-v1;
  }

  &--hover:hover {
    color: $g-color-facebook !important;
  }
}

/* Twitter */
.g-color-twitter {
  color: $g-color-twitter;

  &:hover {
    color: $g-color-twitter-dark-v1;
  }

  &--hover:hover {
    color: $g-color-twitter !important;
  }
}

/* Skype */
.g-color-skype {
  color: $g-color-skype;

  &:hover {
    color: $g-color-skype-dark-v1;
  }

  &--hover:hover {
    color: $g-color-skype !important;
  }
}

/* Pinterest */
.g-color-pinterest {
  color: $g-color-pinterest;

  &:hover {
    color: $g-color-pinterest-dark-v1;
  }

  &--hover:hover {
    color: $g-color-pinterest !important;
  }
}

/* Vine */
.g-color-vine {
  color: $g-color-vine;

  &:hover {
    color: $g-color-vine-dark-v1;
  }

  &--hover:hover {
    color: $g-color-vine !important;
  }
}

/* Youtube */
.g-color-youtube {
  color: $g-color-youtube;

  &:hover {
    color: $g-color-youtube-dark-v1;
  }

  &--hover:hover {
    color: $g-color-youtube !important;
  }
}

/* Google plus */
.g-color-google-plus {
  color: $g-color-google-plus;

  &:hover {
    color: $g-color-google-plus-dark-v1;
  }

  &--hover:hover {
    color: $g-color-google-plus !important;
  }
}

/* Dribbble */
.g-color-dribbble {
  color: $g-color-dribbble;

  &:hover {
    color: $g-color-dribbble-dark-v1;
  }

  &--hover:hover {
    color: $g-color-dribbble !important;
  }
}

/* VK */
.g-color-vk {
  color: $g-color-vk;

  &:hover {
    color: $g-color-vk-dark-v1;
  }

  &--hover:hover {
    color: $g-color-vk !important;
  }
}

/* Linkedin */
.g-color-linkedin {
  color: $g-color-linkedin;

  &:hover {
    color: $g-color-linkedin-dark-v1;
  }

  &--hover:hover {
    color: $g-color-linkedin !important;
  }
}

/* Instagram */
.g-color-instagram {
  color: $g-color-instagram;

  &:hover {
    color: $g-color-instagram-dark-v1;
  }

  &--hover:hover {
    color: $g-color-instagram !important;
  }
}