/*------------------------------------
  Carousel indicators v23
------------------------------------*/

.u-carousel-indicators-v23,
.u-carousel-indicators-v23--white {
  white-space: nowrap;

  li {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 5px;

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      text-indent: -9999px;
      transition: background-color .3s ease;

      &::before,
      &::after {
        content: "";
        display: none;
      }
    }

    &.slick-active {
      &:first-child {
        & ~ .u-dots-helper {
          transform: translateX(0);
        }
      }

      $i: 2;
      @while $i < 30 {
        &:nth-child( #{$i} ) {
          & ~ .u-dots-helper {
            transform: translateX(($i - 1) * 100%);
          }
        }
        $i: $i + 1;
      }
    }
  }

  .u-dots-helper {
    position: absolute;
    left: 0;
    top: 0;
    width: 26px;
    transition: transform .3s ease;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      transform: translateX(-50%);
    }
  }
}

.u-carousel-indicators-v23--white {
  li {
    span {
      background: rgba($g-color-white, .5);

      &:hover,
      &:focus {
        background-color: $g-color-white;
      }
    }
  }

  .u-dots-helper {
    &::before {
      background: $g-color-primary;
    }
  }
}
