//
// Variables and Mixins

// ACBVI
//

@import "custom/custom.variables";
@import "unify";

body {
  background-color: $g-color-white;
}

p {
  color: $g-color-text;
}

/*------------------------------------
  Colors
------------------------------------*/
.g-color-text {
  color: $g-color-text;
}

.g-color-main {
  color: $g-color-main;

  &-opacity-0_5 {
    color: rgba($g-color-main, .5);

    &--hover:hover {
      color: rgba($g-color-main, .5);
    }
  }
}

.g-color-dark {
  color: $g-color-dark;

  &-light-v1 {
    color: $g-color-dark-light-v1;
  }
}

/*------------------------------------
  Background Colors
------------------------------------*/
.g-bg-main {
  background-color: $g-color-main !important;

  &-opacity-0_5 {
    background-color: rgba($g-color-main, .5);
  }

  &-opacity-0_8--after::after {
    background-color: rgba($g-color-main, .8);
  }

  &-opacity-0_9--after::after {
    background-color: rgba($g-color-main, .9);
  }

  &--hover:hover {
    background-color: $g-color-main !important;
  }

  &--focus:focus {
    background-color: $g-color-main !important;
  }
}

.active.g-bg-main--active {
  background-color: $g-color-main !important;
}

.g-bg-secondary {
  background-color: $g-color-secondary;
}

.g-bg-dark {
  background-color: $g-color-dark;

  &-light-v1 {
    background-color: $g-color-dark-light-v1;
  }

  &-opacity-0_8--after::after {
    background-color: rgba($g-color-dark-light-v1, .95);
  }
}

/*------------------------------------
  Border Colors
------------------------------------*/
.g-brd-main {
  &-light-v1 {
    border-color: $g-color-main-light-v1 !important;
  }

  &--hover:hover {
    border-color: $g-color-main !important;
  }
}

.active.g-brd-primary--active {
  border-color: $g-color-main !important;
}

.g-brd-primary {
  &-light-v1 {
    border-color: $g-color-primary-light-v1 !important;
  }
}

.g-brd-text {
  border-color: $g-color-text !important;
}

/*--------------------------------------------------
  Info Block v10-1
----------------------------------------------------*/
.u-info-v10-1 {
  &::before,
  &::after {
    background: $g-color-gray-light-v4;
  }
}

/*------------------------------------
  Dropdown Toggle
------------------------------------*/
.dropdown-toggle::after {
  position: absolute;
  top: 50%;
  right: 30px;
  margin-top: -4px;
}

/*------------------------------------
  Box-shadows-v29
------------------------------------*/
.u-shadow-v29 {
  &--hover:hover {
    box-shadow: 0 15px 50px 0 rgba($g-color-main, .2);
  }
}

/*------------------------------------
  Carousel indicators v33
------------------------------------*/
.u-carousel-indicators-v33 {
  li {
    color: $g-color-text;
  }
}

// donate

//
// Variables and Mixins
//
@import "settings/variables.donate";
@import "unify";

/* Typography */
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: $g-color-gray-dark-v1;
}

a {
  &:hover,
  &:active,
  &:focus {
    color: $g-color-primary;
  }
}

/* Hamburger */
.hamburger {
  padding: 0;
}

.hamburger-box,
.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
  width: 25px;
}

.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
  .light-theme & {
    background-color: $g-color-white;
  }
}

.hamburger-box {
  height: 17px;
}

.hamburger--slider .hamburger-inner {
  top: 0;
}

.hamburger--slider .hamburger-inner:before {
  top: 9px;
}

.hamburger--slider .hamburger-inner:after {
  top: 17px;
}

.hamburger--slider.is-active .hamburger-inner:after {
  transform: translate3d(0, -17px, 0) rotate(-90deg);
}

/* Main navigation */
.light-theme {
  .nav-link {
    color: $g-color-white;
  }

  &:hover {
    color: $g-color-primary;
  }

  li.active {
    .nav-link {
      color: $g-color-primary;
    }
  }
}

.dark-theme {
  .nav-link {
    color: $g-color-gray-dark-v4;
  }

  &:hover {
    color: $g-color-primary;
  }

  li.active {
    .nav-link {
      color: $g-color-primary;
    }
  }
}

/* Background */
.g-theme-bg-gray-light-v3 {
  background-color: $g-theme-color-gray-light-v3 !important;
}

/* Border */
.g-theme-brd-color-gray-light-v2 {
  border-color: $g-theme-color-gray-light-v2 !important;
}

.g-theme-brd-color-dark-light-v1 {
  border-color: $g-theme-color-gray-dark-v1 !important;
}

/* Carousel */
.u-carousel-v5 .js-slide {
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($g-color-white, .7);
    opacity: 1;
    transition: opacity .2s ease-in;
  }

  &.slick-active {
    &::after {
      opacity: 0;
    }
  }
}

/* Carousel indicators */
.u-carousel-indicators-v1 li span {
  background-color: $g-theme-color-gray-light-v1;
}

.u-carousel-indicators-v1 li.slick-active span {
  background-color: $g-color-primary;
}

/* Pin map */
.point,
.point::after {
  background-color: rgba($g-color-black, .2);
}

.point::before {
  background-color: $g-color-primary;
}

/* Progress bar */
.u-progress__pointer-v2::after {
  border-color: $g-color-primary transparent;
}

@media (max-width: $g-lg - 1) {
  .u-header__section:not(.js-header-change-moment) .navbar-collapse {
    padding: 0 20px 10px;
    background-color: $g-color-white;

    .nav-item .nav-link {
      color: $g-color-gray-dark-v4;
    }

    .active.nav-item .nav-link {
      color: $g-color-primary;
    }
  }
}