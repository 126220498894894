/*------------------------------------
  Dot line v1
------------------------------------*/

.u-dot-line-v1,
.u-dot-line-v1-2 {
  position: relative;
  display: block;
  width: 100%;
  font-size: 0;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    display: block;
    width: calc(50% + 30px);
    border-top: {
      width: 1px;
      style: solid;
    }
    margin-top: -1px;
  }

  &::before {
    left: -30px;

    li:first-child & {
      display: none;
    }
  }

  &::after {
    li:last-child & {
      display: none;
    }

    right: -30px;
  }

  &__inner {
    position: relative;
    z-index: 2;
    display: inline-block;
    width: 30px;
    height: 30px;
    border: {
      width: 1px;
      style: solid;
      radius: 50%;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 12px;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
  }

  &-2 {
    &::before,
    &::after {
      display: none;
    }
  }
}

@media (min-width: $g-lg) {
  .u-dot-line-v1 {
    &-2 {
      &::before,
      &::after {
        display: block;
      }
    }
  }
}
