/*------------------------------------
  Checkboxes v1
------------------------------------*/
.u-check {
  &-icon {
    &-checkbox,
    &-radio {
      // v1
      &-v1 {
        background-color: $g-color-white;
        border: solid 1px $g-color-gray-light-v2;
      }
      // End v1
    }

    &-checkbox {
      // v1
      &-v1 {
        border-radius: 1px;
      }
      // End v1
    }

    &-radio {
      // v1
      &-v1 {
        border-radius: 50%;
      }
      // End v1
    }
  }

  // Control
  input[type="checkbox"],
  input[type="radio"] {
    &:checked {
      & +,
      & + * {
        // v1
        .u-check-icon-checkbox-v1 {
          color: $g-color-white;
          background-color: $g-color-primary;
          border-color: $g-color-primary;

          &::before {
            content: attr(data-check-icon);
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 12px;
            transform: translate(-50%, -50%);
          }
        }

        .u-check-icon-radio-v1 {
          border: {
            width: 4px;
            color: $g-color-primary;
          }
        }
        // End v1
      }
    }
  }
}