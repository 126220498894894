/*------------------------------------
  Carousel indicators v26
------------------------------------*/

.u-carousel-indicators-v26,
.u-carousel-indicators-v26--white,
.u-carousel-indicators-v26--vertical,
.u-carousel-indicators-v26--vertical--white {
  li {
    position: relative;
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 40px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 16px;
      height: 16px;
      border: 2px solid;
      border-radius: 50%;
      transition: width .3s, height .3s, border-color .3s, border-width .3s, background .3s;
      transform: translateX(-50%) translateY(-50%);
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      z-index: -1;
      display: block;
      width: 44px;
      height: 0;
      border-top: 2px solid;
      transform: translateY(-50%);
      margin-left: -2px;
    }

    span {
      display: block;
      position: absolute;
      bottom: 100%;
      left: 50%;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      white-space: nowrap;
      opacity: .3;
      transition: opacity .3s, color .3s;
      transform: translateX(-50%);
      padding: 0 0 15px 0;
    }

    &.slick-active {
      &::before {
        width: 20px;
        height: 20px;
      }

      span {
        opacity: 1;
      }
    }

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;

      &::after {
        display: none;
      }
    }
  }
}

.u-carousel-indicators-v26--vertical,
.u-carousel-indicators-v26--vertical--white {
  width: 20px;

  li {
    display: block;
    float: none;
    margin-bottom: 15px;
    margin-right: 0;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      z-index: -1;
      display: block;
      width: 0;
      height: 19px;
      border-left: 2px solid;
      transform: translateX(-50%) translateY(0);
      margin-top: -2px;
      margin-left: 0;
    }

    span {
      display: block;
      position: absolute;
      top: 50%;
      bottom: auto;
      left: 100%;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      white-space: nowrap;
      opacity: .3;
      transition: opacity .3s, color .3s;
      transform: translateX(0) translateY(-50%);
      padding: 0 0 0 15px;
    }
  }
}

.u-carousel-indicators-v26--white,
.u-carousel-indicators-v26--vertical--white {
  li {
    &::before {
      border-color: $g-color-primary;
    }

    &::after {
      border-color: $g-color-primary;
    }

    span {
      color: $g-color-white;
    }

    &:not(.slick-active) {
      &:hover {
        &::before {
          border-color: $g-color-white;
          background-color: $g-color-white;
        }
      }
    }

    &.slick-active {
      &::before {
        border-color: $g-color-white;
      }

      span {
        color: $g-color-white;
      }
    }
  }
}
