/*------------------------------------
  Info v11-1
------------------------------------*/
.u-info-v11-1 {
	&-img {
		border: 0 solid $g-color-gray-light-v4;
		transform: scale(1);
	  transition: all .3s ease;
	}

	&:hover .u-info-v11-1-img {
		border-width: 5px;
		transform: scale(.95);
	}
}