/*------------------------------------
  Navigation Behavior (Smart)
------------------------------------*/

.u-smart-nav {
	position: fixed;
	z-index: 2;

	opacity: 0;
	visibility: hidden;

	transform: scale3d(0.2,0.2,0.2);

	.navbar {
		position: absolute;

		@include px-to-rem(width,300px);

		opacity: 0;
		visibility: hidden;

		.dropdown {
			z-index: 1;
		}

		.dropdown-menu {
			position: relative;
			width: 100%;
		}

		li {
			transform: translate3d(30px,0,0);

			transition: all .3s ease;
		}

		@for $i from 1 to 20 {
			li:nth-child(#{$i}) {
				$delay: ($i + 1) * 30;
				transition-delay: #{$delay}ms;
			}
		}
	}
	&__toggler {
		position: relative;
		z-index: 1;
	}
	&--opened {
		.navbar {
			opacity: 1;
			visibility: visible;

			transform: scale3d(1,1,1) !important;

			li {
				transform: translate3d(0,0,0);
			}
		}
	}
	&--shown {
		opacity: 1;
		visibility: visible;

		transform: scale3d(1,1,1);
	}
	&--bottom-right {
		@include px-to-rem(right,10px);
		@include px-to-rem(bottom,10px);

		.navbar {
			bottom: 0;
			right: 0;

			transform: scale3d(0.1,0.1,0.1);
			transform-origin: 100% 100%;

			@include px-to-rem(padding-right,80px);
		}
	}
	&--bottom-left {

		@include px-to-rem(left,10px);
		@include px-to-rem(bottom,10px);

		.navbar {
			text-align: right;
			bottom: 0;
			left: 0;

			transform: scale3d(0.1,0.1,0.1);
			transform-origin: 0% 100%;

			@include px-to-rem(padding-left,80px);
		}
	}
	&--top-left {
		@include px-to-rem(left,10px);
		@include px-to-rem(top,10px);

		.navbar {
			text-align: right;
			top: 0;
			left: 0;

			transform: scale3d(0.1,0.1,0.1);
			transform-origin: 0% 0%;

			@include px-to-rem(padding-left,80px);
		}
	}
	&--top-right {
		@include px-to-rem(right,10px);
		@include px-to-rem(top,10px);

		.navbar {
			top: 0;
			right: 0;

			transform: scale3d(0.1,0.1,0.1);
			transform-origin: 100% 0%;

			@include px-to-rem(padding-right,80px);
		}
	}
}