/*------------------------------------
  File Attachments v1
------------------------------------*/

.u-file-attach-v1 {
  position: relative;
  overflow: hidden;
  cursor: pointer;

  input {
    &[type="file"] {
      position: absolute;
      top: -25%;
      left: -25%;
      z-index: 10;
      width: 150%;
      height: 150%;
      opacity: 0;
      cursor: pointer;
    }

    &[readonly] {
      background-color: transparent;
    }
  }
}
