/*------------------------------------
  Background Gradient Colors
------------------------------------*/
/* Simple Linear Gradients
------------------------------------*/
/* Gray Light v1 */
.g-bg-gray-light-gradient-v1 {
  &--after::after {
    background: linear-gradient(to bottom, rgba($g-color-gray-light-v5,.5), rgba($g-color-gray-light-v5,1));
  }
}

/* White v1 */
.g-bg-white-gradient-v1 {
  &--after::after {
    background: linear-gradient(to bottom, rgba($g-color-white,.5), rgba($g-color-white,.9));
  }
}

/* Linear Gradients
------------------------------------*/
/* Gray Gradient */
.g-bg-gray-lineargradient {
  @include gradient-directional($start-color: #fff, $end-color: #dbdbdb, $deg: 180deg);
}

/* Blue Pink Gradient */
.g-bg-bluepink-lineargradient {
  @include gradient-directional($start-color: hsla(205, 62%, 44%, 1), $end-color: #f62d51, $deg: 50deg);
}

/* Blue Gradient */
.g-bg-blue-lineargradient {
  @include gradient-directional($start-color: #73bec7, $end-color: #4e66b0, $deg: 0deg);
}
.g-bg-blue-lineargradient-v2 {
  @include gradient-directional($start-color: #429edc, $end-color: #00629e, $deg: 225deg);
}
.g-bg-blue-lineargradient-v3 {
  @include gradient-directional($start-color: #3266a2, $end-color: #98cfbf, $deg: 225deg);
}
.g-bg-blue-lineargradient-v4 {
  @include gradient-directional($start-color: #5b85df, $end-color: #3ec2e3, $deg: 225deg);
}
.g-bg-blue-lineargradient-v5 {
  @include gradient-directional($start-color: #0050c2, $end-color: #5180fa, $deg: 225deg);
}

/* Orange Gradient */
.g-bg-orange-lineargradient {
  @include gradient-directional($start-color: #be1715, $end-color: #ed6d0f, $deg: 225deg);
}
.g-bg-orange-lineargradient-v2 {
  @include gradient-directional($start-color: #f5cd21, $end-color: #c22821, $deg: 225deg);
}

/* Yellow Gradient */
.g-bg-yellow-lineargradient {
  @include gradient-directional($start-color: #f59b00, $end-color: #f5d400, $deg: 225deg);
}

/* Dark Purple Gradient */
.g-bg-darkpurple-lineargradient {
  @include gradient-directional($start-color: #0b011d, $end-color: #330c3c, $deg: 225deg);
}

/* Blue Gray Gradient */
.g-bg-bluegray-lineargradient {
  @include gradient-directional($start-color: #1d253c, $end-color: #3e4757, $deg: 225deg);
}

/* Pink Gradient */
.g-bg-pink-lineargradient {
  @include gradient-directional($start-color: #b6003b, $end-color: #ed1261, $deg: 225deg);
}

/* Pink-v2 Gradient */
.g-bg-pink-lineargradient-v2 {
  @include gradient-x-three-colors($x-derection: right, $y-direction: top, $start-color: #fc7279, $start-color-stop: 10%, $mid-color: #ff7b9c, $mid-color-stop: 65%, $end-color: #ffc019, $end-color-stop: 125%);
}

/* Red Gradient */
.g-bg-red-lineargradient {
  @include gradient-directional($start-color: #d70417, $end-color: #9f041b, $deg: 225deg);
}

/* Black Gradient */
.g-bg-black-gradient-opacity-v1,
.g-bg-black-gradient-opacity-v1--after::after {
  @include gradient-y-bottom($start-color: rgba(#000, 0), $end-color: rgba(#000, .9));
}

/* Green Gradient */
.g-bg-green-gradient-opacity-v1,
.g-bg-green-gradient-opacity-v1--after::after {
  @include gradient-y-bottom($start-color: rgba(#3398dc, .5), $end-color: rgba(#72c02c, .8));
}

/* Purple Gradient */
.g-bg-purple-gradient-opacity-v1,
.g-bg-purple-gradient-opacity-v1--after::after {
  @include gradient-y-bottom($start-color: rgba(#72c02c, .5), $end-color: rgba(#9a69cb, .8));
}

/* Pink Light Gradient */
.g-bg-pink-gradient-opacity-v1,
.g-bg-pink-gradient-opacity-v1--after::after {
  @include gradient-y-bottom($start-color: rgba(#72c02c, .5), $end-color: rgba(#e81c62, .8));
}

/* Cyan Gradient */
.g-bg-cyan-gradient-opacity-v1,
.g-bg-cyan-gradient-opacity-v1--after::after {
  @include gradient-y-bottom($start-color: rgba(#009, .5), $end-color: rgba(#00bed6, .6));
}

/* Purple Gradient */
.g-bg-purple-gradient-opacity-v1,
.g-bg-purple-gradient-opacity-v1--after::after {
  @include gradient-directional($start-color: rgba(#B7EBF6, 1), $end-color: rgba(#E461D2, .7), $deg: 150deg);
}

/* Primary Gradient */
.g-bg-primary-gradient-opacity-v1,
.g-bg-primary-gradient-opacity-v1--after::after {
  @include gradient-directional($start-color: rgba(#72c02c, 1), $end-color: rgba(#3398dc, .7), $deg: 150deg);
}

/* Gray Gradient */
.g-bg-gray-gradient-opacity-v1,
.g-bg-gray-gradient-opacity-v1--after::after {
  @include gradient-y-top($start-color: rgba($g-color-gray-light-v5, 1), $end-color: rgba(#fff, 1));
}

/* White Gradient */
.g-bg-white-gradient-opacity-v1,
.g-bg-white-gradient-opacity-v1--after::after {
  @include gradient-y-top($start-color: rgba(#000, .7), $end-color: rgba(#000, 0));
}

/* White Gradient */
.g-bg-white-gradient-opacity-v2,
.g-bg-white-gradient-opacity-v2--after::after {
  @include gradient-y-top($start-color: rgba(#000, 1), $end-color: rgba(#000, .1));
}

/* White Gradient */
.g-bg-white-gradient-opacity-v3,
.g-bg-white-gradient-opacity-v3--after::after {
  @include gradient-x-left($start-color: rgba(#fff, 1), $end-color: rgba(#fff, .3), $start-percent: 30%);
}

/* White Gradient */
.g-bg-white-gradient-opacity-v4,
.g-bg-white-gradient-opacity-v4--after::after {
  @include gradient-x-right($start-color: rgba(#fff, 1), $end-color: rgba(#fff, .3), $start-percent: 30%);
}

/* White Gradient */
.g-bg-white-gradient-opacity-v5,
.g-bg-white-gradient-opacity-v5--after::after {
  @include gradient-x-right($start-color: rgba(#fff, 1), $end-color: rgba(#fff, 0), $start-percent: 47%, $end-percent: 75%);
}

/* Bluegray Gradient */
.g-bg-bluegray-gradient-opacity-v1,
.g-bg-bluegray-gradient-opacity-v1--after::after {
  @include gradient-y-top($start-color: rgba($g-color-bluegray, .2), $end-color: rgba(#000, 0));
}

/* Bluegray Gradient */
.g-bg-bluegray-gradient-opacity-v2,
.g-bg-bluegray-gradient-opacity-v2--after::after {
  @include gradient-y-top($start-color: rgba($g-color-bluegray, .3), $end-color: rgba(#000, 0));
}

/* Bluegray Gradient */
.g-bg-bluegray-gradient-opacity-v3,
.g-bg-bluegray-gradient-opacity-v3--after::after {
  @include gradient-directional($start-color: rgba(#6b7de0, .85), $end-color: rgba(#2A2734, 1), $deg: 150deg);
}

/* Secondary Gradient v1 */
.g-bg-secondary-gradient-v1,
.g-bg-secondary-gradient-v1--after::after {
  @include gradient-y-top($start-color: rgba($g-bg-color-secondary, 0), $end-color: rgba($g-bg-color-secondary, 1));
}

/* Secondary Gradient v2 */
.g-bg-secondary-gradient-v2,
.g-bg-secondary-gradient-v2--after::after {
  @include gradient-y-top($start-color: rgba($g-bg-color-secondary, 1), $end-color: rgba($g-bg-color-secondary, 0));
}

/* Radial Gradients (Ellipse)
------------------------------------*/
/* Lightblue Gradient */
.g-bg-lightblue-radialgradient-ellipse {
  @include gradient-radial-ellipse($inner-color: #fff 22%, $outer-color: #d6e2ee);
}

/* Gray Gradient */
.g-bg-gray-radialgradient-ellipse {
  @include gradient-radial-ellipse($inner-color: #dbdbdb 22%, $outer-color: #bbb);
}

/* Gray Gradient */
.g-bg-graylight-radialgradient-ellipse {
  @include gradient-radial-ellipse($inner-color: #fff 25%, $outer-color: #eee);
}

/*  Radial Gradients (Circle)
------------------------------------*/
/* Lightblue Gradient */
.g-bg-lightblue-radialgradient-circle {
  @include gradient-radial-circle($inner-color: #fff, $outer-color: #d6e2ee, $pos-x: 75%, $pos-y: 95%);
}

/* Blue Gradient */
.g-bg-blue-radialgradient-circle {
  @include gradient-radial-circle($inner-color: #2294d7, $outer-color: #275296, $gradient-size: farthest-side);
}

/* Dark Gray Gradient */
.g-bg-darkgray-radialgradient-circle {
  @include gradient-radial-circle($inner-color: #596070, $outer-color: #31353e, $pos-x: 110%, $pos-y: 0, $gradient-size: farthest-side);
}


//
// Complementary Gradients
//

.g-bg-blue-radialgradient-circle-endless {
  background-image: repeating-radial-gradient(circle at 50% 50%, #b9ecfe, #b9ecfe 10px, #82ddff 10px, #82ddff 20px);
}

.g-bg-gray-verticalstripes-endless {
  background: repeating-linear-gradient(to bottom, #fff, #fff 5px, #eee 5px, #eee 10px);
}
