/*------------------------------------
  Carousel indicators v10
------------------------------------*/

.u-carousel-indicators-v10 {
  padding-left: 0;

  li {
    display: block;
    color: $g-color-black;
    text-transform: uppercase;
    background-color: $g-color-gray-light-v1;
    border-top: {
      width: 0;
      style: solid;
      color: $g-color-white;
    }
    vertical-align: top;
    cursor: pointer;
    padding: 10px;

    & + li {
      border-top-width: 1px;
    }

    &.slick-active {
      color: $g-color-white;
      background-color: $g-color-primary;
    }
  }
}

@media (min-width: $g-sm) {
  .u-carousel-indicators-v10 {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;

    li {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
