/*------------------------------------
  Navigation Behavior (Overlay)
------------------------------------*/

body.u-main-nav--overlay {
	&-left,
	&-right {
		.u-main-nav__overlay {
			overflow: hidden;
		}
	}

	&-right {
		.u-main-nav {
			&--overlay {
				@include px-to-rem(right, -300px);
			}
			&__overlay {
				right: 100%;
			}
		}
		&.u-main-nav--overlay-opened {
			.u-main-nav {
				&--overlay {
					right: 0;
				}
				&__overlay {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
	&-left {
		.u-main-nav {
			&--overlay {
				@include px-to-rem(left, -300px);
			}
			&__overlay {
				left: 100%;
			}
		}
		&.u-main-nav--overlay-opened {
			.u-main-nav {
				&--overlay {
					left: 0;
				}
				&__overlay {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}

.navbar-collapse.u-main-nav--overlay {
	position: fixed;
	top: 0;
	height: 100%;
	z-index: 5;

	margin: 0 !important;
	@include px-to-rem(width, 300px);;
	align-items: flex-start !important;

	.navbar-nav {
		width: 100%;
		flex-direction: column;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.dropdown-menu {
		position: static;
		width: 100%;
	}

	.u-main-nav__list-wrapper {
		margin-left: 0;
		margin-right: 0;
		@include px-to-rem(padding, 30px 10px);
		width: 100%;
		height: 100%;

		.navbar-nav {
			@include px-to-rem(padding, 10px);
		}
	}
}

