.owl-carousel .owl-wrapper,
.owl-carousel .owl-item,
.swiper-wrapper,
.swiper-slide {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.single-slide {
  opacity: 0 !important;

  &.set-position {
    opacity: 1 !important;
  }
}

.js-origin {
  perspective: 1200px;
  perspective-origin: 50% 50%;
}

/* backSlide */
.js-back-slide-out {
  -webkit-animation: backSlideOut 1s both ease;
  -moz-animation: backSlideOut 1s both ease;
  animation: backSlideOut 1s both ease;
}

.js-back-slide-in {
  -webkit-animation: backSlideIn 1s both ease;
  -moz-animation: backSlideIn 1s both ease;
  animation: backSlideIn 1s both ease;
}

.swiper-carousel {
  overflow: hidden;
}

@-webkit-keyframes backSlideOut {
  25% {
    opacity: .5;
    -webkit-transform: translateZ(-400px);
  }
  75% {
    opacity: .5;
    -webkit-transform: translateZ(-400px) translateX(-200%);
  }
  100% {
    opacity: .5;
    -webkit-transform: translateZ(-400px) translateX(-200%);
  }
}

@-moz-keyframes backSlideOut {
  25% {
    opacity: .5;
    -moz-transform: translateZ(-400px);
  }
  75% {
    opacity: .5;
    -moz-transform: translateZ(-400px) translateX(-200%);
  }
  100% {
    opacity: .5;
    -moz-transform: translateZ(-400px) translateX(-200%);
  }
}

@keyframes backSlideOut {
  25% {
    opacity: .5;
    transform: translateZ(-400px);
  }
  75% {
    opacity: .5;
    transform: translateZ(-400px) translateX(-200%);
  }
  100% {
    opacity: .5;
    transform: translateZ(-400px) translateX(-200%);
  }
}

@-webkit-keyframes backSlideIn {
  0%, 25% {
    opacity: .5;
    -webkit-transform: translateZ(-400px) translateX(200%);
  }
  75% {
    opacity: .5;
    -webkit-transform: translateZ(-400px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0) translateX(0);
  }
}

@-moz-keyframes backSlideIn {
  0%, 25% {
    opacity: .5;
    -moz-transform: translateZ(-400px) translateX(200%);
  }
  75% {
    opacity: .5;
    -moz-transform: translateZ(-400px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateZ(0) translateX(0);
  }
}

@keyframes backSlideIn {
  0%, 25% {
    opacity: .5;
    transform: translateZ(-400px) translateX(200%);
  }
  75% {
    opacity: .5;
    transform: translateZ(-400px);
  }
  100% {
    opacity: 1;
    transform: translateZ(0) translateX(0);
  }
}