/*------------------------------------
  Carousels
------------------------------------*/

.u-carousel {
  &-v1 {
    .js-prev,
    .js-next {
      opacity: 0;
      transition: {
        property: opacity;
        duration: .2s;
        timing-function: ease-in;
      }
    }

    &:hover {
      .js-prev,
      .js-next {
        opacity: 1;
      }
    }
  }

  &-v2 {
    .slick {
      &-slide {
        padding: {
          top: 4px;
          bottom: 4px;
        }
        opacity: .5;
        transform: scale(1);
        transition: {
          property: all;
          duration: .4s;
          timing-function: ease-in;
        }
      }

      &-center {
        padding: {
          top: 0;
          bottom: 0;
        }
        opacity: 1;
        transform: scale(1.1);
      }
    }
  }

  &-v3 {
    .slick {
      &-slide {
        opacity: .5;
        transition: {
          property: opacity;
          duration: .4s;
          timing-function: ease-in;
        }
      }

      &-center {
        opacity: 1;
      }
    }
  }

  &-v4 {
    .js-pagination {
      opacity: 0;
      transition: {
        property: opacity;
        duration: .2s;
        timing-function: ease-in;
      }
    }

    &:hover {
      .js-pagination {
        opacity: 1;
      }
    }
  }

  &-v5 {
    .slick-list {
      height: 100%;
    }

    .slick-track {
      display: flex;
      height: 100%;

      .slick-slide {
        display: flex;
        height: auto;
      }
    }
  }

  &-v6 {
    .slick-list.draggable {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}

.u-carousel-v11 .slick-slide {
  opacity: .5;

  &.slick-current {
    opacity: 1;
  }
}


/*------------------------------------
  Slick Carousel v12
------------------------------------*/
.u-carousel-v12 .slick-current .g-opacity-1--active {
  opacity: 1 !important;
}

.u-carousel-v12 .slick-current .u-block-hover__main--grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}