/*------------------------------------
  Carousel indicators v11
------------------------------------*/

.u-carousel-indicators-v11 {
  span {
    display: block;
    width: 12px;
    height: 12px;
    border: 1px solid $g-color-gray-light-v1;
    border-radius: 50%;
    background-color: transparent;
  }

  li {
    margin: 0 3px;

    &.slick-active {
      span {
        background-color: $g-color-gray-light-v1;
      }
    }
  }
}
