/*------------------------------------
  Box-shadows-v24
------------------------------------*/
.u-shadow-v24 {
  box-shadow: 0 15px 20px 0 rgba($g-color-black, .06);
}

.g-parent.active {
  .u-shadow-v24--active {
    box-shadow: 0 15px 20px 0 rgba($g-color-black, .06);
  }
}
