/*------------------------------------
  Effect v2
------------------------------------*/
@import "icon-hover-extend";

.u-icon-effect-v2--hover {
  transition: all .5s;
  overflow: inherit;
}

.u-icon-effect-v2--hover::after {
  @extend %u-icon-hover-effect-after;
  top: -4px;
  left: -4px;
  padding: 4px;
  // background: $g-color-primary;
  z-index: -1;
  transition: all .5s;
}
.u-icon-effect-v2--hover:hover,
.u-icon-effect-v2--hover:hover::after {
  background: $g-color-primary;
}

.u-icon-effect-v2--hover:hover::after,
.u-icon-block--hover:hover .u-icon-effect-v2--hover::after {
  opacity: 0;
  transform: scale(1.5);
}