/*------------------------------------
  Charts
------------------------------------*/

/* Sparkline chart's tooltip */

.jqstooltip {
	width: auto !important;
	height: auto !important;
}
