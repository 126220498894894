/*------------------------------------
  Alignments
------------------------------------*/
.g-valign {
  &-super {
   vertical-align: super !important;
  }
  &-sub {
   vertical-align: sub !important;
  }
  &-top {
   vertical-align: top !important;
  }
  &-middle {
   vertical-align: middle !important;
  }
  &-bottom {
   vertical-align: bottom !important;
  }
}