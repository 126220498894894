/*------------------------------------
  Icon-v1
------------------------------------*/
//
// Sizes
//

// Default (md)
.u-icon-v1,
.u-icon-v1 .u-icon__elem-regular,
.u-icon-v1 .u-icon__elem-hover {
  @include px-to-rem(width, 36px);
  @include px-to-rem(height, 36px);
  @include px-to-rem(font-size, 20px);
}

.u-icon-v1 {
  &.u-icon-size--xs,
  &.u-icon-size--xs .u-icon__elem-regular,
  &.u-icon-size--xs .u-icon__elem-hover {
    @include px-to-rem(width, 23px);
    @include px-to-rem(height, 23px);
    @include px-to-rem(font-size, 13px);
  }
  &.u-icon-size--sm,
  &.u-icon-size--sm .u-icon__elem-regular,
  &.u-icon-size--sm .u-icon__elem-hover {
    @include px-to-rem(width, 32px);
    @include px-to-rem(height, 32px);
    @include px-to-rem(font-size, 18px);
  }
  &.u-icon-size--lg,
  &.u-icon-size--lg .u-icon__elem-regular,
  &.u-icon-size--lg .u-icon__elem-hover {
    @include px-to-rem(width, 63px);
    @include px-to-rem(height, 63px);
    @include px-to-rem(font-size, 35px);
  }
  &.u-icon-size--xl,
  &.u-icon-size--xl .u-icon__elem-regular,
  &.u-icon-size--xl .u-icon__elem-hover {
    @include px-to-rem(width, 72px);
    @include px-to-rem(height, 72px);
    @include px-to-rem(font-size, 40px);
  }
  &.u-icon-size--2xl,
  &.u-icon-size--2xl .u-icon__elem-regular,
  &.u-icon-size--2xl .u-icon__elem-hover {
    @include px-to-rem(width, 82px);
    @include px-to-rem(height, 82px);
    @include px-to-rem(font-size, 50px);
  }
  &.u-icon-size--3xl,
  &.u-icon-size--3xl .u-icon__elem-regular,
  &.u-icon-size--3xl .u-icon__elem-hover {
    @include px-to-rem(width, 112px);
    @include px-to-rem(height, 112px);
    @include px-to-rem(font-size, 80px);
  }
}