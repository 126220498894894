@charset "UTF-8";
/*------------------------------------
  Globals
------------------------------------*/
/* Text */
.g-word-break {
  word-wrap: break-word;
}

/* Puller */
.g-pull-50x-up {
  transform: translateY(-51%);
}

.g-pull-50x-bottom {
  transform: translateY(51%);
}

.g-pull-50x-left {
  transform: translateX(-51%);
}

.g-pull-50x-right {
  transform: translateX(51%);
}

/* Link overlay */
/*------------------------------------
  Background Attachment
------------------------------------*/
.g-bg-attachment-fixed {
  background-attachment: fixed;
}

/*------------------------------------
  Background Gradient Colors
------------------------------------*/
/* Simple Linear Gradients
------------------------------------*/
/* Gray Light v1 */
.g-bg-gray-light-gradient-v1--after::after {
  background: linear-gradient(to bottom, rgba(247, 247, 247, 0.5), #f7f7f7);
}

/* White v1 */
.g-bg-white-gradient-v1--after::after {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.9));
}

/* Linear Gradients
------------------------------------*/
/* Gray Gradient */
.g-bg-gray-lineargradient {
  background-repeat: repeat-x;
  background-image: linear-gradient(180deg, #fff, #dbdbdb);
}

/* Blue Pink Gradient */
.g-bg-bluepink-lineargradient {
  background-repeat: repeat-x;
  background-image: linear-gradient(50deg, #2b7cb6, #f62d51);
}

/* Blue Gradient */
.g-bg-blue-lineargradient {
  background-repeat: repeat-x;
  background-image: linear-gradient(0deg, #73bec7, #4e66b0);
}

.g-bg-blue-lineargradient-v2 {
  background-repeat: repeat-x;
  background-image: linear-gradient(225deg, #429edc, #00629e);
}

.g-bg-blue-lineargradient-v3 {
  background-repeat: repeat-x;
  background-image: linear-gradient(225deg, #3266a2, #98cfbf);
}

.g-bg-blue-lineargradient-v4 {
  background-repeat: repeat-x;
  background-image: linear-gradient(225deg, #5b85df, #3ec2e3);
}

.g-bg-blue-lineargradient-v5 {
  background-repeat: repeat-x;
  background-image: linear-gradient(225deg, #0050c2, #5180fa);
}

/* Orange Gradient */
.g-bg-orange-lineargradient {
  background-repeat: repeat-x;
  background-image: linear-gradient(225deg, #be1715, #ed6d0f);
}

.g-bg-orange-lineargradient-v2 {
  background-repeat: repeat-x;
  background-image: linear-gradient(225deg, #f5cd21, #c22821);
}

/* Yellow Gradient */
.g-bg-yellow-lineargradient {
  background-repeat: repeat-x;
  background-image: linear-gradient(225deg, #f59b00, #f5d400);
}

/* Dark Purple Gradient */
.g-bg-darkpurple-lineargradient {
  background-repeat: repeat-x;
  background-image: linear-gradient(225deg, #0b011d, #330c3c);
}

/* Blue Gray Gradient */
.g-bg-bluegray-lineargradient {
  background-repeat: repeat-x;
  background-image: linear-gradient(225deg, #1d253c, #3e4757);
}

/* Pink Gradient */
.g-bg-pink-lineargradient {
  background-repeat: repeat-x;
  background-image: linear-gradient(225deg, #b6003b, #ed1261);
}

/* Pink-v2 Gradient */
.g-bg-pink-lineargradient-v2 {
  background-image: linear-gradient(to right top, #fc7279 10%, #ff7b9c 65%, #ffc019 125%);
  background-repeat: no-repeat;
}

/* Red Gradient */
.g-bg-red-lineargradient {
  background-repeat: repeat-x;
  background-image: linear-gradient(225deg, #d70417, #9f041b);
}

/* Black Gradient */
.g-bg-black-gradient-opacity-v1,
.g-bg-black-gradient-opacity-v1--after::after {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
  background-repeat: repeat-x;
}

/* Green Gradient */
.g-bg-green-gradient-opacity-v1,
.g-bg-green-gradient-opacity-v1--after::after {
  background-image: linear-gradient(to bottom, rgba(51, 152, 220, 0.5) 0%, rgba(114, 192, 44, 0.8) 100%);
  background-repeat: repeat-x;
}

/* Purple Gradient */
.g-bg-purple-gradient-opacity-v1,
.g-bg-purple-gradient-opacity-v1--after::after {
  background-image: linear-gradient(to bottom, rgba(114, 192, 44, 0.5) 0%, rgba(154, 105, 203, 0.8) 100%);
  background-repeat: repeat-x;
}

/* Pink Light Gradient */
.g-bg-pink-gradient-opacity-v1,
.g-bg-pink-gradient-opacity-v1--after::after {
  background-image: linear-gradient(to bottom, rgba(114, 192, 44, 0.5) 0%, rgba(232, 28, 98, 0.8) 100%);
  background-repeat: repeat-x;
}

/* Cyan Gradient */
.g-bg-cyan-gradient-opacity-v1,
.g-bg-cyan-gradient-opacity-v1--after::after {
  background-image: linear-gradient(to bottom, rgba(0, 0, 153, 0.5) 0%, rgba(0, 190, 214, 0.6) 100%);
  background-repeat: repeat-x;
}

/* Purple Gradient */
.g-bg-purple-gradient-opacity-v1,
.g-bg-purple-gradient-opacity-v1--after::after {
  background-repeat: repeat-x;
  background-image: linear-gradient(150deg, #b7ebf6, rgba(228, 97, 210, 0.7));
}

/* Primary Gradient */
.g-bg-primary-gradient-opacity-v1,
.g-bg-primary-gradient-opacity-v1--after::after {
  background-repeat: repeat-x;
  background-image: linear-gradient(150deg, #72c02c, rgba(51, 152, 220, 0.7));
}

/* Gray Gradient */
.g-bg-gray-gradient-opacity-v1,
.g-bg-gray-gradient-opacity-v1--after::after {
  background-image: linear-gradient(to top, #f7f7f7 0%, white 100%);
  background-repeat: repeat-x;
}

/* White Gradient */
.g-bg-white-gradient-opacity-v1,
.g-bg-white-gradient-opacity-v1--after::after {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
  background-repeat: repeat-x;
}

/* White Gradient */
.g-bg-white-gradient-opacity-v2,
.g-bg-white-gradient-opacity-v2--after::after {
  background-image: linear-gradient(to top, black 0%, rgba(0, 0, 0, 0.1) 100%);
  background-repeat: repeat-x;
}

/* White Gradient */
.g-bg-white-gradient-opacity-v3,
.g-bg-white-gradient-opacity-v3--after::after {
  background-image: linear-gradient(to left, white 30%, rgba(255, 255, 255, 0.3) 100%);
  background-repeat: repeat-x;
}

/* White Gradient */
.g-bg-white-gradient-opacity-v4,
.g-bg-white-gradient-opacity-v4--after::after {
  background-image: linear-gradient(to right, white 30%, rgba(255, 255, 255, 0.3) 100%);
  background-repeat: repeat-x;
}

/* White Gradient */
.g-bg-white-gradient-opacity-v5,
.g-bg-white-gradient-opacity-v5--after::after {
  background-image: linear-gradient(to right, white 47%, rgba(255, 255, 255, 0) 75%);
  background-repeat: repeat-x;
}

/* Bluegray Gradient */
.g-bg-bluegray-gradient-opacity-v1,
.g-bg-bluegray-gradient-opacity-v1--after::after {
  background-image: linear-gradient(to top, rgba(88, 95, 105, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
  background-repeat: repeat-x;
}

/* Bluegray Gradient */
.g-bg-bluegray-gradient-opacity-v2,
.g-bg-bluegray-gradient-opacity-v2--after::after {
  background-image: linear-gradient(to top, rgba(88, 95, 105, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  background-repeat: repeat-x;
}

/* Bluegray Gradient */
.g-bg-bluegray-gradient-opacity-v3,
.g-bg-bluegray-gradient-opacity-v3--after::after {
  background-repeat: repeat-x;
  background-image: linear-gradient(150deg, rgba(107, 125, 224, 0.85), #2a2734);
}

/* Secondary Gradient v1 */
.g-bg-secondary-gradient-v1,
.g-bg-secondary-gradient-v1--after::after {
  background-image: linear-gradient(to top, rgba(250, 250, 250, 0) 0%, #fafafa 100%);
  background-repeat: repeat-x;
}

/* Secondary Gradient v2 */
.g-bg-secondary-gradient-v2,
.g-bg-secondary-gradient-v2--after::after {
  background-image: linear-gradient(to top, #fafafa 0%, rgba(250, 250, 250, 0) 100%);
  background-repeat: repeat-x;
}

/* Radial Gradients (Ellipse)
------------------------------------*/
/* Lightblue Gradient */
.g-bg-lightblue-radialgradient-ellipse {
  background-image: radial-gradient(ellipse farthest-corner at 50% 50%, #fff 22%, #d6e2ee);
  background-repeat: no-repeat;
}

/* Gray Gradient */
.g-bg-gray-radialgradient-ellipse {
  background-image: radial-gradient(ellipse farthest-corner at 50% 50%, #dbdbdb 22%, #bbb);
  background-repeat: no-repeat;
}

/* Gray Gradient */
.g-bg-graylight-radialgradient-ellipse {
  background-image: radial-gradient(ellipse farthest-corner at 50% 50%, #fff 25%, #eee);
  background-repeat: no-repeat;
}

/*  Radial Gradients (Circle)
------------------------------------*/
/* Lightblue Gradient */
.g-bg-lightblue-radialgradient-circle {
  background-image: radial-gradient(circle farthest-corner at 75% 95%, #fff, #d6e2ee);
  background-repeat: no-repeat;
}

/* Blue Gradient */
.g-bg-blue-radialgradient-circle {
  background-image: radial-gradient(circle farthest-side at 50% 50%, #2294d7, #275296);
  background-repeat: no-repeat;
}

/* Dark Gray Gradient */
.g-bg-darkgray-radialgradient-circle {
  background-image: radial-gradient(circle farthest-side at 110% 0, #596070, #31353e);
  background-repeat: no-repeat;
}

.g-bg-blue-radialgradient-circle-endless {
  background-image: repeating-radial-gradient(circle at 50% 50%, #b9ecfe, #b9ecfe 10px, #82ddff 10px, #82ddff 20px);
}

.g-bg-gray-verticalstripes-endless {
  background: repeating-linear-gradient(to bottom, #fff, #fff 5px, #eee 5px, #eee 10px);
}

/*------------------------------------
  Background Colors
------------------------------------*/
/* Basic Colors
------------------------------------*/
/* Main Colors */
.g-bg-main {
  background-color: #fff !important;
}

/* Main Secondary */
.g-bg-secondary {
  background-color: #fafafa !important;
}

/* Primary Colors */
.g-bg-primary {
  background-color: #9900cc !important;
}

.g-bg-primary--hover:hover {
  background-color: #9900cc !important;
}

.u-block-hover:hover .g-bg-primary--hover {
  background-color: #9900cc;
}

.g-parent:hover .g-bg-primary--parent-hover {
  background-color: #9900cc !important;
}

.g-bg-primary--active.active,
.active .g-bg-primary--active {
  background-color: #9900cc !important;
}

.g-parent.active .g-bg-primary--parent-active {
  background-color: #9900cc !important;
}

.g-bg-primary--before::before, .g-bg-primary--after::after {
  background-color: #9900cc !important;
}

.g-bg-primary-dark-v1 {
  background-color: #9100c2 !important;
}

.g-bg-primary-dark-v2 {
  background-color: #8600b3 !important;
}

.g-bg-primary-dark-v3 {
  background-color: #730099 !important;
}

.g-bg-primary-opacity-0_1 {
  background-color: rgba(153, 0, 204, 0.1) !important;
}

.g-bg-primary-opacity-0_2 {
  background-color: rgba(153, 0, 204, 0.2) !important;
}

.g-bg-primary-opacity-0_2--hover:hover {
  background-color: rgba(153, 0, 204, 0.2) !important;
}

.g-bg-primary-opacity-0_2--hover--after:hover::after {
  background-color: rgba(153, 0, 204, 0.2) !important;
}

.active .g-bg-primary-opacity-0_2--active {
  background-color: rgba(153, 0, 204, 0.2) !important;
}

.g-bg-primary-opacity-0_2--before::before, .g-bg-primary-opacity-0_2--after::after {
  background-color: rgba(153, 0, 204, 0.2) !important;
}

.g-bg-primary-opacity-0_3 {
  background-color: rgba(153, 0, 204, 0.3) !important;
}

.g-bg-primary-opacity-0_3--hover:hover {
  background-color: rgba(153, 0, 204, 0.3) !important;
}

.g-bg-primary-opacity-0_3--hover--after:hover::after {
  background-color: rgba(153, 0, 204, 0.3) !important;
}

.active .g-bg-primary-opacity-0_3--active {
  background-color: rgba(153, 0, 204, 0.3) !important;
}

.g-bg-primary-opacity-0_3--before::before, .g-bg-primary-opacity-0_3--after::after {
  background-color: rgba(153, 0, 204, 0.3) !important;
}

.g-bg-primary-opacity-0_4 {
  background-color: rgba(153, 0, 204, 0.4) !important;
}

.g-bg-primary-opacity-0_4--hover:hover {
  background-color: rgba(153, 0, 204, 0.4) !important;
}

.g-bg-primary-opacity-0_4--hover--after:hover::after {
  background-color: rgba(153, 0, 204, 0.4) !important;
}

.g-bg-primary-opacity-0_4--after::after {
  background-color: rgba(153, 0, 204, 0.4) !important;
}

.g-bg-primary-opacity-0_6 {
  background-color: rgba(153, 0, 204, 0.6) !important;
}

.g-bg-primary-opacity-0_6--hover:hover {
  background-color: rgba(153, 0, 204, 0.6) !important;
}

.g-bg-primary-opacity-0_6--hover--after:hover::after {
  background-color: rgba(153, 0, 204, 0.6) !important;
}

.g-bg-primary-opacity-0_6--after::after {
  background-color: rgba(153, 0, 204, 0.6) !important;
}

.g-bg-primary-opacity-0_8 {
  background-color: rgba(153, 0, 204, 0.8) !important;
}

.g-bg-primary-opacity-0_8--hover:hover {
  background-color: rgba(153, 0, 204, 0.8) !important;
}

.g-bg-primary-opacity-0_8--hover--after:hover::after {
  background-color: rgba(153, 0, 204, 0.8) !important;
}

.g-bg-primary-opacity-0_8--before::after, .g-bg-primary-opacity-0_8--after::after {
  background-color: rgba(153, 0, 204, 0.8) !important;
}

.g-bg-primary-opacity-0_8--before--hover:hover::after, .g-bg-primary-opacity-0_8--after--hover:hover::after {
  background-color: rgba(153, 0, 204, 0.8) !important;
}

.g-bg-primary-opacity-0_9 {
  background-color: rgba(153, 0, 204, 0.9) !important;
}

.g-bg-primary-opacity-0_9--hover:hover {
  background-color: rgba(153, 0, 204, 0.9) !important;
}

.g-bg-primary-opacity-0_9--hover--after:hover::after {
  background-color: rgba(153, 0, 204, 0.9) !important;
}

.g-bg-primary-opacity-0_9--before::after, .g-bg-primary-opacity-0_9--after::after {
  background-color: rgba(153, 0, 204, 0.9) !important;
}

.g-bg-primary-opacity-0_9--before--hover:hover::after, .g-bg-primary-opacity-0_9--after--hover:hover::after {
  background-color: rgba(153, 0, 204, 0.9) !important;
}

/* Black Colors */
.g-bg-black {
  background-color: #000 !important;
}

.g-bg-black--hover:hover {
  background-color: #000 !important;
}

.g-bg-black--after::after {
  background-color: #000 !important;
}

.g-bg-black-opacity-0_1 {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.g-bg-black-opacity-0_1--after::after {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.g-bg-black-opacity-0_2 {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.g-bg-black-opacity-0_2--after::after {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.g-bg-black-opacity-0_3 {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.g-bg-black-opacity-0_3--hover:hover {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.g-bg-black-opacity-0_3--after::after, .g-bg-black-opacity-0_3--before::before {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.g-bg-black-opacity-0_3--hover--after:hover::after {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.g-bg-black-opacity-0_4 {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.g-bg-black-opacity-0_4--after::after {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.g-bg-black-opacity-0_5 {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.g-bg-black-opacity-0_5--hover:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.g-bg-black-opacity-0_5--after::after {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.g-bg-black-opacity-0_6 {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.g-bg-black-opacity-0_6--hover:hover {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.g-bg-black-opacity-0_6--after::after {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.g-bg-black-opacity-0_7 {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.g-bg-black-opacity-0_7--hover:hover {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.g-bg-black-opacity-0_7--after::after {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.g-bg-black-opacity-0_8 {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.g-bg-black-opacity-0_8--after::after {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.g-bg-black-opacity-0_8--hover:hover {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.g-bg-black-opacity-0_9 {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.g-bg-black-opacity-0_9--after::after {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.g-bg-black-opacity-0_9--hover:hover {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.u-block-hover:hover .g-bg-black--hover {
  background-color: #000 !important;
}

/* White Colors */
.g-bg-white {
  background-color: #fff !important;
}

.g-bg-white--before::before, .g-bg-white--after::after {
  background-color: #fff !important;
}

.g-bg-white--hover:hover, .g-bg-white--active.active {
  background-color: #fff !important;
}

.g-parent:hover .g-bg-white--parent-hover {
  background-color: #fff !important;
}

.g-bg-white--disabled:disabled {
  background-color: #fff !important;
}

.g-bg-white--before::before, .g-bg-white--after::after {
  background-color: #fff !important;
}

.g-parent:hover .g-bg-white-opacity-0--after--parent-hover::after {
  background-color: rgba(255, 255, 255, 0) !important;
}

.g-bg-white-opacity-0_05 {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.g-bg-white-opacity-0_1 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.g-bg-white-opacity-0_1--after::after {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.g-bg-white-opacity-0_2 {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.g-bg-white-opacity-0_2--hover:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.g-bg-white-opacity-0_3 {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.g-bg-white-opacity-0_3--hover:hover {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.g-bg-white-opacity-0_3--after::after {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.g-bg-white-opacity-0_4 {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.g-bg-white-opacity-0_4--hover:hover {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.g-bg-white-opacity-0_5 {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.g-bg-white-opacity-0_5--after::after {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.g-bg-white-opacity-0_7 {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.g-bg-white-opacity-0_7--hover:hover {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.g-bg-white-opacity-0_7--after::after {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.g-bg-white-opacity-0_8 {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.g-bg-white-opacity-0_8--hover:hover {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.g-bg-white-opacity-0_8--after::after {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.g-bg-white-opacity-0_8--hover--after:hover::after {
  background-color: rgba(153, 0, 204, 0.8) !important;
}

.g-bg-white-opacity-0_9 {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.g-bg-white-opacity-0_9--hover:hover {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.g-bg-white-opacity-0_9--after::after {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

/* Gray Colors */
.g-bg-gray-dark-v1 {
  background-color: #111 !important;
}

.g-bg-gray-dark-v1--hover:hover {
  background-color: #111 !important;
}

.g-bg-gray-dark-v2 {
  background-color: #333 !important;
}

.g-bg-gray-dark-v2--hover:hover {
  background-color: #333 !important;
}

.g-bg-gray-dark-v3 {
  background-color: #555 !important;
}

.g-bg-gray-dark-v3--hover:hover {
  background-color: #555 !important;
}

.g-bg-gray-dark-v4 {
  background-color: #777 !important;
}

.g-bg-gray-dark-v4--hover:hover {
  background-color: #777 !important;
}

.g-bg-gray-dark-v5 {
  background-color: #999 !important;
}

.g-bg-gray-dark-v5--hover:hover {
  background-color: #999 !important;
}

.g-bg-gray-light-v1 {
  background-color: #bbb !important;
}

.g-bg-gray-light-v1--hover:hover {
  background-color: #bbb !important;
}

.g-bg-gray-light-v2 {
  background-color: #ccc !important;
}

.g-bg-gray-light-v2--hover:hover {
  background-color: #ccc !important;
}

.g-bg-gray-light-v3 {
  background-color: #ddd !important;
}

.g-bg-gray-light-v3--hover:hover {
  background-color: #ddd !important;
}

.g-bg-gray-light-v4 {
  background-color: #eee !important;
}

.g-bg-gray-light-v4--hover:hover {
  background-color: #eee !important;
}

.g-bg-gray-light-v5 {
  background-color: #f7f7f7 !important;
}

.g-bg-gray-light-v5--hover:hover {
  background-color: #f7f7f7 !important;
}

.g-bg-gray-light-v5--active.active,
.active .g-bg-gray-light-v5--active {
  background-color: #f7f7f7 !important;
}

/* Transparent */
.g-bg-transparent {
  background-color: transparent !important;
}

.g-bg-transparent--hover:hover {
  background-color: transparent !important;
}

.g-bg-transparent--hover--after:hover::after {
  background-color: transparent !important;
}

.g-parent:hover .g-bg-transparent--parent-hover {
  background-color: transparent !important;
}

/* Complementary Colors
------------------------------------*/
/* Beige Colors */
.g-bg-beige {
  background-color: #e5e1de !important;
}

/* Color Green */
.g-bg-green {
  background-color: #72c02c !important;
}

.g-bg-green-opacity-0_1 {
  background-color: rgba(114, 192, 44, 0.1) !important;
}

.g-bg-green--hover:hover {
  background-color: #72c02c !important;
}

/* Color Blue */
.g-bg-blue {
  background-color: #3398dc !important;
}

.g-bg-blue-opacity-0_1 {
  background-color: rgba(51, 152, 220, 0.1) !important;
}

.g-bg-blue-opacity-0_7 {
  background-color: rgba(51, 152, 220, 0.7) !important;
}

.g-bg-blue-opacity-0_9 {
  background-color: rgba(51, 152, 220, 0.9) !important;
}

.g-bg-blue--hover:hover {
  background-color: #3398dc !important;
}

/* Color Light Blue */
.g-bg-lightblue {
  background-color: #edf2f8 !important;
}

.g-bg-lightblue-opacity-0_1 {
  background-color: rgba(237, 242, 248, 0.1) !important;
}

.g-bg-lightblue-v1 {
  background-color: #d6e2ee !important;
}

.g-bg-lightblue-v1-opacity-0_1 {
  background-color: rgba(214, 226, 238, 0.1) !important;
}

/* Color Dark Blue */
.g-bg-darkblue {
  background-color: #009 !important;
}

.g-bg-darkblue-opacity-0_1 {
  background-color: rgba(0, 0, 153, 0.1) !important;
}

/* Color Indigo */
.g-bg-indigo {
  background-color: #4263a3 !important;
}

.g-bg-indigo-opacity-0_1 {
  background-color: rgba(66, 99, 163, 0.1) !important;
}

/* Color Red */
.g-bg-red {
  background-color: #f00 !important;
}

.g-bg-red-opacity-0_1 {
  background-color: rgba(255, 0, 0, 0.1) !important;
}

.g-bg-red-opacity-0_2 {
  background-color: rgba(255, 0, 0, 0.2) !important;
}

.g-bg-red-opacity-0_5 {
  background-color: rgba(255, 0, 0, 0.5) !important;
}

.g-bg-red-opacity-0_8 {
  background-color: rgba(255, 0, 0, 0.8) !important;
}

.g-bg-red--hover:hover {
  background-color: #f00 !important;
}

/* Color Light Red */
.g-bg-lightred {
  background-color: #e64b3b !important;
}

.g-bg-lightred-opacity-0_1 {
  background-color: rgba(230, 75, 59, 0.1) !important;
}

.g-bg-lightred--hover:hover {
  background-color: #e64b3b !important;
}

/* Color Dark Red */
.g-bg-darkred {
  background-color: #a10f2b !important;
}

.g-bg-darkred-opacity-0_1 {
  background-color: rgba(161, 15, 43, 0.1) !important;
}

/* Color Purple */
.g-bg-purple {
  background-color: #9a69cb !important;
}

.g-bg-purple-opacity-0_1 {
  background-color: rgba(154, 105, 203, 0.1) !important;
}

.g-bg-purple-opacity-0_7 {
  background-color: rgba(154, 105, 203, 0.7) !important;
}

.g-bg-purple-opacity-0_9 {
  background-color: rgba(154, 105, 203, 0.9) !important;
}

.g-bg-purple-opacity-0_9--after::after {
  background-color: rgba(154, 105, 203, 0.9) !important;
}

.g-bg-purple--hover:hover {
  background-color: #9a69cb !important;
}

/* Color Dark Purple */
.g-bg-darkpurple {
  background-color: #6639b6 !important;
}

.g-bg-darkpurple-opacity-0_1 {
  background-color: rgba(102, 57, 182, 0.1) !important;
}

/* Color Pink */
.g-bg-pink {
  background-color: #e81c62 !important;
}

.g-bg-pink-opacity-0_1 {
  background-color: rgba(232, 28, 98, 0.1) !important;
}

.g-bg-pink-opacity-0_9 {
  background-color: rgba(232, 28, 98, 0.9) !important;
}

.g-bg-pink--hover:hover {
  background-color: #e81c62 !important;
}

.g-bg-pink--before::after, .g-bg-pink--after::after {
  background-color: #e81c62 !important;
}

.g-bg-pink--before--hover:hover::after, .g-bg-pink--after--hover:hover::after {
  background-color: #e81c62 !important;
}

/* Color Orange */
.g-bg-orange {
  background-color: #e57d20 !important;
}

.g-bg-orange-opacity-0_1 {
  background-color: rgba(229, 125, 32, 0.1) !important;
}

.g-bg-orange-opacity-0_2 {
  background-color: rgba(229, 125, 32, 0.2) !important;
}

.g-bg-orange--hover:hover {
  background-color: #e57d20 !important;
}

/* Color Deep Orange */
.g-bg-deeporange {
  background-color: #fe541e !important;
}

.g-bg-deeporange-opacity-0_1 {
  background-color: rgba(254, 84, 30, 0.1) !important;
}

.g-bg-deeporange--hover:hover {
  background-color: #fe541e !important;
}

/* Color Yellow */
.g-bg-yellow {
  background-color: #ebc71d !important;
}

.g-bg-yellow-opacity-0_1 {
  background-color: rgba(235, 199, 29, 0.1) !important;
}

.g-bg-yellow-opacity-0_7 {
  background: rgba(235, 199, 29, 0.7) !important;
}

.g-bg-yellow--hover:hover {
  background-color: #ebc71d !important;
}

/* Color Aqua */
.g-bg-aqua {
  background-color: #29d6e6 !important;
}

.g-bg-aqua-opacity-0_1 {
  background-color: rgba(41, 214, 230, 0.1) !important;
}

.g-bg-aqua-opacity-0_9 {
  background-color: rgba(41, 214, 230, 0.9) !important;
}

.g-bg-aqua--hover:hover {
  background-color: #29d6e6 !important;
}

/* Color Cyan */
.g-bg-cyan {
  background-color: #00bed6 !important;
}

.g-bg-cyan-opacity-0_1 {
  background-color: rgba(0, 190, 214, 0.1) !important;
}

.g-bg-cyan-opacity-0_9 {
  background-color: rgba(0, 190, 214, 0.9) !important;
}

.g-bg-cyan-opacity-0_9--after::after {
  background-color: rgba(0, 190, 214, 0.9) !important;
}

.g-bg-cyan--hover:hover {
  background-color: #00bed6 !important;
}

/* Color Teal */
.g-bg-teal {
  background-color: #18ba9b !important;
}

.g-bg-teal-opacity-0_1 {
  background-color: rgba(24, 186, 155, 0.1) !important;
}

.g-bg-teal-opacity-0_9 {
  background-color: rgba(24, 186, 155, 0.9) !important;
}

.g-bg-teal-opacity-0_9--after::after {
  background-color: rgba(24, 186, 155, 0.9) !important;
}

.g-bg-teal--hover:hover {
  background-color: #18ba9b !important;
}

/* Color Brown */
.g-bg-brown {
  background-color: #9c8061 !important;
}

.g-bg-brown-opacity-0_1 {
  background-color: rgba(156, 128, 97, 0.1) !important;
}

.g-bg-brown--hover:hover {
  background-color: #9c8061 !important;
}

/* Color Blue Gray */
.g-bg-bluegray {
  background-color: #585f69 !important;
}

.g-bg-bluegray-opacity-0_1 {
  background-color: rgba(88, 95, 105, 0.1) !important;
}

.g-bg-bluegray-opacity-0_2--before::before, .g-bg-bluegray-opacity-0_2--after::after {
  background-color: rgba(88, 95, 105, 0.2) !important;
}

.g-bg-bluegray-opacity-0_3--before::before, .g-bg-bluegray-opacity-0_3--after::after {
  background-color: rgba(88, 95, 105, 0.3) !important;
}

.g-bg-bluegray-opacity-0_5, .g-bg-bluegray-opacity-0_5--after::after {
  background-color: rgba(88, 95, 105, 0.5) !important;
}

.g-bg-bluegray-opacity-0_7 {
  background-color: rgba(88, 95, 105, 0.7) !important;
}

.g-bg-bluegray--hover:hover {
  background-color: #585f69 !important;
}

.g-grayscale-100x {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  /* Firefox 10+, Firefox on Android */
  filter: gray;
  -webkit-filter: grayscale(100%);
}

.g-parent:hover .g-grayscale-0--parent-hover {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}

.g-grayscale-0--hover:hover {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}

/* O */
@media (min-width: 576px) {
  .g-bg-transparent--sm {
    background-color: transparent !important;
  }
}

/*------------------------------------
  Background Colors Extended
------------------------------------*/
.js-header-change-moment .g-bg-primary--scrolling {
  background-color: #9900cc !important;
}

/*------------------------------------
  Social Background Colors
------------------------------------*/
/* Facebook */
.g-bg-facebook {
  background-color: #3b5998;
}

.u-block-hover:hover .g-bg-facebook--hover, .g-bg-facebook--hover:hover {
  background-color: #344e86 !important;
}

/* Twitter */
.g-bg-twitter {
  background-color: #00acee;
}

.u-block-hover:hover .g-bg-twitter--hover, .g-bg-twitter--hover:hover {
  background-color: #009ad5 !important;
}

/* Skype */
.g-bg-skype {
  background-color: #00aff0;
}

.u-block-hover:hover .g-bg-skype--hover, .g-bg-skype--hover:hover {
  background-color: #009cd7 !important;
}

/* Pinterest */
.g-bg-pinterest {
  background-color: #c8232c;
}

.u-block-hover:hover .g-bg-pinterest--hover, .g-bg-pinterest--hover:hover {
  background-color: #b21f27 !important;
}

/* Vine */
.g-bg-vine {
  background-color: #00bf8f;
}

.u-block-hover:hover .g-bg-vine--hover, .g-bg-vine--hover:hover {
  background-color: #00a67c !important;
}

/* Youtube */
.g-bg-youtube {
  background-color: #c4302b;
}

.u-block-hover:hover .g-bg-youtube--hover, .g-bg-youtube--hover:hover {
  background-color: #af2b26 !important;
}

/* Google plus */
.g-bg-google-plus {
  background-color: #dd4b39;
}

.u-block-hover:hover .g-bg-google-plus--hover, .g-bg-google-plus--hover:hover {
  background-color: #d73925 !important;
}

/* Dribbble */
.g-bg-dribbble {
  background-color: #ea4c89;
}

.u-block-hover:hover .g-bg-dribbble--hover, .g-bg-dribbble--hover:hover {
  background-color: #e7357a !important;
}

/* VK */
.g-bg-vk {
  background-color: #2b587a;
}

.u-block-hover:hover .g-bg-vk--hover, .g-bg-vk--hover:hover {
  background-color: #244a67 !important;
}

/* Linkedin */
.g-bg-linkedin {
  background-color: #0e76a8;
}

.u-block-hover:hover .g-bg-linkedin--hover, .g-bg-linkedin--hover:hover {
  background-color: #0c6590 !important;
}

/* Instagram */
.g-bg-instagram {
  background-color: #3f729b;
}

.u-block-hover:hover .g-bg-instagram--hover, .g-bg-instagram--hover:hover {
  background-color: #386589 !important;
}

/*------------------------------------
  Images Grid
------------------------------------*/
.g-bg-grid-v1--before::before {
  background-image: url(../img/bg/grid/wave.png);
}

.g-bg-grid-v2--before::after {
  background-image: url(../img/bg/grid/tile-left.png);
}

.g-bg-grid-v3--before::after {
  background-image: url(../img/bg/grid/tile-right.png);
}

.g-bg-grid-v4--before::after {
  background-image: url(../img/bg/grid/diagonal-left.png);
}

.g-bg-grid-v5--before::after {
  background-image: url(../img/bg/grid/diagonal-right.png);
}

.g-bg-grid-v6--before::after {
  background-image: url(../img/bg/grid/zigzag-left.png);
}

.g-bg-grid-v7--before::after {
  background-image: url(../img/bg/grid/zigzag-right.png);
}

/*------------------------------------
  Covers
------------------------------------*/
.g-bg-cover {
  position: relative;
}

.g-bg-cover::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.g-bg-cover-v1::after {
  position: absolute;
  top: 0.3571428571rem;
  right: 0.3571428571rem;
  bottom: 0.3571428571rem;
  left: 0.3571428571rem;
}

.g-bg-cover__inner {
  z-index: 1;
  position: relative;
}

/*------------------------------------
  Background Image Style
------------------------------------*/
.g-bg-img-hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/*------------------------------------
  Background Patterns
------------------------------------*/
.g-bg-pattern-green {
  background-image: url("../img/bg/pattern--green.png");
}

/*------------------------------------
  Background Position
------------------------------------*/
.g-bg-pos-center {
  background-position: center;
}

.g-bg-pos-left-center {
  background-position: left center;
}

.g-bg-pos-top-left {
  background-position: top left;
}

.g-bg-pos-top-right {
  background-position: top right;
}

.g-bg-pos-top-center {
  background-position: top center;
}

.g-bg-pos-bottom-center {
  background-position: bottom center;
}

/*------------------------------------
  Background Repeat
------------------------------------*/
.g-bg-no-repeat {
  background-repeat: no-repeat;
}

/*------------------------------------
  Background Size
------------------------------------*/
.g-bg-size-cover {
  background-size: cover !important;
}

.g-bg-size-100x {
  background-size: 100% !important;
}

.g-bg-size-100x--hover:hover {
  background-size: 100% !important;
}

.g-parent:hover .g-bg-size-100x--parent-hover {
  background-size: 100% !important;
}

.g-bg-size-120x {
  background-size: 120% !important;
}

.g-bg-size-120x--hover {
  background-size: 120% !important;
}

.g-parent:hover .g-bg-size-120x--parent-hover {
  background-size: 120% !important;
}

.g-bg-repeat {
  background-repeat: repeat !important;
  /* P */
  background-size: auto !important;
}

/* Primary Colors */
.g-fill-primary {
  fill: #9900cc !important;
}

.g-fill-primary path,
.g-fill-primary polygon {
  fill: #9900cc !important;
}

.g-fill-primary--hover:hover path,
.g-fill-primary--hover:hover polygon {
  fill: #9900cc !important;
}

*:hover > * > .g-fill-primary--hover-parent svg path,
*:hover > * > .g-fill-primary--hover-parent svg polygon {
  fill: #9900cc !important;
}

.g-fill-white {
  fill: #fff !important;
}

.g-fill-white path,
.g-fill-white polygon {
  fill: #fff !important;
}

.g-fill-white--opened-menu:not(.collapsed) path,
.g-fill-white--opened-menu:not(.collapsed) polygon {
  fill: #fff !important;
}

*:not(.collapsed) > .g-fill-white--parent-opened-menu path,
*:not(.collapsed) > .g-fill-white--parent-opened-menu polygon {
  fill: #fff !important;
}

.g-fill-white--hover:hover path,
.g-fill-white--hover:hover polygon {
  fill: #fff !important;
}

*:hover > * > .g-fill-white--hover-parent svg path,
*:hover > * > .g-fill-white--hover-parent svg polygon {
  fill: #fff !important;
}

.g-fill-main {
  fill: #9900cc !important;
}

.g-fill-main path,
.g-fill-main polygon {
  fill: #9900cc !important;
}

.g-fill-main--opened-menu:not(.collapsed) path,
.g-fill-main--opened-menu:not(.collapsed) polygon {
  fill: #9900cc !important;
}

*:not(.collapsed) > .g-fill-main--parent-opened-menu path,
*:not(.collapsed) > .g-fill-main--parent-opened-menu polygon {
  fill: #9900cc !important;
}

.g-fill-main--hover:hover path,
.g-fill-main--hover:hover polygon {
  fill: #9900cc !important;
}

*:hover > * > .g-fill-main--hover-parent svg path,
*:hover > * > .g-fill-main--hover-parent svg polygon {
  fill: #fff !important;
}

.g-fill-gray-light-v4 path,
.g-fill-gray-light-v4 polygon {
  fill: #eee !important;
}

.g-fill-gray-light-v4--hover:hover path,
.g-fill-gray-light-v4--hover:hover polygon {
  fill: #eee !important;
}

*:hover > * > .g-fill-gray-light-v4--hover-parent svg path,
*:hover > * > .g-fill-gray-light-v4--hover-parent svg polygon {
  fill: #eee !important;
}

.g-fill-red path,
.g-fill-red polygon {
  fill: #f00 !important;
}

.g-fill-red--hover:hover path,
.g-fill-red--hover:hover polygon {
  fill: #f00 !important;
}

*:hover > * > .g-fill-red--hover-parent path,
*:hover > * > .g-fill-red--hover-parent polygon {
  fill: #f00 !important;
}

*:hover > .g-fill-red--parent-hover path,
*:hover > .g-fill-red--parent-hover polygon {
  fill: #f00 !important;
}

*:hover > * > * > .g-fill-red--parent-parent-parent-hover path,
*:hover > * > * > .g-fill-red--parent-parent-parent-hover polygon {
  fill: #f00 !important;
}

/*------------------------------------
  Borders Default (solid)
------------------------------------*/
.g-brd-none {
  /* P */
  border: none !important;
}

.g-brd-x {
  border-left: solid 1px transparent !important;
  border-right: solid 1px transparent !important;
}

.g-brd-y {
  border-top: solid 1px transparent !important;
  border-bottom: solid 1px transparent !important;
}

.g-brd-around {
  border: solid 1px transparent !important;
}

.g-brd-top {
  border-top: solid 1px transparent !important;
}

.g-brd-right {
  border-right: solid 1px transparent !important;
}

.g-brd-bottom {
  border-bottom: solid 1px transparent !important;
}

.g-brd-left {
  border-left: solid 1px transparent !important;
}

.g-brd-left-none {
  border-left: none !important;
}

@media (min-width: 576px) {
  .g-brd-around--sm {
    border: solid 1px transparent !important;
  }
  .g-brd-top--sm {
    border-top: solid 1px transparent !important;
  }
  .g-brd-right--sm {
    border-right: solid 1px transparent !important;
  }
  .g-brd-bottom--sm {
    border-bottom: solid 1px transparent !important;
  }
  .g-brd-left--sm {
    border-left: solid 1px transparent !important;
  }
}

@media (min-width: 768px) {
  .g-brd-x--md {
    border-left: solid 1px transparent !important;
    border-right: solid 1px transparent !important;
  }
  .g-brd-around--md {
    border: solid 1px transparent !important;
  }
  .g-brd-top--md {
    border-top: solid 1px transparent !important;
  }
  .g-brd-right--md {
    border-right: solid 1px transparent !important;
  }
  .g-brd-bottom--md {
    border-bottom: solid 1px transparent !important;
  }
  .g-brd-left--md {
    border-left: solid 1px transparent !important;
  }
}

@media (min-width: 992px) {
  /* P */
  .g-brd-around--lg {
    border: solid 1px transparent !important;
  }
  .g-brd-top--lg {
    border-top: solid 1px transparent !important;
  }
  .g-brd-right--lg {
    border-right: solid 1px transparent !important;
  }
  .g-brd-bottom--lg {
    border-bottom: solid 1px transparent !important;
  }
  .g-brd-left--lg {
    border-left: solid 1px transparent !important;
  }
  .g-brd-left-none--lg {
    border-left: none !important;
  }
  .g-brd-x--lg {
    border-left: solid 1px transparent;
    border-right: solid 1px transparent;
  }
  .g-brd-none--lg {
    border: none !important;
  }
}

/*------------------------------------
  Borders None
------------------------------------*/
.g-brd-around-none {
  border-width: 0 !important;
}

.g-brd-y-none {
  border-top: none !important;
  border-bottom: none !important;
}

.g-brd-top-none {
  border-top: none !important;
}

.g-brd-bottom-none {
  border-bottom: none !important;
}

.g-brd-x-none {
  border-left: none !important;
  border-right: none !important;
}

.g-brd-left-none {
  border-left: none !important;
}

.g-brd-right-none {
  border-right: none !important;
}

@media (min-width: 576px) {
  .g-brd-around-none--sm {
    border-width: 0 !important;
  }
  .g-brd-y-none--sm {
    border-top: none !important;
    border-bottom: none !important;
  }
  .g-brd-top-none--sm {
    border-top: none !important;
  }
  .g-brd-bottom-none--sm {
    border-bottom: none !important;
  }
  .g-brd-x-none--sm {
    border-left: none !important;
    border-right: none !important;
  }
  .g-brd-left-none--sm {
    border-left: none !important;
  }
  .g-brd-right-none--sm {
    border-right: none !important;
  }
}

@media (min-width: 768px) {
  .g-brd-around-none--md {
    border-width: 0 !important;
  }
  .g-brd-y-none--md {
    border-top: none !important;
    border-bottom: none !important;
  }
  .g-brd-top-none--md {
    border-top: none !important;
  }
  .g-brd-bottom-none--md {
    border-bottom: none !important;
  }
  .g-brd-x-none--md {
    border-left: none !important;
    border-right: none !important;
  }
  .g-brd-left-none--md {
    border-left: none !important;
  }
  .g-brd-right-none--md {
    border-right: none !important;
  }
}

@media (min-width: 992px) {
  .g-brd-around-none--lg {
    border-width: 0 !important;
  }
  .g-brd-y-none--lg {
    border-top: none !important;
    border-bottom: none !important;
  }
  .g-brd-top-none--lg {
    border-top: none !important;
  }
  .g-brd-bottom-none--lg {
    border-bottom: none !important;
  }
  .g-brd-x-none--lg {
    border-left: none !important;
    border-right: none !important;
  }
  .g-brd-left-none--lg {
    border-left: none !important;
  }
  .g-brd-right-none--lg {
    border-right: none !important;
  }
}

@media (min-width: 1200px) {
  .g-brd-around-none--xl {
    border-width: 0 !important;
  }
  .g-brd-y-none--xl {
    border-top: none !important;
    border-bottom: none !important;
  }
  .g-brd-top-none--xl {
    border-top: none !important;
  }
  .g-brd-bottom-none--xl {
    border-bottom: none !important;
  }
  .g-brd-x-none--xl {
    border-left: none !important;
    border-right: none !important;
  }
  .g-brd-left-none--xl {
    border-left: none !important;
  }
  .g-brd-right-none--xl {
    border-right: none !important;
  }
}

/*------------------------------------
  Border Style
------------------------------------*/
.g-brd-style-solid {
  border-style: solid !important;
}

.g-brd-style-dashed {
  border-style: dashed !important;
}

.g-brd-style-dotted {
  border-style: dotted !important;
}

/*------------------------------------
  Border Radius (Rounded Styles)
------------------------------------*/
/* Rounded Around */
.rounded-0 {
  border-radius: 0 !important;
}

.g-rounded-50x {
  border-radius: 50%;
}

.g-rounded-1 {
  border-radius: 1px !important;
}

.g-rounded-2 {
  border-radius: 2px !important;
}

.g-rounded-3 {
  border-radius: 3px !important;
}

.g-rounded-4 {
  border-radius: 4px !important;
}

.g-rounded-5 {
  border-radius: 5px !important;
}

.g-rounded-6 {
  border-radius: 6px !important;
}

.g-rounded-7 {
  border-radius: 7px !important;
}

.g-rounded-10 {
  border-radius: 10px !important;
}

.g-rounded-15 {
  border-radius: 15px !important;
}

.g-rounded-20 {
  border-radius: 20px !important;
}

.g-rounded-25 {
  border-radius: 25px !important;
}

.g-rounded-30 {
  border-radius: 30px !important;
}

.g-rounded-50 {
  border-radius: 50px !important;
}

.g-rounded-100 {
  border-radius: 100px !important;
}

.g-rounded-100--after::after {
  border-radius: 100px !important;
}

/* Rounded Top */
.g-rounded-top-5 {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

/* Rounded Right */
.g-rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.g-rounded-right-3 {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.g-rounded-right-4 {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.g-rounded-right-5 {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.g-rounded-right-10 {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.g-rounded-right-20 {
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.g-rounded-right-25 {
  border-bottom-right-radius: 25px !important;
  border-top-right-radius: 25px !important;
}

.g-rounded-right-30 {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.g-rounded-right-50 {
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}

/* Rounded Bottom */
.g-rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.g-rounded-bottom-3 {
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.g-rounded-bottom-4 {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.g-rounded-bottom-5 {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

/* Rounded Left */
.g-rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.g-rounded-left-3 {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.g-rounded-left-4 {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.g-rounded-left-5 {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.g-rounded-left-10 {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.g-rounded-left-20 {
  border-bottom-left-radius: 20px !important;
  border-top-left-radius: 20px !important;
}

.g-rounded-left-25 {
  border-bottom-left-radius: 25px !important;
  border-top-left-radius: 25px !important;
}

.g-rounded-left-30 {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

.g-rounded-left-50 {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}

.g-rounded-circle--before::before {
  border-radius: 50% !important;
}

.g-rounded-circle--after::after {
  border-radius: 50% !important;
}

@media (min-width: 768px) {
  .g-rounded-0--md {
    border-radius: 0 !important;
  }
  .g-rounded-left-0--md {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .g-rounded-right-0--md {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

@media (min-width: 992px) {
  .g-rounded-left-5--lg-up {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
  .g-rounded-right-5--lg-up {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}

/*------------------------------------
  Borders Default (dashed)
------------------------------------*/
.g-brd-around--dashed {
  border: dashed 1px transparent;
}

.g-brd-top--dashed {
  border-top: dashed 1px transparent;
}

.g-brd-bottom--dashed {
  border-bottom: dashed 1px transparent;
}

.g-brd-right--dashed {
  border-right: dashed 1px transparent;
}

.g-brd-left--dashed {
  border-left: dashed 1px transparent;
}

@media (min-width: 576px) {
  .g-brd-around--dashed--sm {
    border: dashed 1px transparent;
  }
  .g-brd-top--dashed--sm {
    border-top: dashed 1px transparent;
  }
  .g-brd-bottom--dashed--sm {
    border-bottom: dashed 1px transparent;
  }
  .g-brd-right--dashed--sm {
    border-right: dashed 1px transparent;
  }
  .g-brd-left--dashed--sm {
    border-left: dashed 1px transparent;
  }
}

@media (min-width: 768px) {
  .g-brd-around--dashed--md {
    border: dashed 1px transparent;
  }
  .g-brd-top--dashed--md {
    border-top: dashed 1px transparent;
  }
  .g-brd-bottom--dashed--md {
    border-bottom: dashed 1px transparent;
  }
  .g-brd-right--dashed--md {
    border-right: dashed 1px transparent;
  }
  .g-brd-left--dashed--md {
    border-left: dashed 1px transparent;
  }
}

/*------------------------------------
  Borders Default (dotted)
------------------------------------*/
.g-brd-around--dotted {
  border: dotted 1px transparent;
}

.g-brd-top--dotted {
  border-top: dotted 1px transparent;
}

.g-brd-bottom--dotted {
  border-bottom: dotted 1px transparent;
}

.g-brd-right--dotted {
  border-right: dotted 1px transparent;
}

.g-brd-left--dotted {
  border-left: dotted 1px transparent;
}

@media (min-width: 576px) {
  .g-brd-around--dotted--sm {
    border: dotted 1px transparent;
  }
  .g-brd-top--dotted--sm {
    border-top: dotted 1px transparent;
  }
  .g-brd-bottom--dotted--sm {
    border-bottom: dotted 1px transparent;
  }
  .g-brd-right--dotted--sm {
    border-right: dotted 1px transparent;
  }
  .g-brd-left--dotted--sm {
    border-left: dotted 1px transparent;
  }
}

@media (min-width: 768px) {
  .g-brd-around--dotted--md {
    border: dotted 1px transparent;
  }
  .g-brd-top--dotted--md {
    border-top: dotted 1px transparent;
  }
  .g-brd-bottom--dotted--md {
    border-bottom: dotted 1px transparent;
  }
  .g-brd-right--dotted--md {
    border-right: dotted 1px transparent;
  }
  .g-brd-left--dotted--md {
    border-left: dotted 1px transparent;
  }
}

/*------------------------------------
  Border Width
------------------------------------*/
.g-brd-0 {
  border-width: 0 !important;
}

.g-brd-1 {
  border-width: 1px !important;
}

.g-brd-2 {
  border-width: 2px !important;
}

.g-brd-3 {
  border-width: 3px !important;
}

.g-brd-3--before::before {
  border-width: 3px !important;
}

.g-brd-3--before--active.active::before {
  border-width: 3px !important;
}

.g-brd-4 {
  border-width: 4px !important;
}

.g-brd-5 {
  border-width: 5px !important;
}

.g-brd-6 {
  border-width: 6px !important;
}

.g-brd-7 {
  border-width: 7px !important;
}

.g-brd-10 {
  border-width: 10px !important;
}

.g-brd-12 {
  border-width: 12px !important;
}

.g-brd-top-0 {
  border-top-width: 0 !important;
}

.g-brd-top-2 {
  border-top-width: 2px !important;
}

.g-brd-top-3 {
  border-top-width: 3px !important;
}

.g-brd-top-5 {
  border-top-width: 5px !important;
}

.g-brd-bottom-0 {
  border-bottom-width: 0 !important;
}

.g-brd-bottom-2 {
  border-bottom-width: 2px !important;
}

.g-brd-bottom-2--hover:hover {
  border-bottom-width: 2px !important;
}

.g-parent:hover .g-brd-bottom-2--parent-hover {
  border-bottom-width: 2px !important;
}

.g-brd-bottom-5 {
  border-bottom-width: 5px !important;
}

.g-brd-left-0 {
  border-left-width: 0 !important;
}

.g-brd-left-1 {
  border-left-width: 1px !important;
}

.g-brd-left-2 {
  border-left-width: 2px !important;
}

.g-brd-left-3 {
  border-left-width: 3px !important;
}

.g-brd-left-4 {
  border-left-width: 4px !important;
}

.g-brd-right-0 {
  border-right-width: 0 !important;
}

.g-brd-right-1 {
  border-right-width: 1px !important;
}

.g-brd-right-2 {
  border-right-width: 2px !important;
}

.g-brd-right-3 {
  border-right-width: 3px !important;
}

.g-brd-right-4 {
  border-right-width: 4px !important;
}

@media (min-width: 768px) {
  .g-brd-0--md {
    border-width: 0 !important;
  }
  .g-brd-1--md {
    border-width: 1px !important;
  }
  .g-brd-2--md {
    border-width: 2px !important;
  }
  .g-brd-3--md {
    border-width: 3px !important;
  }
  .g-brd-4--md {
    border-width: 4px !important;
  }
  .g-brd-5--md {
    border-width: 5px !important;
  }
  .g-brd-6--md {
    border-width: 6px !important;
  }
  .g-brd-10--md {
    border-width: 10px !important;
  }
  .g-brd-top-0--md {
    border-top-width: 0 !important;
  }
  .g-brd-top-2--md {
    border-top-width: 2px !important;
  }
  .g-brd-top-3--md {
    border-top-width: 3px !important;
  }
  .g-brd-top-5--md {
    border-top-width: 5px !important;
  }
  .g-brd-bottom-0--md {
    border-bottom-width: 0 !important;
  }
  .g-brd-bottom-2--md {
    border-bottom-width: 2px !important;
  }
  .g-brd-bottom-5--md {
    border-bottom-width: 5px !important;
  }
  .g-brd-left-0--md {
    border-left-width: 0 !important;
  }
  .g-brd-left-1--md {
    border-left-width: 1px !important;
  }
  .g-brd-left-2--md {
    border-left-width: 2px !important;
  }
  .g-brd-left-3--md {
    border-left-width: 3px !important;
  }
  .g-brd-left-4--md {
    border-left-width: 4px !important;
  }
  .g-brd-right-0--md {
    border-right-width: 0 !important;
  }
  .g-brd-right-1--md {
    border-right-width: 1px !important;
  }
}

@media (min-width: 992px) {
  .g-brd-0--lg {
    border-width: 0 !important;
  }
  .g-brd-1--lg {
    border-width: 1px !important;
  }
  .g-brd-top-0--lg {
    border-top-width: 0 !important;
  }
  .g-brd-top-1--lg {
    border-top-width: 1px !important;
  }
  .g-brd-bottom-0--lg {
    border-bottom-width: 0 !important;
  }
  .g-brd-bottom-1--lg {
    border-bottom-width: 1px !important;
  }
  .g-brd-left-0--lg {
    border-left-width: 0 !important;
  }
  .g-brd-left-1--lg {
    border-left-width: 1px !important;
  }
  .g-brd-right-0--lg {
    border-right-width: 0 !important;
  }
  .g-brd-right-1--lg {
    border-right-width: 1px !important;
  }
}

/*------------------------------------
  Border Colors
------------------------------------*/
/* Basic Colors
------------------------------------*/
/* Primary Colors */
.g-brd-primary {
  border-color: #9900cc !important;
}

.g-brd-primary--before::before {
  border-color: #9900cc !important;
}

.g-brd-primary--before--active.active::before {
  border-color: #9900cc !important;
}

.g-brd-primary--hover:hover, .g-brd-primary--active.active {
  border-color: #9900cc !important;
}

.g-brd-primary--active.active,
.active .g-brd-primary--active {
  border-color: #9900cc !important;
}

*:hover > .g-brd-primary--hover-parent {
  border-color: #9900cc !important;
}

.g-brd-primary-opacity-0_3 {
  border-color: rgba(153, 0, 204, 0.3) !important;
}

.g-brd-primary-opacity-0_3--hover:hover, .g-brd-primary-opacity-0_3--active.active {
  border-color: rgba(153, 0, 204, 0.3) !important;
}

*:hover > .g-brd-primary-opacity-0_3--hover-parent {
  border-color: rgba(153, 0, 204, 0.3) !important;
}

.g-brd-primary-dark-dark-v1 {
  border-color: #9100c2 !important;
}

.g-brd-primary-dark-dark-v1--hover:hover, .g-brd-primary-dark-dark-v1--active.active {
  border-color: #9100c2 !important;
}

*:hover > .g-brd-primary-dark-dark-v1--hover-parent {
  border-color: #9100c2 !important;
}

.g-brd-primary-dark-dark-v2 {
  border-color: #8600b3 !important;
}

.g-brd-primary-dark-dark-v2--hover:hover, .g-brd-primary-dark-dark-v2--active.active {
  border-color: #8600b3 !important;
}

*:hover > .g-brd-primary-dark-dark-v2--hover-parent {
  border-color: #8600b3 !important;
}

.g-brd-primary-dark-dark-v3 {
  border-color: #730099 !important;
}

.g-brd-primary-dark-dark-v3--hover:hover, .g-brd-primary-dark-dark-v3--active.active {
  border-color: #730099 !important;
}

*:hover > .g-brd-primary-dark-dark-v3--hover-parent {
  border-color: #730099 !important;
}

.g-brd-bottom-primary--hover:hover {
  border-bottom-color: #9900cc !important;
}

/* Main Colors */
.g-brd-main {
  border-color: #9900cc !important;
}

.g-brd-main--hover:hover {
  border-color: #9900cc !important;
}

/* Black Colors */
.g-brd-black {
  border-color: #000 !important;
}

.g-brd-black--hover:hover, .g-brd-black--active.active {
  border-color: #000 !important;
}

*:hover > .g-brd-black--hover-parent {
  border-color: #000 !important;
}

.g-brd-black-opacity-0_4 {
  border-color: rgba(0, 0, 0, 0.4) !important;
}

/* White Colors */
.g-brd-white {
  border-color: #fff !important;
}

.g-brd-white--hover:hover, .g-brd-white--active.active {
  border-color: #fff !important;
}

*:hover > .g-brd-white--hover-parent {
  border-color: #fff !important;
}

.g-brd-white-opacity-0_1 {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.g-brd-white-opacity-0_1--hover:hover, .g-brd-white-opacity-0_1--active.active {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

*:hover > .g-brd-white-opacity-0_1--hover-parent {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.g-brd-white-opacity-0_2 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.g-brd-white-opacity-0_2--hover:hover, .g-brd-white-opacity-0_2--active.active {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

*:hover > .g-brd-white-opacity-0_2--hover-parent {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.g-brd-white-opacity-0_3 {
  border-color: rgba(255, 255, 255, 0.3) !important;
}

.g-brd-white-opacity-0_3--hover:hover, .g-brd-white-opacity-0_3--active.active {
  border-color: rgba(255, 255, 255, 0.3) !important;
}

*:hover > .g-brd-white-opacity-0_3--hover-parent {
  border-color: rgba(255, 255, 255, 0.3) !important;
}

.g-brd-white-opacity-0_4 {
  border-color: rgba(255, 255, 255, 0.4) !important;
}

.g-brd-white-opacity-0_4--hover:hover, .g-brd-white-opacity-0_4--active.active {
  border-color: rgba(255, 255, 255, 0.4) !important;
}

*:hover > .g-brd-white-opacity-0_4--hover-parent {
  border-color: rgba(255, 255, 255, 0.4) !important;
}

.g-brd-white-opacity-0_5 {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.g-brd-white-opacity-0_5--hover:hover, .g-brd-white-opacity-0_5--active.active {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

*:hover > .g-brd-white-opacity-0_5--hover-parent {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.g-brd-white-opacity-0_6 {
  border-color: rgba(255, 255, 255, 0.6) !important;
}

.g-brd-white-opacity-0_6--hover:hover, .g-brd-white-opacity-0_6--active.active {
  border-color: rgba(255, 255, 255, 0.6) !important;
}

*:hover > .g-brd-white-opacity-0_6--hover-parent {
  border-color: rgba(255, 255, 255, 0.6) !important;
}

.g-brd-white-opacity-0_8 {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

.g-brd-white-opacity-0_8--hover:hover, .g-brd-white-opacity-0_8--active.active {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

*:hover > .g-brd-white-opacity-0_8--hover-parent {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

/* Gray Colors */
.g-brd-gray-dark-v1 {
  border-color: #111 !important;
}

.g-brd-gray-dark-v1--hover:hover, .g-brd-gray-dark-v1--active.active {
  border-color: #111 !important;
}

*:hover > .g-brd-gray-dark-v1--hover-parent {
  border-color: #111 !important;
}

.g-brd-gray-dark-v2 {
  border-color: #333 !important;
}

.g-brd-gray-dark-v2--hover:hover, .g-brd-gray-dark-v2--active.active {
  border-color: #333 !important;
}

*:hover > .g-brd-gray-dark-v2--hover-parent {
  border-color: #333 !important;
}

.g-brd-gray-dark-v3 {
  border-color: #555 !important;
}

.g-brd-gray-dark-v3--hover:hover, .g-brd-gray-dark-v3--active.active {
  border-color: #555 !important;
}

*:hover > .g-brd-gray-dark-v3--hover-parent {
  border-color: #555 !important;
}

.g-brd-gray-dark-v4 {
  border-color: #777 !important;
}

.g-brd-gray-dark-v4--hover:hover, .g-brd-gray-dark-v4--active.active {
  border-color: #777 !important;
}

*:hover > .g-brd-gray-dark-v4--hover-parent {
  border-color: #777 !important;
}

.g-brd-gray-dark-v5 {
  border-color: #999 !important;
}

.g-brd-gray-dark-v5--hover:hover, .g-brd-gray-dark-v5--active.active {
  border-color: #999 !important;
}

*:hover > .g-brd-gray-dark-v5--hover-parent {
  border-color: #999 !important;
}

.g-brd-gray-light-v1 {
  border-color: #bbb !important;
}

.g-brd-gray-light-v1--hover:hover, .g-brd-gray-light-v1--focus:focus, .g-brd-gray-light-v1--active.active {
  border-color: #bbb !important;
}

*:hover > .g-brd-gray-light-v1--hover-parent {
  border-color: #bbb !important;
}

.g-brd-gray-light-v2 {
  border-color: #ccc !important;
}

.g-brd-gray-light-v2--before::before {
  border-color: #ccc !important;
}

.g-brd-gray-light-v2--after::after {
  border-color: #ccc !important;
}

.g-brd-gray-light-v2--hover:hover, .g-brd-gray-light-v2--active.active {
  border-color: #ccc !important;
}

*:hover > .g-brd-gray-light-v2--hover-parent {
  border-color: #ccc !important;
}

.g-brd-gray-light-v3 {
  border-color: #ddd !important;
}

.g-brd-gray-light-v3--hover:hover, .g-brd-gray-light-v3--active.active, .g-brd-gray-light-v3--focus:focus {
  border-color: #ddd !important;
}

*:hover > .g-brd-gray-light-v3--hover-parent {
  border-color: #ddd !important;
}

.g-brd-gray-light-v4 {
  border-color: #eee !important;
}

.g-brd-gray-light-v4--hover:hover, .g-brd-gray-light-v4--active.active {
  border-color: #eee !important;
}

*:hover > .g-brd-gray-light-v4--hover-parent {
  border-color: #eee !important;
}

.g-brd-gray-light-v5 {
  border-color: #f7f7f7 !important;
}

.g-brd-gray-light-v5--hover:hover, .g-brd-gray-light-v5--active.active {
  border-color: #f7f7f7 !important;
}

*:hover > .g-brd-gray-light-v5--hover-parent {
  border-color: #f7f7f7 !important;
}

/* Transparent */
.g-brd-transparent {
  border-color: transparent !important;
}

.g-brd-transparent--before::before {
  border-color: transparent !important;
}

.g-brd-transparent--after::after {
  border-color: transparent !important;
}

.g-brd-transparent--hover:hover, .g-brd-transparent--active.active {
  border-color: transparent !important;
}

*:hover > .g-brd-transparent--hover-parent {
  border-color: transparent !important;
}

/* Complementary Colors
------------------------------------*/
/* Color Green */
.g-brd-green {
  border-color: #72c02c !important;
}

.g-brd-green--hover:hover, .g-brd-green--active.active {
  border-color: #72c02c !important;
}

*:hover > .g-brd-green--hover-parent {
  border-color: #72c02c !important;
}

/* Color Blue */
.g-brd-blue {
  border-color: #3398dc !important;
}

.g-brd-blue--hover:hover, .g-brd-blue--active.active {
  border-color: #3398dc !important;
}

*:hover > .g-brd-blue--hover-parent {
  border-color: #3398dc !important;
}

.g-brd-blue-opacity-0_1 {
  border-color: rgba(51, 152, 220, 0.1) !important;
}

/* Color Light Blue */
.g-brd-lightblue {
  border-color: #edf2f8 !important;
}

.g-brd-lightblue--hover:hover, .g-brd-lightblue--active.active {
  border-color: #edf2f8 !important;
}

*:hover > .g-brd-lightblue--hover-parent {
  border-color: #edf2f8 !important;
}

.g-brd-lightblue-v1 {
  border-color: #d6e2ee !important;
}

.g-brd-lightblue-v1--hover:hover, .g-brd-lightblue-v1--active.active {
  border-color: #d6e2ee !important;
}

*:hover > .g-brd-lightblue-v1--hover-parent {
  border-color: #d6e2ee !important;
}

/* Color Dark Blue */
.g-brd-darkblue {
  border-color: #009 !important;
}

.g-brd-darkblue--hover:hover, .g-brd-darkblue--active.active {
  border-color: #009 !important;
}

*:hover > .g-brd-darkblue--hover-parent {
  border-color: #009 !important;
}

/* Color Indigo */
.g-brd-indigo {
  border-color: #4263a3 !important;
}

.g-brd-indigo--hover:hover, .g-brd-indigo--active.active {
  border-color: #4263a3 !important;
}

*:hover > .g-brd-indigo--hover-parent {
  border-color: #4263a3 !important;
}

/* Color Red */
.g-brd-red {
  border-color: #f00 !important;
}

.g-brd-red--hover:hover, .g-brd-red--active.active {
  border-color: #f00 !important;
}

*:hover > .g-brd-red--hover-parent {
  border-color: #f00 !important;
}

/* Color Light Red */
.g-brd-lightred {
  border-color: #e64b3b !important;
}

.g-brd-lightred--hover:hover, .g-brd-lightred--active.active {
  border-color: #e64b3b !important;
}

*:hover > .g-brd-lightred--hover-parent {
  border-color: #e64b3b !important;
}

/* Color Dark Red */
.g-brd-darkred {
  border-color: #a10f2b !important;
}

.g-brd-darkred--hover:hover, .g-brd-darkred--active.active {
  border-color: #a10f2b !important;
}

*:hover > .g-brd-darkred--hover-parent {
  border-color: #a10f2b !important;
}

/* Color Purple */
.g-brd-purple {
  border-color: #9a69cb !important;
}

.g-brd-purple--hover:hover, .g-brd-purple--active.active {
  border-color: #9a69cb !important;
}

*:hover > .g-brd-purple--hover-parent {
  border-color: #9a69cb !important;
}

.g-brd-purple-opacity-0_1 {
  border-color: rgba(154, 105, 203, 0.1) !important;
}

/* Color Dark Purple */
.g-brd-darkpurple {
  border-color: #6639b6 !important;
}

.g-brd-darkpurple--hover:hover, .g-brd-darkpurple--active.active {
  border-color: #6639b6 !important;
}

*:hover > .g-brd-darkpurple--hover-parent {
  border-color: #6639b6 !important;
}

/* Color Pink */
.g-brd-pink {
  border-color: #e81c62 !important;
}

.g-brd-pink--hover:hover, .g-brd-pink--active.active {
  border-color: #e81c62 !important;
}

*:hover > .g-brd-pink--hover-parent {
  border-color: #e81c62 !important;
}

/* Color Orange */
.g-brd-orange {
  border-color: #e57d20 !important;
}

.g-brd-orange--hover:hover, .g-brd-orange--active.active {
  border-color: #e57d20 !important;
}

*:hover > .g-brd-orange--hover-parent {
  border-color: #e57d20 !important;
}

/* Color Deep Orange */
.g-brd-deeporange {
  border-color: #fe541e !important;
}

.g-brd-deeporange--hover:hover, .g-brd-deeporange--active.active {
  border-color: #fe541e !important;
}

*:hover > .g-brd-deeporange--hover-parent {
  border-color: #fe541e !important;
}

/* Color Yellow */
.g-brd-yellow {
  border-color: #ebc71d !important;
}

.g-brd-yellow--hover:hover, .g-brd-yellow--active.active {
  border-color: #ebc71d !important;
}

*:hover > .g-brd-yellow--hover-parent {
  border-color: #ebc71d !important;
}

/* Color Aqua */
.g-brd-aqua {
  border-color: #29d6e6 !important;
}

.g-brd-aqua--hover:hover, .g-brd-aqua--active.active {
  border-color: #29d6e6 !important;
}

*:hover > .g-brd-aqua--hover-parent {
  border-color: #29d6e6 !important;
}

/* Color Cyan */
.g-brd-cyan {
  border-color: #00bed6 !important;
}

.g-brd-cyan--hover:hover, .g-brd-cyan--active.active {
  border-color: #00bed6 !important;
}

*:hover > .g-brd-cyan--hover-parent {
  border-color: #00bed6 !important;
}

/* Color Teal */
.g-brd-teal {
  border-color: #18ba9b !important;
}

.g-brd-teal--hover:hover, .g-brd-teal--active.active {
  border-color: #18ba9b !important;
}

*:hover > .g-brd-teal--hover-parent {
  border-color: #18ba9b !important;
}

/* Color Brown */
.g-brd-brown {
  border-color: #9c8061 !important;
}

.g-brd-brown--hover:hover, .g-brd-brown--active.active {
  border-color: #9c8061 !important;
}

*:hover > .g-brd-brown--hover-parent {
  border-color: #9c8061 !important;
}

/* Color Blue Gray */
.g-brd-bluegray {
  border-color: #585f69 !important;
}

.g-brd-bluegray--hover:hover, .g-brd-bluegray--active.active {
  border-color: #585f69 !important;
}

*:hover > .g-brd-bluegray--hover-parent {
  border-color: #585f69 !important;
}

/* Primary Colors */
.g-brd-primary-top {
  border-top-color: #9900cc !important;
}

.g-brd-primary-top--before::before {
  border-top-color: #9900cc !important;
}

.g-brd-primary-bottom {
  border-bottom-color: #9900cc !important;
}

.g-brd-primary-bottom--before:before {
  border-bottom-color: #9900cc !important;
}

.g-brd-primary-left {
  border-left-color: #9900cc !important;
}

.g-brd-primary-left--before:before {
  border-left-color: #9900cc !important;
}

.g-brd-primary-right {
  border-right-color: #9900cc !important;
}

.g-brd-primary-right--before:before {
  border-right-color: #9900cc !important;
}

.g-brd-primary-opacity-0_3-top {
  border-top-color: rgba(153, 0, 204, 0.3) !important;
}

.g-brd-primary-opacity-0_3-bottom {
  border-bottom-color: rgba(153, 0, 204, 0.3) !important;
}

.g-brd-primary-opacity-0_3-left {
  border-left-color: rgba(153, 0, 204, 0.3) !important;
}

.g-brd-primary-opacity-0_3-right {
  border-right-color: rgba(153, 0, 204, 0.3) !important;
}

.g-brd-primary-dark-dark-v1-top {
  border-top-color: #9100c2 !important;
}

.g-brd-primary-dark-dark-v1-bottom {
  border-bottom-color: #9100c2 !important;
}

.g-brd-primary-dark-dark-v1-left {
  border-left-color: #9100c2 !important;
}

.g-brd-primary-dark-dark-v1-right {
  border-right-color: #9100c2 !important;
}

.g-brd-primary-dark-dark-v2-top {
  border-top-color: #8600b3 !important;
}

.g-brd-primary-dark-dark-v2-bottom {
  border-bottom-color: #8600b3 !important;
}

.g-brd-primary-dark-dark-v2-left {
  border-left-color: #8600b3 !important;
}

.g-brd-primary-dark-dark-v2-right {
  border-right-color: #8600b3 !important;
}

.g-brd-primary-dark-dark-v3-top {
  border-top-color: #730099 !important;
}

.g-brd-primary-dark-dark-v3-bottom {
  border-bottom-color: #730099 !important;
}

.g-brd-primary-dark-dark-v3-left {
  border-left-color: #730099 !important;
}

.g-brd-primary-dark-dark-v3-right {
  border-right-color: #730099 !important;
}

/* Black Colors */
.g-brd-black-top {
  border-top-color: #000 !important;
}

.g-brd-black-bottom {
  border-bottom-color: #000 !important;
}

.g-brd-black-left {
  border-left-color: #000 !important;
}

.g-brd-black-right {
  border-right-color: #000 !important;
}

/* White */
.g-brd-white-top {
  border-top-color: #fff !important;
}

.g-brd-white-bottom {
  border-bottom-color: #fff !important;
}

.g-brd-white-left {
  border-left-color: #fff !important;
}

.g-brd-white-right {
  border-right-color: #fff !important;
}

.g-brd-white-opacity-0_1-top {
  border-top-color: rgba(255, 255, 255, 0.1) !important;
}

.g-brd-white-opacity-0_1-bottom {
  border-bottom-color: rgba(255, 255, 255, 0.1) !important;
}

.g-brd-white-opacity-0_1-left {
  border-left-color: rgba(255, 255, 255, 0.1) !important;
}

.g-brd-white-opacity-0_1-right {
  border-right-color: rgba(255, 255, 255, 0.1) !important;
}

.g-brd-white-opacity-0_2-top {
  border-top-color: rgba(255, 255, 255, 0.2) !important;
}

.g-brd-white-opacity-0_2-bottom {
  border-bottom-color: rgba(255, 255, 255, 0.2) !important;
}

.g-brd-white-opacity-0_2-left {
  border-left-color: rgba(255, 255, 255, 0.2) !important;
}

.g-brd-white-opacity-0_2-right {
  border-right-color: rgba(255, 255, 255, 0.2) !important;
}

.g-brd-white-opacity-0_3-top {
  border-top-color: rgba(255, 255, 255, 0.3) !important;
}

.g-brd-white-opacity-0_3-bottom {
  border-bottom-color: rgba(255, 255, 255, 0.3) !important;
}

.g-brd-white-opacity-0_3-left {
  border-left-color: rgba(255, 255, 255, 0.3) !important;
}

.g-brd-white-opacity-0_3-right {
  border-right-color: rgba(255, 255, 255, 0.3) !important;
}

.g-brd-white-opacity-0_4-top {
  border-top-color: rgba(255, 255, 255, 0.4) !important;
}

.g-brd-white-opacity-0_4-bottom {
  border-bottom-color: rgba(255, 255, 255, 0.4) !important;
}

.g-brd-white-opacity-0_4-left {
  border-left-color: rgba(255, 255, 255, 0.4) !important;
}

.g-brd-white-opacity-0_4-right {
  border-right-color: rgba(255, 255, 255, 0.4) !important;
}

.g-brd-white-opacity-0_5-top {
  border-top-color: rgba(255, 255, 255, 0.5) !important;
}

.g-brd-white-opacity-0_5-bottom {
  border-bottom-color: rgba(255, 255, 255, 0.5) !important;
}

.g-brd-white-opacity-0_5-left {
  border-left-color: rgba(255, 255, 255, 0.5) !important;
}

.g-brd-white-opacity-0_5-right {
  border-right-color: rgba(255, 255, 255, 0.5) !important;
}

.g-brd-white-opacity-0_6-top {
  border-top-color: rgba(255, 255, 255, 0.6) !important;
}

.g-brd-white-opacity-0_6-bottom {
  border-bottom-color: rgba(255, 255, 255, 0.6) !important;
}

.g-brd-white-opacity-0_6-left {
  border-left-color: rgba(255, 255, 255, 0.6) !important;
}

.g-brd-white-opacity-0_6-right {
  border-right-color: rgba(255, 255, 255, 0.6) !important;
}

/* Gray Colors */
.g-brd-gray-dark-v1-top {
  border-top-color: #111 !important;
}

.g-brd-gray-dark-v1-bottom {
  border-bottom-color: #111 !important;
}

.g-brd-gray-dark-v1-left {
  border-left-color: #111 !important;
}

.g-brd-gray-dark-v1-right {
  border-right-color: #111 !important;
}

.g-brd-gray-dark-v2-top {
  border-top-color: #333 !important;
}

.g-brd-gray-dark-v2-bottom {
  border-bottom-color: #333 !important;
}

.g-brd-gray-dark-v2-left {
  border-left-color: #333 !important;
}

.g-brd-gray-dark-v2-right {
  border-right-color: #333 !important;
}

.g-brd-gray-dark-v3-top {
  border-top-color: #555 !important;
}

.g-brd-gray-dark-v3-bottom {
  border-bottom-color: #555 !important;
}

.g-brd-gray-dark-v3-left {
  border-left-color: #555 !important;
}

.g-brd-gray-dark-v3-right {
  border-right-color: #555 !important;
}

.g-brd-gray-dark-v4-top {
  border-top-color: #777 !important;
}

.g-brd-gray-dark-v4-bottom {
  border-bottom-color: #777 !important;
}

.g-brd-gray-dark-v4-left {
  border-left-color: #777 !important;
}

.g-brd-gray-dark-v4-right {
  border-right-color: #777 !important;
}

.g-brd-gray-dark-v5-top {
  border-top-color: #999 !important;
}

.g-brd-gray-dark-v5-bottom {
  border-bottom-color: #999 !important;
}

.g-brd-gray-dark-v5-left {
  border-left-color: #999 !important;
}

.g-brd-gray-dark-v5-right {
  border-right-color: #999 !important;
}

.g-brd-gray-light-v1-top {
  border-top-color: #bbb !important;
}

.g-brd-gray-light-v1-bottom {
  border-bottom-color: #bbb !important;
}

.g-brd-gray-light-v1-left {
  border-left-color: #bbb !important;
}

.g-brd-gray-light-v1-right {
  border-right-color: #bbb !important;
}

.g-brd-gray-light-v2-top {
  border-top-color: #ccc !important;
}

.g-brd-gray-light-v2-bottom {
  border-bottom-color: #ccc !important;
}

.g-brd-gray-light-v2-left {
  border-left-color: #ccc !important;
}

.g-brd-gray-light-v2-right {
  border-right-color: #ccc !important;
}

.g-brd-gray-light-v3-top {
  border-top-color: #ddd !important;
}

.g-brd-gray-light-v3-bottom {
  border-bottom-color: #ddd !important;
}

.g-brd-gray-light-v3-left {
  border-left-color: #ddd !important;
}

.g-brd-gray-light-v3-right {
  border-right-color: #ddd !important;
}

.g-brd-gray-light-v4-top {
  border-top-color: #eee !important;
}

.g-brd-gray-light-v4-bottom {
  border-bottom-color: #eee !important;
}

.g-brd-gray-light-v4-left {
  border-left-color: #eee !important;
}

.g-brd-gray-light-v4-right {
  border-right-color: #eee !important;
}

.g-brd-gray-light-v5-top {
  border-top-color: #f7f7f7 !important;
}

.g-brd-gray-light-v5-bottom {
  border-bottom-color: #f7f7f7 !important;
}

.g-brd-gray-light-v5-left {
  border-left-color: #f7f7f7 !important;
}

.g-brd-gray-light-v5-right {
  border-right-color: #f7f7f7 !important;
}

/* Transparent */
.g-brd-transparent-top {
  border-top-color: transparent !important;
}

.g-brd-transparent-bottom {
  border-bottom-color: transparent !important;
}

.g-brd-transparent-left {
  border-left-color: transparent !important;
}

.g-brd-transparent-right {
  border-right-color: transparent !important;
}

/* Complementary Colors
------------------------------------*/
/* Color Green */
.g-brd-green-top {
  border-top-color: #72c02c !important;
}

.g-brd-green-bottom {
  border-bottom-color: #72c02c !important;
}

.g-brd-green-left {
  border-left-color: #72c02c !important;
}

.g-brd-green-right {
  border-right-color: #72c02c !important;
}

/* Color Blue */
.g-brd-blue-top {
  border-top-color: #3398dc !important;
}

.g-brd-blue-bottom {
  border-bottom-color: #3398dc !important;
}

.g-brd-blue-left {
  border-left-color: #3398dc !important;
}

.g-brd-blue-right {
  border-right-color: #3398dc !important;
}

/* Color Light Blue */
.g-brd-lightblue-top {
  border-top-color: #edf2f8 !important;
}

.g-brd-lightblue-bottom {
  border-bottom-color: #edf2f8 !important;
}

.g-brd-lightblue-left {
  border-left-color: #edf2f8 !important;
}

.g-brd-lightblue-right {
  border-right-color: #edf2f8 !important;
}

.g-brd-lightblue-v1-top {
  border-top-color: #d6e2ee !important;
}

.g-brd-lightblue-v1-bottom {
  border-bottom-color: #d6e2ee !important;
}

.g-brd-lightblue-v1-left {
  border-left-color: #d6e2ee !important;
}

.g-brd-lightblue-v1-right {
  border-right-color: #d6e2ee !important;
}

/* Color Dark Blue */
.g-brd-darkblue-top {
  border-top-color: #009 !important;
}

.g-brd-darkblue-bottom {
  border-bottom-color: #009 !important;
}

.g-brd-darkblue-left {
  border-left-color: #009 !important;
}

.g-brd-darkblue-right {
  border-right-color: #009 !important;
}

/* Color Indigo */
.g-brd-indigo-top {
  border-top-color: #4263a3 !important;
}

.g-brd-indigo-bottom {
  border-bottom-color: #4263a3 !important;
}

.g-brd-indigo-left {
  border-left-color: #4263a3 !important;
}

.g-brd-indigo-right {
  border-right-color: #4263a3 !important;
}

/* Color Red */
.g-brd-red-top {
  border-top-color: #f00 !important;
}

.g-brd-red-bottom {
  border-bottom-color: #f00 !important;
}

.g-brd-red-left {
  border-left-color: #f00 !important;
}

.g-brd-red-right {
  border-right-color: #f00 !important;
}

/* Color Light Red */
.g-brd-lightred-top {
  border-top-color: #e64b3b !important;
}

.g-brd-lightred-bottom {
  border-bottom-color: #e64b3b !important;
}

.g-brd-lightred-left {
  border-left-color: #e64b3b !important;
}

.g-brd-lightred-right {
  border-right-color: #e64b3b !important;
}

/* Color Dark Red */
.g-brd-darkred-top {
  border-top-color: #a10f2b !important;
}

.g-brd-darkred-bottom {
  border-bottom-color: #a10f2b !important;
}

.g-brd-darkred-left {
  border-left-color: #a10f2b !important;
}

.g-brd-darkred-right {
  border-right-color: #a10f2b !important;
}

/* Color Purple */
.g-brd-purple-top {
  border-top-color: #9a69cb !important;
}

.g-brd-purple-bottom {
  border-bottom-color: #9a69cb !important;
}

.g-brd-purple-left {
  border-left-color: #9a69cb !important;
}

.g-brd-purple-right {
  border-right-color: #9a69cb !important;
}

/* Color Dark Purple */
.g-brd-darkpurple-top {
  border-top-color: #6639b6 !important;
}

.g-brd-darkpurple-bottom {
  border-bottom-color: #6639b6 !important;
}

.g-brd-darkpurple-left {
  border-left-color: #6639b6 !important;
}

.g-brd-darkpurple-right {
  border-right-color: #6639b6 !important;
}

/* Color Pink */
.g-brd-pink-top {
  border-top-color: #e81c62 !important;
}

.g-brd-pink-bottom {
  border-bottom-color: #e81c62 !important;
}

.g-brd-pink-left {
  border-left-color: #e81c62 !important;
}

.g-brd-pink-right {
  border-right-color: #e81c62 !important;
}

/* Color Orange */
.g-brd-orange-top {
  border-top-color: #e57d20 !important;
}

.g-brd-orange-bottom {
  border-bottom-color: #e57d20 !important;
}

.g-brd-orange-left {
  border-left-color: #e57d20 !important;
}

.g-brd-orange-right {
  border-right-color: #e57d20 !important;
}

/* Color Deep Orange */
.g-brd-deeporange-top {
  border-top-color: #fe541e !important;
}

.g-brd-deeporange-bottom {
  border-bottom-color: #fe541e !important;
}

.g-brd-deeporange-left {
  border-left-color: #fe541e !important;
}

.g-brd-deeporange-right {
  border-right-color: #fe541e !important;
}

/* Color Yellow */
.g-brd-yellow-top {
  border-top-color: #ebc71d !important;
}

.g-brd-yellow-bottom {
  border-bottom-color: #ebc71d !important;
}

.g-brd-yellow-left {
  border-left-color: #ebc71d !important;
}

.g-brd-yellow-right {
  border-right-color: #ebc71d !important;
}

/* Color Aqua */
.g-brd-aqua-top {
  border-top-color: #29d6e6 !important;
}

.g-brd-aqua-bottom {
  border-bottom-color: #29d6e6 !important;
}

.g-brd-aqua-left {
  border-left-color: #29d6e6 !important;
}

.g-brd-aqua-right {
  border-right-color: #29d6e6 !important;
}

/* Color Cyan */
.g-brd-cyan-top {
  border-top-color: #00bed6 !important;
}

.g-brd-cyan-bottom {
  border-bottom-color: #00bed6 !important;
}

.g-brd-cyan-left {
  border-left-color: #00bed6 !important;
}

.g-brd-cyan-right {
  border-right-color: #00bed6 !important;
}

/* Color Teal */
.g-brd-teal-top {
  border-top-color: #18ba9b !important;
}

.g-brd-teal-bottom {
  border-bottom-color: #18ba9b !important;
}

.g-brd-teal-left {
  border-left-color: #18ba9b !important;
}

.g-brd-teal-right {
  border-right-color: #18ba9b !important;
}

/* Color Brown */
.g-brd-brown-top {
  border-top-color: #9c8061 !important;
}

.g-brd-brown-bottom {
  border-bottom-color: #9c8061 !important;
}

.g-brd-brown-left {
  border-left-color: #9c8061 !important;
}

.g-brd-brown-right {
  border-right-color: #9c8061 !important;
}

/* Color Blue Gray */
.g-brd-bluegray-top {
  border-top-color: #585f69 !important;
}

.g-brd-bluegray-bottom {
  border-bottom-color: #585f69 !important;
}

.g-brd-bluegray-left {
  border-left-color: #585f69 !important;
}

.g-brd-bluegray-right {
  border-right-color: #585f69 !important;
}

/* O */
.g-brd-primary-top--hover:hover {
  border-top-color: #9900cc !important;
}

.g-brd-primary-top--hover:hover::after {
  border-top-color: #9900cc;
}

.g-brd-primary-top--active.active {
  border-top-color: #9900cc !important;
}

.g-brd-primary-top--active.active::after {
  border-top-color: #9900cc;
}

.g-brd-primary-bottom--hover:hover {
  border-bottom-color: #9900cc !important;
}

.g-brd-primary-bottom--hover:hover::after {
  border-bottom-color: #9900cc;
}

.g-brd-primary-bottom--active.active {
  border-bottom-color: #9900cc !important;
}

.g-brd-primary-bottom--active.active::after {
  border-bottom-color: #9900cc;
}

.g-brd-pinterest {
  border-color: #c8232c !important;
}

.g-brd-pinterest::after {
  border-color: #c8232c !important;
}

.g-brd-pinterest-top--hover:hover {
  border-top-color: #c8232c !important;
}

.g-brd-pinterest-top--hover:hover::after {
  border-top-color: #c8232c;
}

.g-brd-pinterest-top--active.active {
  border-top-color: #c8232c !important;
}

.g-brd-pinterest-top--active.active::after {
  border-top-color: #c8232c;
}

.g-brd-pinterest-bottom--hover:hover {
  border-bottom-color: #c8232c !important;
}

.g-brd-pinterest-bottom--hover:hover::after {
  border-bottom-color: #c8232c;
}

.g-brd-pinterest-bottom--active.active {
  border-bottom-color: #c8232c !important;
}

.g-brd-pinterest-bottom--active.active::after {
  border-bottom-color: #c8232c;
}

/*------------------------------------
  Social Border Colors
------------------------------------*/
/* Facebook */
.g-brd-facebook {
  border-color: #3b5998;
}

.g-brd-facebook--hover:hover {
  border-color: #3b5998 !important;
}

/* Twitter */
.g-brd-twitter {
  border-color: #00acee;
}

.g-brd-twitter--hover:hover {
  border-color: #00acee !important;
}

/* Skype */
.g-brd-skype {
  border-color: #00aff0;
}

.g-brd-skype--hover:hover {
  border-color: #00aff0 !important;
}

/* Pinterest */
.g-brd-pinterest {
  border-color: #c8232c;
}

.g-brd-pinterest--hover:hover {
  border-color: #c8232c !important;
}

/* Vine */
.g-brd-vine {
  border-color: #00bf8f;
}

.g-brd-vine--hover:hover {
  border-color: #00bf8f !important;
}

/* Youtube */
.g-brd-youtube {
  border-color: #c4302b;
}

.g-brd-youtube--hover:hover {
  border-color: #c4302b !important;
}

/* Google plus */
.g-brd-google-plus {
  border-color: #dd4b39;
}

.g-brd-google-plus--hover:hover {
  border-color: #dd4b39 !important;
}

/* Dribbble */
.g-brd-dribbble {
  border-color: #ea4c89;
}

.g-brd-dribbble--hover:hover {
  border-color: #ea4c89 !important;
}

/* VK */
.g-brd-vk {
  border-color: #2b587a;
}

.g-brd-vk--hover:hover {
  border-color: #2b587a !important;
}

/* Linkedin */
.g-brd-linkedin {
  border-color: #0e76a8;
}

.g-brd-linkedin--hover:hover {
  border-color: #0e76a8 !important;
}

/* Instagram */
.g-brd-instagram {
  border-color: #3f729b;
}

.g-brd-instagram--hover:hover {
  border-color: #3f729b !important;
}

/*------------------------------------
  Border Gradient Colors
------------------------------------*/
/* Cyan Gradient */
.g-brd-cyan-gradient-opacity-v1 {
  border-image: linear-gradient(160deg, rgba(0, 0, 153, 0.55) 0%, rgba(0, 190, 214, 0.6) 100%);
  border-image-slice: 1;
}

/*------------------------------------
  Typography Font Family
------------------------------------*/
.g-font-secondary {
  font-family: "Roboto Slab", Helvetica, Arial, sans-serif;
}

.g-font-code {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.g-font-niconne {
  font-family: "Niconne", cursive;
}

/*------------------------------------
  Typography Font Size
------------------------------------*/
.g-font-size-default {
  font-size: 1rem !important;
}

.g-font-size-70x {
  font-size: 70% !important;
}

.g-font-size-75x {
  font-size: 75% !important;
}

.g-font-size-80x {
  font-size: 80% !important;
}

.g-font-size-85x {
  font-size: 85% !important;
}

.g-font-size-90x {
  font-size: 90% !important;
}

.g-font-size-95x {
  font-size: 95% !important;
}

.g-font-size-0 {
  font-size: 0;
}

.g-font-size-5 {
  font-size: 0.3571428571rem !important;
}

.g-font-size-8 {
  font-size: 0.5714285714rem !important;
}

.g-font-size-9 {
  font-size: 0.6428571429rem !important;
}

.g-font-size-10 {
  font-size: 0.7142857143rem !important;
}

.g-font-size-11 {
  font-size: 0.7857142857rem !important;
}

.g-font-size-12 {
  font-size: 0.8571428571rem !important;
}

.g-font-size-13 {
  font-size: 0.9285714286rem !important;
}

.g-font-size-14 {
  font-size: 1rem !important;
}

.g-font-size-15 {
  font-size: 1.0714285714rem !important;
}

.g-font-size-16 {
  font-size: 1.1428571429rem !important;
}

.g-font-size-17 {
  font-size: 1.2142857143rem !important;
}

.g-font-size-18 {
  font-size: 1.2857142857rem !important;
}

.g-font-size-20 {
  font-size: 1.4285714286rem !important;
}

.g-font-size-22 {
  font-size: 1.5714285714rem !important;
}

.g-font-size-23 {
  font-size: 1.6428571429rem !important;
}

.g-font-size-24 {
  font-size: 1.7142857143rem !important;
}

.g-font-size-25 {
  font-size: 1.7857142857rem !important;
}

.g-font-size-26 {
  font-size: 1.8571428571rem !important;
}

.g-font-size-27 {
  font-size: 1.9285714286rem !important;
}

.g-font-size-28 {
  font-size: 2rem !important;
}

.g-font-size-30 {
  font-size: 2.1428571429rem !important;
}

.g-font-size-32 {
  font-size: 2.2857142857rem !important;
}

.g-font-size-33 {
  font-size: 2.3571428571rem !important;
}

.g-font-size-35 {
  font-size: 2.5rem !important;
}

.g-font-size-36 {
  font-size: 2.5714285714rem !important;
}

.g-font-size-38 {
  font-size: 2.7142857143rem !important;
}

.g-font-size-40 {
  font-size: 2.8571428571rem !important;
}

.g-font-size-42 {
  font-size: 3rem !important;
}

.g-font-size-45 {
  font-size: 3.2142857143rem !important;
}

.g-font-size-46 {
  font-size: 3.2857142857rem !important;
}

.g-font-size-48 {
  font-size: 3.4285714286rem !important;
}

.g-font-size-50 {
  font-size: 3.5714285714rem !important;
}

.g-font-size-55 {
  font-size: 3.9285714286rem !important;
}

.g-font-size-56 {
  font-size: 4rem !important;
}

.g-font-size-60 {
  font-size: 4.2857142857rem !important;
}

.g-font-size-65 {
  font-size: 4.6428571429rem !important;
}

.g-font-size-70 {
  line-height: 70px;
  font-size: 5rem !important;
}

.g-font-size-75 {
  font-size: 5.3571428571rem !important;
}

.g-font-size-76 {
  font-size: 5.4285714286rem !important;
}

.g-font-size-80 {
  font-size: 5.7142857143rem !important;
}

.g-font-size-86 {
  font-size: 6.1428571429rem !important;
}

.g-font-size-90 {
  font-size: 6.4285714286rem !important;
}

.g-font-size-120 {
  font-size: 8.5714285714rem !important;
}

.g-font-size-200 {
  line-height: 210px;
  font-size: 14.2857142857rem !important;
}

.g-font-size-180 {
  font-size: 12.8571428571rem !important;
}

@media (min-width: 576px) {
  .g-font-size-20--sm {
    font-size: 1.4285714286rem !important;
  }
  .g-font-size-25--sm {
    font-size: 1.7857142857rem !important;
  }
  .g-font-size-40--sm {
    font-size: 2.8571428571rem !important;
  }
  .g-font-size-50--sm {
    font-size: 3.5714285714rem !important;
  }
  .g-font-size-240--sm {
    font-size: 17.1428571429rem !important;
  }
}

@media (min-width: 768px) {
  .g-font-size-default--md {
    font-size: 1rem !important;
  }
  .g-font-size-12--md {
    font-size: 0.8571428571rem !important;
  }
  .g-font-size-16--md {
    font-size: 1.1428571429rem !important;
  }
  .g-font-size-18--md {
    font-size: 1.2857142857rem !important;
  }
  .g-font-size-20--md {
    font-size: 1.4285714286rem !important;
  }
  .g-font-size-24--md {
    font-size: 1.7142857143rem !important;
  }
  .g-font-size-25--md {
    font-size: 1.7857142857rem !important;
  }
  .g-font-size-26--md {
    font-size: 1.8571428571rem !important;
  }
  .g-font-size-27--md {
    font-size: 1.9285714286rem !important;
  }
  .g-font-size-28--md {
    font-size: 2rem !important;
  }
  .g-font-size-30--md {
    font-size: 2.1428571429rem !important;
  }
  .g-font-size-32--md {
    font-size: 2.2857142857rem !important;
  }
  .g-font-size-35--md {
    font-size: 2.5rem !important;
  }
  .g-font-size-36--md {
    font-size: 2.5714285714rem !important;
  }
  .g-font-size-40--md {
    font-size: 2.8571428571rem !important;
  }
  .g-font-size-45--md {
    font-size: 3.2142857143rem !important;
  }
  .g-font-size-46--md {
    font-size: 3.2857142857rem !important;
  }
  .g-font-size-48--md {
    font-size: 3.4285714286rem !important;
  }
  .g-font-size-50--md {
    font-size: 3.5714285714rem !important;
  }
  .g-font-size-55--md {
    font-size: 3.9285714286rem !important;
  }
  .g-font-size-56--md {
    font-size: 4rem !important;
  }
  .g-font-size-60--md {
    font-size: 4.2857142857rem !important;
  }
  .g-font-size-65--md {
    font-size: 4.6428571429rem !important;
  }
  .g-font-size-70--md {
    font-size: 5rem !important;
  }
  .g-font-size-75--md {
    font-size: 5.3571428571rem !important;
  }
  .g-font-size-76--md {
    font-size: 5.4285714286rem !important;
  }
  .g-font-size-90--md {
    font-size: 6.4285714286rem !important;
  }
  .g-font-size-130--md {
    font-size: 9.2857142857rem !important;
  }
}

@media (min-width: 992px) {
  .g-font-size-default--lg {
    font-size: 1rem !important;
  }
  .g-font-size-16--lg {
    font-size: 1.1428571429rem !important;
  }
  .g-font-size-18--lg {
    font-size: 1.2857142857rem !important;
  }
  .g-font-size-26--lg {
    font-size: 1.8571428571rem !important;
  }
  .g-font-size-32--lg {
    font-size: 2.2857142857rem !important;
  }
  .g-font-size-35--lg {
    font-size: 2.5rem !important;
  }
  .g-font-size-36--lg {
    font-size: 2.5714285714rem !important;
  }
  .g-font-size-42--lg {
    font-size: 3rem !important;
  }
  .g-font-size-60--lg {
    font-size: 4.2857142857rem !important;
  }
  .g-font-size-75--lg {
    font-size: 5.3571428571rem !important;
  }
  .g-font-size-76--lg {
    font-size: 5.4285714286rem !important;
  }
  .g-font-size-420--lg {
    font-size: 30rem !important;
  }
}

@media (min-width: 1200px) {
  .g-font-size-17--xl {
    font-size: 1.2142857143rem !important;
  }
}

@media (max-width: 576px - 1) {
  .g-font-size-25 {
    font-size: 1.7857142857rem !important;
  }
}

/*------------------------------------
  Typography Font Weight
------------------------------------*/
.g-font-weight-100 {
  font-weight: 100 !important;
}

.g-font-weight-200 {
  font-weight: 200 !important;
}

.g-font-weight-300 {
  font-weight: 300 !important;
}

.g-font-weight-400 {
  font-weight: 400 !important;
}

.g-font-weight-500 {
  font-weight: 500 !important;
}

.g-font-weight-600 {
  font-weight: 600 !important;
}

.g-font-weight-700 {
  font-weight: 700 !important;
}

.g-font-weight-800 {
  font-weight: 800 !important;
}

.g-font-weight-900 {
  font-weight: 900 !important;
}

@media (min-width: 768px) {
  .g-font-weight-100--md {
    font-weight: 100 !important;
  }
  .g-font-weight-200--md {
    font-weight: 200 !important;
  }
  .g-font-weight-300--md {
    font-weight: 300 !important;
  }
  .g-font-weight-400--md {
    font-weight: 400 !important;
  }
  .g-font-weight-500--md {
    font-weight: 500 !important;
  }
  .g-font-weight-600--md {
    font-weight: 600 !important;
  }
  .g-font-weight-700--md {
    font-weight: 700 !important;
  }
  .g-font-weight-800--md {
    font-weight: 800 !important;
  }
  .g-font-weight-900--md {
    font-weight: 900 !important;
  }
}

/*------------------------------------
  Typography Text Transform
------------------------------------*/
.g-text-transform-none {
  text-transform: none !important;
}

/*------------------------------------
  Typography Text Decoration
------------------------------------*/
.g-text-underline {
  text-decoration: underline;
}

.g-text-underline--none--hover:focus, .g-text-underline--none--hover:hover {
  text-decoration: none;
}

.g-text-strike {
  text-decoration: line-through;
}

/*------------------------------------
  Typography Letter Spacing
------------------------------------*/
.g-letter-spacing-minus-2 {
  letter-spacing: -0.1428571429rem;
}

.g-letter-spacing-0_5 {
  letter-spacing: 0.0357142857rem;
}

.g-letter-spacing-1_5 {
  letter-spacing: 0.1071428571rem;
}

.g-letter-spacing-0 {
  letter-spacing: 0px;
}

.g-letter-spacing-1 {
  letter-spacing: 0.0714285714rem;
}

.g-letter-spacing-2 {
  letter-spacing: 0.1428571429rem;
}

.g-letter-spacing-3 {
  letter-spacing: 0.2142857143rem;
}

.g-letter-spacing-4 {
  letter-spacing: 0.2857142857rem;
}

.g-letter-spacing-5 {
  letter-spacing: 0.3571428571rem;
}

.g-letter-spacing-6 {
  letter-spacing: 0.4285714286rem;
}

.g-letter-spacing-7 {
  letter-spacing: 0.5rem;
}

.g-letter-spacing-8 {
  letter-spacing: 0.5714285714rem;
}

.g-letter-spacing-9 {
  letter-spacing: 0.6428571429rem;
}

.g-letter-spacing-10 {
  letter-spacing: 0.7142857143rem;
}

.g-letter-spacing-11 {
  letter-spacing: 0.7857142857rem;
}

.g-letter-spacing-12 {
  letter-spacing: 0.8571428571rem;
}

/*------------------------------------
  Typography Line Height
------------------------------------*/
.g-line-height-0 {
  line-height: 0 !important;
}

.g-line-height-0_7 {
  line-height: .7 !important;
}

.g-line-height-0_8 {
  line-height: .8 !important;
}

.g-line-height-0_9 {
  line-height: .9 !important;
}

.g-line-height-1 {
  line-height: 1 !important;
}

.g-line-height-1_1 {
  line-height: 1.1 !important;
}

.g-line-height-1_2 {
  line-height: 1.2 !important;
}

.g-line-height-1_3 {
  line-height: 1.3 !important;
}

.g-line-height-1_4 {
  line-height: 1.4 !important;
}

.g-line-height-1_5 {
  line-height: 1.5 !important;
}

.g-line-height-1_6 {
  line-height: 1.6 !important;
}

.g-line-height-1_8 {
  line-height: 1.8 !important;
}

.g-line-height-2 {
  line-height: 2 !important;
}

/*------------------------------------
  Typography Font Style
------------------------------------*/
.g-font-style-normal {
  font-style: normal;
}

.g-font-style-italic {
  font-style: italic;
}

/*------------------------------------
  List Style Types
------------------------------------*/
.g-list-style-circle {
  list-style-type: circle;
}

.g-list-style-disc {
  list-style-type: disc;
}

.g-list-style-square {
  list-style-type: square;
}

.g-list-style-lower-roman {
  list-style-type: lower-roman;
}

.g-list-style-upper-roman {
  list-style-type: upper-roman;
}

.g-list-style-lower-latin {
  list-style-type: lower-latin;
}

.g-list-style-upper-latin {
  list-style-type: upper-latin;
}

/*------------------------------------
  Text Types
------------------------------------*/
.g-text-break-word {
  word-wrap: break-word;
}

/*------------------------------------
  Quotes
------------------------------------*/
/* Quote v1 */
.u-quote-v1::before {
  content: "\“";
  font-size: 36px;
  line-height: 0.75em;
  text-align: center;
  font-weight: 600;
  display: block;
}

.u-quote-v1::after {
  display: none;
}

/*------------------------------------
  Positions
------------------------------------*/
.g-pos-rel {
  position: relative !important;
}

.g-pos-abs {
  position: absolute !important;
}

.g-pos-stc {
  position: static !important;
}

.g-pos-fix {
  position: fixed !important;
}

@media (min-width: 576px) {
  .g-pos-rel--sm {
    position: relative !important;
  }
  .g-pos-fix--sm {
    position: fixed !important;
  }
  .g-pos-abs--sm {
    position: absolute !important;
  }
  .g-pos-stc--sm {
    position: static !important;
  }
}

@media (min-width: 768px) {
  .g-pos-rel--md {
    position: relative !important;
  }
  .g-pos-fix--md {
    position: fixed !important;
  }
  .g-pos-abs--md {
    position: absolute !important;
  }
  .g-pos-stc--sm {
    position: static !important;
  }
}

@media (min-width: 992px) {
  .g-pos-rel--lg {
    position: relative !important;
  }
  .g-pos-fix--lg {
    position: fixed !important;
  }
  .g-pos-abs--lg {
    position: absolute !important;
  }
  .g-pos-stc--lg {
    position: static !important;
  }
}

/*------------------------------------
  Position Spaces
------------------------------------*/
/* Top */
.g-top-auto {
  /* P */
  top: auto;
}

.g-parent:hover .g-top-auto--parent-hover {
  top: auto;
}

.g-top-0 {
  top: 0 !important;
}

.g-top-0--hover:hover {
  top: 0 !important;
}

.g-parent:hover .g-top-0--parent-hover {
  top: 0 !important;
}

.g-top-1 {
  top: 0.0714285714rem;
}

.g-top-2 {
  top: 0.1428571429rem;
}

.g-top-3 {
  top: 0.2142857143rem;
}

.g-top-5 {
  top: 0.3571428571rem !important;
}

.g-top-7 {
  top: 0.5rem !important;
}

.g-top-10 {
  top: 0.7142857143rem !important;
}

.g-top-12 {
  top: 0.8571428571rem !important;
}

.g-top-15 {
  top: 1.0714285714rem;
}

.g-top-18 {
  top: 1.2857142857rem;
}

.g-top-20 {
  top: 1.4285714286rem;
}

.g-top-25 {
  top: 1.7857142857rem;
}

.g-top-30 {
  top: 2.1428571429rem;
}

.g-top-35 {
  top: 2.5rem;
}

.g-top-55 {
  top: 3.9285714286rem;
}

.g-top-65 {
  top: 4.6428571429rem;
}

.g-top-100 {
  top: 7.1428571429rem;
}

.g-top-15x {
  top: 15%;
}

.g-top-20x {
  top: 20%;
}

.g-top-25x {
  top: 25%;
}

.g-top-30x {
  top: 30%;
}

.g-top-35x {
  top: 35%;
}

.g-top-50x {
  top: 50%;
}

.g-top-100x {
  top: 100%;
}

/* Top Minis */
.g-top-minus-1 {
  top: -0.0714285714rem;
}

.g-top-minus-2 {
  top: -0.1428571429rem;
}

.g-top-minus-3 {
  top: -0.2142857143rem !important;
}

.g-top-minus-4 {
  top: -0.2857142857rem;
}

.g-top-minus-5 {
  top: -0.3571428571rem;
}

.g-top-minus-6 {
  top: -0.4285714286rem;
}

.g-top-minus-8 {
  top: -0.5714285714rem;
}

.g-top-minus-10 {
  top: -0.7142857143rem;
}

.g-top-minus-15 {
  top: -1.0714285714rem;
}

.g-top-minus-20 {
  top: -1.4285714286rem;
}

.g-top-minus-30 {
  top: -2.1428571429rem;
}

.g-top-minus-35 {
  top: -2.5rem;
}

.g-top-minus-40 {
  top: -2.8571428571rem;
}

.g-top-minus-70 {
  top: -5rem;
}

.g-top-minus-120 {
  top: -8.5714285714rem;
}

.g-top-minus-25x {
  top: -25%;
}

.g-top-minus-80x {
  top: -80%;
}

/* Left */
.g-left-auto {
  /* P */
  left: auto !important;
}

.g-left-0 {
  left: 0;
}

.g-left-0--hover:hover {
  left: 0;
}

.g-parent:hover .g-left-0--parent-hover {
  left: 0;
}

.g-left-2 {
  left: 0.1428571429rem;
}

.g-left-5 {
  left: 0.3571428571rem;
}

.g-left-10 {
  left: 0.7142857143rem;
}

.g-left-15 {
  left: 1.0714285714rem !important;
}

.g-left-20 {
  left: 1.4285714286rem;
}

.g-left-30 {
  left: 2.1428571429rem;
}

.g-left-40 {
  left: 2.8571428571rem;
  /* O */
}

.g-left-45 {
  left: 3.2142857143rem;
  /* P */
}

.g-left-75 {
  left: 5.3571428571rem;
}

.g-left-110 {
  left: 7.8571428571rem;
}

.g-left-130 {
  left: 9.2857142857rem;
}

.g-left-200 {
  left: 14.2857142857rem;
}

.g-left-15x {
  left: 15%;
}

.g-left-35x {
  left: 35%;
}

.g-left-50x {
  left: 50%;
}

.g-left-100x {
  left: 100%;
}

/* Left Minus */
.g-left-minus-3 {
  left: -0.2142857143rem;
}

.g-left-minus-6 {
  left: -0.4285714286rem;
}

.g-left-minus-7 {
  left: -0.5rem;
}

.g-left-minus-10 {
  left: -0.7142857143rem;
}

.g-left-minus-15 {
  left: -1.0714285714rem;
}

.g-left-minus-20 {
  left: -1.4285714286rem;
}

.g-left-minus-25 {
  left: -1.7857142857rem;
}

.g-left-minus-30 {
  left: -2.1428571429rem;
}

.g-left-minus-40 {
  left: -2.8571428571rem;
}

.g-left-minus-50 {
  left: -3.5714285714rem;
}

.g-parent:hover .g-left-minus-50--parent-hover {
  left: -3.5714285714rem;
}

/* Right */
.g-right-auto {
  /* P */
  right: auto;
}

.g-right-0 {
  right: 0 !important;
}

.g-right-0--hover:hover {
  right: 0 !important;
}

.g-parent:hover .g-right-0--parent-hover {
  right: 0 !important;
}

.g-right-5 {
  right: 0.3571428571rem !important;
}

.g-right-7 {
  right: 0.5rem !important;
}

.g-right-10 {
  right: 0.7142857143rem !important;
}

.g-right-12 {
  right: 0.8571428571rem !important;
}

.g-right-14 {
  right: 1rem !important;
}

.g-right-15 {
  right: 1.0714285714rem !important;
}

.g-right-20 {
  right: 1.4285714286rem;
}

.g-right-30 {
  right: 2.1428571429rem;
}

.g-right-40 {
  right: 2.8571428571rem;
}

.g-right-45 {
  right: 3.2142857143rem;
  /* P */
}

.g-right-55 {
  right: 3.9285714286rem;
  /* O */
}

.g-right-65 {
  right: 4.6428571429rem;
}

.g-right-100 {
  right: 7.1428571429rem;
}

.g-right-110 {
  right: 7.8571428571rem;
}

.g-right-130 {
  right: 9.2857142857rem;
}

.g-right-15x {
  right: 15%;
}

.g-right-35x {
  right: 35%;
}

.g-right-50x {
  right: 50%;
}

.g-right-100x {
  right: 100%;
}

/* Right Minus */
.g-right-minus-3 {
  right: -0.2142857143rem !important;
}

.g-right-minus-6 {
  right: -0.4285714286rem;
}

.g-right-minus-5 {
  right: -0.3571428571rem;
}

.g-right-minus-10 {
  right: -0.7142857143rem;
}

.g-right-minus-13 {
  right: -0.9285714286rem;
}

.g-right-minus-15 {
  right: -1.0714285714rem;
}

.g-right-minus-20 {
  right: -1.4285714286rem;
}

.g-right-minus-25 {
  right: -1.7857142857rem;
}

.g-right-minus-40 {
  right: -2.8571428571rem;
}

.g-right-minus-50 {
  right: -3.5714285714rem;
}

/* Bottom */
.g-bottom-auto {
  /* P */
  bottom: auto;
}

.g-bottom-0 {
  bottom: 0;
}

.g-bottom-0--hover:hover {
  bottom: 0;
}

.g-parent:hover .g-bottom-0--parent-hover {
  bottom: 0;
}

.g-bottom-6 {
  bottom: 0.4285714286rem;
}

.g-bottom-10 {
  bottom: 0.7142857143rem;
}

.g-bottom-20 {
  bottom: 1.4285714286rem;
}

.g-bottom-30 {
  bottom: 2.1428571429rem;
}

.g-bottom-40 {
  bottom: 2.8571428571rem;
}

.g-bottom-50 {
  bottom: 3.5714285714rem;
}

.g-parent:hover .g-bottom-50--parent-hover {
  bottom: 3.5714285714rem;
}

.g-bottom-60 {
  bottom: 4.2857142857rem;
}

.g-bottom-80 {
  bottom: 5.7142857143rem;
}

.g-bottom-minus-20 {
  bottom: -1.4285714286rem;
}

.g-bottom-minus-30 {
  bottom: -2.1428571429rem;
}

.g-bottom-minus-40 {
  bottom: -2.8571428571rem;
}

.g-bottom-minus-70 {
  bottom: -5rem;
}

.g-bottom-minus-35x {
  bottom: -35%;
}

.g-bottom-15x {
  bottom: 15%;
}

.g-bottom-minus-25x {
  bottom: -25%;
}

.g-bottom-100x {
  bottom: 100%;
}

/* Z */
@media (min-width: 576px) {
  .g-top-auto--sm {
    top: auto;
  }
  .g-left-auto--sm {
    left: auto;
  }
  .g-left-minus-20--sm {
    left: -20px;
  }
  .g-right-minus-20--sm {
    right: -20px;
  }
}

/* O */
@media (min-width: 768px) {
  .g-right-0--md {
    right: 0 !important;
  }
  .g-left-0--md {
    left: 0 !important;
  }
  .g-right-minus-15--md {
    right: -1.0714285714rem;
  }
  .g-left-minus-15--md {
    left: -1.0714285714rem;
  }
  .g-top-minus-20--md {
    top: -1.4285714286rem;
  }
  .g-right-minus-25--md {
    right: -1.7857142857rem;
  }
  .g-right-100--md {
    right: 7.1428571429rem;
  }
  .g-bottom-minus-50--md {
    bottom: -3.5714285714rem;
  }
  .g-left-auto--md {
    left: auto !important;
  }
  .g-right-auto--md {
    right: auto !important;
  }
  .g-left-minus-25--md {
    left: -1.7857142857rem;
  }
  .g-left-130--md {
    left: 9.2857142857rem !important;
  }
  .g-left-35x--md {
    left: 35%;
  }
  .g-left-50x--md {
    left: 50%;
  }
  .g-right-130--md {
    right: 9.2857142857rem !important;
  }
  .g-right-35x--md {
    right: 35%;
  }
  .g-right-50x--md {
    right: 50%;
  }
}

@media (min-width: 992px) {
  .g-top-0--lg {
    top: 0 !important;
  }
  .g-right-0--lg {
    right: 0;
  }
  .g-left-minus-35--lg {
    left: -2.5rem;
  }
  .g-left-40--lg {
    left: 2.8571428571rem;
  }
  .g-right-minus-35--lg {
    right: -2.5rem;
  }
  .g-right-40--lg {
    right: 2.8571428571rem;
  }
}

/*------------------------------------
  Block Alignments
------------------------------------*/
/* Absolute (Position, X, Y) */
.g-absolute-centered {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  -webkit-backface-visibility: hidden;
}

.g-absolute-centered--x {
  position: absolute !important;
  left: 50% !important;
  transform: translateX(-50%);
  -webkit-backface-visibility: hidden;
}

@media (min-width: 576px) {
  .g-absolute-centered--x--sm {
    position: absolute !important;
    left: 50% !important;
    transform: translateX(-50%);
    -webkit-backface-visibility: hidden;
  }
}

@media (min-width: 768px) {
  .g-absolute-centered--x--md {
    position: absolute !important;
    left: 50% !important;
    transform: translateX(-50%);
    -webkit-backface-visibility: hidden;
  }
}

.g-absolute-centered--y {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%);
  -webkit-backface-visibility: hidden;
}

@media (min-width: 768px) {
  .g-absolute-centered--y--md {
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%);
    -webkit-backface-visibility: hidden;
  }
}

@media (min-width: 992px) {
  .g-absolute-centered--y--lg {
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%);
    -webkit-backface-visibility: hidden;
  }
}

/* O */
.g-transform-origin--top-left {
  transform-origin: top left;
}

.g-transform-origin--top-right {
  transform-origin: top right;
}

.g-absolute-centered--y--scl-0_6 {
  position: absolute;
  top: 50%;
  transform: scale(0.6) translateY(-50%);
}

/* Relative (Position, X, Y) */
.g-relative-centered {
  position: relative;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  -webkit-backface-visibility: hidden;
}

.g-relative-centered--x {
  position: relative;
  left: 50% !important;
  transform: translateX(-50%);
  -webkit-backface-visibility: hidden;
}

.g-relative-centered--y {
  position: relative;
  top: 50% !important;
  transform: translateY(-50%);
  -webkit-backface-visibility: hidden;
}

/* Flex centered */
.g-flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.g-flex-centered-item {
  flex: 1;
}

.g-flex-centered-item--top {
  align-self: flex-start;
}

.g-flex-centered-item--bottom {
  align-self: flex-end;
}

/* Flex right */
.g-flex-right--xs {
  justify-content: flex-end;
}

/* Flex middle */
.g-flex-middle {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.g-flex-middle-item {
  margin-top: auto;
  margin-bottom: auto;
}

.g-flex-middle-item--top {
  margin-top: 0;
  margin-bottom: 0;
}

.g-flex-middle-item--bottom {
  margin-top: auto;
  margin-bottom: 0;
}

.g-flex-middle-item--fixed {
  flex: none;
  max-width: 50%;
}

@media (min-width: 768px) {
  /* Flex right */
  .g-flex-right--md {
    justify-content: flex-end;
  }
  .g-flex-centered--md {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .g-absolute-centered--y--scl-1--md {
    position: absolute;
    top: 50%;
    transform: scale(1) translateY(-50%);
  }
}

@media (min-width: 576px) {
  .g-absolute-centered--sm {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    -webkit-backface-visibility: hidden;
  }
  .g-absolute-centered--x-sm--reset {
    position: static !important;
    left: auto !important;
    transform: translateX(0) !important;
  }
}

@media (min-width: 768px) {
  .g-absolute-centered--md {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    -webkit-backface-visibility: hidden;
  }
}

@media (min-width: 992px) {
  .g-absolute-centered--x--lg {
    position: absolute !important;
    left: 50% !important;
    transform: translateX(-50%);
    -webkit-backface-visibility: hidden;
  }
  .g-flex-centered--lg {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/*------------------------------------
  Alignments
------------------------------------*/
.g-valign-super {
  vertical-align: super !important;
}

.g-valign-sub {
  vertical-align: sub !important;
}

.g-valign-top {
  vertical-align: top !important;
}

.g-valign-middle {
  vertical-align: middle !important;
}

.g-valign-bottom {
  vertical-align: bottom !important;
}

/*------------------------------------
  Blur
------------------------------------*/
.g-blur-30 {
  filter: blur(30px);
}

.g-blur-30--hover:hover {
  filter: blur(30px);
}

.g-parent:hover .g-blur-30--parent-hover {
  filter: blur(30px);
}

/*------------------------------------
  Box-shadows
------------------------------------*/
.g-box-shadow-none {
  box-shadow: none !important;
}

/*------------------------------------
  Clear
------------------------------------*/
.g-clear {
  clear: both;
}

.g-clear--left {
  clear: left;
}

.g-clear--right {
  clear: right;
}

/*------------------------------------
  Cursors
------------------------------------*/
.g-cursor-pointer {
  cursor: pointer;
}

/*------------------------------------
  Overflows
------------------------------------*/
.g-overflow-hidden {
  overflow: hidden !important;
}

.g-overflow-visible {
  overflow: visible;
}

.g-overflow-x-hidden {
  overflow-x: hidden;
}

.g-overflow-x-auto {
  overflow-x: auto !important;
}

.g-overflow-x-scroll {
  overflow-x: scroll;
}

.g-overflow-y-auto {
  overflow-y: auto;
}

.g-overflow-y-hidden {
  overflow-y: hidden;
}

@media (min-width: 768px) {
  .g-overflow-x-visible--md {
    overflow-x: visible;
  }
}

@media (min-width: 992px) {
  .g-overflow-x-visible--lg {
    overflow-x: visible;
  }
}

/*------------------------------------
  Transitions
------------------------------------*/
/*.g-transition {
  &-0_2 {
    transition: .2s ease-out;
  }
  &-0_3 {
    transition: .3s ease-out;
  }
  &-0_6 {
    transition: .6s ease-out;
    &-ease {
      transition: .6s ease;
    }
  }
}

[class*="g-color-"][class*="--hover"] {
  transition: color .2s ease-out;
}
[class*="g-bg-"][class*="--hover"] {
  transition: background-color .3s ease-out;
}
[class*="g-color-"][class*="--hover"][class*="g-bg-"][class*="--hover"] {
  transition: .3s ease-out;
}*/
[class*="g-transition"] {
  transition-property: all;
  transition-timing-function: ease;
  transition-delay: 0s;
}

[class*="g-transition"]::before, [class*="g-transition"]::after {
  transition-property: all;
  transition-timing-function: ease;
  transition-delay: 0s;
}

svg[class*="g-transition"] path,
svg[class*="g-transition"] polygon {
  transition-property: all;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.g-transition-0 {
  transition-duration: 0s;
}

.g-transition-0::before, .g-transition-0::after {
  transition-duration: 0s;
}

svg.g-transition-0 path,
svg.g-transition-0 polygon {
  transition-duration: 0s;
}

.g-transition-0_2 {
  transition-duration: .2s;
}

.g-transition-0_2::before, .g-transition-0_2::after {
  transition-duration: .2s;
}

svg.g-transition-0_2 path,
svg.g-transition-0_2 polygon {
  transition-duration: .2s;
}

.g-transition-0_3 {
  transition-duration: .3s;
}

.g-transition-0_3::before, .g-transition-0_3::after {
  transition-duration: .3s;
}

svg.g-transition-0_3 path,
svg.g-transition-0_3 polygon {
  transition-duration: .3s;
}

.g-transition-0_5 {
  transition-duration: .5s;
}

.g-transition-0_5::before, .g-transition-0_5::after {
  transition-duration: .5s;
}

svg.g-transition-0_5 path,
svg.g-transition-0_5 polygon {
  transition-duration: .5s;
}

.g-transition-0_6 {
  transition-duration: .6s;
}

.g-transition-0_6::before, .g-transition-0_6::after {
  transition-duration: .6s;
}

svg.g-transition-0_6 path,
svg.g-transition-0_6 polygon {
  transition-duration: .6s;
}

.g-transition-delay-0_11 {
  transition-delay: .11s;
}

.g-transition-delay-0_11::before, .g-transition-delay-0_11::after {
  transition-delay: .11s;
}

svg.g-transition-0_11 path,
svg.g-transition-0_11 polygon {
  transition-duration: .11s;
}

.g-transition-delay-0_2 {
  transition-delay: .2s;
}

.g-transition-delay-0_2::before, .g-transition-delay-0_2::after {
  transition-delay: .2s;
}

svg.g-transition-delay-0_2 path,
svg.g-transition-delay-0_2 polygon {
  transition-delay: .2s;
}

.g-transition-delay-0_45 {
  transition-delay: .45s;
}

.g-transition-delay-0_45::before, .g-transition-delay-0_45::after {
  transition-delay: .45s;
}

svg.g-transition-delay-0_45 path,
svg.g-transition-delay-0_45 polygon {
  transition-delay: .45s;
}

.g-transition--ease-out {
  transition-timing-function: ease-out;
}

.g-transition--ease-out::before, .g-transition--ease-out::after {
  transition-timing-function: ease-out;
}

svg.g-transition--ease-out path,
svg.g-transition--ease-out polygon {
  transition-timing-function: ease-out;
}

.g-transition--ease-in {
  transition-timing-function: ease-in;
}

.g-transition--ease-in::before, .g-transition--ease-in::after {
  transition-timing-function: ease-in;
}

svg.g-transition--ease-in path,
svg.g-transition--ease-in polygon {
  transition-timing-function: ease-in;
}

.g-transition--ease-in-out {
  transition-timing-function: ease-in-out;
}

.g-transition--ease-in-out::before, .g-transition--ease-in-out::after {
  transition-timing-function: ease-in-out;
}

svg.g-transition--ease-in-out path,
svg.g-transition--ease-in-out polygon {
  transition-timing-function: ease-in-out;
}

.g-transition--linear {
  transition-timing-function: linear;
}

.g-transition--linear::before, .g-transition--linear::after {
  transition-timing-function: linear;
}

svg.g-transition--linear path,
svg.g-transition--linear polygon {
  transition-timing-function: linear;
}

/*------------------------------------
  Transforms
------------------------------------*/
.g-transform-scale-0_5 {
  transform: scale(0.5);
}

.g-transform-scale-0_5--hover:hover {
  transform: scale(0.5);
}

.g-parent:hover .g-transform-scale-0_5--parent-hover {
  transform: scale(0.5);
}

.g-transform-scale-0_8 {
  transform: scale(0.8);
}

.g-transform-scale-0_8--hover:hover {
  transform: scale(0.8);
}

.g-parent:hover .g-transform-scale-0_8--parent-hover {
  transform: scale(0.8);
}

.g-transform-scale-0_85 {
  transform: scale(0.85);
}

.g-transform-scale-0_85--hover:hover {
  transform: scale(0.85);
}

.g-parent:hover .g-transform-scale-0_85--parent-hover {
  transform: scale(0.85);
}

.g-transform-scale-0_9 {
  transform: scale(0.9);
}

.g-transform-scale-0_9--hover:hover {
  transform: scale(0.9);
}

.g-parent:hover .g-transform-scale-0_9--parent-hover {
  transform: scale(0.9);
}

.g-transform-scale-1 {
  transform: scale(1);
}

.g-transform-scale-1--hover:hover {
  transform: scale(1);
}

.g-parent:hover .g-transform-scale-1--parent-hover {
  transform: scale(1);
}

.g-transform-scale-1_05 {
  transform: scale3d(1.05, 1.05, 1.05);
}

.g-transform-scale-1_05--hover:hover {
  transform: scale3d(1.05, 1.05, 1.05);
  z-index: 2;
}

.g-parent:hover .g-transform-scale-1_05--parent-hover {
  transform: scale3d(1.1, 1.1, 1.1);
}

.g-transform-scale-1_1 {
  transform: scale3d(1.1, 1.1, 1.1);
}

.g-transform-scale-1_1--hover:hover {
  transform: scale3d(1.1, 1.1, 1.1);
  z-index: 2;
}

.g-parent:hover .g-transform-scale-1_1--parent-hover {
  transform: scale3d(1.1, 1.1, 1.1);
}

.g-transform-scale-1_2 {
  transform: scale(1.2);
}

.g-transform-scale-1_2--hover:hover {
  transform: scale(1.2);
}

.g-parent:hover .g-transform-scale-1_2--parent-hover {
  transform: scale(1.2);
}

.g-transform-translate-x-100x {
  transform: translateX(100%);
}

.g-transform-translate-x-100x--hover:hover {
  transform: translateX(100%);
}

.g-parent:hover .g-transform-translate-x-100x--parent-hover {
  transform: translateX(100%);
}

.g-transform-translate-x-minus-100x {
  transform: translateX(-100%);
}

.g-transform-translate-x-minus-100x--hover:hover {
  transform: translateX(-100%);
}

.g-parent:hover .g-transform-translate-x-minus-100x--parent-hover {
  transform: translateX(-100%);
}

.g-transform-translate-y-5 {
  transform: translateY(5px);
}

.g-transform-translate-y-5--hover:hover {
  transform: translateY(5px);
}

.g-parent:hover .g-transform-translate-y-5--parent-hover {
  transform: translateY(5px);
}

.g-transform-translate-y-100x {
  transform: translateY(100%);
}

.g-transform-translate-y-100x--hover:hover {
  transform: translateY(100%);
}

.g-parent:hover .g-transform-translate-y-100x--parent-hover {
  transform: translateY(100%);
}

.g-transform-translate-y-minus-5 {
  transform: translateY(-5px);
}

.g-transform-translate-y-minus-5--hover:hover {
  transform: translateY(-5px);
}

.g-parent:hover .g-transform-translate-y-minus-5--parent-hover {
  transform: translateY(-5px);
}

.g-transform-translate-y-minus-70 {
  transform: translateY(-70px);
}

.g-transform-translate-y-minus-70--hover:hover {
  transform: translateY(-70px);
}

.g-parent:hover .g-transform-translate-y-minus-70--parent-hover {
  transform: translateY(-70px);
}

.g-transform-translate-y-minus-100x {
  transform: translateY(-100%);
}

.g-transform-translate-y-minus-100x--hover:hover {
  transform: translateY(-100%);
}

.g-parent:hover .g-transform-translate-y-minus-100x--parent-hover {
  transform: translateY(-100%);
}

/*------------------------------------
  Opacity
------------------------------------*/
.opacity-0 {
  opacity: 0 !important;
}

.g-opacity-0_3 {
  opacity: .3 !important;
}

.g-opacity-0_4 {
  opacity: .4 !important;
}

.g-opacity-0_5 {
  opacity: .5 !important;
}

.g-opacity-0_6 {
  opacity: .6 !important;
}

.g-opacity-0_7 {
  opacity: .7 !important;
}

.g-opacity-0_8 {
  opacity: .8 !important;
}

.g-opacity-1 {
  opacity: 1 !important;
}

.opacity-0--hover:hover {
  opacity: 0 !important;
}

.g-parent:hover .opacity-0--parent-hover {
  opacity: 0 !important;
}

.g-opacity-0_3--hover:hover {
  opacity: .3 !important;
}

.g-parent:hover .g-opacity-0_3--parent-hover {
  opacity: .3 !important;
}

.g-opacity-0_5--hover:hover {
  opacity: .5 !important;
}

.g-parent:hover .g-opacity-0_5--parent-hover {
  opacity: .5 !important;
}

.g-opacity-0_6--hover:hover {
  opacity: .6 !important;
}

.g-parent:hover .g-opacity-0_6--parent-hover {
  opacity: .6 !important;
}

.g-opacity-0_7--hover:hover {
  opacity: .7 !important;
}

.g-parent:hover .g-opacity-0_7--parent-hover {
  opacity: .7 !important;
}

.g-opacity-0_8--hover:hover {
  opacity: .8 !important;
}

.g-parent:hover .g-opacity-0_8--parent-hover {
  opacity: .8 !important;
}

.u-block-hover:hover .g-opacity-1--hover, .g-opacity-1--hover:hover {
  opacity: 1 !important;
}

.g-parent:hover .g-opacity-1--parent-hover {
  opacity: 1 !important;
}

.selected .g-opacity-1--parents-selected {
  opacity: 1 !important;
}

/*------------------------------------
  Z-Index
------------------------------------*/
.g-z-index-auto {
  z-index: auto;
}

.g-z-index-1 {
  z-index: 1;
}

.g-z-index-2 {
  z-index: 2;
}

.g-z-index-3 {
  z-index: 3;
}

.g-z-index-3--hover:hover {
  z-index: 3;
}

.g-z-index-4 {
  z-index: 4;
}

.g-z-index-4--hover:hover {
  z-index: 4;
}

.g-z-index-99 {
  z-index: 99;
}

.g-z-index-9999 {
  z-index: 9999;
}

.g-z-index-minus-1 {
  z-index: -1;
}

/*------------------------------------
  Resize
------------------------------------*/
.g-resize-none {
  resize: none;
}

/*------------------------------------
  Placeholder
------------------------------------*/
.g-placeholder-white::placeholder {
  opacity: 1;
  color: #fff;
}

.g-placeholder-white::-moz-placeholder {
  opacity: 1;
  color: #fff;
}

.g-placeholder-white::-webkit-input-placeholder {
  opacity: 1;
  color: #fff;
}

.g-placeholder-primary::placeholder {
  color: #fff;
}

.g-placeholder-inherit::placeholder {
  color: inherit;
  opacity: 1;
}

.g-placeholder-inherit::-moz-placeholder {
  color: inherit;
  opacity: 1;
}

.g-placeholder-inherit::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

/*------------------------------------
  Offsets
------------------------------------*/
@media (min-width: 768px) {
  .g-offset-md-1 {
    margin-left: 8.333333%;
  }
}

@media (min-width: 992px) {
  .g-offset-lg-1 {
    margin-left: 8.333333%;
  }
  .g-offset-lg-4 {
    margin-left: 33.333333%;
  }
}

/*------------------------------------
  Colors
------------------------------------*/
/* Basic Colors
------------------------------------*/
/* Inherit Colors */
.g-color-inherit {
  color: inherit !important;
}

/* Main Colors */
.g-color-main {
  color: #9900cc !important;
}

.g-color-main--hover:hover {
  color: #9900cc !important;
}

/* Primary Colors */
.g-color-primary {
  color: #9900cc !important;
}

.u-block-hover:hover .g-color-primary--hover, .g-color-primary--hover:hover {
  color: #9900cc !important;
}

.g-parent:hover .g-color-primary--parent-hover {
  color: #9900cc !important;
}

.g-color-primary-opacity-0_3 {
  color: rgba(153, 0, 204, 0.3) !important;
}

.g-color-primary-opacity-0_4 {
  color: rgba(153, 0, 204, 0.4) !important;
}

.g-color-primary--active.active {
  color: #9900cc !important;
}

.active .g-color-primary--parent-active {
  color: #9900cc !important;
}

.g-color-primary--before::before, .g-color-primary--after::after {
  color: #9900cc;
}

/* Secondary Colors */
.g-color-secondary {
  color: white !important;
}

.u-block-hover:hover .g-color-secondary--hover, .g-color-secondary--hover:hover {
  color: white !important;
}

.g-parent:hover .g-color-secondary--parent-hover {
  color: white !important;
}

/* Black Colors */
.g-color-black {
  color: #000 !important;
}

.g-color-black--hover:hover {
  color: #000 !important;
}

.g-parent:hover .g-color-black--parent-hover {
  color: #000 !important;
}

.g-color-black--active.active {
  color: #000 !important;
}

.g-color-black-opacity-0_1 {
  color: rgba(0, 0, 0, 0.1) !important;
}

.g-color-black-opacity-0_3 {
  color: rgba(0, 0, 0, 0.3) !important;
}

.g-color-black-opacity-0_5 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.g-color-black-opacity-0_6 {
  color: rgba(0, 0, 0, 0.6) !important;
}

.g-color-black-opacity-0_7 {
  color: rgba(0, 0, 0, 0.7) !important;
}

.g-color-black-opacity-0_8 {
  color: rgba(0, 0, 0, 0.8) !important;
}

.g-color-black-opacity-0_8--child * {
  color: rgba(0, 0, 0, 0.8) !important;
}

.g-color-black-opacity-0_9 {
  color: rgba(0, 0, 0, 0.9) !important;
}

/* White Colors */
.g-color-white {
  color: #fff !important;
}

.g-color-white--opened-menu:not(.collapsed) {
  color: #fff !important;
}

.u-block-hover:hover .g-color-white--hover, .g-color-white--hover:hover {
  color: #fff !important;
}

.g-parent:hover .g-color-white--parent-hover {
  color: #fff !important;
}

.g-color-white--active.active {
  color: #fff !important;
}

.active .g-color-white--parent-active {
  color: #fff !important;
}

.g-color-white--child * {
  color: #fff !important;
}

.g-color-white-opacity-0_1 {
  color: rgba(255, 255, 255, 0.1) !important;
}

.g-color-white-opacity-0_2 {
  color: rgba(255, 255, 255, 0.2) !important;
}

.g-color-white-opacity-0_3 {
  color: rgba(255, 255, 255, 0.3) !important;
}

.g-color-white-opacity-0_5, .g-color-white-opacity-0_5--hover:hover {
  color: rgba(255, 255, 255, 0.5) !important;
}

.g-color-white-opacity-0_6 {
  color: rgba(255, 255, 255, 0.6) !important;
}

.g-parent:hover .g-color-white-opacity-0_6--parent-hover {
  color: rgba(255, 255, 255, 0.6) !important;
}

.g-color-white-opacity-0_7, .g-color-white-opacity-0_7--hover:hover {
  color: rgba(255, 255, 255, 0.7) !important;
}

.g-color-white-opacity-0_75 {
  color: rgba(255, 255, 255, 0.75) !important;
}

.g-color-white-opacity-0_8 {
  color: rgba(255, 255, 255, 0.8) !important;
}

.g-color-white-opacity-0_8--child * {
  color: rgba(255, 255, 255, 0.8) !important;
}

.g-color-white-opacity-0_9, .g-color-white-opacity-0_9--hover:hover {
  color: rgba(255, 255, 255, 0.9) !important;
}

.u-block-hover:hover .g-color-white-opacity-0_7--hover {
  color: rgba(255, 255, 255, 0.7) !important;
}

/* Gray Colors */
.g-color-gray-light-v1 {
  color: #bbb !important;
}

.g-color-gray-light-v1--hover:hover {
  color: #bbb !important;
}

.g-color-gray-light-v2 {
  color: #ccc !important;
}

.g-color-gray-light-v2--hover:hover {
  color: #ccc !important;
}

.g-color-gray-light-v3 {
  color: #ddd !important;
}

.g-color-gray-light-v3--hover:hover {
  color: #ddd !important;
}

.g-color-gray-light-v4 {
  color: #eee !important;
}

.g-color-gray-light-v4--hover:hover {
  color: #eee !important;
}

.g-color-gray-light-v4-opacity-0_6 {
  color: rgba(238, 238, 238, 0.6) !important;
}

.g-color-gray-light-v5 {
  color: #f7f7f7 !important;
}

.g-color-gray-light-v5--hover:hover {
  color: #f7f7f7 !important;
}

.g-color-gray-dark-v1 {
  color: #111 !important;
}

.g-color-gray-dark-v1--hover:hover {
  color: #111 !important;
}

.g-color-gray-dark-v2 {
  color: #333 !important;
}

.g-color-gray-dark-v2--hover:hover {
  color: #333 !important;
}

.g-color-gray-dark-v2-opacity-0_75 {
  color: rgba(51, 51, 51, 0.75) !important;
}

.g-color-gray-dark-v3 {
  color: #555 !important;
}

.g-color-gray-dark-v3--hover:hover {
  color: #555 !important;
}

.g-color-gray-dark-v4 {
  color: #777 !important;
}

.g-color-gray-dark-v4--hover:hover {
  color: #777 !important;
}

.g-color-gray-dark-v5 {
  color: #999 !important;
}

.g-color-gray-dark-v5--hover:hover {
  color: #999 !important;
}

.g-parent:hover .g-color-gray-dark-v5--parent-hover {
  color: #999 !important;
}

/* Complementary Colors
------------------------------------*/
/* Color Green */
.g-color-green {
  color: #72c02c !important;
}

.g-color-green--hover:hover {
  color: #72c02c !important;
}

/* Color Blue */
.g-color-blue {
  color: #3398dc !important;
}

.g-color-blue--hover:hover {
  color: #3398dc !important;
}

.g-color-blue-dark-v1 {
  color: #175a88 !important;
}

/* Color Light Blue */
.g-color-lightblue {
  color: #edf2f8 !important;
}

.g-color-lightblue--hover:hover {
  color: #edf2f8 !important;
}

.g-color-lightblue-v1 {
  color: #d6e2ee !important;
}

.g-color-lightblue-v1--hover:hover {
  color: #d6e2ee !important;
}

/* Color Dark Blue */
.g-color-darkblue {
  color: #009 !important;
}

.g-color-darkblue--hover:hover {
  color: #009 !important;
}

/* Color Indigo */
.g-color-indigo {
  color: #4263a3 !important;
}

.g-color-indigo--hover:hover {
  color: #4263a3 !important;
}

/* Color Red */
.g-color-red {
  color: #f00 !important;
}

.g-color-red--hover:hover {
  color: #f00 !important;
}

*:hover > .g-color-red--parent-hover {
  color: #f00 !important;
}

/* Color Light Red */
.g-color-lightred {
  color: #e64b3b !important;
}

.g-color-lightred--hover:hover {
  color: #e64b3b !important;
}

/* Color Dark Red */
.g-color-darkred {
  color: #a10f2b !important;
}

.g-color-darkred--hover:hover {
  color: #a10f2b !important;
}

/* Color Purple */
.g-color-purple {
  color: #9a69cb;
}

.g-color-purple--hover:hover {
  color: #9a69cb !important;
}

.g-color-purple-dark-v1 {
  color: #552c7e !important;
}

/* Color Dark Purple */
.g-color-darkpurple {
  color: #6639b6 !important;
}

.g-color-darkpurple--hover:hover {
  color: #6639b6 !important;
}

/* Color Pink */
.g-color-pink {
  color: #e81c62;
}

.g-color-pink--hover:hover {
  color: #e81c62 !important;
}

.g-color-pink-dark-v1 {
  color: #6f0b2d !important;
}

/* Color Orange */
.g-color-orange {
  color: #e57d20 !important;
}

.g-color-orange--hover:hover {
  color: #e57d20 !important;
}

/* Color Deep Orange */
.g-color-deeporange {
  color: #fe541e !important;
}

.g-color-deeporange--hover:hover {
  color: #fe541e !important;
}

/* Color Yellow */
.g-color-yellow {
  color: #ebc71d !important;
}

.g-color-yellow--hover:hover {
  color: #ebc71d !important;
}

/* Color Aqua */
.g-color-aqua {
  color: #29d6e6;
}

.g-color-aqua--hover:hover {
  color: #29d6e6 !important;
}

.g-color-aqua-dark-v1 {
  color: #11848e !important;
}

/* Color Cyan */
.g-color-cyan {
  color: #00bed6 !important;
}

.g-color-cyan--hover:hover {
  color: #00bed6 !important;
}

/* Color Teal */
.g-color-teal {
  color: #18ba9b !important;
}

.g-color-teal--hover:hover {
  color: #18ba9b !important;
}

/* Color Brown */
.g-color-brown {
  color: #9c8061 !important;
}

.g-color-brown--hover:hover {
  color: #9c8061 !important;
}

/* Color Blue Gray */
.g-color-bluegray {
  color: #585f69 !important;
}

.g-color-bluegray--hover:hover {
  color: #585f69 !important;
}

/*------------------------------------
  Social Colors
------------------------------------*/
/* Facebook */
.g-color-facebook {
  color: #3b5998;
}

.g-color-facebook:hover {
  color: #344e86;
}

.g-color-facebook--hover:hover {
  color: #3b5998 !important;
}

/* Twitter */
.g-color-twitter {
  color: #00acee;
}

.g-color-twitter:hover {
  color: #009ad5;
}

.g-color-twitter--hover:hover {
  color: #00acee !important;
}

/* Skype */
.g-color-skype {
  color: #00aff0;
}

.g-color-skype:hover {
  color: #009cd7;
}

.g-color-skype--hover:hover {
  color: #00aff0 !important;
}

/* Pinterest */
.g-color-pinterest {
  color: #c8232c;
}

.g-color-pinterest:hover {
  color: #b21f27;
}

.g-color-pinterest--hover:hover {
  color: #c8232c !important;
}

/* Vine */
.g-color-vine {
  color: #00bf8f;
}

.g-color-vine:hover {
  color: #00a67c;
}

.g-color-vine--hover:hover {
  color: #00bf8f !important;
}

/* Youtube */
.g-color-youtube {
  color: #c4302b;
}

.g-color-youtube:hover {
  color: #af2b26;
}

.g-color-youtube--hover:hover {
  color: #c4302b !important;
}

/* Google plus */
.g-color-google-plus {
  color: #dd4b39;
}

.g-color-google-plus:hover {
  color: #d73925;
}

.g-color-google-plus--hover:hover {
  color: #dd4b39 !important;
}

/* Dribbble */
.g-color-dribbble {
  color: #ea4c89;
}

.g-color-dribbble:hover {
  color: #e7357a;
}

.g-color-dribbble--hover:hover {
  color: #ea4c89 !important;
}

/* VK */
.g-color-vk {
  color: #2b587a;
}

.g-color-vk:hover {
  color: #244a67;
}

.g-color-vk--hover:hover {
  color: #2b587a !important;
}

/* Linkedin */
.g-color-linkedin {
  color: #0e76a8;
}

.g-color-linkedin:hover {
  color: #0c6590;
}

.g-color-linkedin--hover:hover {
  color: #0e76a8 !important;
}

/* Instagram */
.g-color-instagram {
  color: #3f729b;
}

.g-color-instagram:hover {
  color: #386589;
}

.g-color-instagram--hover:hover {
  color: #3f729b !important;
}

/*------------------------------------
  Gradient Colors
------------------------------------*/
/* Cyan Gradient */
.g-color-cyan-gradient-opacity-v1 {
  background-repeat: repeat-x;
  background-image: linear-gradient(160deg, rgba(0, 0, 153, 0.55), rgba(0, 190, 214, 0.6));
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

/* Blue Gradient */
.g-color-blue-gradient-opacity-v1 {
  background-repeat: repeat-x;
  background-image: linear-gradient(-260deg, #8654da, rgba(66, 229, 248, 0.8));
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

/*------------------------------------
  Widths
------------------------------------*/
/* Width in Percentage (%) */
.g-width-30x {
  width: 30% !important;
  /* P */
}

.g-width-40x {
  width: 40% !important;
  /* P */
}

.g-width-50x {
  width: 50% !important;
  /* P */
}

.g-width-60x {
  width: 60% !important;
  /* P */
}

.g-width-70x {
  width: 70% !important;
  /* P */
}

.g-width-80x {
  width: 80% !important;
  /* P */
}

.g-width-90x {
  width: 90% !important;
  /* P */
}

@media (max-width: 446px - 1) {
  .w-100--2xs {
    width: 100% !important;
  }
}

@media (min-width: 576px) {
  .g-width-20x--sm {
    width: 20% !important;
  }
  .g-width-25x--sm {
    width: 25% !important;
  }
  .g-width-33_3x--sm {
    width: 33.333333% !important;
  }
  .g-width-40x--sm {
    width: 40% !important;
  }
  .g-width-60x--sm {
    width: 60% !important;
  }
  .g-width-16_6x--sm {
    width: 16.666666% !important;
  }
  .g-width-66_6x--sm {
    width: 66.666666% !important;
  }
}

@media (min-width: 768px) {
  /* P */
  .g-width-20x--md {
    width: 20% !important;
    /* P */
  }
  .g-width-25x--md {
    width: 25% !important;
    /* P */
  }
  .g-width-30x--md {
    width: 30% !important;
    /* P */
  }
  .g-width-35x--md {
    width: 35% !important;
    /* P */
  }
  .g-width-40x--md {
    width: 40% !important;
    /* P */
  }
  .g-width-45x--md {
    width: 45% !important;
    /* P */
  }
  .g-width-50x--md {
    width: 50% !important;
    /* P */
  }
  .g-width-55x--md {
    width: 55% !important;
    /* P */
  }
  .g-width-60x--md {
    width: 60% !important;
    /* P */
  }
  .g-width-65x--md {
    width: 65% !important;
    /* P */
  }
  .g-width-70x--md {
    width: 70% !important;
    /* P */
  }
  .g-width-75x--md {
    width: 75% !important;
    /* P */
  }
  .g-width-80x--md {
    width: 80% !important;
    /* P */
  }
  .g-width-85x--md {
    width: 85% !important;
    /* P */
  }
  .g-width-90x--md {
    width: 90% !important;
    /* P */
  }
  .g-width-33_3x--md {
    /* P */
    width: 33.333333% !important;
  }
  .g-width-16_6x--md {
    /* P */
    width: 16.666666% !important;
  }
  .g-width-66_6x--md {
    /* P */
    width: 66.666666% !important;
  }
}

.g-width-auto {
  width: auto !important;
  /* P */
}

/* Max Width in Percentage (%) */
.g-width-1x {
  max-width: 1%;
}

.g-width-80x {
  max-width: 80%;
}

.g-width-90x {
  max-width: 90%;
}

.g-width-95x {
  width: 95%;
}

.g-max-width-60x {
  max-width: 60%;
}

.g-max-width-100x {
  max-width: 100%;
}

/* Min Width in Percentage (%) */
.g-min-width-100x {
  min-width: 100%;
}

/* Width Viewport Width (vw) */
.g-width-50vw {
  width: 50vw !important;
  /* P */
}

.g-width-100vw {
  width: 100vw !important;
  /* P */
}

/* Width in Pixels (px) */
.g-width-3 {
  width: 3px !important;
  /* O */
}

.g-width-10 {
  width: 10px !important;
  /* O */
}

.g-width-12 {
  width: 12px !important;
  /* Z */
}

.g-width-16 {
  width: 16px !important;
  /* O */
}

.g-width-18 {
  width: 18px !important;
  /* O */
}

.g-width-20 {
  width: 20px !important;
  /* O */
}

.g-width-24 {
  width: 24px !important;
  /* O */
}

.g-width-25 {
  width: 25px !important;
}

.g-width-26 {
  width: 26px !important;
  /* P */
}

.g-width-28 {
  width: 28px !important;
  /* O */
}

.g-width-30 {
  width: 30px !important;
  /* P */
}

.g-width-32 {
  width: 32px !important;
  /* O */
}

.g-width-35 {
  width: 35px !important;
  /* P */
}

.g-width-36 {
  width: 36px !important;
  /* O */
}

.g-width-40 {
  width: 40px !important;
  /* P */
}

.g-width-45 {
  width: 45px !important;
  /* P */
}

.g-width-48 {
  width: 48px !important;
  /* P */
}

.g-width-50 {
  width: 50px !important;
  /* P */
}

.g-width-54 {
  width: 54px !important;
  /* P */
}

.g-width-55 {
  width: 55px !important;
  /* P */
}

.g-width-60 {
  width: 60px !important;
  /* P */
}

.g-width-64 {
  width: 64px !important;
  /* P */
}

.g-width-70 {
  width: 70px !important;
  /* P */
}

.g-width-75 {
  width: 75px !important;
  /* O */
}

.g-width-80 {
  width: 80px !important;
  /* P */
}

.g-width-85 {
  width: 85px !important;
  /* P */
}

.g-width-95 {
  width: 95px !important;
  /* P */
}

.g-width-100 {
  width: 100px !important;
  /* P */
}

.g-width-105 {
  width: 105px;
}

.g-width-110 {
  width: 110px !important;
  /* O */
}

.g-width-115 {
  width: 115px !important;
  /* O */
}

.g-width-120 {
  width: 120px !important;
  /* P */
}

.g-width-125 {
  width: 125px !important;
  /* P */
}

.g-width-130 {
  width: 130px !important;
  /* P */
}

.g-width-135 {
  width: 135px !important;
  /* O */
}

.g-width-140 {
  width: 140px !important;
  /* P */
}

.g-width-150 {
  width: 150px !important;
}

.g-width-160 {
  width: 160px !important;
  /* P */
}

.g-width-170 {
  width: 170px !important;
  /* P */
}

.g-width-180 {
  width: 180px !important;
  /* O */
}

.g-width-200 {
  width: 200px !important;
  /* P */
}

.g-width-220 {
  width: 220px !important;
}

.g-width-215 {
  width: 215px !important;
}

.g-width-235 {
  width: 235px !important;
}

.g-width-250 {
  /* RG-Q */
  width: 250px !important;
  /* P */
  max-width: 100%;
}

.g-width-270 {
  width: 270px !important;
  /* O */
  max-width: 100%;
}

.g-width-300 {
  width: 300px !important;
  /* P */
  max-width: 100%;
}

.g-width-400 {
  width: 400px !important;
  /* O */
  max-width: 100%;
}

.g-width-340 {
  width: 340px !important;
  /* O */
  max-width: 100%;
}

.g-width-360 {
  width: 360px !important;
  /* O */
  max-width: 100%;
}

.g-width-370 {
  width: 370px !important;
  /* P */
  max-width: 100%;
}

.g-width-380 {
  width: 380px !important;
  /* O */
  max-width: 100%;
}

.g-width-410 {
  width: 410px !important;
  /* O */
}

.g-width-460 {
  width: 460px !important;
  /* O */
  max-width: 100%;
}

.g-width-465 {
  width: 465px !important;
  /* O */
  max-width: 100%;
}

.g-width-485 {
  width: 485px !important;
  /* O */
  max-width: 100%;
}

.g-width-560 {
  width: 560px !important;
  /* O */
  max-width: 100%;
}

.g-width-590 {
  width: 590px !important;
  max-width: 100%;
}

.g-width-600 {
  width: 600px !important;
  /* O */
  max-width: 100%;
}

.g-width-630 {
  width: 630px !important;
  /* O */
  max-width: 100%;
}

.g-width-680 {
  width: 680px !important;
  /* O */
  max-width: 100%;
}

.g-width-720 {
  width: 720px !important;
  /* O */
  max-width: 100%;
}

.g-width-760 {
  width: 760px !important;
  max-width: 100%;
}

.g-width-770 {
  width: 770px !important;
  max-width: 100%;
}

.g-width-780 {
  /* RG-Q */
  width: 780px !important;
  /* P */
  max-width: 100%;
}

.g-width-900 {
  width: 900px !important;
  /* P */
  max-width: 100%;
}

.g-width-945 {
  width: 945px !important;
  max-width: 100%;
}

.g-width-1025 {
  width: 1025px !important;
  max-width: 100%;
}

/* Max Width in Pixels (px) */
.g-max-width-100 {
  max-width: 100px;
}

.g-max-width-120 {
  max-width: 120px;
}

.g-max-width-170 {
  max-width: 170px;
  /* O */
}

.g-max-width-190 {
  max-width: 190px;
}

.g-max-width-200 {
  max-width: 200px;
}

.g-max-width-250 {
  max-width: 250px;
}

.g-max-width-300 {
  max-width: 300px;
}

.g-max-width-400 {
  max-width: 400px;
}

.g-max-width-500 {
  max-width: 500px;
}

.g-max-width-540 {
  max-width: 540px;
}

.g-max-width-550 {
  max-width: 550px;
}

.g-max-width-570 {
  max-width: 570px;
}

.g-max-width-600 {
  max-width: 600px;
}

.g-max-width-645 {
  max-width: 645px;
}

.g-max-width-670 {
  max-width: 670px;
}

.g-max-width-700 {
  max-width: 700px;
}

.g-max-width-750 {
  max-width: 750px;
}

.g-max-width-770 {
  max-width: 770px;
}

.g-max-width-780 {
  max-width: 780px;
}

.g-max-width-800 {
  max-width: 800px;
}

.g-max-width-840 {
  max-width: 840px;
}

.g-max-width-870 {
  max-width: 870px;
}

.g-max-width-960 {
  max-width: 960px;
}

/* Min Width in Pixels (px) */
.g-min-width-35 {
  min-width: 35px;
}

.g-min-width-40 {
  min-width: 40px;
}

.g-min-width-65 {
  min-width: 65px;
}

.g-min-width-110 {
  min-width: 110px;
}

.g-min-width-130 {
  min-width: 130px;
}

.g-min-width-150 {
  min-width: 150px;
}

.g-min-width-170 {
  min-width: 170px;
}

.g-min-width-200 {
  min-width: 200px;
}

.g-min-width-220 {
  min-width: 220px;
}

.g-min-width-300 {
  min-width: 300px;
}

.g-min-width-400 {
  min-width: 400px;
}

@media (min-width: 576px) {
  .g-width-50x--sm {
    width: 50% !important;
    /* P */
  }
  .g-width-80x--sm {
    width: 80% !important;
    /* R */
  }
  .g-width-100--sm {
    width: 100px !important;
    /* P */
  }
  .g-width-150--sm {
    width: 150px !important;
    /* O */
  }
  .g-width-170--sm {
    width: 170px !important;
    /* O */
  }
  .g-width-180--sm {
    width: 180px !important;
  }
  .g-min-width-180--sm {
    min-width: 180px;
  }
  .g-width-485--sm {
    width: 485px !important;
    /* O */
    max-width: 100%;
  }
  .g-width-auto--sm {
    width: auto !important;
  }
  .g-max-width-300--sm {
    max-width: 300px;
  }
}

@media (min-width: 768px) {
  .g-width-40--md {
    width: 40px !important;
    /* O */
  }
  .g-width-45--md {
    width: 45px !important;
    /* O */
  }
  .g-width-60--md {
    width: 60px !important;
    /* O */
  }
  .g-width-80--md {
    width: 80px !important;
    /* O */
  }
  .g-width-50x--md {
    width: 50% !important;
    /* P */
  }
  .g-width-60x--md {
    width: 60% !important;
    /* P */
  }
  .g-width-80x--md {
    width: 80% !important;
    /* R */
  }
  .g-width-45--md {
    width: 45px !important;
  }
  .g-width-100--md {
    width: 100px !important;
    /* O */
  }
  .g-width-125--md {
    width: 125px !important;
    /* P */
  }
  .g-width-135--md {
    width: 135px !important;
  }
  .g-width-140--md {
    width: 140px !important;
    /* R */
  }
  .g-width-155--md {
    width: 155px !important;
    /* R */
  }
  .g-width-180--md {
    width: 180px !important;
  }
  .g-width-225--md {
    width: 225px !important;
  }
  .g-width-160--md {
    width: 160px !important;
  }
  .g-width-165--md {
    width: 165px !important;
  }
  .g-width-170--md {
    width: 170px !important;
    /* P */
  }
  .g-width-185--md {
    width: 185px !important;
    /* O */
  }
  .g-width-280--md {
    width: 280px !important;
    /* O */
  }
  .g-width-780--md {
    width: 780px !important;
    /* O */
    max-width: 100%;
  }
  .g-max-width-200--md {
    max-width: 200px;
  }
  .g-max-width-400--md {
    max-width: 400px;
  }
  .g-min-width-110--md {
    min-width: 110px;
  }
  .g-width-auto--md {
    width: auto !important;
    /* P */
  }
}

@media (min-width: 992px) {
  .g-width-60x--lg {
    width: 60% !important;
    /* J */
  }
  .g-width-100x--lg {
    width: 100% !important;
    /* P */
  }
  .g-width-155--lg {
    width: 155px !important;
    /* O */
  }
  .g-width-165--lg {
    width: 165px !important;
  }
  .g-width-200--lg {
    width: 200px !important;
    /* O */
    max-width: 100%;
  }
  .g-width-250--lg {
    /* RG-Q */
    width: 250px !important;
    /* P */
    max-width: 100%;
  }
  .g-width-485--lg {
    width: 485px !important;
    /* O */
    max-width: 100%;
  }
  .g-width-auto--lg {
    width: auto !important;
    /* P */
  }
  .g-max-width-200--lg {
    max-width: 200px;
  }
}

@media (min-width: 1200px) {
  .g-min-width-315--xl {
    min-width: 315px;
  }
}

* > .g-width-70--hover {
  transition-property: width;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

*:hover > .g-width-70--hover {
  width: 70px;
}

/*------------------------------------
  Heights
------------------------------------*/
/* Height in Percentage (%) */
.g-height-50x {
  height: 50%;
}

.g-height-80x {
  height: 80%;
}

.g-height-90x {
  height: 90%;
}

.g-height-95x {
  height: 95%;
}

.g-height-100x {
  height: 100%;
}

/* Max Height in Percentage (%) */
.g-max-height-100x {
  max-height: 100%;
}

/* Max Height in Viewport Height (vh) */
.g-max-height-70vh {
  max-height: 70vh !important;
}

.g-max-height-90vh {
  height: 90vh !important;
}

/* Min Height in Percentage (%) */
.g-min-height-100x {
  min-height: 100%;
}

/* Height  in Viewport Height (vh) */
.g-height-50vh {
  height: 50vh !important;
}

.g-height-70vh {
  height: 70vh;
}

.g-height-75vh {
  height: 75vh;
}

.g-height-100vh {
  height: 100vh !important;
}

/* Min Height in Viewport Height (vh) */
.g-min-height-50vh {
  min-height: 50vh;
}

.g-min-height-60vh {
  min-height: 60vh;
}

.g-min-height-70vh {
  min-height: 70vh;
}

.g-min-height-80vh {
  min-height: 80vh;
}

.g-min-height-90vh {
  min-height: 90vh;
}

.g-min-height-100vh {
  min-height: 100vh;
}

/* Auto Height */
.g-height-auto {
  height: auto;
}

@media (min-width: 576px) {
  .g-height-100vh--sm {
    height: 100vh !important;
  }
  .g-height-auto--sm {
    height: auto;
  }
  .g-height-100x--sm {
    height: 100%;
  }
}

@media (min-width: 768px) {
  .g-height-auto--md {
    height: auto;
  }
  .g-min-height-100vh--md {
    min-height: 100vh;
  }
}

@media (min-width: 992px) {
  .g-min-height-90vh--lg {
    min-height: 90vh;
  }
  .g-height-100vh--lg {
    height: 100vh !important;
  }
  .g-height-auto--lg {
    height: auto;
  }
}

@media (min-width: 1200px) {
  .g-height-auto--xl {
    height: auto;
  }
}

/* Height in Pixels (px) */
.g-height-1 {
  height: 1px;
}

.g-height-2 {
  height: 2px;
}

.g-height-4 {
  height: 4px;
}

.g-height-5 {
  height: 5px !important;
}

.g-height-6 {
  height: 6px !important;
}

.g-height-10 {
  height: 10px !important;
}

.g-height-12 {
  height: 12px !important;
}

.g-height-16 {
  height: 16px !important;
}

.g-height-18 {
  height: 18px;
}

.g-height-20 {
  height: 20px !important;
}

.g-height-22 {
  height: 22px !important;
}

.g-height-24 {
  height: 24px;
}

.g-height-25 {
  height: 25px;
}

.g-height-26 {
  height: 26px;
}

.g-height-28 {
  height: 28px !important;
  /* O */
}

.g-height-30 {
  height: 30px !important;
}

.g-height-32 {
  height: 32px;
  /* O */
}

.g-height-35 {
  height: 35px !important;
}

.g-height-40 {
  height: 40px;
}

.g-height-45 {
  height: 45px;
}

.g-height-48 {
  height: 48px;
  /* O */
}

.g-height-50 {
  height: 50px;
}

.g-height-55 {
  height: 55px;
}

.g-height-60 {
  height: 60px;
}

.g-height-64 {
  height: 64px;
}

.g-height-70 {
  height: 70px;
}

.g-height-75 {
  height: 75px;
  /* O */
}

.g-height-80 {
  height: 80px !important;
}

.g-height-85 {
  height: 85px;
}

.g-height-88 {
  height: 88px;
}

.g-height-90 {
  height: 90px;
}

.g-height-95 {
  height: 95px !important;
}

.g-height-100 {
  height: 100px !important;
}

.g-height-105 {
  height: 105px;
}

.g-height-115 {
  height: 115px !important;
  /* O */
}

.g-height-120 {
  height: 120px;
}

.g-height-125 {
  height: 125px;
}

.g-height-130 {
  height: 130px;
}

.g-height-140 {
  height: 140px;
}

.g-height-150 {
  height: 150px;
}

.g-height-160 {
  height: 160px;
}

.g-height-170 {
  height: 170px;
  /* O */
}

.g-height-180 {
  height: 180px !important;
  /* O */
}

.g-height-200 {
  height: 200px;
}

.g-height-220 {
  height: 220px;
}

.g-height-250 {
  height: 250px;
}

.g-height-280 {
  height: 280px;
}

.g-height-300 {
  height: 300px;
}

.g-height-350 {
  height: 350px;
}

.g-height-400 {
  height: 400px;
}

.g-height-450 {
  height: 450px;
}

.g-height-460 {
  height: 460px;
  /* O */
  max-height: 100% !important;
}

.g-height-500 {
  height: 500px;
}

.g-height-600 {
  height: 600px;
}

.g-height-680 {
  height: 680px;
}

/* Min Height in Pixels (px) */
.g-min-height-4 {
  min-height: 4px;
}

.g-min-height-35 {
  min-height: 35px;
}

.g-min-height-170 {
  min-height: 170px;
}

.g-min-height-200 {
  min-height: 200px;
}

.g-min-height-250 {
  min-height: 250px;
}

.g-min-height-275 {
  min-height: 275px;
}

.g-min-height-300 {
  min-height: 300px;
}

.g-min-height-312 {
  min-height: 312px;
}

.g-min-height-360 {
  min-height: 360px;
}

.g-min-height-400 {
  min-height: 400px;
}

.g-min-height-450 {
  min-height: 450px;
}

.g-min-height-500 {
  min-height: 500px;
}

.g-min-height-600 {
  min-height: 600px;
}

@media (min-width: 576px) {
  .g-height-350--sm {
    height: 350px;
  }
  .g-height-680--sm {
    height: 680px;
  }
}

@media (min-width: 768px) {
  .g-height-40--md {
    height: 40px !important;
  }
  .g-height-45--md {
    height: 45px !important;
  }
  .g-height-60--md {
    height: 60px !important;
  }
  .g-height-100--md {
    height: 100px !important;
  }
  .g-height-170--md {
    height: 170px !important;
  }
  .g-height-350--md {
    height: 350px;
  }
  .g-height-500--md {
    height: 500px;
  }
  .g-height-680--md {
    height: 680px;
  }
  .g-height-100x--md {
    height: 100%;
  }
  .g-height-100vh--md {
    height: 100vh;
  }
  .g-min-height-230--md {
    min-height: 230px;
  }
  .g-min-height-500--md {
    min-height: 500px;
  }
}

@media (min-width: 992px) {
  .g-height-80--lg {
    height: 80px !important;
  }
  .g-height-200--lg {
    height: 200px !important;
  }
  .g-height-230--lg {
    height: 230px;
  }
  .g-height-350--lg {
    height: 350px;
  }
  .g-height-680--lg {
    height: 680px;
  }
}

@media (min-width: 1200px) {
  .g-height-350--xl {
    height: 350px;
  }
  .g-height-680--xl {
    height: 680px;
  }
}

/*------------------------------------
  Margin Spaces
------------------------------------*/
/* Margin Spaces (xs)
------------------------------------*/
@media (min-width: 0) {
  .g-ma-0 {
    margin: 0 !important;
  }
  .g-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .g-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .g-ml-0 {
    margin-left: 0 !important;
  }
  .g-mr-0 {
    margin-right: 0 !important;
  }
  .g-mt-0 {
    margin-top: 0 !important;
  }
  .g-mb-0 {
    margin-bottom: 0 !important;
  }
  .g-mx-1 {
    margin-left: 0.0714285714rem !important;
    margin-right: 0.0714285714rem !important;
  }
  .g-mx-2 {
    margin-left: 0.1428571429rem !important;
    margin-right: 0.1428571429rem !important;
  }
  .g-mx-3 {
    margin-left: 0.2142857143rem !important;
    margin-right: 0.2142857143rem !important;
  }
  .g-mx-4 {
    margin-left: 0.2857142857rem !important;
    margin-right: 0.2857142857rem !important;
  }
  .g-mx-5 {
    margin-left: 0.3571428571rem !important;
    margin-right: 0.3571428571rem !important;
  }
  .g-mx-6 {
    margin-left: 0.4285714286rem !important;
    margin-right: 0.4285714286rem !important;
  }
  .g-mx-7 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .g-mx-8 {
    margin-left: 0.5714285714rem !important;
    margin-right: 0.5714285714rem !important;
  }
  .g-mx-9 {
    margin-left: 0.6428571429rem !important;
    margin-right: 0.6428571429rem !important;
  }
  .g-mx-10 {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }
  .g-mx-10 {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }
  .g-mx-15 {
    margin-left: 1.0714285714rem !important;
    margin-right: 1.0714285714rem !important;
  }
  .g-mx-20 {
    margin-left: 1.4285714286rem !important;
    margin-right: 1.4285714286rem !important;
  }
  .g-mx-25 {
    margin-left: 1.7857142857rem !important;
    margin-right: 1.7857142857rem !important;
  }
  .g-mx-30 {
    margin-left: 2.1428571429rem !important;
    margin-right: 2.1428571429rem !important;
  }
  .g-mx-35 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .g-mx-40 {
    margin-left: 2.8571428571rem !important;
    margin-right: 2.8571428571rem !important;
  }
  .g-mx-45 {
    margin-left: 3.2142857143rem !important;
    margin-right: 3.2142857143rem !important;
  }
  .g-mx-50 {
    margin-left: 3.5714285714rem !important;
    margin-right: 3.5714285714rem !important;
  }
  .g-mx-55 {
    margin-left: 3.9285714286rem !important;
    margin-right: 3.9285714286rem !important;
  }
  .g-mx-60 {
    margin-left: 4.2857142857rem !important;
    margin-right: 4.2857142857rem !important;
  }
  .g-mx-65 {
    margin-left: 4.6428571429rem !important;
    margin-right: 4.6428571429rem !important;
  }
  .g-mx-70 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .g-mx-75 {
    margin-left: 5.3571428571rem !important;
    margin-right: 5.3571428571rem !important;
  }
  .g-mx-80 {
    margin-left: 5.7142857143rem !important;
    margin-right: 5.7142857143rem !important;
  }
  .g-mx-85 {
    margin-left: 6.0714285714rem !important;
    margin-right: 6.0714285714rem !important;
  }
  .g-mx-90 {
    margin-left: 6.4285714286rem !important;
    margin-right: 6.4285714286rem !important;
  }
  .g-mx-95 {
    margin-left: 6.7857142857rem !important;
    margin-right: 6.7857142857rem !important;
  }
  .g-mx-100 {
    margin-left: 7.1428571429rem !important;
    margin-right: 7.1428571429rem !important;
  }
  .g-my-1 {
    margin-top: 0.0714285714rem !important;
    margin-bottom: 0.0714285714rem !important;
  }
  .g-my-2 {
    margin-top: 0.1428571429rem !important;
    margin-bottom: 0.1428571429rem !important;
  }
  .g-my-3 {
    margin-top: 0.2142857143rem !important;
    margin-bottom: 0.2142857143rem !important;
  }
  .g-my-4 {
    margin-top: 0.2857142857rem !important;
    margin-bottom: 0.2857142857rem !important;
  }
  .g-my-5 {
    margin-top: 0.3571428571rem !important;
    margin-bottom: 0.3571428571rem !important;
  }
  .g-my-6 {
    margin-top: 0.4285714286rem !important;
    margin-bottom: 0.4285714286rem !important;
  }
  .g-my-7 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .g-my-8 {
    margin-top: 0.5714285714rem !important;
    margin-bottom: 0.5714285714rem !important;
  }
  .g-my-9 {
    margin-top: 0.6428571429rem !important;
    margin-bottom: 0.6428571429rem !important;
  }
  .g-my-10 {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }
  .g-my-10 {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }
  .g-my-15 {
    margin-top: 1.0714285714rem !important;
    margin-bottom: 1.0714285714rem !important;
  }
  .g-my-20 {
    margin-top: 1.4285714286rem !important;
    margin-bottom: 1.4285714286rem !important;
  }
  .g-my-25 {
    margin-top: 1.7857142857rem !important;
    margin-bottom: 1.7857142857rem !important;
  }
  .g-my-30 {
    margin-top: 2.1428571429rem !important;
    margin-bottom: 2.1428571429rem !important;
  }
  .g-my-35 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .g-my-40 {
    margin-top: 2.8571428571rem !important;
    margin-bottom: 2.8571428571rem !important;
  }
  .g-my-45 {
    margin-top: 3.2142857143rem !important;
    margin-bottom: 3.2142857143rem !important;
  }
  .g-my-50 {
    margin-top: 3.5714285714rem !important;
    margin-bottom: 3.5714285714rem !important;
  }
  .g-my-55 {
    margin-top: 3.9285714286rem !important;
    margin-bottom: 3.9285714286rem !important;
  }
  .g-my-60 {
    margin-top: 4.2857142857rem !important;
    margin-bottom: 4.2857142857rem !important;
  }
  .g-my-65 {
    margin-top: 4.6428571429rem !important;
    margin-bottom: 4.6428571429rem !important;
  }
  .g-my-70 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .g-my-75 {
    margin-top: 5.3571428571rem !important;
    margin-bottom: 5.3571428571rem !important;
  }
  .g-my-80 {
    margin-top: 5.7142857143rem !important;
    margin-bottom: 5.7142857143rem !important;
  }
  .g-my-85 {
    margin-top: 6.0714285714rem !important;
    margin-bottom: 6.0714285714rem !important;
  }
  .g-my-90 {
    margin-top: 6.4285714286rem !important;
    margin-bottom: 6.4285714286rem !important;
  }
  .g-my-95 {
    margin-top: 6.7857142857rem !important;
    margin-bottom: 6.7857142857rem !important;
  }
  .g-my-100 {
    margin-top: 7.1428571429rem !important;
    margin-bottom: 7.1428571429rem !important;
  }
  .g-mt-1 {
    margin-top: 0.0714285714rem !important;
  }
  .g-mt-minus-1 {
    margin-top: -0.0714285714rem !important;
  }
  .g-mt-2 {
    margin-top: 0.1428571429rem !important;
  }
  .g-mt-minus-2 {
    margin-top: -0.1428571429rem !important;
  }
  .g-mt-3 {
    margin-top: 0.2142857143rem !important;
  }
  .g-mt-minus-3 {
    margin-top: -0.2142857143rem !important;
  }
  .g-mt-4 {
    margin-top: 0.2857142857rem !important;
  }
  .g-mt-minus-4 {
    margin-top: -0.2857142857rem !important;
  }
  .g-mt-5 {
    margin-top: 0.3571428571rem !important;
  }
  .g-mt-minus-5 {
    margin-top: -0.3571428571rem !important;
  }
  .g-mt-6 {
    margin-top: 0.4285714286rem !important;
  }
  .g-mt-minus-6 {
    margin-top: -0.4285714286rem !important;
  }
  .g-mt-7 {
    margin-top: 0.5rem !important;
  }
  .g-mt-minus-7 {
    margin-top: -0.5rem !important;
  }
  .g-mt-8 {
    margin-top: 0.5714285714rem !important;
  }
  .g-mt-minus-8 {
    margin-top: -0.5714285714rem !important;
  }
  .g-mt-9 {
    margin-top: 0.6428571429rem !important;
  }
  .g-mt-minus-9 {
    margin-top: -0.6428571429rem !important;
  }
  .g-mt-10 {
    margin-top: 0.7142857143rem !important;
  }
  .g-mt-minus-10 {
    margin-top: -0.7142857143rem !important;
  }
  .g-mt-11 {
    margin-top: 0.7857142857rem !important;
  }
  .g-mt-minus-11 {
    margin-top: -0.7857142857rem !important;
  }
  .g-mt-12 {
    margin-top: 0.8571428571rem !important;
  }
  .g-mt-minus-12 {
    margin-top: -0.8571428571rem !important;
  }
  .g-mt-13 {
    margin-top: 0.9285714286rem !important;
  }
  .g-mt-minus-13 {
    margin-top: -0.9285714286rem !important;
  }
  .g-mt-14 {
    margin-top: 1rem !important;
  }
  .g-mt-minus-14 {
    margin-top: -1rem !important;
  }
  .g-mt-15 {
    margin-top: 1.0714285714rem !important;
  }
  .g-mt-minus-15 {
    margin-top: -1.0714285714rem !important;
  }
  .g-mt-16 {
    margin-top: 1.1428571429rem !important;
  }
  .g-mt-minus-16 {
    margin-top: -1.1428571429rem !important;
  }
  .g-mt-17 {
    margin-top: 1.2142857143rem !important;
  }
  .g-mt-minus-17 {
    margin-top: -1.2142857143rem !important;
  }
  .g-mt-18 {
    margin-top: 1.2857142857rem !important;
  }
  .g-mt-minus-18 {
    margin-top: -1.2857142857rem !important;
  }
  .g-mt-19 {
    margin-top: 1.3571428571rem !important;
  }
  .g-mt-minus-19 {
    margin-top: -1.3571428571rem !important;
  }
  .g-mt-20 {
    margin-top: 1.4285714286rem !important;
  }
  .g-mt-minus-20 {
    margin-top: -1.4285714286rem !important;
  }
  .g-mt-10 {
    margin-top: 0.7142857143rem !important;
  }
  .g-mt-minus-10 {
    margin-top: -0.7142857143rem !important;
  }
  .g-mt-15 {
    margin-top: 1.0714285714rem !important;
  }
  .g-mt-minus-15 {
    margin-top: -1.0714285714rem !important;
  }
  .g-mt-20 {
    margin-top: 1.4285714286rem !important;
  }
  .g-mt-minus-20 {
    margin-top: -1.4285714286rem !important;
  }
  .g-mt-25 {
    margin-top: 1.7857142857rem !important;
  }
  .g-mt-minus-25 {
    margin-top: -1.7857142857rem !important;
  }
  .g-mt-30 {
    margin-top: 2.1428571429rem !important;
  }
  .g-mt-minus-30 {
    margin-top: -2.1428571429rem !important;
  }
  .g-mt-35 {
    margin-top: 2.5rem !important;
  }
  .g-mt-minus-35 {
    margin-top: -2.5rem !important;
  }
  .g-mt-40 {
    margin-top: 2.8571428571rem !important;
  }
  .g-mt-minus-40 {
    margin-top: -2.8571428571rem !important;
  }
  .g-mt-45 {
    margin-top: 3.2142857143rem !important;
  }
  .g-mt-minus-45 {
    margin-top: -3.2142857143rem !important;
  }
  .g-mt-50 {
    margin-top: 3.5714285714rem !important;
  }
  .g-mt-minus-50 {
    margin-top: -3.5714285714rem !important;
  }
  .g-mt-55 {
    margin-top: 3.9285714286rem !important;
  }
  .g-mt-minus-55 {
    margin-top: -3.9285714286rem !important;
  }
  .g-mt-60 {
    margin-top: 4.2857142857rem !important;
  }
  .g-mt-minus-60 {
    margin-top: -4.2857142857rem !important;
  }
  .g-mt-65 {
    margin-top: 4.6428571429rem !important;
  }
  .g-mt-minus-65 {
    margin-top: -4.6428571429rem !important;
  }
  .g-mt-70 {
    margin-top: 5rem !important;
  }
  .g-mt-minus-70 {
    margin-top: -5rem !important;
  }
  .g-mt-75 {
    margin-top: 5.3571428571rem !important;
  }
  .g-mt-minus-75 {
    margin-top: -5.3571428571rem !important;
  }
  .g-mt-80 {
    margin-top: 5.7142857143rem !important;
  }
  .g-mt-minus-80 {
    margin-top: -5.7142857143rem !important;
  }
  .g-mt-85 {
    margin-top: 6.0714285714rem !important;
  }
  .g-mt-minus-85 {
    margin-top: -6.0714285714rem !important;
  }
  .g-mt-90 {
    margin-top: 6.4285714286rem !important;
  }
  .g-mt-minus-90 {
    margin-top: -6.4285714286rem !important;
  }
  .g-mt-95 {
    margin-top: 6.7857142857rem !important;
  }
  .g-mt-minus-95 {
    margin-top: -6.7857142857rem !important;
  }
  .g-mt-100 {
    margin-top: 7.1428571429rem !important;
  }
  .g-mt-minus-100 {
    margin-top: -7.1428571429rem !important;
  }
  .g-mt-105 {
    margin-top: 7.5rem !important;
  }
  .g-mt-minus-105 {
    margin-top: -7.5rem !important;
  }
  .g-mt-110 {
    margin-top: 7.8571428571rem !important;
  }
  .g-mt-minus-110 {
    margin-top: -7.8571428571rem !important;
  }
  .g-mt-115 {
    margin-top: 8.2142857143rem !important;
  }
  .g-mt-minus-115 {
    margin-top: -8.2142857143rem !important;
  }
  .g-mt-120 {
    margin-top: 8.5714285714rem !important;
  }
  .g-mt-minus-120 {
    margin-top: -8.5714285714rem !important;
  }
  .g-mt-125 {
    margin-top: 8.9285714286rem !important;
  }
  .g-mt-minus-125 {
    margin-top: -8.9285714286rem !important;
  }
  .g-mt-130 {
    margin-top: 9.2857142857rem !important;
  }
  .g-mt-minus-130 {
    margin-top: -9.2857142857rem !important;
  }
  .g-mt-135 {
    margin-top: 9.6428571429rem !important;
  }
  .g-mt-minus-135 {
    margin-top: -9.6428571429rem !important;
  }
  .g-mt-140 {
    margin-top: 10rem !important;
  }
  .g-mt-minus-140 {
    margin-top: -10rem !important;
  }
  .g-mt-145 {
    margin-top: 10.3571428571rem !important;
  }
  .g-mt-minus-145 {
    margin-top: -10.3571428571rem !important;
  }
  .g-mt-150 {
    margin-top: 10.7142857143rem !important;
  }
  .g-mt-minus-150 {
    margin-top: -10.7142857143rem !important;
  }
  .g-mt-155 {
    margin-top: 11.0714285714rem !important;
  }
  .g-mt-minus-155 {
    margin-top: -11.0714285714rem !important;
  }
  .g-mt-160 {
    margin-top: 11.4285714286rem !important;
  }
  .g-mt-minus-160 {
    margin-top: -11.4285714286rem !important;
  }
  .g-mt-165 {
    margin-top: 11.7857142857rem !important;
  }
  .g-mt-minus-165 {
    margin-top: -11.7857142857rem !important;
  }
  .g-mt-170 {
    margin-top: 12.1428571429rem !important;
  }
  .g-mt-minus-170 {
    margin-top: -12.1428571429rem !important;
  }
  .g-mb-1 {
    margin-bottom: 0.0714285714rem !important;
  }
  .g-mb-minus-1 {
    margin-bottom: -0.0714285714rem !important;
  }
  .g-mb-2 {
    margin-bottom: 0.1428571429rem !important;
  }
  .g-mb-minus-2 {
    margin-bottom: -0.1428571429rem !important;
  }
  .g-mb-3 {
    margin-bottom: 0.2142857143rem !important;
  }
  .g-mb-minus-3 {
    margin-bottom: -0.2142857143rem !important;
  }
  .g-mb-4 {
    margin-bottom: 0.2857142857rem !important;
  }
  .g-mb-minus-4 {
    margin-bottom: -0.2857142857rem !important;
  }
  .g-mb-5 {
    margin-bottom: 0.3571428571rem !important;
  }
  .g-mb-minus-5 {
    margin-bottom: -0.3571428571rem !important;
  }
  .g-mb-6 {
    margin-bottom: 0.4285714286rem !important;
  }
  .g-mb-minus-6 {
    margin-bottom: -0.4285714286rem !important;
  }
  .g-mb-7 {
    margin-bottom: 0.5rem !important;
  }
  .g-mb-minus-7 {
    margin-bottom: -0.5rem !important;
  }
  .g-mb-8 {
    margin-bottom: 0.5714285714rem !important;
  }
  .g-mb-minus-8 {
    margin-bottom: -0.5714285714rem !important;
  }
  .g-mb-9 {
    margin-bottom: 0.6428571429rem !important;
  }
  .g-mb-minus-9 {
    margin-bottom: -0.6428571429rem !important;
  }
  .g-mb-10 {
    margin-bottom: 0.7142857143rem !important;
  }
  .g-mb-minus-10 {
    margin-bottom: -0.7142857143rem !important;
  }
  .g-mb-11 {
    margin-bottom: 0.7857142857rem !important;
  }
  .g-mb-minus-11 {
    margin-bottom: -0.7857142857rem !important;
  }
  .g-mb-12 {
    margin-bottom: 0.8571428571rem !important;
  }
  .g-mb-minus-12 {
    margin-bottom: -0.8571428571rem !important;
  }
  .g-mb-13 {
    margin-bottom: 0.9285714286rem !important;
  }
  .g-mb-minus-13 {
    margin-bottom: -0.9285714286rem !important;
  }
  .g-mb-14 {
    margin-bottom: 1rem !important;
  }
  .g-mb-minus-14 {
    margin-bottom: -1rem !important;
  }
  .g-mb-15 {
    margin-bottom: 1.0714285714rem !important;
  }
  .g-mb-minus-15 {
    margin-bottom: -1.0714285714rem !important;
  }
  .g-mb-16 {
    margin-bottom: 1.1428571429rem !important;
  }
  .g-mb-minus-16 {
    margin-bottom: -1.1428571429rem !important;
  }
  .g-mb-17 {
    margin-bottom: 1.2142857143rem !important;
  }
  .g-mb-minus-17 {
    margin-bottom: -1.2142857143rem !important;
  }
  .g-mb-18 {
    margin-bottom: 1.2857142857rem !important;
  }
  .g-mb-minus-18 {
    margin-bottom: -1.2857142857rem !important;
  }
  .g-mb-19 {
    margin-bottom: 1.3571428571rem !important;
  }
  .g-mb-minus-19 {
    margin-bottom: -1.3571428571rem !important;
  }
  .g-mb-20 {
    margin-bottom: 1.4285714286rem !important;
  }
  .g-mb-minus-20 {
    margin-bottom: -1.4285714286rem !important;
  }
  .g-mb-10 {
    margin-bottom: 0.7142857143rem !important;
  }
  .g-mb-15 {
    margin-bottom: 1.0714285714rem !important;
  }
  .g-mb-20 {
    margin-bottom: 1.4285714286rem !important;
  }
  .g-mb-25 {
    margin-bottom: 1.7857142857rem !important;
  }
  .g-mb-30 {
    margin-bottom: 2.1428571429rem !important;
  }
  .g-mb-35 {
    margin-bottom: 2.5rem !important;
  }
  .g-mb-40 {
    margin-bottom: 2.8571428571rem !important;
  }
  .g-mb-45 {
    margin-bottom: 3.2142857143rem !important;
  }
  .g-mb-50 {
    margin-bottom: 3.5714285714rem !important;
  }
  .g-mb-55 {
    margin-bottom: 3.9285714286rem !important;
  }
  .g-mb-60 {
    margin-bottom: 4.2857142857rem !important;
  }
  .g-mb-65 {
    margin-bottom: 4.6428571429rem !important;
  }
  .g-mb-70 {
    margin-bottom: 5rem !important;
  }
  .g-mb-75 {
    margin-bottom: 5.3571428571rem !important;
  }
  .g-mb-80 {
    margin-bottom: 5.7142857143rem !important;
  }
  .g-mb-85 {
    margin-bottom: 6.0714285714rem !important;
  }
  .g-mb-90 {
    margin-bottom: 6.4285714286rem !important;
  }
  .g-mb-95 {
    margin-bottom: 6.7857142857rem !important;
  }
  .g-mb-100 {
    margin-bottom: 7.1428571429rem !important;
  }
  .g-mb-105 {
    margin-bottom: 7.5rem !important;
  }
  .g-mb-110 {
    margin-bottom: 7.8571428571rem !important;
  }
  .g-mb-115 {
    margin-bottom: 8.2142857143rem !important;
  }
  .g-mb-120 {
    margin-bottom: 8.5714285714rem !important;
  }
  .g-mb-125 {
    margin-bottom: 8.9285714286rem !important;
  }
  .g-mb-130 {
    margin-bottom: 9.2857142857rem !important;
  }
  .g-mb-135 {
    margin-bottom: 9.6428571429rem !important;
  }
  .g-mb-140 {
    margin-bottom: 10rem !important;
  }
  .g-mb-145 {
    margin-bottom: 10.3571428571rem !important;
  }
  .g-mb-150 {
    margin-bottom: 10.7142857143rem !important;
  }
  .g-mb-155 {
    margin-bottom: 11.0714285714rem !important;
  }
  .g-mb-160 {
    margin-bottom: 11.4285714286rem !important;
  }
  .g-mb-165 {
    margin-bottom: 11.7857142857rem !important;
  }
  .g-mb-170 {
    margin-bottom: 12.1428571429rem !important;
  }
  .g-ml-1 {
    margin-left: 0.0714285714rem !important;
  }
  .g-ml-minus-1 {
    margin-left: -0.0714285714rem !important;
  }
  .g-ml-2 {
    margin-left: 0.1428571429rem !important;
  }
  .g-ml-minus-2 {
    margin-left: -0.1428571429rem !important;
  }
  .g-ml-3 {
    margin-left: 0.2142857143rem !important;
  }
  .g-ml-minus-3 {
    margin-left: -0.2142857143rem !important;
  }
  .g-ml-4 {
    margin-left: 0.2857142857rem !important;
  }
  .g-ml-minus-4 {
    margin-left: -0.2857142857rem !important;
  }
  .g-ml-5 {
    margin-left: 0.3571428571rem !important;
  }
  .g-ml-minus-5 {
    margin-left: -0.3571428571rem !important;
  }
  .g-ml-6 {
    margin-left: 0.4285714286rem !important;
  }
  .g-ml-minus-6 {
    margin-left: -0.4285714286rem !important;
  }
  .g-ml-7 {
    margin-left: 0.5rem !important;
  }
  .g-ml-minus-7 {
    margin-left: -0.5rem !important;
  }
  .g-ml-8 {
    margin-left: 0.5714285714rem !important;
  }
  .g-ml-minus-8 {
    margin-left: -0.5714285714rem !important;
  }
  .g-ml-9 {
    margin-left: 0.6428571429rem !important;
  }
  .g-ml-minus-9 {
    margin-left: -0.6428571429rem !important;
  }
  .g-ml-10 {
    margin-left: 0.7142857143rem !important;
  }
  .g-ml-minus-10 {
    margin-left: -0.7142857143rem !important;
  }
  .g-ml-5 {
    margin-left: 0.3571428571rem !important;
  }
  .g-ml-minus-5 {
    margin-left: -0.3571428571rem !important;
  }
  .g-ml-10 {
    margin-left: 0.7142857143rem !important;
  }
  .g-ml-minus-10 {
    margin-left: -0.7142857143rem !important;
  }
  .g-ml-15 {
    margin-left: 1.0714285714rem !important;
  }
  .g-ml-minus-15 {
    margin-left: -1.0714285714rem !important;
  }
  .g-ml-20 {
    margin-left: 1.4285714286rem !important;
  }
  .g-ml-minus-20 {
    margin-left: -1.4285714286rem !important;
  }
  .g-ml-25 {
    margin-left: 1.7857142857rem !important;
  }
  .g-ml-minus-25 {
    margin-left: -1.7857142857rem !important;
  }
  .g-ml-30 {
    margin-left: 2.1428571429rem !important;
  }
  .g-ml-minus-30 {
    margin-left: -2.1428571429rem !important;
  }
  .g-ml-35 {
    margin-left: 2.5rem !important;
  }
  .g-ml-minus-35 {
    margin-left: -2.5rem !important;
  }
  .g-ml-40 {
    margin-left: 2.8571428571rem !important;
  }
  .g-ml-minus-40 {
    margin-left: -2.8571428571rem !important;
  }
  .g-ml-45 {
    margin-left: 3.2142857143rem !important;
  }
  .g-ml-minus-45 {
    margin-left: -3.2142857143rem !important;
  }
  .g-ml-50 {
    margin-left: 3.5714285714rem !important;
  }
  .g-ml-minus-50 {
    margin-left: -3.5714285714rem !important;
  }
  .g-mr-1 {
    margin-right: 0.0714285714rem !important;
  }
  .g-mr-minus-1 {
    margin-right: -0.0714285714rem !important;
  }
  .g-mr-2 {
    margin-right: 0.1428571429rem !important;
  }
  .g-mr-minus-2 {
    margin-right: -0.1428571429rem !important;
  }
  .g-mr-3 {
    margin-right: 0.2142857143rem !important;
  }
  .g-mr-minus-3 {
    margin-right: -0.2142857143rem !important;
  }
  .g-mr-4 {
    margin-right: 0.2857142857rem !important;
  }
  .g-mr-minus-4 {
    margin-right: -0.2857142857rem !important;
  }
  .g-mr-5 {
    margin-right: 0.3571428571rem !important;
  }
  .g-mr-minus-5 {
    margin-right: -0.3571428571rem !important;
  }
  .g-mr-6 {
    margin-right: 0.4285714286rem !important;
  }
  .g-mr-minus-6 {
    margin-right: -0.4285714286rem !important;
  }
  .g-mr-7 {
    margin-right: 0.5rem !important;
  }
  .g-mr-minus-7 {
    margin-right: -0.5rem !important;
  }
  .g-mr-8 {
    margin-right: 0.5714285714rem !important;
  }
  .g-mr-minus-8 {
    margin-right: -0.5714285714rem !important;
  }
  .g-mr-9 {
    margin-right: 0.6428571429rem !important;
  }
  .g-mr-minus-9 {
    margin-right: -0.6428571429rem !important;
  }
  .g-mr-10 {
    margin-right: 0.7142857143rem !important;
  }
  .g-mr-minus-10 {
    margin-right: -0.7142857143rem !important;
  }
  .g-mr-5 {
    margin-right: 0.3571428571rem !important;
  }
  .g-mr-10 {
    margin-right: 0.7142857143rem !important;
  }
  .g-mr-15 {
    margin-right: 1.0714285714rem !important;
  }
  .g-mr-20 {
    margin-right: 1.4285714286rem !important;
  }
  .g-mr-25 {
    margin-right: 1.7857142857rem !important;
  }
  .g-mr-30 {
    margin-right: 2.1428571429rem !important;
  }
  .g-mr-35 {
    margin-right: 2.5rem !important;
  }
  .g-mr-40 {
    margin-right: 2.8571428571rem !important;
  }
  .g-mr-45 {
    margin-right: 3.2142857143rem !important;
  }
  .g-mr-50 {
    margin-right: 3.5714285714rem !important;
  }
}

/* Margin Spaces (sm)
------------------------------------*/
@media (min-width: 576px) {
  .g-ma-0--sm {
    margin: 0 !important;
  }
  .g-mx-0--sm {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .g-my-0--sm {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .g-ml-0--sm {
    margin-left: 0 !important;
  }
  .g-mr-0--sm {
    margin-right: 0 !important;
  }
  .g-mt-0--sm {
    margin-top: 0 !important;
  }
  .g-mb-0--sm {
    margin-bottom: 0 !important;
  }
  .g-mx-1--sm {
    margin-left: 0.0714285714rem !important;
    margin-right: 0.0714285714rem !important;
  }
  .g-mx-2--sm {
    margin-left: 0.1428571429rem !important;
    margin-right: 0.1428571429rem !important;
  }
  .g-mx-3--sm {
    margin-left: 0.2142857143rem !important;
    margin-right: 0.2142857143rem !important;
  }
  .g-mx-4--sm {
    margin-left: 0.2857142857rem !important;
    margin-right: 0.2857142857rem !important;
  }
  .g-mx-5--sm {
    margin-left: 0.3571428571rem !important;
    margin-right: 0.3571428571rem !important;
  }
  .g-mx-6--sm {
    margin-left: 0.4285714286rem !important;
    margin-right: 0.4285714286rem !important;
  }
  .g-mx-7--sm {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .g-mx-8--sm {
    margin-left: 0.5714285714rem !important;
    margin-right: 0.5714285714rem !important;
  }
  .g-mx-9--sm {
    margin-left: 0.6428571429rem !important;
    margin-right: 0.6428571429rem !important;
  }
  .g-mx-10--sm {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }
  .g-mx-10--sm {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }
  .g-mx-15--sm {
    margin-left: 1.0714285714rem !important;
    margin-right: 1.0714285714rem !important;
  }
  .g-mx-20--sm {
    margin-left: 1.4285714286rem !important;
    margin-right: 1.4285714286rem !important;
  }
  .g-mx-25--sm {
    margin-left: 1.7857142857rem !important;
    margin-right: 1.7857142857rem !important;
  }
  .g-mx-30--sm {
    margin-left: 2.1428571429rem !important;
    margin-right: 2.1428571429rem !important;
  }
  .g-mx-35--sm {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .g-mx-40--sm {
    margin-left: 2.8571428571rem !important;
    margin-right: 2.8571428571rem !important;
  }
  .g-mx-45--sm {
    margin-left: 3.2142857143rem !important;
    margin-right: 3.2142857143rem !important;
  }
  .g-mx-50--sm {
    margin-left: 3.5714285714rem !important;
    margin-right: 3.5714285714rem !important;
  }
  .g-mx-55--sm {
    margin-left: 3.9285714286rem !important;
    margin-right: 3.9285714286rem !important;
  }
  .g-mx-60--sm {
    margin-left: 4.2857142857rem !important;
    margin-right: 4.2857142857rem !important;
  }
  .g-mx-65--sm {
    margin-left: 4.6428571429rem !important;
    margin-right: 4.6428571429rem !important;
  }
  .g-mx-70--sm {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .g-mx-75--sm {
    margin-left: 5.3571428571rem !important;
    margin-right: 5.3571428571rem !important;
  }
  .g-mx-80--sm {
    margin-left: 5.7142857143rem !important;
    margin-right: 5.7142857143rem !important;
  }
  .g-mx-85--sm {
    margin-left: 6.0714285714rem !important;
    margin-right: 6.0714285714rem !important;
  }
  .g-mx-90--sm {
    margin-left: 6.4285714286rem !important;
    margin-right: 6.4285714286rem !important;
  }
  .g-mx-95--sm {
    margin-left: 6.7857142857rem !important;
    margin-right: 6.7857142857rem !important;
  }
  .g-mx-100--sm {
    margin-left: 7.1428571429rem !important;
    margin-right: 7.1428571429rem !important;
  }
  .g-my-1--sm {
    margin-top: 0.0714285714rem !important;
    margin-bottom: 0.0714285714rem !important;
  }
  .g-my-2--sm {
    margin-top: 0.1428571429rem !important;
    margin-bottom: 0.1428571429rem !important;
  }
  .g-my-3--sm {
    margin-top: 0.2142857143rem !important;
    margin-bottom: 0.2142857143rem !important;
  }
  .g-my-4--sm {
    margin-top: 0.2857142857rem !important;
    margin-bottom: 0.2857142857rem !important;
  }
  .g-my-5--sm {
    margin-top: 0.3571428571rem !important;
    margin-bottom: 0.3571428571rem !important;
  }
  .g-my-6--sm {
    margin-top: 0.4285714286rem !important;
    margin-bottom: 0.4285714286rem !important;
  }
  .g-my-7--sm {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .g-my-8--sm {
    margin-top: 0.5714285714rem !important;
    margin-bottom: 0.5714285714rem !important;
  }
  .g-my-9--sm {
    margin-top: 0.6428571429rem !important;
    margin-bottom: 0.6428571429rem !important;
  }
  .g-my-10--sm {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }
  .g-my-10--sm {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }
  .g-my-15--sm {
    margin-top: 1.0714285714rem !important;
    margin-bottom: 1.0714285714rem !important;
  }
  .g-my-20--sm {
    margin-top: 1.4285714286rem !important;
    margin-bottom: 1.4285714286rem !important;
  }
  .g-my-25--sm {
    margin-top: 1.7857142857rem !important;
    margin-bottom: 1.7857142857rem !important;
  }
  .g-my-30--sm {
    margin-top: 2.1428571429rem !important;
    margin-bottom: 2.1428571429rem !important;
  }
  .g-my-35--sm {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .g-my-40--sm {
    margin-top: 2.8571428571rem !important;
    margin-bottom: 2.8571428571rem !important;
  }
  .g-my-45--sm {
    margin-top: 3.2142857143rem !important;
    margin-bottom: 3.2142857143rem !important;
  }
  .g-my-50--sm {
    margin-top: 3.5714285714rem !important;
    margin-bottom: 3.5714285714rem !important;
  }
  .g-my-55--sm {
    margin-top: 3.9285714286rem !important;
    margin-bottom: 3.9285714286rem !important;
  }
  .g-my-60--sm {
    margin-top: 4.2857142857rem !important;
    margin-bottom: 4.2857142857rem !important;
  }
  .g-my-65--sm {
    margin-top: 4.6428571429rem !important;
    margin-bottom: 4.6428571429rem !important;
  }
  .g-my-70--sm {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .g-my-75--sm {
    margin-top: 5.3571428571rem !important;
    margin-bottom: 5.3571428571rem !important;
  }
  .g-my-80--sm {
    margin-top: 5.7142857143rem !important;
    margin-bottom: 5.7142857143rem !important;
  }
  .g-my-85--sm {
    margin-top: 6.0714285714rem !important;
    margin-bottom: 6.0714285714rem !important;
  }
  .g-my-90--sm {
    margin-top: 6.4285714286rem !important;
    margin-bottom: 6.4285714286rem !important;
  }
  .g-my-95--sm {
    margin-top: 6.7857142857rem !important;
    margin-bottom: 6.7857142857rem !important;
  }
  .g-my-100--sm {
    margin-top: 7.1428571429rem !important;
    margin-bottom: 7.1428571429rem !important;
  }
  .g-mt-1--sm {
    margin-top: 0.0714285714rem !important;
  }
  .g-mt-minus-1--sm {
    margin-top: -0.0714285714rem !important;
  }
  .g-mt-2--sm {
    margin-top: 0.1428571429rem !important;
  }
  .g-mt-minus-2--sm {
    margin-top: -0.1428571429rem !important;
  }
  .g-mt-3--sm {
    margin-top: 0.2142857143rem !important;
  }
  .g-mt-minus-3--sm {
    margin-top: -0.2142857143rem !important;
  }
  .g-mt-4--sm {
    margin-top: 0.2857142857rem !important;
  }
  .g-mt-minus-4--sm {
    margin-top: -0.2857142857rem !important;
  }
  .g-mt-5--sm {
    margin-top: 0.3571428571rem !important;
  }
  .g-mt-minus-5--sm {
    margin-top: -0.3571428571rem !important;
  }
  .g-mt-6--sm {
    margin-top: 0.4285714286rem !important;
  }
  .g-mt-minus-6--sm {
    margin-top: -0.4285714286rem !important;
  }
  .g-mt-7--sm {
    margin-top: 0.5rem !important;
  }
  .g-mt-minus-7--sm {
    margin-top: -0.5rem !important;
  }
  .g-mt-8--sm {
    margin-top: 0.5714285714rem !important;
  }
  .g-mt-minus-8--sm {
    margin-top: -0.5714285714rem !important;
  }
  .g-mt-9--sm {
    margin-top: 0.6428571429rem !important;
  }
  .g-mt-minus-9--sm {
    margin-top: -0.6428571429rem !important;
  }
  .g-mt-10--sm {
    margin-top: 0.7142857143rem !important;
  }
  .g-mt-minus-10--sm {
    margin-top: -0.7142857143rem !important;
  }
  .g-mt-11--sm {
    margin-top: 0.7857142857rem !important;
  }
  .g-mt-minus-11--sm {
    margin-top: -0.7857142857rem !important;
  }
  .g-mt-12--sm {
    margin-top: 0.8571428571rem !important;
  }
  .g-mt-minus-12--sm {
    margin-top: -0.8571428571rem !important;
  }
  .g-mt-13--sm {
    margin-top: 0.9285714286rem !important;
  }
  .g-mt-minus-13--sm {
    margin-top: -0.9285714286rem !important;
  }
  .g-mt-14--sm {
    margin-top: 1rem !important;
  }
  .g-mt-minus-14--sm {
    margin-top: -1rem !important;
  }
  .g-mt-15--sm {
    margin-top: 1.0714285714rem !important;
  }
  .g-mt-minus-15--sm {
    margin-top: -1.0714285714rem !important;
  }
  .g-mt-16--sm {
    margin-top: 1.1428571429rem !important;
  }
  .g-mt-minus-16--sm {
    margin-top: -1.1428571429rem !important;
  }
  .g-mt-17--sm {
    margin-top: 1.2142857143rem !important;
  }
  .g-mt-minus-17--sm {
    margin-top: -1.2142857143rem !important;
  }
  .g-mt-18--sm {
    margin-top: 1.2857142857rem !important;
  }
  .g-mt-minus-18--sm {
    margin-top: -1.2857142857rem !important;
  }
  .g-mt-19--sm {
    margin-top: 1.3571428571rem !important;
  }
  .g-mt-minus-19--sm {
    margin-top: -1.3571428571rem !important;
  }
  .g-mt-20--sm {
    margin-top: 1.4285714286rem !important;
  }
  .g-mt-minus-20--sm {
    margin-top: -1.4285714286rem !important;
  }
  .g-mt-10--sm {
    margin-top: 0.7142857143rem !important;
  }
  .g-mt-minus-10--sm {
    margin-top: -0.7142857143rem !important;
  }
  .g-mt-15--sm {
    margin-top: 1.0714285714rem !important;
  }
  .g-mt-minus-15--sm {
    margin-top: -1.0714285714rem !important;
  }
  .g-mt-20--sm {
    margin-top: 1.4285714286rem !important;
  }
  .g-mt-minus-20--sm {
    margin-top: -1.4285714286rem !important;
  }
  .g-mt-25--sm {
    margin-top: 1.7857142857rem !important;
  }
  .g-mt-minus-25--sm {
    margin-top: -1.7857142857rem !important;
  }
  .g-mt-30--sm {
    margin-top: 2.1428571429rem !important;
  }
  .g-mt-minus-30--sm {
    margin-top: -2.1428571429rem !important;
  }
  .g-mt-35--sm {
    margin-top: 2.5rem !important;
  }
  .g-mt-minus-35--sm {
    margin-top: -2.5rem !important;
  }
  .g-mt-40--sm {
    margin-top: 2.8571428571rem !important;
  }
  .g-mt-minus-40--sm {
    margin-top: -2.8571428571rem !important;
  }
  .g-mt-45--sm {
    margin-top: 3.2142857143rem !important;
  }
  .g-mt-minus-45--sm {
    margin-top: -3.2142857143rem !important;
  }
  .g-mt-50--sm {
    margin-top: 3.5714285714rem !important;
  }
  .g-mt-minus-50--sm {
    margin-top: -3.5714285714rem !important;
  }
  .g-mt-55--sm {
    margin-top: 3.9285714286rem !important;
  }
  .g-mt-minus-55--sm {
    margin-top: -3.9285714286rem !important;
  }
  .g-mt-60--sm {
    margin-top: 4.2857142857rem !important;
  }
  .g-mt-minus-60--sm {
    margin-top: -4.2857142857rem !important;
  }
  .g-mt-65--sm {
    margin-top: 4.6428571429rem !important;
  }
  .g-mt-minus-65--sm {
    margin-top: -4.6428571429rem !important;
  }
  .g-mt-70--sm {
    margin-top: 5rem !important;
  }
  .g-mt-minus-70--sm {
    margin-top: -5rem !important;
  }
  .g-mt-75--sm {
    margin-top: 5.3571428571rem !important;
  }
  .g-mt-minus-75--sm {
    margin-top: -5.3571428571rem !important;
  }
  .g-mt-80--sm {
    margin-top: 5.7142857143rem !important;
  }
  .g-mt-minus-80--sm {
    margin-top: -5.7142857143rem !important;
  }
  .g-mt-85--sm {
    margin-top: 6.0714285714rem !important;
  }
  .g-mt-minus-85--sm {
    margin-top: -6.0714285714rem !important;
  }
  .g-mt-90--sm {
    margin-top: 6.4285714286rem !important;
  }
  .g-mt-minus-90--sm {
    margin-top: -6.4285714286rem !important;
  }
  .g-mt-95--sm {
    margin-top: 6.7857142857rem !important;
  }
  .g-mt-minus-95--sm {
    margin-top: -6.7857142857rem !important;
  }
  .g-mt-100--sm {
    margin-top: 7.1428571429rem !important;
  }
  .g-mt-minus-100--sm {
    margin-top: -7.1428571429rem !important;
  }
  .g-mt-105--sm {
    margin-top: 7.5rem !important;
  }
  .g-mt-minus-105--sm {
    margin-top: -7.5rem !important;
  }
  .g-mt-110--sm {
    margin-top: 7.8571428571rem !important;
  }
  .g-mt-minus-110--sm {
    margin-top: -7.8571428571rem !important;
  }
  .g-mt-115--sm {
    margin-top: 8.2142857143rem !important;
  }
  .g-mt-minus-115--sm {
    margin-top: -8.2142857143rem !important;
  }
  .g-mt-120--sm {
    margin-top: 8.5714285714rem !important;
  }
  .g-mt-minus-120--sm {
    margin-top: -8.5714285714rem !important;
  }
  .g-mt-125--sm {
    margin-top: 8.9285714286rem !important;
  }
  .g-mt-minus-125--sm {
    margin-top: -8.9285714286rem !important;
  }
  .g-mt-130--sm {
    margin-top: 9.2857142857rem !important;
  }
  .g-mt-minus-130--sm {
    margin-top: -9.2857142857rem !important;
  }
  .g-mt-135--sm {
    margin-top: 9.6428571429rem !important;
  }
  .g-mt-minus-135--sm {
    margin-top: -9.6428571429rem !important;
  }
  .g-mt-140--sm {
    margin-top: 10rem !important;
  }
  .g-mt-minus-140--sm {
    margin-top: -10rem !important;
  }
  .g-mt-145--sm {
    margin-top: 10.3571428571rem !important;
  }
  .g-mt-minus-145--sm {
    margin-top: -10.3571428571rem !important;
  }
  .g-mt-150--sm {
    margin-top: 10.7142857143rem !important;
  }
  .g-mt-minus-150--sm {
    margin-top: -10.7142857143rem !important;
  }
  .g-mt-155--sm {
    margin-top: 11.0714285714rem !important;
  }
  .g-mt-minus-155--sm {
    margin-top: -11.0714285714rem !important;
  }
  .g-mt-160--sm {
    margin-top: 11.4285714286rem !important;
  }
  .g-mt-minus-160--sm {
    margin-top: -11.4285714286rem !important;
  }
  .g-mt-165--sm {
    margin-top: 11.7857142857rem !important;
  }
  .g-mt-minus-165--sm {
    margin-top: -11.7857142857rem !important;
  }
  .g-mt-170--sm {
    margin-top: 12.1428571429rem !important;
  }
  .g-mt-minus-170--sm {
    margin-top: -12.1428571429rem !important;
  }
  .g-mb-1--sm {
    margin-bottom: 0.0714285714rem !important;
  }
  .g-mb-minus-1--sm {
    margin-bottom: -0.0714285714rem !important;
  }
  .g-mb-2--sm {
    margin-bottom: 0.1428571429rem !important;
  }
  .g-mb-minus-2--sm {
    margin-bottom: -0.1428571429rem !important;
  }
  .g-mb-3--sm {
    margin-bottom: 0.2142857143rem !important;
  }
  .g-mb-minus-3--sm {
    margin-bottom: -0.2142857143rem !important;
  }
  .g-mb-4--sm {
    margin-bottom: 0.2857142857rem !important;
  }
  .g-mb-minus-4--sm {
    margin-bottom: -0.2857142857rem !important;
  }
  .g-mb-5--sm {
    margin-bottom: 0.3571428571rem !important;
  }
  .g-mb-minus-5--sm {
    margin-bottom: -0.3571428571rem !important;
  }
  .g-mb-6--sm {
    margin-bottom: 0.4285714286rem !important;
  }
  .g-mb-minus-6--sm {
    margin-bottom: -0.4285714286rem !important;
  }
  .g-mb-7--sm {
    margin-bottom: 0.5rem !important;
  }
  .g-mb-minus-7--sm {
    margin-bottom: -0.5rem !important;
  }
  .g-mb-8--sm {
    margin-bottom: 0.5714285714rem !important;
  }
  .g-mb-minus-8--sm {
    margin-bottom: -0.5714285714rem !important;
  }
  .g-mb-9--sm {
    margin-bottom: 0.6428571429rem !important;
  }
  .g-mb-minus-9--sm {
    margin-bottom: -0.6428571429rem !important;
  }
  .g-mb-10--sm {
    margin-bottom: 0.7142857143rem !important;
  }
  .g-mb-minus-10--sm {
    margin-bottom: -0.7142857143rem !important;
  }
  .g-mb-11--sm {
    margin-bottom: 0.7857142857rem !important;
  }
  .g-mb-minus-11--sm {
    margin-bottom: -0.7857142857rem !important;
  }
  .g-mb-12--sm {
    margin-bottom: 0.8571428571rem !important;
  }
  .g-mb-minus-12--sm {
    margin-bottom: -0.8571428571rem !important;
  }
  .g-mb-13--sm {
    margin-bottom: 0.9285714286rem !important;
  }
  .g-mb-minus-13--sm {
    margin-bottom: -0.9285714286rem !important;
  }
  .g-mb-14--sm {
    margin-bottom: 1rem !important;
  }
  .g-mb-minus-14--sm {
    margin-bottom: -1rem !important;
  }
  .g-mb-15--sm {
    margin-bottom: 1.0714285714rem !important;
  }
  .g-mb-minus-15--sm {
    margin-bottom: -1.0714285714rem !important;
  }
  .g-mb-16--sm {
    margin-bottom: 1.1428571429rem !important;
  }
  .g-mb-minus-16--sm {
    margin-bottom: -1.1428571429rem !important;
  }
  .g-mb-17--sm {
    margin-bottom: 1.2142857143rem !important;
  }
  .g-mb-minus-17--sm {
    margin-bottom: -1.2142857143rem !important;
  }
  .g-mb-18--sm {
    margin-bottom: 1.2857142857rem !important;
  }
  .g-mb-minus-18--sm {
    margin-bottom: -1.2857142857rem !important;
  }
  .g-mb-19--sm {
    margin-bottom: 1.3571428571rem !important;
  }
  .g-mb-minus-19--sm {
    margin-bottom: -1.3571428571rem !important;
  }
  .g-mb-20--sm {
    margin-bottom: 1.4285714286rem !important;
  }
  .g-mb-minus-20--sm {
    margin-bottom: -1.4285714286rem !important;
  }
  .g-mb-10--sm {
    margin-bottom: 0.7142857143rem !important;
  }
  .g-mb-15--sm {
    margin-bottom: 1.0714285714rem !important;
  }
  .g-mb-20--sm {
    margin-bottom: 1.4285714286rem !important;
  }
  .g-mb-25--sm {
    margin-bottom: 1.7857142857rem !important;
  }
  .g-mb-30--sm {
    margin-bottom: 2.1428571429rem !important;
  }
  .g-mb-35--sm {
    margin-bottom: 2.5rem !important;
  }
  .g-mb-40--sm {
    margin-bottom: 2.8571428571rem !important;
  }
  .g-mb-45--sm {
    margin-bottom: 3.2142857143rem !important;
  }
  .g-mb-50--sm {
    margin-bottom: 3.5714285714rem !important;
  }
  .g-mb-55--sm {
    margin-bottom: 3.9285714286rem !important;
  }
  .g-mb-60--sm {
    margin-bottom: 4.2857142857rem !important;
  }
  .g-mb-65--sm {
    margin-bottom: 4.6428571429rem !important;
  }
  .g-mb-70--sm {
    margin-bottom: 5rem !important;
  }
  .g-mb-75--sm {
    margin-bottom: 5.3571428571rem !important;
  }
  .g-mb-80--sm {
    margin-bottom: 5.7142857143rem !important;
  }
  .g-mb-85--sm {
    margin-bottom: 6.0714285714rem !important;
  }
  .g-mb-90--sm {
    margin-bottom: 6.4285714286rem !important;
  }
  .g-mb-95--sm {
    margin-bottom: 6.7857142857rem !important;
  }
  .g-mb-100--sm {
    margin-bottom: 7.1428571429rem !important;
  }
  .g-mb-105--sm {
    margin-bottom: 7.5rem !important;
  }
  .g-mb-110--sm {
    margin-bottom: 7.8571428571rem !important;
  }
  .g-mb-115--sm {
    margin-bottom: 8.2142857143rem !important;
  }
  .g-mb-120--sm {
    margin-bottom: 8.5714285714rem !important;
  }
  .g-mb-125--sm {
    margin-bottom: 8.9285714286rem !important;
  }
  .g-mb-130--sm {
    margin-bottom: 9.2857142857rem !important;
  }
  .g-mb-135--sm {
    margin-bottom: 9.6428571429rem !important;
  }
  .g-mb-140--sm {
    margin-bottom: 10rem !important;
  }
  .g-mb-145--sm {
    margin-bottom: 10.3571428571rem !important;
  }
  .g-mb-150--sm {
    margin-bottom: 10.7142857143rem !important;
  }
  .g-mb-155--sm {
    margin-bottom: 11.0714285714rem !important;
  }
  .g-mb-160--sm {
    margin-bottom: 11.4285714286rem !important;
  }
  .g-mb-165--sm {
    margin-bottom: 11.7857142857rem !important;
  }
  .g-mb-170--sm {
    margin-bottom: 12.1428571429rem !important;
  }
  .g-ml-1--sm {
    margin-left: 0.0714285714rem !important;
  }
  .g-ml-minus-1--sm {
    margin-left: -0.0714285714rem !important;
  }
  .g-ml-2--sm {
    margin-left: 0.1428571429rem !important;
  }
  .g-ml-minus-2--sm {
    margin-left: -0.1428571429rem !important;
  }
  .g-ml-3--sm {
    margin-left: 0.2142857143rem !important;
  }
  .g-ml-minus-3--sm {
    margin-left: -0.2142857143rem !important;
  }
  .g-ml-4--sm {
    margin-left: 0.2857142857rem !important;
  }
  .g-ml-minus-4--sm {
    margin-left: -0.2857142857rem !important;
  }
  .g-ml-5--sm {
    margin-left: 0.3571428571rem !important;
  }
  .g-ml-minus-5--sm {
    margin-left: -0.3571428571rem !important;
  }
  .g-ml-6--sm {
    margin-left: 0.4285714286rem !important;
  }
  .g-ml-minus-6--sm {
    margin-left: -0.4285714286rem !important;
  }
  .g-ml-7--sm {
    margin-left: 0.5rem !important;
  }
  .g-ml-minus-7--sm {
    margin-left: -0.5rem !important;
  }
  .g-ml-8--sm {
    margin-left: 0.5714285714rem !important;
  }
  .g-ml-minus-8--sm {
    margin-left: -0.5714285714rem !important;
  }
  .g-ml-9--sm {
    margin-left: 0.6428571429rem !important;
  }
  .g-ml-minus-9--sm {
    margin-left: -0.6428571429rem !important;
  }
  .g-ml-10--sm {
    margin-left: 0.7142857143rem !important;
  }
  .g-ml-minus-10--sm {
    margin-left: -0.7142857143rem !important;
  }
  .g-ml-5--sm {
    margin-left: 0.3571428571rem !important;
  }
  .g-ml-minus-5--sm {
    margin-left: -0.3571428571rem !important;
  }
  .g-ml-10--sm {
    margin-left: 0.7142857143rem !important;
  }
  .g-ml-minus-10--sm {
    margin-left: -0.7142857143rem !important;
  }
  .g-ml-15--sm {
    margin-left: 1.0714285714rem !important;
  }
  .g-ml-minus-15--sm {
    margin-left: -1.0714285714rem !important;
  }
  .g-ml-20--sm {
    margin-left: 1.4285714286rem !important;
  }
  .g-ml-minus-20--sm {
    margin-left: -1.4285714286rem !important;
  }
  .g-ml-25--sm {
    margin-left: 1.7857142857rem !important;
  }
  .g-ml-minus-25--sm {
    margin-left: -1.7857142857rem !important;
  }
  .g-ml-30--sm {
    margin-left: 2.1428571429rem !important;
  }
  .g-ml-minus-30--sm {
    margin-left: -2.1428571429rem !important;
  }
  .g-ml-35--sm {
    margin-left: 2.5rem !important;
  }
  .g-ml-minus-35--sm {
    margin-left: -2.5rem !important;
  }
  .g-ml-40--sm {
    margin-left: 2.8571428571rem !important;
  }
  .g-ml-minus-40--sm {
    margin-left: -2.8571428571rem !important;
  }
  .g-ml-45--sm {
    margin-left: 3.2142857143rem !important;
  }
  .g-ml-minus-45--sm {
    margin-left: -3.2142857143rem !important;
  }
  .g-ml-50--sm {
    margin-left: 3.5714285714rem !important;
  }
  .g-ml-minus-50--sm {
    margin-left: -3.5714285714rem !important;
  }
  .g-mr-1--sm {
    margin-right: 0.0714285714rem !important;
  }
  .g-mr-minus-1--sm {
    margin-right: -0.0714285714rem !important;
  }
  .g-mr-2--sm {
    margin-right: 0.1428571429rem !important;
  }
  .g-mr-minus-2--sm {
    margin-right: -0.1428571429rem !important;
  }
  .g-mr-3--sm {
    margin-right: 0.2142857143rem !important;
  }
  .g-mr-minus-3--sm {
    margin-right: -0.2142857143rem !important;
  }
  .g-mr-4--sm {
    margin-right: 0.2857142857rem !important;
  }
  .g-mr-minus-4--sm {
    margin-right: -0.2857142857rem !important;
  }
  .g-mr-5--sm {
    margin-right: 0.3571428571rem !important;
  }
  .g-mr-minus-5--sm {
    margin-right: -0.3571428571rem !important;
  }
  .g-mr-6--sm {
    margin-right: 0.4285714286rem !important;
  }
  .g-mr-minus-6--sm {
    margin-right: -0.4285714286rem !important;
  }
  .g-mr-7--sm {
    margin-right: 0.5rem !important;
  }
  .g-mr-minus-7--sm {
    margin-right: -0.5rem !important;
  }
  .g-mr-8--sm {
    margin-right: 0.5714285714rem !important;
  }
  .g-mr-minus-8--sm {
    margin-right: -0.5714285714rem !important;
  }
  .g-mr-9--sm {
    margin-right: 0.6428571429rem !important;
  }
  .g-mr-minus-9--sm {
    margin-right: -0.6428571429rem !important;
  }
  .g-mr-10--sm {
    margin-right: 0.7142857143rem !important;
  }
  .g-mr-minus-10--sm {
    margin-right: -0.7142857143rem !important;
  }
  .g-mr-5--sm {
    margin-right: 0.3571428571rem !important;
  }
  .g-mr-10--sm {
    margin-right: 0.7142857143rem !important;
  }
  .g-mr-15--sm {
    margin-right: 1.0714285714rem !important;
  }
  .g-mr-20--sm {
    margin-right: 1.4285714286rem !important;
  }
  .g-mr-25--sm {
    margin-right: 1.7857142857rem !important;
  }
  .g-mr-30--sm {
    margin-right: 2.1428571429rem !important;
  }
  .g-mr-35--sm {
    margin-right: 2.5rem !important;
  }
  .g-mr-40--sm {
    margin-right: 2.8571428571rem !important;
  }
  .g-mr-45--sm {
    margin-right: 3.2142857143rem !important;
  }
  .g-mr-50--sm {
    margin-right: 3.5714285714rem !important;
  }
}

/* Margin Spaces (md)
------------------------------------*/
@media (min-width: 768px) {
  .g-ma-0--md {
    margin: 0 !important;
  }
  .g-mx-0--md {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .g-my-0--md {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .g-ml-0--md {
    margin-left: 0 !important;
  }
  .g-mr-0--md {
    margin-right: 0 !important;
  }
  .g-mt-0--md {
    margin-top: 0 !important;
  }
  .g-mb-0--md {
    margin-bottom: 0 !important;
  }
  .g-mx-1--md {
    margin-left: 0.0714285714rem !important;
    margin-right: 0.0714285714rem !important;
  }
  .g-mx-2--md {
    margin-left: 0.1428571429rem !important;
    margin-right: 0.1428571429rem !important;
  }
  .g-mx-3--md {
    margin-left: 0.2142857143rem !important;
    margin-right: 0.2142857143rem !important;
  }
  .g-mx-4--md {
    margin-left: 0.2857142857rem !important;
    margin-right: 0.2857142857rem !important;
  }
  .g-mx-5--md {
    margin-left: 0.3571428571rem !important;
    margin-right: 0.3571428571rem !important;
  }
  .g-mx-6--md {
    margin-left: 0.4285714286rem !important;
    margin-right: 0.4285714286rem !important;
  }
  .g-mx-7--md {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .g-mx-8--md {
    margin-left: 0.5714285714rem !important;
    margin-right: 0.5714285714rem !important;
  }
  .g-mx-9--md {
    margin-left: 0.6428571429rem !important;
    margin-right: 0.6428571429rem !important;
  }
  .g-mx-10--md {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }
  .g-mx-10--md {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }
  .g-mx-15--md {
    margin-left: 1.0714285714rem !important;
    margin-right: 1.0714285714rem !important;
  }
  .g-mx-20--md {
    margin-left: 1.4285714286rem !important;
    margin-right: 1.4285714286rem !important;
  }
  .g-mx-25--md {
    margin-left: 1.7857142857rem !important;
    margin-right: 1.7857142857rem !important;
  }
  .g-mx-30--md {
    margin-left: 2.1428571429rem !important;
    margin-right: 2.1428571429rem !important;
  }
  .g-mx-35--md {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .g-mx-40--md {
    margin-left: 2.8571428571rem !important;
    margin-right: 2.8571428571rem !important;
  }
  .g-mx-45--md {
    margin-left: 3.2142857143rem !important;
    margin-right: 3.2142857143rem !important;
  }
  .g-mx-50--md {
    margin-left: 3.5714285714rem !important;
    margin-right: 3.5714285714rem !important;
  }
  .g-mx-55--md {
    margin-left: 3.9285714286rem !important;
    margin-right: 3.9285714286rem !important;
  }
  .g-mx-60--md {
    margin-left: 4.2857142857rem !important;
    margin-right: 4.2857142857rem !important;
  }
  .g-mx-65--md {
    margin-left: 4.6428571429rem !important;
    margin-right: 4.6428571429rem !important;
  }
  .g-mx-70--md {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .g-mx-75--md {
    margin-left: 5.3571428571rem !important;
    margin-right: 5.3571428571rem !important;
  }
  .g-mx-80--md {
    margin-left: 5.7142857143rem !important;
    margin-right: 5.7142857143rem !important;
  }
  .g-mx-85--md {
    margin-left: 6.0714285714rem !important;
    margin-right: 6.0714285714rem !important;
  }
  .g-mx-90--md {
    margin-left: 6.4285714286rem !important;
    margin-right: 6.4285714286rem !important;
  }
  .g-mx-95--md {
    margin-left: 6.7857142857rem !important;
    margin-right: 6.7857142857rem !important;
  }
  .g-mx-100--md {
    margin-left: 7.1428571429rem !important;
    margin-right: 7.1428571429rem !important;
  }
  .g-my-1--md {
    margin-top: 0.0714285714rem !important;
    margin-bottom: 0.0714285714rem !important;
  }
  .g-my-2--md {
    margin-top: 0.1428571429rem !important;
    margin-bottom: 0.1428571429rem !important;
  }
  .g-my-3--md {
    margin-top: 0.2142857143rem !important;
    margin-bottom: 0.2142857143rem !important;
  }
  .g-my-4--md {
    margin-top: 0.2857142857rem !important;
    margin-bottom: 0.2857142857rem !important;
  }
  .g-my-5--md {
    margin-top: 0.3571428571rem !important;
    margin-bottom: 0.3571428571rem !important;
  }
  .g-my-6--md {
    margin-top: 0.4285714286rem !important;
    margin-bottom: 0.4285714286rem !important;
  }
  .g-my-7--md {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .g-my-8--md {
    margin-top: 0.5714285714rem !important;
    margin-bottom: 0.5714285714rem !important;
  }
  .g-my-9--md {
    margin-top: 0.6428571429rem !important;
    margin-bottom: 0.6428571429rem !important;
  }
  .g-my-10--md {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }
  .g-my-10--md {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }
  .g-my-15--md {
    margin-top: 1.0714285714rem !important;
    margin-bottom: 1.0714285714rem !important;
  }
  .g-my-20--md {
    margin-top: 1.4285714286rem !important;
    margin-bottom: 1.4285714286rem !important;
  }
  .g-my-25--md {
    margin-top: 1.7857142857rem !important;
    margin-bottom: 1.7857142857rem !important;
  }
  .g-my-30--md {
    margin-top: 2.1428571429rem !important;
    margin-bottom: 2.1428571429rem !important;
  }
  .g-my-35--md {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .g-my-40--md {
    margin-top: 2.8571428571rem !important;
    margin-bottom: 2.8571428571rem !important;
  }
  .g-my-45--md {
    margin-top: 3.2142857143rem !important;
    margin-bottom: 3.2142857143rem !important;
  }
  .g-my-50--md {
    margin-top: 3.5714285714rem !important;
    margin-bottom: 3.5714285714rem !important;
  }
  .g-my-55--md {
    margin-top: 3.9285714286rem !important;
    margin-bottom: 3.9285714286rem !important;
  }
  .g-my-60--md {
    margin-top: 4.2857142857rem !important;
    margin-bottom: 4.2857142857rem !important;
  }
  .g-my-65--md {
    margin-top: 4.6428571429rem !important;
    margin-bottom: 4.6428571429rem !important;
  }
  .g-my-70--md {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .g-my-75--md {
    margin-top: 5.3571428571rem !important;
    margin-bottom: 5.3571428571rem !important;
  }
  .g-my-80--md {
    margin-top: 5.7142857143rem !important;
    margin-bottom: 5.7142857143rem !important;
  }
  .g-my-85--md {
    margin-top: 6.0714285714rem !important;
    margin-bottom: 6.0714285714rem !important;
  }
  .g-my-90--md {
    margin-top: 6.4285714286rem !important;
    margin-bottom: 6.4285714286rem !important;
  }
  .g-my-95--md {
    margin-top: 6.7857142857rem !important;
    margin-bottom: 6.7857142857rem !important;
  }
  .g-my-100--md {
    margin-top: 7.1428571429rem !important;
    margin-bottom: 7.1428571429rem !important;
  }
  .g-mt-1--md {
    margin-top: 0.0714285714rem !important;
  }
  .g-mt-minus-1--md {
    margin-top: -0.0714285714rem !important;
  }
  .g-mt-2--md {
    margin-top: 0.1428571429rem !important;
  }
  .g-mt-minus-2--md {
    margin-top: -0.1428571429rem !important;
  }
  .g-mt-3--md {
    margin-top: 0.2142857143rem !important;
  }
  .g-mt-minus-3--md {
    margin-top: -0.2142857143rem !important;
  }
  .g-mt-4--md {
    margin-top: 0.2857142857rem !important;
  }
  .g-mt-minus-4--md {
    margin-top: -0.2857142857rem !important;
  }
  .g-mt-5--md {
    margin-top: 0.3571428571rem !important;
  }
  .g-mt-minus-5--md {
    margin-top: -0.3571428571rem !important;
  }
  .g-mt-6--md {
    margin-top: 0.4285714286rem !important;
  }
  .g-mt-minus-6--md {
    margin-top: -0.4285714286rem !important;
  }
  .g-mt-7--md {
    margin-top: 0.5rem !important;
  }
  .g-mt-minus-7--md {
    margin-top: -0.5rem !important;
  }
  .g-mt-8--md {
    margin-top: 0.5714285714rem !important;
  }
  .g-mt-minus-8--md {
    margin-top: -0.5714285714rem !important;
  }
  .g-mt-9--md {
    margin-top: 0.6428571429rem !important;
  }
  .g-mt-minus-9--md {
    margin-top: -0.6428571429rem !important;
  }
  .g-mt-10--md {
    margin-top: 0.7142857143rem !important;
  }
  .g-mt-minus-10--md {
    margin-top: -0.7142857143rem !important;
  }
  .g-mt-11--md {
    margin-top: 0.7857142857rem !important;
  }
  .g-mt-minus-11--md {
    margin-top: -0.7857142857rem !important;
  }
  .g-mt-12--md {
    margin-top: 0.8571428571rem !important;
  }
  .g-mt-minus-12--md {
    margin-top: -0.8571428571rem !important;
  }
  .g-mt-13--md {
    margin-top: 0.9285714286rem !important;
  }
  .g-mt-minus-13--md {
    margin-top: -0.9285714286rem !important;
  }
  .g-mt-14--md {
    margin-top: 1rem !important;
  }
  .g-mt-minus-14--md {
    margin-top: -1rem !important;
  }
  .g-mt-15--md {
    margin-top: 1.0714285714rem !important;
  }
  .g-mt-minus-15--md {
    margin-top: -1.0714285714rem !important;
  }
  .g-mt-16--md {
    margin-top: 1.1428571429rem !important;
  }
  .g-mt-minus-16--md {
    margin-top: -1.1428571429rem !important;
  }
  .g-mt-17--md {
    margin-top: 1.2142857143rem !important;
  }
  .g-mt-minus-17--md {
    margin-top: -1.2142857143rem !important;
  }
  .g-mt-18--md {
    margin-top: 1.2857142857rem !important;
  }
  .g-mt-minus-18--md {
    margin-top: -1.2857142857rem !important;
  }
  .g-mt-19--md {
    margin-top: 1.3571428571rem !important;
  }
  .g-mt-minus-19--md {
    margin-top: -1.3571428571rem !important;
  }
  .g-mt-20--md {
    margin-top: 1.4285714286rem !important;
  }
  .g-mt-minus-20--md {
    margin-top: -1.4285714286rem !important;
  }
  .g-mt-10--md {
    margin-top: 0.7142857143rem !important;
  }
  .g-mt-minus-10--md {
    margin-top: -0.7142857143rem !important;
  }
  .g-mt-15--md {
    margin-top: 1.0714285714rem !important;
  }
  .g-mt-minus-15--md {
    margin-top: -1.0714285714rem !important;
  }
  .g-mt-20--md {
    margin-top: 1.4285714286rem !important;
  }
  .g-mt-minus-20--md {
    margin-top: -1.4285714286rem !important;
  }
  .g-mt-25--md {
    margin-top: 1.7857142857rem !important;
  }
  .g-mt-minus-25--md {
    margin-top: -1.7857142857rem !important;
  }
  .g-mt-30--md {
    margin-top: 2.1428571429rem !important;
  }
  .g-mt-minus-30--md {
    margin-top: -2.1428571429rem !important;
  }
  .g-mt-35--md {
    margin-top: 2.5rem !important;
  }
  .g-mt-minus-35--md {
    margin-top: -2.5rem !important;
  }
  .g-mt-40--md {
    margin-top: 2.8571428571rem !important;
  }
  .g-mt-minus-40--md {
    margin-top: -2.8571428571rem !important;
  }
  .g-mt-45--md {
    margin-top: 3.2142857143rem !important;
  }
  .g-mt-minus-45--md {
    margin-top: -3.2142857143rem !important;
  }
  .g-mt-50--md {
    margin-top: 3.5714285714rem !important;
  }
  .g-mt-minus-50--md {
    margin-top: -3.5714285714rem !important;
  }
  .g-mt-55--md {
    margin-top: 3.9285714286rem !important;
  }
  .g-mt-minus-55--md {
    margin-top: -3.9285714286rem !important;
  }
  .g-mt-60--md {
    margin-top: 4.2857142857rem !important;
  }
  .g-mt-minus-60--md {
    margin-top: -4.2857142857rem !important;
  }
  .g-mt-65--md {
    margin-top: 4.6428571429rem !important;
  }
  .g-mt-minus-65--md {
    margin-top: -4.6428571429rem !important;
  }
  .g-mt-70--md {
    margin-top: 5rem !important;
  }
  .g-mt-minus-70--md {
    margin-top: -5rem !important;
  }
  .g-mt-75--md {
    margin-top: 5.3571428571rem !important;
  }
  .g-mt-minus-75--md {
    margin-top: -5.3571428571rem !important;
  }
  .g-mt-80--md {
    margin-top: 5.7142857143rem !important;
  }
  .g-mt-minus-80--md {
    margin-top: -5.7142857143rem !important;
  }
  .g-mt-85--md {
    margin-top: 6.0714285714rem !important;
  }
  .g-mt-minus-85--md {
    margin-top: -6.0714285714rem !important;
  }
  .g-mt-90--md {
    margin-top: 6.4285714286rem !important;
  }
  .g-mt-minus-90--md {
    margin-top: -6.4285714286rem !important;
  }
  .g-mt-95--md {
    margin-top: 6.7857142857rem !important;
  }
  .g-mt-minus-95--md {
    margin-top: -6.7857142857rem !important;
  }
  .g-mt-100--md {
    margin-top: 7.1428571429rem !important;
  }
  .g-mt-minus-100--md {
    margin-top: -7.1428571429rem !important;
  }
  .g-mt-105--md {
    margin-top: 7.5rem !important;
  }
  .g-mt-minus-105--md {
    margin-top: -7.5rem !important;
  }
  .g-mt-110--md {
    margin-top: 7.8571428571rem !important;
  }
  .g-mt-minus-110--md {
    margin-top: -7.8571428571rem !important;
  }
  .g-mt-115--md {
    margin-top: 8.2142857143rem !important;
  }
  .g-mt-minus-115--md {
    margin-top: -8.2142857143rem !important;
  }
  .g-mt-120--md {
    margin-top: 8.5714285714rem !important;
  }
  .g-mt-minus-120--md {
    margin-top: -8.5714285714rem !important;
  }
  .g-mt-125--md {
    margin-top: 8.9285714286rem !important;
  }
  .g-mt-minus-125--md {
    margin-top: -8.9285714286rem !important;
  }
  .g-mt-130--md {
    margin-top: 9.2857142857rem !important;
  }
  .g-mt-minus-130--md {
    margin-top: -9.2857142857rem !important;
  }
  .g-mt-135--md {
    margin-top: 9.6428571429rem !important;
  }
  .g-mt-minus-135--md {
    margin-top: -9.6428571429rem !important;
  }
  .g-mt-140--md {
    margin-top: 10rem !important;
  }
  .g-mt-minus-140--md {
    margin-top: -10rem !important;
  }
  .g-mt-145--md {
    margin-top: 10.3571428571rem !important;
  }
  .g-mt-minus-145--md {
    margin-top: -10.3571428571rem !important;
  }
  .g-mt-150--md {
    margin-top: 10.7142857143rem !important;
  }
  .g-mt-minus-150--md {
    margin-top: -10.7142857143rem !important;
  }
  .g-mt-155--md {
    margin-top: 11.0714285714rem !important;
  }
  .g-mt-minus-155--md {
    margin-top: -11.0714285714rem !important;
  }
  .g-mt-160--md {
    margin-top: 11.4285714286rem !important;
  }
  .g-mt-minus-160--md {
    margin-top: -11.4285714286rem !important;
  }
  .g-mt-165--md {
    margin-top: 11.7857142857rem !important;
  }
  .g-mt-minus-165--md {
    margin-top: -11.7857142857rem !important;
  }
  .g-mt-170--md {
    margin-top: 12.1428571429rem !important;
  }
  .g-mt-minus-170--md {
    margin-top: -12.1428571429rem !important;
  }
  .g-mb-1--md {
    margin-bottom: 0.0714285714rem !important;
  }
  .g-mb-minus-1--md {
    margin-bottom: -0.0714285714rem !important;
  }
  .g-mb-2--md {
    margin-bottom: 0.1428571429rem !important;
  }
  .g-mb-minus-2--md {
    margin-bottom: -0.1428571429rem !important;
  }
  .g-mb-3--md {
    margin-bottom: 0.2142857143rem !important;
  }
  .g-mb-minus-3--md {
    margin-bottom: -0.2142857143rem !important;
  }
  .g-mb-4--md {
    margin-bottom: 0.2857142857rem !important;
  }
  .g-mb-minus-4--md {
    margin-bottom: -0.2857142857rem !important;
  }
  .g-mb-5--md {
    margin-bottom: 0.3571428571rem !important;
  }
  .g-mb-minus-5--md {
    margin-bottom: -0.3571428571rem !important;
  }
  .g-mb-6--md {
    margin-bottom: 0.4285714286rem !important;
  }
  .g-mb-minus-6--md {
    margin-bottom: -0.4285714286rem !important;
  }
  .g-mb-7--md {
    margin-bottom: 0.5rem !important;
  }
  .g-mb-minus-7--md {
    margin-bottom: -0.5rem !important;
  }
  .g-mb-8--md {
    margin-bottom: 0.5714285714rem !important;
  }
  .g-mb-minus-8--md {
    margin-bottom: -0.5714285714rem !important;
  }
  .g-mb-9--md {
    margin-bottom: 0.6428571429rem !important;
  }
  .g-mb-minus-9--md {
    margin-bottom: -0.6428571429rem !important;
  }
  .g-mb-10--md {
    margin-bottom: 0.7142857143rem !important;
  }
  .g-mb-minus-10--md {
    margin-bottom: -0.7142857143rem !important;
  }
  .g-mb-11--md {
    margin-bottom: 0.7857142857rem !important;
  }
  .g-mb-minus-11--md {
    margin-bottom: -0.7857142857rem !important;
  }
  .g-mb-12--md {
    margin-bottom: 0.8571428571rem !important;
  }
  .g-mb-minus-12--md {
    margin-bottom: -0.8571428571rem !important;
  }
  .g-mb-13--md {
    margin-bottom: 0.9285714286rem !important;
  }
  .g-mb-minus-13--md {
    margin-bottom: -0.9285714286rem !important;
  }
  .g-mb-14--md {
    margin-bottom: 1rem !important;
  }
  .g-mb-minus-14--md {
    margin-bottom: -1rem !important;
  }
  .g-mb-15--md {
    margin-bottom: 1.0714285714rem !important;
  }
  .g-mb-minus-15--md {
    margin-bottom: -1.0714285714rem !important;
  }
  .g-mb-16--md {
    margin-bottom: 1.1428571429rem !important;
  }
  .g-mb-minus-16--md {
    margin-bottom: -1.1428571429rem !important;
  }
  .g-mb-17--md {
    margin-bottom: 1.2142857143rem !important;
  }
  .g-mb-minus-17--md {
    margin-bottom: -1.2142857143rem !important;
  }
  .g-mb-18--md {
    margin-bottom: 1.2857142857rem !important;
  }
  .g-mb-minus-18--md {
    margin-bottom: -1.2857142857rem !important;
  }
  .g-mb-19--md {
    margin-bottom: 1.3571428571rem !important;
  }
  .g-mb-minus-19--md {
    margin-bottom: -1.3571428571rem !important;
  }
  .g-mb-20--md {
    margin-bottom: 1.4285714286rem !important;
  }
  .g-mb-minus-20--md {
    margin-bottom: -1.4285714286rem !important;
  }
  .g-mb-10--md {
    margin-bottom: 0.7142857143rem !important;
  }
  .g-mb-15--md {
    margin-bottom: 1.0714285714rem !important;
  }
  .g-mb-20--md {
    margin-bottom: 1.4285714286rem !important;
  }
  .g-mb-25--md {
    margin-bottom: 1.7857142857rem !important;
  }
  .g-mb-30--md {
    margin-bottom: 2.1428571429rem !important;
  }
  .g-mb-35--md {
    margin-bottom: 2.5rem !important;
  }
  .g-mb-40--md {
    margin-bottom: 2.8571428571rem !important;
  }
  .g-mb-45--md {
    margin-bottom: 3.2142857143rem !important;
  }
  .g-mb-50--md {
    margin-bottom: 3.5714285714rem !important;
  }
  .g-mb-55--md {
    margin-bottom: 3.9285714286rem !important;
  }
  .g-mb-60--md {
    margin-bottom: 4.2857142857rem !important;
  }
  .g-mb-65--md {
    margin-bottom: 4.6428571429rem !important;
  }
  .g-mb-70--md {
    margin-bottom: 5rem !important;
  }
  .g-mb-75--md {
    margin-bottom: 5.3571428571rem !important;
  }
  .g-mb-80--md {
    margin-bottom: 5.7142857143rem !important;
  }
  .g-mb-85--md {
    margin-bottom: 6.0714285714rem !important;
  }
  .g-mb-90--md {
    margin-bottom: 6.4285714286rem !important;
  }
  .g-mb-95--md {
    margin-bottom: 6.7857142857rem !important;
  }
  .g-mb-100--md {
    margin-bottom: 7.1428571429rem !important;
  }
  .g-mb-105--md {
    margin-bottom: 7.5rem !important;
  }
  .g-mb-110--md {
    margin-bottom: 7.8571428571rem !important;
  }
  .g-mb-115--md {
    margin-bottom: 8.2142857143rem !important;
  }
  .g-mb-120--md {
    margin-bottom: 8.5714285714rem !important;
  }
  .g-mb-125--md {
    margin-bottom: 8.9285714286rem !important;
  }
  .g-mb-130--md {
    margin-bottom: 9.2857142857rem !important;
  }
  .g-mb-135--md {
    margin-bottom: 9.6428571429rem !important;
  }
  .g-mb-140--md {
    margin-bottom: 10rem !important;
  }
  .g-mb-145--md {
    margin-bottom: 10.3571428571rem !important;
  }
  .g-mb-150--md {
    margin-bottom: 10.7142857143rem !important;
  }
  .g-mb-155--md {
    margin-bottom: 11.0714285714rem !important;
  }
  .g-mb-160--md {
    margin-bottom: 11.4285714286rem !important;
  }
  .g-mb-165--md {
    margin-bottom: 11.7857142857rem !important;
  }
  .g-mb-170--md {
    margin-bottom: 12.1428571429rem !important;
  }
  .g-ml-1--md {
    margin-left: 0.0714285714rem !important;
  }
  .g-ml-minus-1--md {
    margin-left: -0.0714285714rem !important;
  }
  .g-ml-2--md {
    margin-left: 0.1428571429rem !important;
  }
  .g-ml-minus-2--md {
    margin-left: -0.1428571429rem !important;
  }
  .g-ml-3--md {
    margin-left: 0.2142857143rem !important;
  }
  .g-ml-minus-3--md {
    margin-left: -0.2142857143rem !important;
  }
  .g-ml-4--md {
    margin-left: 0.2857142857rem !important;
  }
  .g-ml-minus-4--md {
    margin-left: -0.2857142857rem !important;
  }
  .g-ml-5--md {
    margin-left: 0.3571428571rem !important;
  }
  .g-ml-minus-5--md {
    margin-left: -0.3571428571rem !important;
  }
  .g-ml-6--md {
    margin-left: 0.4285714286rem !important;
  }
  .g-ml-minus-6--md {
    margin-left: -0.4285714286rem !important;
  }
  .g-ml-7--md {
    margin-left: 0.5rem !important;
  }
  .g-ml-minus-7--md {
    margin-left: -0.5rem !important;
  }
  .g-ml-8--md {
    margin-left: 0.5714285714rem !important;
  }
  .g-ml-minus-8--md {
    margin-left: -0.5714285714rem !important;
  }
  .g-ml-9--md {
    margin-left: 0.6428571429rem !important;
  }
  .g-ml-minus-9--md {
    margin-left: -0.6428571429rem !important;
  }
  .g-ml-10--md {
    margin-left: 0.7142857143rem !important;
  }
  .g-ml-minus-10--md {
    margin-left: -0.7142857143rem !important;
  }
  .g-ml-5--md {
    margin-left: 0.3571428571rem !important;
  }
  .g-ml-minus-5--md {
    margin-left: -0.3571428571rem !important;
  }
  .g-ml-10--md {
    margin-left: 0.7142857143rem !important;
  }
  .g-ml-minus-10--md {
    margin-left: -0.7142857143rem !important;
  }
  .g-ml-15--md {
    margin-left: 1.0714285714rem !important;
  }
  .g-ml-minus-15--md {
    margin-left: -1.0714285714rem !important;
  }
  .g-ml-20--md {
    margin-left: 1.4285714286rem !important;
  }
  .g-ml-minus-20--md {
    margin-left: -1.4285714286rem !important;
  }
  .g-ml-25--md {
    margin-left: 1.7857142857rem !important;
  }
  .g-ml-minus-25--md {
    margin-left: -1.7857142857rem !important;
  }
  .g-ml-30--md {
    margin-left: 2.1428571429rem !important;
  }
  .g-ml-minus-30--md {
    margin-left: -2.1428571429rem !important;
  }
  .g-ml-35--md {
    margin-left: 2.5rem !important;
  }
  .g-ml-minus-35--md {
    margin-left: -2.5rem !important;
  }
  .g-ml-40--md {
    margin-left: 2.8571428571rem !important;
  }
  .g-ml-minus-40--md {
    margin-left: -2.8571428571rem !important;
  }
  .g-ml-45--md {
    margin-left: 3.2142857143rem !important;
  }
  .g-ml-minus-45--md {
    margin-left: -3.2142857143rem !important;
  }
  .g-ml-50--md {
    margin-left: 3.5714285714rem !important;
  }
  .g-ml-minus-50--md {
    margin-left: -3.5714285714rem !important;
  }
  .g-mr-1--md {
    margin-right: 0.0714285714rem !important;
  }
  .g-mr-minus-1--md {
    margin-right: -0.0714285714rem !important;
  }
  .g-mr-2--md {
    margin-right: 0.1428571429rem !important;
  }
  .g-mr-minus-2--md {
    margin-right: -0.1428571429rem !important;
  }
  .g-mr-3--md {
    margin-right: 0.2142857143rem !important;
  }
  .g-mr-minus-3--md {
    margin-right: -0.2142857143rem !important;
  }
  .g-mr-4--md {
    margin-right: 0.2857142857rem !important;
  }
  .g-mr-minus-4--md {
    margin-right: -0.2857142857rem !important;
  }
  .g-mr-5--md {
    margin-right: 0.3571428571rem !important;
  }
  .g-mr-minus-5--md {
    margin-right: -0.3571428571rem !important;
  }
  .g-mr-6--md {
    margin-right: 0.4285714286rem !important;
  }
  .g-mr-minus-6--md {
    margin-right: -0.4285714286rem !important;
  }
  .g-mr-7--md {
    margin-right: 0.5rem !important;
  }
  .g-mr-minus-7--md {
    margin-right: -0.5rem !important;
  }
  .g-mr-8--md {
    margin-right: 0.5714285714rem !important;
  }
  .g-mr-minus-8--md {
    margin-right: -0.5714285714rem !important;
  }
  .g-mr-9--md {
    margin-right: 0.6428571429rem !important;
  }
  .g-mr-minus-9--md {
    margin-right: -0.6428571429rem !important;
  }
  .g-mr-10--md {
    margin-right: 0.7142857143rem !important;
  }
  .g-mr-minus-10--md {
    margin-right: -0.7142857143rem !important;
  }
  .g-mr-5--md {
    margin-right: 0.3571428571rem !important;
  }
  .g-mr-10--md {
    margin-right: 0.7142857143rem !important;
  }
  .g-mr-15--md {
    margin-right: 1.0714285714rem !important;
  }
  .g-mr-20--md {
    margin-right: 1.4285714286rem !important;
  }
  .g-mr-25--md {
    margin-right: 1.7857142857rem !important;
  }
  .g-mr-30--md {
    margin-right: 2.1428571429rem !important;
  }
  .g-mr-35--md {
    margin-right: 2.5rem !important;
  }
  .g-mr-40--md {
    margin-right: 2.8571428571rem !important;
  }
  .g-mr-45--md {
    margin-right: 3.2142857143rem !important;
  }
  .g-mr-50--md {
    margin-right: 3.5714285714rem !important;
  }
}

/* Margin Spaces (lg)
------------------------------------*/
@media (min-width: 992px) {
  .g-ma-0--lg {
    margin: 0 !important;
  }
  .g-mx-0--lg {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .g-my-0--lg {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .g-ml-0--lg {
    margin-left: 0 !important;
  }
  .g-mr-0--lg {
    margin-right: 0 !important;
  }
  .g-mt-0--lg {
    margin-top: 0 !important;
  }
  .g-mb-0--lg {
    margin-bottom: 0 !important;
  }
  .g-mx-1--lg {
    margin-left: 0.0714285714rem !important;
    margin-right: 0.0714285714rem !important;
  }
  .g-mx-2--lg {
    margin-left: 0.1428571429rem !important;
    margin-right: 0.1428571429rem !important;
  }
  .g-mx-3--lg {
    margin-left: 0.2142857143rem !important;
    margin-right: 0.2142857143rem !important;
  }
  .g-mx-4--lg {
    margin-left: 0.2857142857rem !important;
    margin-right: 0.2857142857rem !important;
  }
  .g-mx-5--lg {
    margin-left: 0.3571428571rem !important;
    margin-right: 0.3571428571rem !important;
  }
  .g-mx-6--lg {
    margin-left: 0.4285714286rem !important;
    margin-right: 0.4285714286rem !important;
  }
  .g-mx-7--lg {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .g-mx-8--lg {
    margin-left: 0.5714285714rem !important;
    margin-right: 0.5714285714rem !important;
  }
  .g-mx-9--lg {
    margin-left: 0.6428571429rem !important;
    margin-right: 0.6428571429rem !important;
  }
  .g-mx-10--lg {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }
  .g-mx-10--lg {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }
  .g-mx-15--lg {
    margin-left: 1.0714285714rem !important;
    margin-right: 1.0714285714rem !important;
  }
  .g-mx-20--lg {
    margin-left: 1.4285714286rem !important;
    margin-right: 1.4285714286rem !important;
  }
  .g-mx-25--lg {
    margin-left: 1.7857142857rem !important;
    margin-right: 1.7857142857rem !important;
  }
  .g-mx-30--lg {
    margin-left: 2.1428571429rem !important;
    margin-right: 2.1428571429rem !important;
  }
  .g-mx-35--lg {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .g-mx-40--lg {
    margin-left: 2.8571428571rem !important;
    margin-right: 2.8571428571rem !important;
  }
  .g-mx-45--lg {
    margin-left: 3.2142857143rem !important;
    margin-right: 3.2142857143rem !important;
  }
  .g-mx-50--lg {
    margin-left: 3.5714285714rem !important;
    margin-right: 3.5714285714rem !important;
  }
  .g-mx-55--lg {
    margin-left: 3.9285714286rem !important;
    margin-right: 3.9285714286rem !important;
  }
  .g-mx-60--lg {
    margin-left: 4.2857142857rem !important;
    margin-right: 4.2857142857rem !important;
  }
  .g-mx-65--lg {
    margin-left: 4.6428571429rem !important;
    margin-right: 4.6428571429rem !important;
  }
  .g-mx-70--lg {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .g-mx-75--lg {
    margin-left: 5.3571428571rem !important;
    margin-right: 5.3571428571rem !important;
  }
  .g-mx-80--lg {
    margin-left: 5.7142857143rem !important;
    margin-right: 5.7142857143rem !important;
  }
  .g-mx-85--lg {
    margin-left: 6.0714285714rem !important;
    margin-right: 6.0714285714rem !important;
  }
  .g-mx-90--lg {
    margin-left: 6.4285714286rem !important;
    margin-right: 6.4285714286rem !important;
  }
  .g-mx-95--lg {
    margin-left: 6.7857142857rem !important;
    margin-right: 6.7857142857rem !important;
  }
  .g-mx-100--lg {
    margin-left: 7.1428571429rem !important;
    margin-right: 7.1428571429rem !important;
  }
  .g-my-1--lg {
    margin-top: 0.0714285714rem !important;
    margin-bottom: 0.0714285714rem !important;
  }
  .g-my-2--lg {
    margin-top: 0.1428571429rem !important;
    margin-bottom: 0.1428571429rem !important;
  }
  .g-my-3--lg {
    margin-top: 0.2142857143rem !important;
    margin-bottom: 0.2142857143rem !important;
  }
  .g-my-4--lg {
    margin-top: 0.2857142857rem !important;
    margin-bottom: 0.2857142857rem !important;
  }
  .g-my-5--lg {
    margin-top: 0.3571428571rem !important;
    margin-bottom: 0.3571428571rem !important;
  }
  .g-my-6--lg {
    margin-top: 0.4285714286rem !important;
    margin-bottom: 0.4285714286rem !important;
  }
  .g-my-7--lg {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .g-my-8--lg {
    margin-top: 0.5714285714rem !important;
    margin-bottom: 0.5714285714rem !important;
  }
  .g-my-9--lg {
    margin-top: 0.6428571429rem !important;
    margin-bottom: 0.6428571429rem !important;
  }
  .g-my-10--lg {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }
  .g-my-10--lg {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }
  .g-my-15--lg {
    margin-top: 1.0714285714rem !important;
    margin-bottom: 1.0714285714rem !important;
  }
  .g-my-20--lg {
    margin-top: 1.4285714286rem !important;
    margin-bottom: 1.4285714286rem !important;
  }
  .g-my-25--lg {
    margin-top: 1.7857142857rem !important;
    margin-bottom: 1.7857142857rem !important;
  }
  .g-my-30--lg {
    margin-top: 2.1428571429rem !important;
    margin-bottom: 2.1428571429rem !important;
  }
  .g-my-35--lg {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .g-my-40--lg {
    margin-top: 2.8571428571rem !important;
    margin-bottom: 2.8571428571rem !important;
  }
  .g-my-45--lg {
    margin-top: 3.2142857143rem !important;
    margin-bottom: 3.2142857143rem !important;
  }
  .g-my-50--lg {
    margin-top: 3.5714285714rem !important;
    margin-bottom: 3.5714285714rem !important;
  }
  .g-my-55--lg {
    margin-top: 3.9285714286rem !important;
    margin-bottom: 3.9285714286rem !important;
  }
  .g-my-60--lg {
    margin-top: 4.2857142857rem !important;
    margin-bottom: 4.2857142857rem !important;
  }
  .g-my-65--lg {
    margin-top: 4.6428571429rem !important;
    margin-bottom: 4.6428571429rem !important;
  }
  .g-my-70--lg {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .g-my-75--lg {
    margin-top: 5.3571428571rem !important;
    margin-bottom: 5.3571428571rem !important;
  }
  .g-my-80--lg {
    margin-top: 5.7142857143rem !important;
    margin-bottom: 5.7142857143rem !important;
  }
  .g-my-85--lg {
    margin-top: 6.0714285714rem !important;
    margin-bottom: 6.0714285714rem !important;
  }
  .g-my-90--lg {
    margin-top: 6.4285714286rem !important;
    margin-bottom: 6.4285714286rem !important;
  }
  .g-my-95--lg {
    margin-top: 6.7857142857rem !important;
    margin-bottom: 6.7857142857rem !important;
  }
  .g-my-100--lg {
    margin-top: 7.1428571429rem !important;
    margin-bottom: 7.1428571429rem !important;
  }
  .g-mt-1--lg {
    margin-top: 0.0714285714rem !important;
  }
  .g-mt-minus-1--lg {
    margin-top: -0.0714285714rem !important;
  }
  .g-mt-2--lg {
    margin-top: 0.1428571429rem !important;
  }
  .g-mt-minus-2--lg {
    margin-top: -0.1428571429rem !important;
  }
  .g-mt-3--lg {
    margin-top: 0.2142857143rem !important;
  }
  .g-mt-minus-3--lg {
    margin-top: -0.2142857143rem !important;
  }
  .g-mt-4--lg {
    margin-top: 0.2857142857rem !important;
  }
  .g-mt-minus-4--lg {
    margin-top: -0.2857142857rem !important;
  }
  .g-mt-5--lg {
    margin-top: 0.3571428571rem !important;
  }
  .g-mt-minus-5--lg {
    margin-top: -0.3571428571rem !important;
  }
  .g-mt-6--lg {
    margin-top: 0.4285714286rem !important;
  }
  .g-mt-minus-6--lg {
    margin-top: -0.4285714286rem !important;
  }
  .g-mt-7--lg {
    margin-top: 0.5rem !important;
  }
  .g-mt-minus-7--lg {
    margin-top: -0.5rem !important;
  }
  .g-mt-8--lg {
    margin-top: 0.5714285714rem !important;
  }
  .g-mt-minus-8--lg {
    margin-top: -0.5714285714rem !important;
  }
  .g-mt-9--lg {
    margin-top: 0.6428571429rem !important;
  }
  .g-mt-minus-9--lg {
    margin-top: -0.6428571429rem !important;
  }
  .g-mt-10--lg {
    margin-top: 0.7142857143rem !important;
  }
  .g-mt-minus-10--lg {
    margin-top: -0.7142857143rem !important;
  }
  .g-mt-11--lg {
    margin-top: 0.7857142857rem !important;
  }
  .g-mt-minus-11--lg {
    margin-top: -0.7857142857rem !important;
  }
  .g-mt-12--lg {
    margin-top: 0.8571428571rem !important;
  }
  .g-mt-minus-12--lg {
    margin-top: -0.8571428571rem !important;
  }
  .g-mt-13--lg {
    margin-top: 0.9285714286rem !important;
  }
  .g-mt-minus-13--lg {
    margin-top: -0.9285714286rem !important;
  }
  .g-mt-14--lg {
    margin-top: 1rem !important;
  }
  .g-mt-minus-14--lg {
    margin-top: -1rem !important;
  }
  .g-mt-15--lg {
    margin-top: 1.0714285714rem !important;
  }
  .g-mt-minus-15--lg {
    margin-top: -1.0714285714rem !important;
  }
  .g-mt-16--lg {
    margin-top: 1.1428571429rem !important;
  }
  .g-mt-minus-16--lg {
    margin-top: -1.1428571429rem !important;
  }
  .g-mt-17--lg {
    margin-top: 1.2142857143rem !important;
  }
  .g-mt-minus-17--lg {
    margin-top: -1.2142857143rem !important;
  }
  .g-mt-18--lg {
    margin-top: 1.2857142857rem !important;
  }
  .g-mt-minus-18--lg {
    margin-top: -1.2857142857rem !important;
  }
  .g-mt-19--lg {
    margin-top: 1.3571428571rem !important;
  }
  .g-mt-minus-19--lg {
    margin-top: -1.3571428571rem !important;
  }
  .g-mt-20--lg {
    margin-top: 1.4285714286rem !important;
  }
  .g-mt-minus-20--lg {
    margin-top: -1.4285714286rem !important;
  }
  .g-mt-10--lg {
    margin-top: 0.7142857143rem !important;
  }
  .g-mt-minus-10--lg {
    margin-top: -0.7142857143rem !important;
  }
  .g-mt-15--lg {
    margin-top: 1.0714285714rem !important;
  }
  .g-mt-minus-15--lg {
    margin-top: -1.0714285714rem !important;
  }
  .g-mt-20--lg {
    margin-top: 1.4285714286rem !important;
  }
  .g-mt-minus-20--lg {
    margin-top: -1.4285714286rem !important;
  }
  .g-mt-25--lg {
    margin-top: 1.7857142857rem !important;
  }
  .g-mt-minus-25--lg {
    margin-top: -1.7857142857rem !important;
  }
  .g-mt-30--lg {
    margin-top: 2.1428571429rem !important;
  }
  .g-mt-minus-30--lg {
    margin-top: -2.1428571429rem !important;
  }
  .g-mt-35--lg {
    margin-top: 2.5rem !important;
  }
  .g-mt-minus-35--lg {
    margin-top: -2.5rem !important;
  }
  .g-mt-40--lg {
    margin-top: 2.8571428571rem !important;
  }
  .g-mt-minus-40--lg {
    margin-top: -2.8571428571rem !important;
  }
  .g-mt-45--lg {
    margin-top: 3.2142857143rem !important;
  }
  .g-mt-minus-45--lg {
    margin-top: -3.2142857143rem !important;
  }
  .g-mt-50--lg {
    margin-top: 3.5714285714rem !important;
  }
  .g-mt-minus-50--lg {
    margin-top: -3.5714285714rem !important;
  }
  .g-mt-55--lg {
    margin-top: 3.9285714286rem !important;
  }
  .g-mt-minus-55--lg {
    margin-top: -3.9285714286rem !important;
  }
  .g-mt-60--lg {
    margin-top: 4.2857142857rem !important;
  }
  .g-mt-minus-60--lg {
    margin-top: -4.2857142857rem !important;
  }
  .g-mt-65--lg {
    margin-top: 4.6428571429rem !important;
  }
  .g-mt-minus-65--lg {
    margin-top: -4.6428571429rem !important;
  }
  .g-mt-70--lg {
    margin-top: 5rem !important;
  }
  .g-mt-minus-70--lg {
    margin-top: -5rem !important;
  }
  .g-mt-75--lg {
    margin-top: 5.3571428571rem !important;
  }
  .g-mt-minus-75--lg {
    margin-top: -5.3571428571rem !important;
  }
  .g-mt-80--lg {
    margin-top: 5.7142857143rem !important;
  }
  .g-mt-minus-80--lg {
    margin-top: -5.7142857143rem !important;
  }
  .g-mt-85--lg {
    margin-top: 6.0714285714rem !important;
  }
  .g-mt-minus-85--lg {
    margin-top: -6.0714285714rem !important;
  }
  .g-mt-90--lg {
    margin-top: 6.4285714286rem !important;
  }
  .g-mt-minus-90--lg {
    margin-top: -6.4285714286rem !important;
  }
  .g-mt-95--lg {
    margin-top: 6.7857142857rem !important;
  }
  .g-mt-minus-95--lg {
    margin-top: -6.7857142857rem !important;
  }
  .g-mt-100--lg {
    margin-top: 7.1428571429rem !important;
  }
  .g-mt-minus-100--lg {
    margin-top: -7.1428571429rem !important;
  }
  .g-mt-105--lg {
    margin-top: 7.5rem !important;
  }
  .g-mt-minus-105--lg {
    margin-top: -7.5rem !important;
  }
  .g-mt-110--lg {
    margin-top: 7.8571428571rem !important;
  }
  .g-mt-minus-110--lg {
    margin-top: -7.8571428571rem !important;
  }
  .g-mt-115--lg {
    margin-top: 8.2142857143rem !important;
  }
  .g-mt-minus-115--lg {
    margin-top: -8.2142857143rem !important;
  }
  .g-mt-120--lg {
    margin-top: 8.5714285714rem !important;
  }
  .g-mt-minus-120--lg {
    margin-top: -8.5714285714rem !important;
  }
  .g-mt-125--lg {
    margin-top: 8.9285714286rem !important;
  }
  .g-mt-minus-125--lg {
    margin-top: -8.9285714286rem !important;
  }
  .g-mt-130--lg {
    margin-top: 9.2857142857rem !important;
  }
  .g-mt-minus-130--lg {
    margin-top: -9.2857142857rem !important;
  }
  .g-mt-135--lg {
    margin-top: 9.6428571429rem !important;
  }
  .g-mt-minus-135--lg {
    margin-top: -9.6428571429rem !important;
  }
  .g-mt-140--lg {
    margin-top: 10rem !important;
  }
  .g-mt-minus-140--lg {
    margin-top: -10rem !important;
  }
  .g-mt-145--lg {
    margin-top: 10.3571428571rem !important;
  }
  .g-mt-minus-145--lg {
    margin-top: -10.3571428571rem !important;
  }
  .g-mt-150--lg {
    margin-top: 10.7142857143rem !important;
  }
  .g-mt-minus-150--lg {
    margin-top: -10.7142857143rem !important;
  }
  .g-mt-155--lg {
    margin-top: 11.0714285714rem !important;
  }
  .g-mt-minus-155--lg {
    margin-top: -11.0714285714rem !important;
  }
  .g-mt-160--lg {
    margin-top: 11.4285714286rem !important;
  }
  .g-mt-minus-160--lg {
    margin-top: -11.4285714286rem !important;
  }
  .g-mt-165--lg {
    margin-top: 11.7857142857rem !important;
  }
  .g-mt-minus-165--lg {
    margin-top: -11.7857142857rem !important;
  }
  .g-mt-170--lg {
    margin-top: 12.1428571429rem !important;
  }
  .g-mt-minus-170--lg {
    margin-top: -12.1428571429rem !important;
  }
  .g-mb-1--lg {
    margin-bottom: 0.0714285714rem !important;
  }
  .g-mb-minus-1--lg {
    margin-bottom: -0.0714285714rem !important;
  }
  .g-mb-2--lg {
    margin-bottom: 0.1428571429rem !important;
  }
  .g-mb-minus-2--lg {
    margin-bottom: -0.1428571429rem !important;
  }
  .g-mb-3--lg {
    margin-bottom: 0.2142857143rem !important;
  }
  .g-mb-minus-3--lg {
    margin-bottom: -0.2142857143rem !important;
  }
  .g-mb-4--lg {
    margin-bottom: 0.2857142857rem !important;
  }
  .g-mb-minus-4--lg {
    margin-bottom: -0.2857142857rem !important;
  }
  .g-mb-5--lg {
    margin-bottom: 0.3571428571rem !important;
  }
  .g-mb-minus-5--lg {
    margin-bottom: -0.3571428571rem !important;
  }
  .g-mb-6--lg {
    margin-bottom: 0.4285714286rem !important;
  }
  .g-mb-minus-6--lg {
    margin-bottom: -0.4285714286rem !important;
  }
  .g-mb-7--lg {
    margin-bottom: 0.5rem !important;
  }
  .g-mb-minus-7--lg {
    margin-bottom: -0.5rem !important;
  }
  .g-mb-8--lg {
    margin-bottom: 0.5714285714rem !important;
  }
  .g-mb-minus-8--lg {
    margin-bottom: -0.5714285714rem !important;
  }
  .g-mb-9--lg {
    margin-bottom: 0.6428571429rem !important;
  }
  .g-mb-minus-9--lg {
    margin-bottom: -0.6428571429rem !important;
  }
  .g-mb-10--lg {
    margin-bottom: 0.7142857143rem !important;
  }
  .g-mb-minus-10--lg {
    margin-bottom: -0.7142857143rem !important;
  }
  .g-mb-11--lg {
    margin-bottom: 0.7857142857rem !important;
  }
  .g-mb-minus-11--lg {
    margin-bottom: -0.7857142857rem !important;
  }
  .g-mb-12--lg {
    margin-bottom: 0.8571428571rem !important;
  }
  .g-mb-minus-12--lg {
    margin-bottom: -0.8571428571rem !important;
  }
  .g-mb-13--lg {
    margin-bottom: 0.9285714286rem !important;
  }
  .g-mb-minus-13--lg {
    margin-bottom: -0.9285714286rem !important;
  }
  .g-mb-14--lg {
    margin-bottom: 1rem !important;
  }
  .g-mb-minus-14--lg {
    margin-bottom: -1rem !important;
  }
  .g-mb-15--lg {
    margin-bottom: 1.0714285714rem !important;
  }
  .g-mb-minus-15--lg {
    margin-bottom: -1.0714285714rem !important;
  }
  .g-mb-16--lg {
    margin-bottom: 1.1428571429rem !important;
  }
  .g-mb-minus-16--lg {
    margin-bottom: -1.1428571429rem !important;
  }
  .g-mb-17--lg {
    margin-bottom: 1.2142857143rem !important;
  }
  .g-mb-minus-17--lg {
    margin-bottom: -1.2142857143rem !important;
  }
  .g-mb-18--lg {
    margin-bottom: 1.2857142857rem !important;
  }
  .g-mb-minus-18--lg {
    margin-bottom: -1.2857142857rem !important;
  }
  .g-mb-19--lg {
    margin-bottom: 1.3571428571rem !important;
  }
  .g-mb-minus-19--lg {
    margin-bottom: -1.3571428571rem !important;
  }
  .g-mb-20--lg {
    margin-bottom: 1.4285714286rem !important;
  }
  .g-mb-minus-20--lg {
    margin-bottom: -1.4285714286rem !important;
  }
  .g-mb-10--lg {
    margin-bottom: 0.7142857143rem !important;
  }
  .g-mb-15--lg {
    margin-bottom: 1.0714285714rem !important;
  }
  .g-mb-20--lg {
    margin-bottom: 1.4285714286rem !important;
  }
  .g-mb-25--lg {
    margin-bottom: 1.7857142857rem !important;
  }
  .g-mb-30--lg {
    margin-bottom: 2.1428571429rem !important;
  }
  .g-mb-35--lg {
    margin-bottom: 2.5rem !important;
  }
  .g-mb-40--lg {
    margin-bottom: 2.8571428571rem !important;
  }
  .g-mb-45--lg {
    margin-bottom: 3.2142857143rem !important;
  }
  .g-mb-50--lg {
    margin-bottom: 3.5714285714rem !important;
  }
  .g-mb-55--lg {
    margin-bottom: 3.9285714286rem !important;
  }
  .g-mb-60--lg {
    margin-bottom: 4.2857142857rem !important;
  }
  .g-mb-65--lg {
    margin-bottom: 4.6428571429rem !important;
  }
  .g-mb-70--lg {
    margin-bottom: 5rem !important;
  }
  .g-mb-75--lg {
    margin-bottom: 5.3571428571rem !important;
  }
  .g-mb-80--lg {
    margin-bottom: 5.7142857143rem !important;
  }
  .g-mb-85--lg {
    margin-bottom: 6.0714285714rem !important;
  }
  .g-mb-90--lg {
    margin-bottom: 6.4285714286rem !important;
  }
  .g-mb-95--lg {
    margin-bottom: 6.7857142857rem !important;
  }
  .g-mb-100--lg {
    margin-bottom: 7.1428571429rem !important;
  }
  .g-mb-105--lg {
    margin-bottom: 7.5rem !important;
  }
  .g-mb-110--lg {
    margin-bottom: 7.8571428571rem !important;
  }
  .g-mb-115--lg {
    margin-bottom: 8.2142857143rem !important;
  }
  .g-mb-120--lg {
    margin-bottom: 8.5714285714rem !important;
  }
  .g-mb-125--lg {
    margin-bottom: 8.9285714286rem !important;
  }
  .g-mb-130--lg {
    margin-bottom: 9.2857142857rem !important;
  }
  .g-mb-135--lg {
    margin-bottom: 9.6428571429rem !important;
  }
  .g-mb-140--lg {
    margin-bottom: 10rem !important;
  }
  .g-mb-145--lg {
    margin-bottom: 10.3571428571rem !important;
  }
  .g-mb-150--lg {
    margin-bottom: 10.7142857143rem !important;
  }
  .g-mb-155--lg {
    margin-bottom: 11.0714285714rem !important;
  }
  .g-mb-160--lg {
    margin-bottom: 11.4285714286rem !important;
  }
  .g-mb-165--lg {
    margin-bottom: 11.7857142857rem !important;
  }
  .g-mb-170--lg {
    margin-bottom: 12.1428571429rem !important;
  }
  .g-ml-1--lg {
    margin-left: 0.0714285714rem !important;
  }
  .g-ml-minus-1--lg {
    margin-left: -0.0714285714rem !important;
  }
  .g-ml-2--lg {
    margin-left: 0.1428571429rem !important;
  }
  .g-ml-minus-2--lg {
    margin-left: -0.1428571429rem !important;
  }
  .g-ml-3--lg {
    margin-left: 0.2142857143rem !important;
  }
  .g-ml-minus-3--lg {
    margin-left: -0.2142857143rem !important;
  }
  .g-ml-4--lg {
    margin-left: 0.2857142857rem !important;
  }
  .g-ml-minus-4--lg {
    margin-left: -0.2857142857rem !important;
  }
  .g-ml-5--lg {
    margin-left: 0.3571428571rem !important;
  }
  .g-ml-minus-5--lg {
    margin-left: -0.3571428571rem !important;
  }
  .g-ml-6--lg {
    margin-left: 0.4285714286rem !important;
  }
  .g-ml-minus-6--lg {
    margin-left: -0.4285714286rem !important;
  }
  .g-ml-7--lg {
    margin-left: 0.5rem !important;
  }
  .g-ml-minus-7--lg {
    margin-left: -0.5rem !important;
  }
  .g-ml-8--lg {
    margin-left: 0.5714285714rem !important;
  }
  .g-ml-minus-8--lg {
    margin-left: -0.5714285714rem !important;
  }
  .g-ml-9--lg {
    margin-left: 0.6428571429rem !important;
  }
  .g-ml-minus-9--lg {
    margin-left: -0.6428571429rem !important;
  }
  .g-ml-10--lg {
    margin-left: 0.7142857143rem !important;
  }
  .g-ml-minus-10--lg {
    margin-left: -0.7142857143rem !important;
  }
  .g-ml-5--lg {
    margin-left: 0.3571428571rem !important;
  }
  .g-ml-minus-5--lg {
    margin-left: -0.3571428571rem !important;
  }
  .g-ml-10--lg {
    margin-left: 0.7142857143rem !important;
  }
  .g-ml-minus-10--lg {
    margin-left: -0.7142857143rem !important;
  }
  .g-ml-15--lg {
    margin-left: 1.0714285714rem !important;
  }
  .g-ml-minus-15--lg {
    margin-left: -1.0714285714rem !important;
  }
  .g-ml-20--lg {
    margin-left: 1.4285714286rem !important;
  }
  .g-ml-minus-20--lg {
    margin-left: -1.4285714286rem !important;
  }
  .g-ml-25--lg {
    margin-left: 1.7857142857rem !important;
  }
  .g-ml-minus-25--lg {
    margin-left: -1.7857142857rem !important;
  }
  .g-ml-30--lg {
    margin-left: 2.1428571429rem !important;
  }
  .g-ml-minus-30--lg {
    margin-left: -2.1428571429rem !important;
  }
  .g-ml-35--lg {
    margin-left: 2.5rem !important;
  }
  .g-ml-minus-35--lg {
    margin-left: -2.5rem !important;
  }
  .g-ml-40--lg {
    margin-left: 2.8571428571rem !important;
  }
  .g-ml-minus-40--lg {
    margin-left: -2.8571428571rem !important;
  }
  .g-ml-45--lg {
    margin-left: 3.2142857143rem !important;
  }
  .g-ml-minus-45--lg {
    margin-left: -3.2142857143rem !important;
  }
  .g-ml-50--lg {
    margin-left: 3.5714285714rem !important;
  }
  .g-ml-minus-50--lg {
    margin-left: -3.5714285714rem !important;
  }
  .g-mr-1--lg {
    margin-right: 0.0714285714rem !important;
  }
  .g-mr-minus-1--lg {
    margin-right: -0.0714285714rem !important;
  }
  .g-mr-2--lg {
    margin-right: 0.1428571429rem !important;
  }
  .g-mr-minus-2--lg {
    margin-right: -0.1428571429rem !important;
  }
  .g-mr-3--lg {
    margin-right: 0.2142857143rem !important;
  }
  .g-mr-minus-3--lg {
    margin-right: -0.2142857143rem !important;
  }
  .g-mr-4--lg {
    margin-right: 0.2857142857rem !important;
  }
  .g-mr-minus-4--lg {
    margin-right: -0.2857142857rem !important;
  }
  .g-mr-5--lg {
    margin-right: 0.3571428571rem !important;
  }
  .g-mr-minus-5--lg {
    margin-right: -0.3571428571rem !important;
  }
  .g-mr-6--lg {
    margin-right: 0.4285714286rem !important;
  }
  .g-mr-minus-6--lg {
    margin-right: -0.4285714286rem !important;
  }
  .g-mr-7--lg {
    margin-right: 0.5rem !important;
  }
  .g-mr-minus-7--lg {
    margin-right: -0.5rem !important;
  }
  .g-mr-8--lg {
    margin-right: 0.5714285714rem !important;
  }
  .g-mr-minus-8--lg {
    margin-right: -0.5714285714rem !important;
  }
  .g-mr-9--lg {
    margin-right: 0.6428571429rem !important;
  }
  .g-mr-minus-9--lg {
    margin-right: -0.6428571429rem !important;
  }
  .g-mr-10--lg {
    margin-right: 0.7142857143rem !important;
  }
  .g-mr-minus-10--lg {
    margin-right: -0.7142857143rem !important;
  }
  .g-mr-5--lg {
    margin-right: 0.3571428571rem !important;
  }
  .g-mr-10--lg {
    margin-right: 0.7142857143rem !important;
  }
  .g-mr-15--lg {
    margin-right: 1.0714285714rem !important;
  }
  .g-mr-20--lg {
    margin-right: 1.4285714286rem !important;
  }
  .g-mr-25--lg {
    margin-right: 1.7857142857rem !important;
  }
  .g-mr-30--lg {
    margin-right: 2.1428571429rem !important;
  }
  .g-mr-35--lg {
    margin-right: 2.5rem !important;
  }
  .g-mr-40--lg {
    margin-right: 2.8571428571rem !important;
  }
  .g-mr-45--lg {
    margin-right: 3.2142857143rem !important;
  }
  .g-mr-50--lg {
    margin-right: 3.5714285714rem !important;
  }
}

/* Margin Spaces (xl)
------------------------------------*/
/* P */
@media (min-width: 1200px) {
  .g-ma-0--xl {
    margin: 0 !important;
  }
  .g-mx-0--xl {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .g-my-0--xl {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .g-ml-0--xl {
    margin-left: 0 !important;
  }
  .g-mr-0--xl {
    margin-right: 0 !important;
  }
  .g-mt-0--xl {
    margin-top: 0 !important;
  }
  .g-mb-0--xl {
    margin-bottom: 0 !important;
  }
  .g-mx-1--xl {
    margin-left: 0.0714285714rem !important;
    margin-right: 0.0714285714rem !important;
  }
  .g-mx-2--xl {
    margin-left: 0.1428571429rem !important;
    margin-right: 0.1428571429rem !important;
  }
  .g-mx-3--xl {
    margin-left: 0.2142857143rem !important;
    margin-right: 0.2142857143rem !important;
  }
  .g-mx-4--xl {
    margin-left: 0.2857142857rem !important;
    margin-right: 0.2857142857rem !important;
  }
  .g-mx-5--xl {
    margin-left: 0.3571428571rem !important;
    margin-right: 0.3571428571rem !important;
  }
  .g-mx-6--xl {
    margin-left: 0.4285714286rem !important;
    margin-right: 0.4285714286rem !important;
  }
  .g-mx-7--xl {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .g-mx-8--xl {
    margin-left: 0.5714285714rem !important;
    margin-right: 0.5714285714rem !important;
  }
  .g-mx-9--xl {
    margin-left: 0.6428571429rem !important;
    margin-right: 0.6428571429rem !important;
  }
  .g-mx-10--xl {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }
  .g-mx-10--xl {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }
  .g-mx-15--xl {
    margin-left: 1.0714285714rem !important;
    margin-right: 1.0714285714rem !important;
  }
  .g-mx-20--xl {
    margin-left: 1.4285714286rem !important;
    margin-right: 1.4285714286rem !important;
  }
  .g-mx-25--xl {
    margin-left: 1.7857142857rem !important;
    margin-right: 1.7857142857rem !important;
  }
  .g-mx-30--xl {
    margin-left: 2.1428571429rem !important;
    margin-right: 2.1428571429rem !important;
  }
  .g-mx-35--xl {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .g-mx-40--xl {
    margin-left: 2.8571428571rem !important;
    margin-right: 2.8571428571rem !important;
  }
  .g-mx-45--xl {
    margin-left: 3.2142857143rem !important;
    margin-right: 3.2142857143rem !important;
  }
  .g-mx-50--xl {
    margin-left: 3.5714285714rem !important;
    margin-right: 3.5714285714rem !important;
  }
  .g-mx-55--xl {
    margin-left: 3.9285714286rem !important;
    margin-right: 3.9285714286rem !important;
  }
  .g-mx-60--xl {
    margin-left: 4.2857142857rem !important;
    margin-right: 4.2857142857rem !important;
  }
  .g-mx-65--xl {
    margin-left: 4.6428571429rem !important;
    margin-right: 4.6428571429rem !important;
  }
  .g-mx-70--xl {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .g-mx-75--xl {
    margin-left: 5.3571428571rem !important;
    margin-right: 5.3571428571rem !important;
  }
  .g-mx-80--xl {
    margin-left: 5.7142857143rem !important;
    margin-right: 5.7142857143rem !important;
  }
  .g-mx-85--xl {
    margin-left: 6.0714285714rem !important;
    margin-right: 6.0714285714rem !important;
  }
  .g-mx-90--xl {
    margin-left: 6.4285714286rem !important;
    margin-right: 6.4285714286rem !important;
  }
  .g-mx-95--xl {
    margin-left: 6.7857142857rem !important;
    margin-right: 6.7857142857rem !important;
  }
  .g-mx-100--xl {
    margin-left: 7.1428571429rem !important;
    margin-right: 7.1428571429rem !important;
  }
  .g-my-1--xl {
    margin-top: 0.0714285714rem !important;
    margin-bottom: 0.0714285714rem !important;
  }
  .g-my-2--xl {
    margin-top: 0.1428571429rem !important;
    margin-bottom: 0.1428571429rem !important;
  }
  .g-my-3--xl {
    margin-top: 0.2142857143rem !important;
    margin-bottom: 0.2142857143rem !important;
  }
  .g-my-4--xl {
    margin-top: 0.2857142857rem !important;
    margin-bottom: 0.2857142857rem !important;
  }
  .g-my-5--xl {
    margin-top: 0.3571428571rem !important;
    margin-bottom: 0.3571428571rem !important;
  }
  .g-my-6--xl {
    margin-top: 0.4285714286rem !important;
    margin-bottom: 0.4285714286rem !important;
  }
  .g-my-7--xl {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .g-my-8--xl {
    margin-top: 0.5714285714rem !important;
    margin-bottom: 0.5714285714rem !important;
  }
  .g-my-9--xl {
    margin-top: 0.6428571429rem !important;
    margin-bottom: 0.6428571429rem !important;
  }
  .g-my-10--xl {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }
  .g-my-10--xl {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }
  .g-my-15--xl {
    margin-top: 1.0714285714rem !important;
    margin-bottom: 1.0714285714rem !important;
  }
  .g-my-20--xl {
    margin-top: 1.4285714286rem !important;
    margin-bottom: 1.4285714286rem !important;
  }
  .g-my-25--xl {
    margin-top: 1.7857142857rem !important;
    margin-bottom: 1.7857142857rem !important;
  }
  .g-my-30--xl {
    margin-top: 2.1428571429rem !important;
    margin-bottom: 2.1428571429rem !important;
  }
  .g-my-35--xl {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .g-my-40--xl {
    margin-top: 2.8571428571rem !important;
    margin-bottom: 2.8571428571rem !important;
  }
  .g-my-45--xl {
    margin-top: 3.2142857143rem !important;
    margin-bottom: 3.2142857143rem !important;
  }
  .g-my-50--xl {
    margin-top: 3.5714285714rem !important;
    margin-bottom: 3.5714285714rem !important;
  }
  .g-my-55--xl {
    margin-top: 3.9285714286rem !important;
    margin-bottom: 3.9285714286rem !important;
  }
  .g-my-60--xl {
    margin-top: 4.2857142857rem !important;
    margin-bottom: 4.2857142857rem !important;
  }
  .g-my-65--xl {
    margin-top: 4.6428571429rem !important;
    margin-bottom: 4.6428571429rem !important;
  }
  .g-my-70--xl {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .g-my-75--xl {
    margin-top: 5.3571428571rem !important;
    margin-bottom: 5.3571428571rem !important;
  }
  .g-my-80--xl {
    margin-top: 5.7142857143rem !important;
    margin-bottom: 5.7142857143rem !important;
  }
  .g-my-85--xl {
    margin-top: 6.0714285714rem !important;
    margin-bottom: 6.0714285714rem !important;
  }
  .g-my-90--xl {
    margin-top: 6.4285714286rem !important;
    margin-bottom: 6.4285714286rem !important;
  }
  .g-my-95--xl {
    margin-top: 6.7857142857rem !important;
    margin-bottom: 6.7857142857rem !important;
  }
  .g-my-100--xl {
    margin-top: 7.1428571429rem !important;
    margin-bottom: 7.1428571429rem !important;
  }
  .g-mt-1--xl {
    margin-top: 0.0714285714rem !important;
  }
  .g-mt-minus-1--xl {
    margin-top: -0.0714285714rem !important;
  }
  .g-mt-2--xl {
    margin-top: 0.1428571429rem !important;
  }
  .g-mt-minus-2--xl {
    margin-top: -0.1428571429rem !important;
  }
  .g-mt-3--xl {
    margin-top: 0.2142857143rem !important;
  }
  .g-mt-minus-3--xl {
    margin-top: -0.2142857143rem !important;
  }
  .g-mt-4--xl {
    margin-top: 0.2857142857rem !important;
  }
  .g-mt-minus-4--xl {
    margin-top: -0.2857142857rem !important;
  }
  .g-mt-5--xl {
    margin-top: 0.3571428571rem !important;
  }
  .g-mt-minus-5--xl {
    margin-top: -0.3571428571rem !important;
  }
  .g-mt-6--xl {
    margin-top: 0.4285714286rem !important;
  }
  .g-mt-minus-6--xl {
    margin-top: -0.4285714286rem !important;
  }
  .g-mt-7--xl {
    margin-top: 0.5rem !important;
  }
  .g-mt-minus-7--xl {
    margin-top: -0.5rem !important;
  }
  .g-mt-8--xl {
    margin-top: 0.5714285714rem !important;
  }
  .g-mt-minus-8--xl {
    margin-top: -0.5714285714rem !important;
  }
  .g-mt-9--xl {
    margin-top: 0.6428571429rem !important;
  }
  .g-mt-minus-9--xl {
    margin-top: -0.6428571429rem !important;
  }
  .g-mt-10--xl {
    margin-top: 0.7142857143rem !important;
  }
  .g-mt-minus-10--xl {
    margin-top: -0.7142857143rem !important;
  }
  .g-mt-11--xl {
    margin-top: 0.7857142857rem !important;
  }
  .g-mt-minus-11--xl {
    margin-top: -0.7857142857rem !important;
  }
  .g-mt-12--xl {
    margin-top: 0.8571428571rem !important;
  }
  .g-mt-minus-12--xl {
    margin-top: -0.8571428571rem !important;
  }
  .g-mt-13--xl {
    margin-top: 0.9285714286rem !important;
  }
  .g-mt-minus-13--xl {
    margin-top: -0.9285714286rem !important;
  }
  .g-mt-14--xl {
    margin-top: 1rem !important;
  }
  .g-mt-minus-14--xl {
    margin-top: -1rem !important;
  }
  .g-mt-15--xl {
    margin-top: 1.0714285714rem !important;
  }
  .g-mt-minus-15--xl {
    margin-top: -1.0714285714rem !important;
  }
  .g-mt-16--xl {
    margin-top: 1.1428571429rem !important;
  }
  .g-mt-minus-16--xl {
    margin-top: -1.1428571429rem !important;
  }
  .g-mt-17--xl {
    margin-top: 1.2142857143rem !important;
  }
  .g-mt-minus-17--xl {
    margin-top: -1.2142857143rem !important;
  }
  .g-mt-18--xl {
    margin-top: 1.2857142857rem !important;
  }
  .g-mt-minus-18--xl {
    margin-top: -1.2857142857rem !important;
  }
  .g-mt-19--xl {
    margin-top: 1.3571428571rem !important;
  }
  .g-mt-minus-19--xl {
    margin-top: -1.3571428571rem !important;
  }
  .g-mt-20--xl {
    margin-top: 1.4285714286rem !important;
  }
  .g-mt-minus-20--xl {
    margin-top: -1.4285714286rem !important;
  }
  .g-mt-10--xl {
    margin-top: 0.7142857143rem !important;
  }
  .g-mt-minus-10--xl {
    margin-top: -0.7142857143rem !important;
  }
  .g-mt-15--xl {
    margin-top: 1.0714285714rem !important;
  }
  .g-mt-minus-15--xl {
    margin-top: -1.0714285714rem !important;
  }
  .g-mt-20--xl {
    margin-top: 1.4285714286rem !important;
  }
  .g-mt-minus-20--xl {
    margin-top: -1.4285714286rem !important;
  }
  .g-mt-25--xl {
    margin-top: 1.7857142857rem !important;
  }
  .g-mt-minus-25--xl {
    margin-top: -1.7857142857rem !important;
  }
  .g-mt-30--xl {
    margin-top: 2.1428571429rem !important;
  }
  .g-mt-minus-30--xl {
    margin-top: -2.1428571429rem !important;
  }
  .g-mt-35--xl {
    margin-top: 2.5rem !important;
  }
  .g-mt-minus-35--xl {
    margin-top: -2.5rem !important;
  }
  .g-mt-40--xl {
    margin-top: 2.8571428571rem !important;
  }
  .g-mt-minus-40--xl {
    margin-top: -2.8571428571rem !important;
  }
  .g-mt-45--xl {
    margin-top: 3.2142857143rem !important;
  }
  .g-mt-minus-45--xl {
    margin-top: -3.2142857143rem !important;
  }
  .g-mt-50--xl {
    margin-top: 3.5714285714rem !important;
  }
  .g-mt-minus-50--xl {
    margin-top: -3.5714285714rem !important;
  }
  .g-mt-55--xl {
    margin-top: 3.9285714286rem !important;
  }
  .g-mt-minus-55--xl {
    margin-top: -3.9285714286rem !important;
  }
  .g-mt-60--xl {
    margin-top: 4.2857142857rem !important;
  }
  .g-mt-minus-60--xl {
    margin-top: -4.2857142857rem !important;
  }
  .g-mt-65--xl {
    margin-top: 4.6428571429rem !important;
  }
  .g-mt-minus-65--xl {
    margin-top: -4.6428571429rem !important;
  }
  .g-mt-70--xl {
    margin-top: 5rem !important;
  }
  .g-mt-minus-70--xl {
    margin-top: -5rem !important;
  }
  .g-mt-75--xl {
    margin-top: 5.3571428571rem !important;
  }
  .g-mt-minus-75--xl {
    margin-top: -5.3571428571rem !important;
  }
  .g-mt-80--xl {
    margin-top: 5.7142857143rem !important;
  }
  .g-mt-minus-80--xl {
    margin-top: -5.7142857143rem !important;
  }
  .g-mt-85--xl {
    margin-top: 6.0714285714rem !important;
  }
  .g-mt-minus-85--xl {
    margin-top: -6.0714285714rem !important;
  }
  .g-mt-90--xl {
    margin-top: 6.4285714286rem !important;
  }
  .g-mt-minus-90--xl {
    margin-top: -6.4285714286rem !important;
  }
  .g-mt-95--xl {
    margin-top: 6.7857142857rem !important;
  }
  .g-mt-minus-95--xl {
    margin-top: -6.7857142857rem !important;
  }
  .g-mt-100--xl {
    margin-top: 7.1428571429rem !important;
  }
  .g-mt-minus-100--xl {
    margin-top: -7.1428571429rem !important;
  }
  .g-mt-105--xl {
    margin-top: 7.5rem !important;
  }
  .g-mt-minus-105--xl {
    margin-top: -7.5rem !important;
  }
  .g-mt-110--xl {
    margin-top: 7.8571428571rem !important;
  }
  .g-mt-minus-110--xl {
    margin-top: -7.8571428571rem !important;
  }
  .g-mt-115--xl {
    margin-top: 8.2142857143rem !important;
  }
  .g-mt-minus-115--xl {
    margin-top: -8.2142857143rem !important;
  }
  .g-mt-120--xl {
    margin-top: 8.5714285714rem !important;
  }
  .g-mt-minus-120--xl {
    margin-top: -8.5714285714rem !important;
  }
  .g-mt-125--xl {
    margin-top: 8.9285714286rem !important;
  }
  .g-mt-minus-125--xl {
    margin-top: -8.9285714286rem !important;
  }
  .g-mt-130--xl {
    margin-top: 9.2857142857rem !important;
  }
  .g-mt-minus-130--xl {
    margin-top: -9.2857142857rem !important;
  }
  .g-mt-135--xl {
    margin-top: 9.6428571429rem !important;
  }
  .g-mt-minus-135--xl {
    margin-top: -9.6428571429rem !important;
  }
  .g-mt-140--xl {
    margin-top: 10rem !important;
  }
  .g-mt-minus-140--xl {
    margin-top: -10rem !important;
  }
  .g-mt-145--xl {
    margin-top: 10.3571428571rem !important;
  }
  .g-mt-minus-145--xl {
    margin-top: -10.3571428571rem !important;
  }
  .g-mt-150--xl {
    margin-top: 10.7142857143rem !important;
  }
  .g-mt-minus-150--xl {
    margin-top: -10.7142857143rem !important;
  }
  .g-mt-155--xl {
    margin-top: 11.0714285714rem !important;
  }
  .g-mt-minus-155--xl {
    margin-top: -11.0714285714rem !important;
  }
  .g-mt-160--xl {
    margin-top: 11.4285714286rem !important;
  }
  .g-mt-minus-160--xl {
    margin-top: -11.4285714286rem !important;
  }
  .g-mt-165--xl {
    margin-top: 11.7857142857rem !important;
  }
  .g-mt-minus-165--xl {
    margin-top: -11.7857142857rem !important;
  }
  .g-mt-170--xl {
    margin-top: 12.1428571429rem !important;
  }
  .g-mt-minus-170--xl {
    margin-top: -12.1428571429rem !important;
  }
  .g-mb-1--xl {
    margin-bottom: 0.0714285714rem !important;
  }
  .g-mb-minus-1--xl {
    margin-bottom: -0.0714285714rem !important;
  }
  .g-mb-2--xl {
    margin-bottom: 0.1428571429rem !important;
  }
  .g-mb-minus-2--xl {
    margin-bottom: -0.1428571429rem !important;
  }
  .g-mb-3--xl {
    margin-bottom: 0.2142857143rem !important;
  }
  .g-mb-minus-3--xl {
    margin-bottom: -0.2142857143rem !important;
  }
  .g-mb-4--xl {
    margin-bottom: 0.2857142857rem !important;
  }
  .g-mb-minus-4--xl {
    margin-bottom: -0.2857142857rem !important;
  }
  .g-mb-5--xl {
    margin-bottom: 0.3571428571rem !important;
  }
  .g-mb-minus-5--xl {
    margin-bottom: -0.3571428571rem !important;
  }
  .g-mb-6--xl {
    margin-bottom: 0.4285714286rem !important;
  }
  .g-mb-minus-6--xl {
    margin-bottom: -0.4285714286rem !important;
  }
  .g-mb-7--xl {
    margin-bottom: 0.5rem !important;
  }
  .g-mb-minus-7--xl {
    margin-bottom: -0.5rem !important;
  }
  .g-mb-8--xl {
    margin-bottom: 0.5714285714rem !important;
  }
  .g-mb-minus-8--xl {
    margin-bottom: -0.5714285714rem !important;
  }
  .g-mb-9--xl {
    margin-bottom: 0.6428571429rem !important;
  }
  .g-mb-minus-9--xl {
    margin-bottom: -0.6428571429rem !important;
  }
  .g-mb-10--xl {
    margin-bottom: 0.7142857143rem !important;
  }
  .g-mb-minus-10--xl {
    margin-bottom: -0.7142857143rem !important;
  }
  .g-mb-11--xl {
    margin-bottom: 0.7857142857rem !important;
  }
  .g-mb-minus-11--xl {
    margin-bottom: -0.7857142857rem !important;
  }
  .g-mb-12--xl {
    margin-bottom: 0.8571428571rem !important;
  }
  .g-mb-minus-12--xl {
    margin-bottom: -0.8571428571rem !important;
  }
  .g-mb-13--xl {
    margin-bottom: 0.9285714286rem !important;
  }
  .g-mb-minus-13--xl {
    margin-bottom: -0.9285714286rem !important;
  }
  .g-mb-14--xl {
    margin-bottom: 1rem !important;
  }
  .g-mb-minus-14--xl {
    margin-bottom: -1rem !important;
  }
  .g-mb-15--xl {
    margin-bottom: 1.0714285714rem !important;
  }
  .g-mb-minus-15--xl {
    margin-bottom: -1.0714285714rem !important;
  }
  .g-mb-16--xl {
    margin-bottom: 1.1428571429rem !important;
  }
  .g-mb-minus-16--xl {
    margin-bottom: -1.1428571429rem !important;
  }
  .g-mb-17--xl {
    margin-bottom: 1.2142857143rem !important;
  }
  .g-mb-minus-17--xl {
    margin-bottom: -1.2142857143rem !important;
  }
  .g-mb-18--xl {
    margin-bottom: 1.2857142857rem !important;
  }
  .g-mb-minus-18--xl {
    margin-bottom: -1.2857142857rem !important;
  }
  .g-mb-19--xl {
    margin-bottom: 1.3571428571rem !important;
  }
  .g-mb-minus-19--xl {
    margin-bottom: -1.3571428571rem !important;
  }
  .g-mb-20--xl {
    margin-bottom: 1.4285714286rem !important;
  }
  .g-mb-minus-20--xl {
    margin-bottom: -1.4285714286rem !important;
  }
  .g-mb-10--xl {
    margin-bottom: 0.7142857143rem !important;
  }
  .g-mb-15--xl {
    margin-bottom: 1.0714285714rem !important;
  }
  .g-mb-20--xl {
    margin-bottom: 1.4285714286rem !important;
  }
  .g-mb-25--xl {
    margin-bottom: 1.7857142857rem !important;
  }
  .g-mb-30--xl {
    margin-bottom: 2.1428571429rem !important;
  }
  .g-mb-35--xl {
    margin-bottom: 2.5rem !important;
  }
  .g-mb-40--xl {
    margin-bottom: 2.8571428571rem !important;
  }
  .g-mb-45--xl {
    margin-bottom: 3.2142857143rem !important;
  }
  .g-mb-50--xl {
    margin-bottom: 3.5714285714rem !important;
  }
  .g-mb-55--xl {
    margin-bottom: 3.9285714286rem !important;
  }
  .g-mb-60--xl {
    margin-bottom: 4.2857142857rem !important;
  }
  .g-mb-65--xl {
    margin-bottom: 4.6428571429rem !important;
  }
  .g-mb-70--xl {
    margin-bottom: 5rem !important;
  }
  .g-mb-75--xl {
    margin-bottom: 5.3571428571rem !important;
  }
  .g-mb-80--xl {
    margin-bottom: 5.7142857143rem !important;
  }
  .g-mb-85--xl {
    margin-bottom: 6.0714285714rem !important;
  }
  .g-mb-90--xl {
    margin-bottom: 6.4285714286rem !important;
  }
  .g-mb-95--xl {
    margin-bottom: 6.7857142857rem !important;
  }
  .g-mb-100--xl {
    margin-bottom: 7.1428571429rem !important;
  }
  .g-mb-105--xl {
    margin-bottom: 7.5rem !important;
  }
  .g-mb-110--xl {
    margin-bottom: 7.8571428571rem !important;
  }
  .g-mb-115--xl {
    margin-bottom: 8.2142857143rem !important;
  }
  .g-mb-120--xl {
    margin-bottom: 8.5714285714rem !important;
  }
  .g-mb-125--xl {
    margin-bottom: 8.9285714286rem !important;
  }
  .g-mb-130--xl {
    margin-bottom: 9.2857142857rem !important;
  }
  .g-mb-135--xl {
    margin-bottom: 9.6428571429rem !important;
  }
  .g-mb-140--xl {
    margin-bottom: 10rem !important;
  }
  .g-mb-145--xl {
    margin-bottom: 10.3571428571rem !important;
  }
  .g-mb-150--xl {
    margin-bottom: 10.7142857143rem !important;
  }
  .g-mb-155--xl {
    margin-bottom: 11.0714285714rem !important;
  }
  .g-mb-160--xl {
    margin-bottom: 11.4285714286rem !important;
  }
  .g-mb-165--xl {
    margin-bottom: 11.7857142857rem !important;
  }
  .g-mb-170--xl {
    margin-bottom: 12.1428571429rem !important;
  }
  .g-ml-1--xl {
    margin-left: 0.0714285714rem !important;
  }
  .g-ml-minus-1--xl {
    margin-left: -0.0714285714rem !important;
  }
  .g-ml-2--xl {
    margin-left: 0.1428571429rem !important;
  }
  .g-ml-minus-2--xl {
    margin-left: -0.1428571429rem !important;
  }
  .g-ml-3--xl {
    margin-left: 0.2142857143rem !important;
  }
  .g-ml-minus-3--xl {
    margin-left: -0.2142857143rem !important;
  }
  .g-ml-4--xl {
    margin-left: 0.2857142857rem !important;
  }
  .g-ml-minus-4--xl {
    margin-left: -0.2857142857rem !important;
  }
  .g-ml-5--xl {
    margin-left: 0.3571428571rem !important;
  }
  .g-ml-minus-5--xl {
    margin-left: -0.3571428571rem !important;
  }
  .g-ml-6--xl {
    margin-left: 0.4285714286rem !important;
  }
  .g-ml-minus-6--xl {
    margin-left: -0.4285714286rem !important;
  }
  .g-ml-7--xl {
    margin-left: 0.5rem !important;
  }
  .g-ml-minus-7--xl {
    margin-left: -0.5rem !important;
  }
  .g-ml-8--xl {
    margin-left: 0.5714285714rem !important;
  }
  .g-ml-minus-8--xl {
    margin-left: -0.5714285714rem !important;
  }
  .g-ml-9--xl {
    margin-left: 0.6428571429rem !important;
  }
  .g-ml-minus-9--xl {
    margin-left: -0.6428571429rem !important;
  }
  .g-ml-10--xl {
    margin-left: 0.7142857143rem !important;
  }
  .g-ml-minus-10--xl {
    margin-left: -0.7142857143rem !important;
  }
  .g-ml-5--xl {
    margin-left: 0.3571428571rem !important;
  }
  .g-ml-minus-5--xl {
    margin-left: -0.3571428571rem !important;
  }
  .g-ml-10--xl {
    margin-left: 0.7142857143rem !important;
  }
  .g-ml-minus-10--xl {
    margin-left: -0.7142857143rem !important;
  }
  .g-ml-15--xl {
    margin-left: 1.0714285714rem !important;
  }
  .g-ml-minus-15--xl {
    margin-left: -1.0714285714rem !important;
  }
  .g-ml-20--xl {
    margin-left: 1.4285714286rem !important;
  }
  .g-ml-minus-20--xl {
    margin-left: -1.4285714286rem !important;
  }
  .g-ml-25--xl {
    margin-left: 1.7857142857rem !important;
  }
  .g-ml-minus-25--xl {
    margin-left: -1.7857142857rem !important;
  }
  .g-ml-30--xl {
    margin-left: 2.1428571429rem !important;
  }
  .g-ml-minus-30--xl {
    margin-left: -2.1428571429rem !important;
  }
  .g-ml-35--xl {
    margin-left: 2.5rem !important;
  }
  .g-ml-minus-35--xl {
    margin-left: -2.5rem !important;
  }
  .g-ml-40--xl {
    margin-left: 2.8571428571rem !important;
  }
  .g-ml-minus-40--xl {
    margin-left: -2.8571428571rem !important;
  }
  .g-ml-45--xl {
    margin-left: 3.2142857143rem !important;
  }
  .g-ml-minus-45--xl {
    margin-left: -3.2142857143rem !important;
  }
  .g-ml-50--xl {
    margin-left: 3.5714285714rem !important;
  }
  .g-ml-minus-50--xl {
    margin-left: -3.5714285714rem !important;
  }
  .g-mr-1--xl {
    margin-right: 0.0714285714rem !important;
  }
  .g-mr-minus-1--xl {
    margin-right: -0.0714285714rem !important;
  }
  .g-mr-2--xl {
    margin-right: 0.1428571429rem !important;
  }
  .g-mr-minus-2--xl {
    margin-right: -0.1428571429rem !important;
  }
  .g-mr-3--xl {
    margin-right: 0.2142857143rem !important;
  }
  .g-mr-minus-3--xl {
    margin-right: -0.2142857143rem !important;
  }
  .g-mr-4--xl {
    margin-right: 0.2857142857rem !important;
  }
  .g-mr-minus-4--xl {
    margin-right: -0.2857142857rem !important;
  }
  .g-mr-5--xl {
    margin-right: 0.3571428571rem !important;
  }
  .g-mr-minus-5--xl {
    margin-right: -0.3571428571rem !important;
  }
  .g-mr-6--xl {
    margin-right: 0.4285714286rem !important;
  }
  .g-mr-minus-6--xl {
    margin-right: -0.4285714286rem !important;
  }
  .g-mr-7--xl {
    margin-right: 0.5rem !important;
  }
  .g-mr-minus-7--xl {
    margin-right: -0.5rem !important;
  }
  .g-mr-8--xl {
    margin-right: 0.5714285714rem !important;
  }
  .g-mr-minus-8--xl {
    margin-right: -0.5714285714rem !important;
  }
  .g-mr-9--xl {
    margin-right: 0.6428571429rem !important;
  }
  .g-mr-minus-9--xl {
    margin-right: -0.6428571429rem !important;
  }
  .g-mr-10--xl {
    margin-right: 0.7142857143rem !important;
  }
  .g-mr-minus-10--xl {
    margin-right: -0.7142857143rem !important;
  }
  .g-mr-5--xl {
    margin-right: 0.3571428571rem !important;
  }
  .g-mr-10--xl {
    margin-right: 0.7142857143rem !important;
  }
  .g-mr-15--xl {
    margin-right: 1.0714285714rem !important;
  }
  .g-mr-20--xl {
    margin-right: 1.4285714286rem !important;
  }
  .g-mr-25--xl {
    margin-right: 1.7857142857rem !important;
  }
  .g-mr-30--xl {
    margin-right: 2.1428571429rem !important;
  }
  .g-mr-35--xl {
    margin-right: 2.5rem !important;
  }
  .g-mr-40--xl {
    margin-right: 2.8571428571rem !important;
  }
  .g-mr-45--xl {
    margin-right: 3.2142857143rem !important;
  }
  .g-mr-50--xl {
    margin-right: 3.5714285714rem !important;
  }
}

/* Margins Around
------------------------------------*/
.g-ma-1 {
  margin: 0.0714285714rem !important;
}

.g-ma-3 {
  margin: 0.2142857143rem !important;
}

.g-ma-5 {
  margin: 0.3571428571rem !important;
}

.g-ma-10 {
  margin: 0.7142857143rem !important;
}

.g-ma-20 {
  margin: 1.4285714286rem !important;
}

.g-ma-minus-1 {
  margin: -0.0714285714rem !important;
}

/* Minus Margins
------------------------------------*/
/* Minus Margin Top */
.g-mt-minus-1 {
  margin-top: -0.0714285714rem;
}

.g-mt-minus-20 {
  margin-top: -1.4285714286rem;
}

.g-mt-minus-25 {
  margin-top: -1.7857142857rem;
}

.g-mt-minus-30 {
  margin-top: -2.1428571429rem;
}

.g-mt-minus-40 {
  margin-top: -2.8571428571rem;
}

.g-mt-minus-70 {
  margin-top: -5rem;
}

.g-mt-minus-73 {
  margin-top: -5.1428571429rem !important;
}

.g-mt-minus-120 {
  margin-top: -8.5714285714rem;
}

.g-mt-minus-200 {
  margin-top: -14.2857142857rem;
}

.g-mt-minus-300 {
  margin-top: -21.4285714286rem;
}

/* Minus Margin Bottom */
.g-mb-minus-70 {
  margin-bottom: -5rem;
}

/* Minus Margin Left */
.g-ml-minus-20 {
  margin-left: -1.4285714286rem;
}

.g-ml-minus-23 {
  margin-left: -1.6428571429rem;
}

.g-ml-minus-35 {
  margin-left: -2.5rem;
}

.g-ml-minus-55 {
  margin-left: -3.9285714286rem;
}

.g-ml-minus-25 {
  margin-left: -1.7857142857rem;
}

.g-ml-minus-82 {
  margin-left: -5.8571428571rem;
}

.g-ml-minus-90 {
  margin-left: -6.4285714286rem;
}

.g-ml-minus-100 {
  margin-left: -7.1428571429rem;
}

.g-ml-minus-118 {
  margin-left: -8.4285714286rem;
}

.g-ml-minus-142 {
  margin-left: -10.1428571429rem;
}

/* Minus Margin Right */
.g-mr-minus-50 {
  margin-right: -3.5714285714rem;
}

.g-mr-minus-100 {
  margin-right: -7.1428571429rem;
}

/* Margin Left and Right */
.g-mx-minus-1 {
  margin-left: -0.0714285714rem;
  margin-right: -0.0714285714rem;
}

.g-mx-minus-2 {
  margin-left: -0.1428571429rem;
  margin-right: -0.1428571429rem;
}

.g-mx-minus-4 {
  margin-left: -0.2857142857rem;
  margin-right: -0.2857142857rem;
}

.g-mx-minus-5 {
  margin-left: -0.3571428571rem;
  margin-right: -0.3571428571rem;
}

.g-mx-minus-15 {
  margin-left: -1.0714285714rem;
  margin-right: -1.0714285714rem;
}

.g-mx-minus-25 {
  margin-left: -1.7857142857rem;
  margin-right: -1.7857142857rem;
}

.g-mx-minus-30 {
  margin-left: -2.1428571429rem;
  margin-right: -2.1428571429rem;
}

/* Custon Spaces
------------------------------------*/
/* Margin Top */
.g-mt-10x {
  margin-top: 10%;
  /* O */
}

.g-mt-21 {
  margin-top: 1.5rem;
}

.g-mt-22 {
  margin-top: 1.5714285714rem;
}

.g-mt-28 {
  margin-top: 2rem !important;
}

.g-mt-32 {
  margin-top: 2.2857142857rem !important;
}

.g-mt-57 {
  margin-top: 4.0714285714rem !important;
}

.g-mt-500 {
  margin-top: 35.7142857143rem;
}

/* Margin Bottom */
.g-mb-23 {
  margin-bottom: 1.6428571429rem;
}

.g-mb-28 {
  margin-bottom: 2rem;
}

.g-mb-500 {
  margin-bottom: 35.7142857143rem;
}

/* Margin Left */
.g-ml-10x {
  margin-left: 10%;
  /* O */
}

.g-ml-12 {
  margin-left: 0.8571428571rem;
}

.g-ml-13 {
  margin-left: 0.9285714286rem;
}

.g-ml-20 {
  margin-left: 1.4285714286rem;
}

.g-ml-25 {
  margin-left: 1.7857142857rem;
}

.g-ml-35 {
  margin-left: 2.5rem;
}

.g-ml-43 {
  margin-left: 3.0714285714rem;
}

.g-mr-43 {
  margin-right: 3.0714285714rem;
}

.g-ml-50 {
  margin-left: 3.5714285714rem;
}

.g-ml-60 {
  margin-left: 4.2857142857rem;
}

.g-ml-75 {
  margin-left: 5.3571428571rem;
}

.g-ml-82 {
  margin-left: 5.8571428571rem;
}

.g-ml-83 {
  margin-left: 5.9285714286rem;
}

.g-ml-85 {
  margin-left: 6.0714285714rem;
}

.g-ml-105 {
  margin-left: 7.5rem;
}

.g-ml-118 {
  margin-left: 8.4285714286rem;
}

.g-ml-142 {
  margin-left: 10.1428571429rem;
}

/* Margin Right */
.g-mr-12 {
  margin-right: 0.8571428571rem;
}

.g-mr-60 {
  margin-right: 4.2857142857rem;
}

.g-mr-63 {
  margin-right: 4.5rem;
}

.g-mr-75 {
  margin-right: 5.3571428571rem;
}

.g-mr-85 {
  margin-right: 6.0714285714rem !important;
}

.g-mr-minus-10 {
  margin-right: -0.7142857143rem;
}

.g-mr-minus-13 {
  margin-right: -0.9285714286rem;
}

.g-mr-minus-15 {
  margin-right: -1.0714285714rem;
}

.g-mr-minus-23 {
  margin-right: -1.6428571429rem;
}

.g-mr-minus-30 {
  margin-right: -2.1428571429rem;
}

/* Margin Left and Right */
.g-mx-minus-10 {
  margin-left: -0.7142857143rem;
  margin-right: -0.7142857143rem;
}

.g-mx-minus-15 {
  margin-left: -1.0714285714rem;
  margin-right: -1.0714285714rem;
}

.g-mx-minus-20 {
  margin-left: -1.4285714286rem;
  margin-right: -1.4285714286rem;
}

.g-mx-minus-25 {
  margin-left: -1.7857142857rem;
  margin-right: -1.7857142857rem;
}

.g-mx-minus-30 {
  margin-left: -2.1428571429rem;
  margin-right: -2.1428571429rem;
}

/* Margin Top and Bottom */
.g-my-minus-1 {
  margin-top: -0.0714285714rem;
  margin-bottom: -0.0714285714rem;
}

.g-my-minus-2 {
  margin-top: -0.1428571429rem;
  margin-bottom: -0.1428571429rem;
}

.g-my-minus-10 {
  margin-top: -0.7142857143rem;
  margin-bottom: -0.7142857143rem;
}

.g-m-reset {
  margin: 0 !important;
}

@media (min-width: 576px) {
  .g-mb-0--sm {
    margin-bottom: 0 !important;
  }
  .g-mx-minus-10--sm {
    margin-left: -0.7142857143rem;
    margin-right: -0.7142857143rem;
  }
  .g-my-minus-5--sm {
    margin-top: -0.3571428571rem;
    margin-bottom: -0.3571428571rem;
  }
  .g-my-minus-10--sm {
    margin-top: -0.7142857143rem;
    margin-bottom: -0.7142857143rem;
  }
  .g-mx-minus-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .g-my-minus-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .g-mr-12--sm {
    margin-right: 0.8571428571rem;
  }
}

@media (min-width: 768px) {
  .g-ml-0--md {
    margin-left: 0 !important;
  }
  .g-mr-0--md {
    margin-right: 0 !important;
  }
  .g-mx-12--md {
    margin-left: 0.8571428571rem !important;
    margin-right: 0.8571428571rem !important;
  }
  .g-ml-12--md {
    margin-left: 0.8571428571rem !important;
  }
  .g-mr-12--md {
    margin-right: 0.8571428571rem !important;
  }
  .g-mb-0--md {
    margin-bottom: 0 !important;
  }
  .g-ml-minus-1--md {
    margin-left: -0.0714285714rem;
  }
  .g-ml-minus-9--md {
    margin-left: -0.6428571429rem !important;
  }
  .g-ml-minus-15--md {
    margin-left: -1.0714285714rem !important;
  }
  .g-ml-minus-23--md {
    margin-left: -1.6428571429rem !important;
  }
  .g-ml-minus-25--md {
    margin-left: -1.7857142857rem !important;
  }
  .g-mr-minus-1--md {
    margin-right: -0.0714285714rem;
  }
  .g-mr-minus-9--md {
    margin-right: -0.6428571429rem !important;
  }
  .g-mr-minus-13--md {
    margin-right: -0.9285714286rem;
  }
  .g-mr-minus-23--md {
    margin-right: -1.6428571429rem;
  }
  .g-ml-minus-82--md {
    margin-left: -5.8571428571rem !important;
  }
  .g-mr-minus-82--md {
    margin-right: -5.8571428571rem !important;
  }
  .g-mr-60--md {
    margin-right: 4.2857142857rem !important;
  }
  .g-mr-63--md {
    margin-right: 4.5rem !important;
  }
  .g-ml-63--md {
    margin-left: 4.5rem !important;
  }
  .g-ml-85--md {
    margin-left: 6.0714285714rem !important;
  }
  .g-ml-250--md {
    margin-left: 17.8571428571rem !important;
  }
  .g-ml-minus-90--md {
    margin-left: -6.4285714286rem !important;
  }
  .g-m-reset--md {
    margin: 0 !important;
  }
  .g-mt-130--md {
    margin-top: 9.2857142857rem !important;
  }
  .g-mt-minus-76--md {
    margin-top: -5.4285714286rem !important;
  }
  .g-my-30--md {
    margin-top: 2.1428571429rem !important;
    margin-bpttpm: 2.1428571429rem !important;
  }
}

@media (min-width: 992px) {
  .g-mx-12--lg {
    margin-left: 0.8571428571rem !important;
    margin-right: 0.8571428571rem !important;
  }
  .g-mb-60--lg {
    margin-bottom: 4.2857142857rem !important;
  }
  .g-ml-12--lg {
    margin-left: 0.8571428571rem !important;
  }
  .g-mr-12--lg {
    margin-right: 0.8571428571rem !important;
  }
  .g-mr-minus-1--lg {
    margin-right: -0.0714285714rem;
  }
  .g-mr-minus-50--lg {
    margin-right: -3.5714285714rem;
  }
  .g-mr-minus-100--lg {
    margin-right: -7.1428571429rem;
  }
  .g-ml-minus-100--lg {
    margin-left: -7.1428571429rem;
  }
  .g-ml-minus-100 {
    margin-left: -7.1428571429rem;
  }
  .g-mx-minus-5--lg {
    margin-left: -0.3571428571rem;
    margin-right: -0.3571428571rem;
  }
  .g-mx-minus-10--lg {
    margin-left: -0.7142857143rem;
    margin-right: -0.7142857143rem;
  }
  .g-mx-minus-15--lg {
    margin-left: -1.0714285714rem;
    margin-right: -1.0714285714rem;
  }
  .g-ml-minus-50--lg {
    margin-left: -3.5714285714rem;
  }
  .g-m-reset--lg {
    margin: 0 !important;
  }
  .g-ml-100--lg {
    margin-left: 7.1428571429rem;
  }
}

@media (min-width: 1200px) {
  .g-mx-minus-10--xl {
    margin-left: -0.7142857143rem;
    margin-right: -0.7142857143rem;
  }
  .g-mx-minus-15--xl {
    margin-left: -1.0714285714rem;
    margin-right: -1.0714285714rem;
  }
  .g-m-reset--xl {
    margin: 0 !important;
  }
  .g-mr-0--xl {
    margin-right: 0px !important;
  }
}

/*------------------------------------
  Margins Extended
------------------------------------*/
@media (min-width: 992px) {
  /* Margin Top */
  .js-header-change-moment .g-mt-1--lg--scrolling {
    margin-top: 0.0714285714rem !important;
  }
  .js-header-change-moment .g-mt-2--lg--scrolling {
    margin-top: 0.1428571429rem !important;
  }
  .js-header-change-moment .g-mt-3--lg--scrolling {
    margin-top: 0.2142857143rem !important;
  }
  .js-header-change-moment .g-mt-4--lg--scrolling {
    margin-top: 0.2857142857rem !important;
  }
  .js-header-change-moment .g-mt-5--lg--scrolling {
    margin-top: 0.3571428571rem !important;
  }
  .js-header-change-moment .g-mt-6--lg--scrolling {
    margin-top: 0.4285714286rem !important;
  }
  .js-header-change-moment .g-mt-7--lg--scrolling {
    margin-top: 0.5rem !important;
  }
  .js-header-change-moment .g-mt-8--lg--scrolling {
    margin-top: 0.5714285714rem !important;
  }
  .js-header-change-moment .g-mt-9--lg--scrolling {
    margin-top: 0.6428571429rem !important;
  }
  .js-header-change-moment .g-mt-10--lg--scrolling {
    margin-top: 0.7142857143rem !important;
  }
  .js-header-change-moment .g-mt-11--lg--scrolling {
    margin-top: 0.7857142857rem !important;
  }
  .js-header-change-moment .g-mt-12--lg--scrolling {
    margin-top: 0.8571428571rem !important;
  }
  .js-header-change-moment .g-mt-13--lg--scrolling {
    margin-top: 0.9285714286rem !important;
  }
  .js-header-change-moment .g-mt-14--lg--scrolling {
    margin-top: 1rem !important;
  }
  .js-header-change-moment .g-mt-15--lg--scrolling {
    margin-top: 1.0714285714rem !important;
  }
  .js-header-change-moment .g-mt-16--lg--scrolling {
    margin-top: 1.1428571429rem !important;
  }
  .js-header-change-moment .g-mt-17--lg--scrolling {
    margin-top: 1.2142857143rem !important;
  }
  .js-header-change-moment .g-mt-18--lg--scrolling {
    margin-top: 1.2857142857rem !important;
  }
  .js-header-change-moment .g-mt-19--lg--scrolling {
    margin-top: 1.3571428571rem !important;
  }
  .js-header-change-moment .g-mt-20--lg--scrolling {
    margin-top: 1.4285714286rem !important;
  }
  .js-header-change-moment .g-mt-21--lg--scrolling {
    margin-top: 1.5rem !important;
  }
  .js-header-change-moment .g-mt-22--lg--scrolling {
    margin-top: 1.5714285714rem !important;
  }
  .js-header-change-moment .g-mt-23--lg--scrolling {
    margin-top: 1.6428571429rem !important;
  }
  .js-header-change-moment .g-mt-24--lg--scrolling {
    margin-top: 1.7142857143rem !important;
  }
  .js-header-change-moment .g-mt-25--lg--scrolling {
    margin-top: 1.7857142857rem !important;
  }
}

/*------------------------------------
  Padding Spaces
------------------------------------*/
/* Padding Spaces (xs)
------------------------------------*/
@media (min-width: 0) {
  .g-pa-0 {
    padding: 0 !important;
  }
  .g-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .g-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .g-pt-0 {
    padding-top: 0 !important;
  }
  .g-pr-0 {
    padding-right: 0 !important;
  }
  .g-pb-0 {
    padding-bottom: 0 !important;
  }
  .g-pl-0 {
    padding-left: 0 !important;
  }
  /* Padding Around */
  .g-pa-2 {
    padding: 0.1428571429rem !important;
  }
  .g-pa-3 {
    padding: 0.2142857143rem !important;
  }
  .g-pa-5 {
    padding: 0.3571428571rem !important;
  }
  .g-pa-7 {
    padding: 0.5rem !important;
  }
  .g-pa-10 {
    padding: 0.7142857143rem !important;
  }
  .g-pa-15 {
    padding: 1.0714285714rem !important;
  }
  .g-pa-20 {
    padding: 1.4285714286rem !important;
  }
  .g-pa-25 {
    padding: 1.7857142857rem !important;
  }
  .g-pa-30 {
    padding: 2.1428571429rem !important;
  }
  .g-pa-35 {
    padding: 2.5rem !important;
  }
  .g-pa-40 {
    padding: 2.8571428571rem !important;
  }
  .g-pa-45 {
    padding: 3.2142857143rem !important;
  }
  .g-pa-50 {
    padding: 3.5714285714rem !important;
  }
  .g-pa-55 {
    padding: 3.9285714286rem !important;
  }
  .g-pa-60 {
    padding: 4.2857142857rem !important;
  }
  .g-pa-65 {
    padding: 4.6428571429rem !important;
  }
  .g-pa-70 {
    padding: 5rem !important;
  }
  .g-pa-75 {
    padding: 5.3571428571rem !important;
  }
  .g-pa-80 {
    padding: 5.7142857143rem !important;
  }
  .g-pa-85 {
    padding: 6.0714285714rem !important;
  }
  .g-pa-90 {
    padding: 6.4285714286rem !important;
  }
  .g-pa-95 {
    padding: 6.7857142857rem !important;
  }
  .g-pa-100 {
    padding: 7.1428571429rem !important;
  }
  .g-pa-105 {
    padding: 7.5rem !important;
  }
  .g-pa-110 {
    padding: 7.8571428571rem !important;
  }
  .g-pa-115 {
    padding: 8.2142857143rem !important;
  }
  .g-pa-120 {
    padding: 8.5714285714rem !important;
  }
  .g-pa-125 {
    padding: 8.9285714286rem !important;
  }
  .g-pa-130 {
    padding: 9.2857142857rem !important;
  }
  .g-pa-135 {
    padding: 9.6428571429rem !important;
  }
  .g-pa-140 {
    padding: 10rem !important;
  }
  .g-pa-145 {
    padding: 10.3571428571rem !important;
  }
  .g-pa-150 {
    padding: 10.7142857143rem !important;
  }
  /* Padding X */
  .g-px-1 {
    padding-left: 0.0714285714rem !important;
    padding-right: 0.0714285714rem !important;
  }
  .g-px-2 {
    padding-left: 0.1428571429rem !important;
    padding-right: 0.1428571429rem !important;
  }
  .g-px-3 {
    padding-left: 0.2142857143rem !important;
    padding-right: 0.2142857143rem !important;
  }
  .g-px-4 {
    padding-left: 0.2857142857rem !important;
    padding-right: 0.2857142857rem !important;
  }
  .g-px-5 {
    padding-left: 0.3571428571rem !important;
    padding-right: 0.3571428571rem !important;
  }
  .g-px-6 {
    padding-left: 0.4285714286rem !important;
    padding-right: 0.4285714286rem !important;
  }
  .g-px-7 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .g-px-8 {
    padding-left: 0.5714285714rem !important;
    padding-right: 0.5714285714rem !important;
  }
  .g-px-9 {
    padding-left: 0.6428571429rem !important;
    padding-right: 0.6428571429rem !important;
  }
  .g-px-10 {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }
  .g-px-11 {
    padding-left: 0.7857142857rem !important;
    padding-right: 0.7857142857rem !important;
  }
  .g-px-12 {
    padding-left: 0.8571428571rem !important;
    padding-right: 0.8571428571rem !important;
  }
  .g-px-13 {
    padding-left: 0.9285714286rem !important;
    padding-right: 0.9285714286rem !important;
  }
  .g-px-14 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .g-px-15 {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }
  .g-px-16 {
    padding-left: 1.1428571429rem !important;
    padding-right: 1.1428571429rem !important;
  }
  .g-px-17 {
    padding-left: 1.2142857143rem !important;
    padding-right: 1.2142857143rem !important;
  }
  .g-px-18 {
    padding-left: 1.2857142857rem !important;
    padding-right: 1.2857142857rem !important;
  }
  .g-px-19 {
    padding-left: 1.3571428571rem !important;
    padding-right: 1.3571428571rem !important;
  }
  .g-px-10 {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }
  .g-px-15 {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }
  .g-px-20 {
    padding-left: 1.4285714286rem !important;
    padding-right: 1.4285714286rem !important;
  }
  .g-px-25 {
    padding-left: 1.7857142857rem !important;
    padding-right: 1.7857142857rem !important;
  }
  .g-px-30 {
    padding-left: 2.1428571429rem !important;
    padding-right: 2.1428571429rem !important;
  }
  .g-px-35 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .g-px-40 {
    padding-left: 2.8571428571rem !important;
    padding-right: 2.8571428571rem !important;
  }
  .g-px-45 {
    padding-left: 3.2142857143rem !important;
    padding-right: 3.2142857143rem !important;
  }
  .g-px-50 {
    padding-left: 3.5714285714rem !important;
    padding-right: 3.5714285714rem !important;
  }
  .g-px-55 {
    padding-left: 3.9285714286rem !important;
    padding-right: 3.9285714286rem !important;
  }
  .g-px-60 {
    padding-left: 4.2857142857rem !important;
    padding-right: 4.2857142857rem !important;
  }
  .g-px-65 {
    padding-left: 4.6428571429rem !important;
    padding-right: 4.6428571429rem !important;
  }
  .g-px-70 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .g-px-75 {
    padding-left: 5.3571428571rem !important;
    padding-right: 5.3571428571rem !important;
  }
  .g-px-80 {
    padding-left: 5.7142857143rem !important;
    padding-right: 5.7142857143rem !important;
  }
  .g-px-85 {
    padding-left: 6.0714285714rem !important;
    padding-right: 6.0714285714rem !important;
  }
  .g-px-90 {
    padding-left: 6.4285714286rem !important;
    padding-right: 6.4285714286rem !important;
  }
  .g-px-95 {
    padding-left: 6.7857142857rem !important;
    padding-right: 6.7857142857rem !important;
  }
  .g-px-100 {
    padding-left: 7.1428571429rem !important;
    padding-right: 7.1428571429rem !important;
  }
  .g-px-105 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .g-px-110 {
    padding-left: 7.8571428571rem !important;
    padding-right: 7.8571428571rem !important;
  }
  .g-px-115 {
    padding-left: 8.2142857143rem !important;
    padding-right: 8.2142857143rem !important;
  }
  .g-px-120 {
    padding-left: 8.5714285714rem !important;
    padding-right: 8.5714285714rem !important;
  }
  .g-px-125 {
    padding-left: 8.9285714286rem !important;
    padding-right: 8.9285714286rem !important;
  }
  .g-px-130 {
    padding-left: 9.2857142857rem !important;
    padding-right: 9.2857142857rem !important;
  }
  .g-px-135 {
    padding-left: 9.6428571429rem !important;
    padding-right: 9.6428571429rem !important;
  }
  .g-px-140 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .g-px-145 {
    padding-left: 10.3571428571rem !important;
    padding-right: 10.3571428571rem !important;
  }
  .g-px-150 {
    padding-left: 10.7142857143rem !important;
    padding-right: 10.7142857143rem !important;
  }
  /* Padding Y */
  .g-py-1 {
    padding-top: 0.0714285714rem !important;
    padding-bottom: 0.0714285714rem !important;
  }
  .g-py-2 {
    padding-top: 0.1428571429rem !important;
    padding-bottom: 0.1428571429rem !important;
  }
  .g-py-3 {
    padding-top: 0.2142857143rem !important;
    padding-bottom: 0.2142857143rem !important;
  }
  .g-py-4 {
    padding-top: 0.2857142857rem !important;
    padding-bottom: 0.2857142857rem !important;
  }
  .g-py-5 {
    padding-top: 0.3571428571rem !important;
    padding-bottom: 0.3571428571rem !important;
  }
  .g-py-6 {
    padding-top: 0.4285714286rem !important;
    padding-bottom: 0.4285714286rem !important;
  }
  .g-py-7 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .g-py-8 {
    padding-top: 0.5714285714rem !important;
    padding-bottom: 0.5714285714rem !important;
  }
  .g-py-9 {
    padding-top: 0.6428571429rem !important;
    padding-bottom: 0.6428571429rem !important;
  }
  .g-py-10 {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }
  .g-py-11 {
    padding-top: 0.7857142857rem !important;
    padding-bottom: 0.7857142857rem !important;
  }
  .g-py-12 {
    padding-top: 0.8571428571rem !important;
    padding-bottom: 0.8571428571rem !important;
  }
  .g-py-13 {
    padding-top: 0.9285714286rem !important;
    padding-bottom: 0.9285714286rem !important;
  }
  .g-py-14 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .g-py-15 {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }
  .g-py-16 {
    padding-top: 1.1428571429rem !important;
    padding-bottom: 1.1428571429rem !important;
  }
  .g-py-17 {
    padding-top: 1.2142857143rem !important;
    padding-bottom: 1.2142857143rem !important;
  }
  .g-py-18 {
    padding-top: 1.2857142857rem !important;
    padding-bottom: 1.2857142857rem !important;
  }
  .g-py-19 {
    padding-top: 1.3571428571rem !important;
    padding-bottom: 1.3571428571rem !important;
  }
  /* P */
  .g-py-10 {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }
  /* P */
  .g-py-15 {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }
  /* P */
  .g-py-20 {
    padding-top: 1.4285714286rem !important;
    padding-bottom: 1.4285714286rem !important;
  }
  /* P */
  .g-py-25 {
    padding-top: 1.7857142857rem !important;
    padding-bottom: 1.7857142857rem !important;
  }
  /* P */
  .g-py-30 {
    padding-top: 2.1428571429rem !important;
    padding-bottom: 2.1428571429rem !important;
  }
  /* P */
  .g-py-35 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  /* P */
  .g-py-40 {
    padding-top: 2.8571428571rem !important;
    padding-bottom: 2.8571428571rem !important;
  }
  /* P */
  .g-py-45 {
    padding-top: 3.2142857143rem !important;
    padding-bottom: 3.2142857143rem !important;
  }
  /* P */
  .g-py-50 {
    padding-top: 3.5714285714rem !important;
    padding-bottom: 3.5714285714rem !important;
  }
  /* P */
  .g-py-55 {
    padding-top: 3.9285714286rem !important;
    padding-bottom: 3.9285714286rem !important;
  }
  /* P */
  .g-py-60 {
    padding-top: 4.2857142857rem !important;
    padding-bottom: 4.2857142857rem !important;
  }
  /* P */
  .g-py-65 {
    padding-top: 4.6428571429rem !important;
    padding-bottom: 4.6428571429rem !important;
  }
  /* P */
  .g-py-70 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  /* P */
  .g-py-75 {
    padding-top: 5.3571428571rem !important;
    padding-bottom: 5.3571428571rem !important;
  }
  /* P */
  .g-py-80 {
    padding-top: 5.7142857143rem !important;
    padding-bottom: 5.7142857143rem !important;
  }
  /* P */
  .g-py-85 {
    padding-top: 6.0714285714rem !important;
    padding-bottom: 6.0714285714rem !important;
  }
  /* P */
  .g-py-90 {
    padding-top: 6.4285714286rem !important;
    padding-bottom: 6.4285714286rem !important;
  }
  /* P */
  .g-py-95 {
    padding-top: 6.7857142857rem !important;
    padding-bottom: 6.7857142857rem !important;
  }
  /* P */
  .g-py-100 {
    padding-top: 7.1428571429rem !important;
    padding-bottom: 7.1428571429rem !important;
  }
  /* P */
  .g-py-105 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  /* P */
  .g-py-110 {
    padding-top: 7.8571428571rem !important;
    padding-bottom: 7.8571428571rem !important;
  }
  /* P */
  .g-py-115 {
    padding-top: 8.2142857143rem !important;
    padding-bottom: 8.2142857143rem !important;
  }
  /* P */
  .g-py-120 {
    padding-top: 8.5714285714rem !important;
    padding-bottom: 8.5714285714rem !important;
  }
  /* P */
  .g-py-125 {
    padding-top: 8.9285714286rem !important;
    padding-bottom: 8.9285714286rem !important;
  }
  /* P */
  .g-py-130 {
    padding-top: 9.2857142857rem !important;
    padding-bottom: 9.2857142857rem !important;
  }
  /* P */
  .g-py-135 {
    padding-top: 9.6428571429rem !important;
    padding-bottom: 9.6428571429rem !important;
  }
  /* P */
  .g-py-140 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  /* P */
  .g-py-145 {
    padding-top: 10.3571428571rem !important;
    padding-bottom: 10.3571428571rem !important;
  }
  /* P */
  .g-py-150 {
    padding-top: 10.7142857143rem !important;
    padding-bottom: 10.7142857143rem !important;
  }
  /* P */
  .g-py-155 {
    padding-top: 11.0714285714rem !important;
    padding-bottom: 11.0714285714rem !important;
  }
  /* P */
  .g-py-160 {
    padding-top: 11.4285714286rem !important;
    padding-bottom: 11.4285714286rem !important;
  }
  /* P */
  .g-py-165 {
    padding-top: 11.7857142857rem !important;
    padding-bottom: 11.7857142857rem !important;
  }
  /* P */
  .g-py-170 {
    padding-top: 12.1428571429rem !important;
    padding-bottom: 12.1428571429rem !important;
  }
  /* P */
  .g-py-175 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  /* P */
  .g-py-180 {
    padding-top: 12.8571428571rem !important;
    padding-bottom: 12.8571428571rem !important;
  }
  /* P */
  .g-py-185 {
    padding-top: 13.2142857143rem !important;
    padding-bottom: 13.2142857143rem !important;
  }
  /* P */
  .g-py-190 {
    padding-top: 13.5714285714rem !important;
    padding-bottom: 13.5714285714rem !important;
  }
  /* P */
  .g-py-195 {
    padding-top: 13.9285714286rem !important;
    padding-bottom: 13.9285714286rem !important;
  }
  /* P */
  .g-py-200 {
    padding-top: 14.2857142857rem !important;
    padding-bottom: 14.2857142857rem !important;
  }
  /* P */
  .g-py-205 {
    padding-top: 14.6428571429rem !important;
    padding-bottom: 14.6428571429rem !important;
  }
  /* P */
  .g-py-210 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  /* Padding Top */
  .g-pt-0 {
    padding-top: 0px !important;
  }
  .g-pt-1 {
    padding-top: 0.0714285714rem !important;
  }
  .g-pt-2 {
    padding-top: 0.1428571429rem !important;
  }
  .g-pt-3 {
    padding-top: 0.2142857143rem !important;
  }
  .g-pt-4 {
    padding-top: 0.2857142857rem !important;
  }
  .g-pt-5 {
    padding-top: 0.3571428571rem !important;
  }
  .g-pt-6 {
    padding-top: 0.4285714286rem !important;
  }
  .g-pt-7 {
    padding-top: 0.5rem !important;
  }
  .g-pt-8 {
    padding-top: 0.5714285714rem !important;
  }
  .g-pt-9 {
    padding-top: 0.6428571429rem !important;
  }
  .g-pt-10 {
    padding-top: 0.7142857143rem !important;
  }
  .g-pt-11 {
    padding-top: 0.7857142857rem !important;
  }
  .g-pt-12 {
    padding-top: 0.8571428571rem !important;
  }
  .g-pt-13 {
    padding-top: 0.9285714286rem !important;
  }
  .g-pt-14 {
    padding-top: 1rem !important;
  }
  .g-pt-15 {
    padding-top: 1.0714285714rem !important;
  }
  .g-pt-16 {
    padding-top: 1.1428571429rem !important;
  }
  .g-pt-17 {
    padding-top: 1.2142857143rem !important;
  }
  .g-pt-18 {
    padding-top: 1.2857142857rem !important;
  }
  .g-pt-19 {
    padding-top: 1.3571428571rem !important;
  }
  .g-pt-10 {
    padding-top: 0.7142857143rem !important;
  }
  .g-pt-15 {
    padding-top: 1.0714285714rem !important;
  }
  .g-pt-20 {
    padding-top: 1.4285714286rem !important;
  }
  .g-pt-25 {
    padding-top: 1.7857142857rem !important;
  }
  .g-pt-30 {
    padding-top: 2.1428571429rem !important;
  }
  .g-pt-35 {
    padding-top: 2.5rem !important;
  }
  .g-pt-40 {
    padding-top: 2.8571428571rem !important;
  }
  .g-pt-45 {
    padding-top: 3.2142857143rem !important;
  }
  .g-pt-50 {
    padding-top: 3.5714285714rem !important;
  }
  .g-pt-55 {
    padding-top: 3.9285714286rem !important;
  }
  .g-pt-60 {
    padding-top: 4.2857142857rem !important;
  }
  .g-pt-65 {
    padding-top: 4.6428571429rem !important;
  }
  .g-pt-70 {
    padding-top: 5rem !important;
  }
  .g-pt-75 {
    padding-top: 5.3571428571rem !important;
  }
  .g-pt-80 {
    padding-top: 5.7142857143rem !important;
  }
  .g-pt-85 {
    padding-top: 6.0714285714rem !important;
  }
  .g-pt-90 {
    padding-top: 6.4285714286rem !important;
  }
  .g-pt-95 {
    padding-top: 6.7857142857rem !important;
  }
  .g-pt-100 {
    padding-top: 7.1428571429rem !important;
  }
  .g-pt-105 {
    padding-top: 7.5rem !important;
  }
  .g-pt-110 {
    padding-top: 7.8571428571rem !important;
  }
  .g-pt-115 {
    padding-top: 8.2142857143rem !important;
  }
  .g-pt-120 {
    padding-top: 8.5714285714rem !important;
  }
  .g-pt-125 {
    padding-top: 8.9285714286rem !important;
  }
  .g-pt-130 {
    padding-top: 9.2857142857rem !important;
  }
  .g-pt-135 {
    padding-top: 9.6428571429rem !important;
  }
  .g-pt-140 {
    padding-top: 10rem !important;
  }
  .g-pt-145 {
    padding-top: 10.3571428571rem !important;
  }
  .g-pt-150 {
    padding-top: 10.7142857143rem !important;
  }
  /* Padding Right */
  .g-pr-0 {
    padding-right: 0px !important;
  }
  .g-pr-1 {
    padding-right: 0.0714285714rem !important;
  }
  .g-pr-2 {
    padding-right: 0.1428571429rem !important;
  }
  .g-pr-3 {
    padding-right: 0.2142857143rem !important;
  }
  .g-pr-4 {
    padding-right: 0.2857142857rem !important;
  }
  .g-pr-5 {
    padding-right: 0.3571428571rem !important;
  }
  .g-pr-6 {
    padding-right: 0.4285714286rem !important;
  }
  .g-pr-7 {
    padding-right: 0.5rem !important;
  }
  .g-pr-8 {
    padding-right: 0.5714285714rem !important;
  }
  .g-pr-9 {
    padding-right: 0.6428571429rem !important;
  }
  /* P */
  .g-pr-10 {
    padding-right: 0.7142857143rem !important;
  }
  /* P */
  .g-pr-15 {
    padding-right: 1.0714285714rem !important;
  }
  /* P */
  .g-pr-20 {
    padding-right: 1.4285714286rem !important;
  }
  /* P */
  .g-pr-25 {
    padding-right: 1.7857142857rem !important;
  }
  /* P */
  .g-pr-30 {
    padding-right: 2.1428571429rem !important;
  }
  /* P */
  .g-pr-35 {
    padding-right: 2.5rem !important;
  }
  /* P */
  .g-pr-40 {
    padding-right: 2.8571428571rem !important;
  }
  /* P */
  .g-pr-45 {
    padding-right: 3.2142857143rem !important;
  }
  /* P */
  .g-pr-50 {
    padding-right: 3.5714285714rem !important;
  }
  /* P */
  .g-pr-55 {
    padding-right: 3.9285714286rem !important;
  }
  /* P */
  .g-pr-60 {
    padding-right: 4.2857142857rem !important;
  }
  /* P */
  .g-pr-65 {
    padding-right: 4.6428571429rem !important;
  }
  /* P */
  .g-pr-70 {
    padding-right: 5rem !important;
  }
  /* P */
  .g-pr-75 {
    padding-right: 5.3571428571rem !important;
  }
  /* P */
  .g-pr-80 {
    padding-right: 5.7142857143rem !important;
  }
  /* P */
  .g-pr-85 {
    padding-right: 6.0714285714rem !important;
  }
  /* P */
  .g-pr-90 {
    padding-right: 6.4285714286rem !important;
  }
  /* P */
  .g-pr-95 {
    padding-right: 6.7857142857rem !important;
  }
  /* P */
  .g-pr-100 {
    padding-right: 7.1428571429rem !important;
  }
  /* P */
  .g-pr-105 {
    padding-right: 7.5rem !important;
  }
  /* P */
  .g-pr-110 {
    padding-right: 7.8571428571rem !important;
  }
  /* P */
  .g-pr-115 {
    padding-right: 8.2142857143rem !important;
  }
  /* P */
  .g-pr-120 {
    padding-right: 8.5714285714rem !important;
  }
  /* P */
  .g-pr-125 {
    padding-right: 8.9285714286rem !important;
  }
  /* P */
  .g-pr-130 {
    padding-right: 9.2857142857rem !important;
  }
  /* P */
  .g-pr-135 {
    padding-right: 9.6428571429rem !important;
  }
  /* P */
  .g-pr-140 {
    padding-right: 10rem !important;
  }
  /* P */
  .g-pr-145 {
    padding-right: 10.3571428571rem !important;
  }
  /* P */
  .g-pr-150 {
    padding-right: 10.7142857143rem !important;
  }
  /* P */
  .g-pr-155 {
    padding-right: 11.0714285714rem !important;
  }
  /* P */
  .g-pr-160 {
    padding-right: 11.4285714286rem !important;
  }
  /* P */
  .g-pr-165 {
    padding-right: 11.7857142857rem !important;
  }
  /* P */
  .g-pr-170 {
    padding-right: 12.1428571429rem !important;
  }
  /* Padding Bottom */
  .g-pb-0 {
    padding-bottom: 0px !important;
  }
  .g-pb-1 {
    padding-bottom: 0.0714285714rem !important;
  }
  .g-pb-2 {
    padding-bottom: 0.1428571429rem !important;
  }
  .g-pb-3 {
    padding-bottom: 0.2142857143rem !important;
  }
  .g-pb-4 {
    padding-bottom: 0.2857142857rem !important;
  }
  .g-pb-5 {
    padding-bottom: 0.3571428571rem !important;
  }
  .g-pb-6 {
    padding-bottom: 0.4285714286rem !important;
  }
  .g-pb-7 {
    padding-bottom: 0.5rem !important;
  }
  .g-pb-8 {
    padding-bottom: 0.5714285714rem !important;
  }
  .g-pb-9 {
    padding-bottom: 0.6428571429rem !important;
  }
  .g-pb-10 {
    padding-bottom: 0.7142857143rem !important;
  }
  .g-pb-15 {
    padding-bottom: 1.0714285714rem !important;
  }
  .g-pb-20 {
    padding-bottom: 1.4285714286rem !important;
  }
  .g-pb-25 {
    padding-bottom: 1.7857142857rem !important;
  }
  .g-pb-30 {
    padding-bottom: 2.1428571429rem !important;
  }
  .g-pb-35 {
    padding-bottom: 2.5rem !important;
  }
  .g-pb-40 {
    padding-bottom: 2.8571428571rem !important;
  }
  .g-pb-45 {
    padding-bottom: 3.2142857143rem !important;
  }
  .g-pb-50 {
    padding-bottom: 3.5714285714rem !important;
  }
  .g-pb-55 {
    padding-bottom: 3.9285714286rem !important;
  }
  .g-pb-60 {
    padding-bottom: 4.2857142857rem !important;
  }
  .g-pb-65 {
    padding-bottom: 4.6428571429rem !important;
  }
  .g-pb-70 {
    padding-bottom: 5rem !important;
  }
  .g-pb-75 {
    padding-bottom: 5.3571428571rem !important;
  }
  .g-pb-80 {
    padding-bottom: 5.7142857143rem !important;
  }
  .g-pb-85 {
    padding-bottom: 6.0714285714rem !important;
  }
  .g-pb-90 {
    padding-bottom: 6.4285714286rem !important;
  }
  .g-pb-95 {
    padding-bottom: 6.7857142857rem !important;
  }
  .g-pb-100 {
    padding-bottom: 7.1428571429rem !important;
  }
  .g-pb-105 {
    padding-bottom: 7.5rem !important;
  }
  .g-pb-110 {
    padding-bottom: 7.8571428571rem !important;
  }
  .g-pb-115 {
    padding-bottom: 8.2142857143rem !important;
  }
  .g-pb-120 {
    padding-bottom: 8.5714285714rem !important;
  }
  .g-pb-125 {
    padding-bottom: 8.9285714286rem !important;
  }
  .g-pb-130 {
    padding-bottom: 9.2857142857rem !important;
  }
  .g-pb-135 {
    padding-bottom: 9.6428571429rem !important;
  }
  .g-pb-140 {
    padding-bottom: 10rem !important;
  }
  .g-pb-145 {
    padding-bottom: 10.3571428571rem !important;
  }
  .g-pb-150 {
    padding-bottom: 10.7142857143rem !important;
  }
  /* Padding Left */
  .g-pl-0 {
    padding-left: 0px !important;
  }
  .g-pl-1 {
    padding-left: 0.0714285714rem !important;
  }
  .g-pl-2 {
    padding-left: 0.1428571429rem !important;
  }
  .g-pl-3 {
    padding-left: 0.2142857143rem !important;
  }
  .g-pl-4 {
    padding-left: 0.2857142857rem !important;
  }
  .g-pl-5 {
    padding-left: 0.3571428571rem !important;
  }
  .g-pl-6 {
    padding-left: 0.4285714286rem !important;
  }
  .g-pl-7 {
    padding-left: 0.5rem !important;
  }
  .g-pl-8 {
    padding-left: 0.5714285714rem !important;
  }
  .g-pl-9 {
    padding-left: 0.6428571429rem !important;
  }
  /* P */
  .g-pl-10 {
    padding-left: 0.7142857143rem !important;
  }
  /* P */
  .g-pl-15 {
    padding-left: 1.0714285714rem !important;
  }
  /* P */
  .g-pl-20 {
    padding-left: 1.4285714286rem !important;
  }
  /* P */
  .g-pl-25 {
    padding-left: 1.7857142857rem !important;
  }
  /* P */
  .g-pl-30 {
    padding-left: 2.1428571429rem !important;
  }
  /* P */
  .g-pl-35 {
    padding-left: 2.5rem !important;
  }
  /* P */
  .g-pl-40 {
    padding-left: 2.8571428571rem !important;
  }
  /* P */
  .g-pl-45 {
    padding-left: 3.2142857143rem !important;
  }
  /* P */
  .g-pl-50 {
    padding-left: 3.5714285714rem !important;
  }
  /* P */
  .g-pl-55 {
    padding-left: 3.9285714286rem !important;
  }
  /* P */
  .g-pl-60 {
    padding-left: 4.2857142857rem !important;
  }
  /* P */
  .g-pl-65 {
    padding-left: 4.6428571429rem !important;
  }
  /* P */
  .g-pl-70 {
    padding-left: 5rem !important;
  }
  /* P */
  .g-pl-75 {
    padding-left: 5.3571428571rem !important;
  }
  /* P */
  .g-pl-80 {
    padding-left: 5.7142857143rem !important;
  }
  /* P */
  .g-pl-85 {
    padding-left: 6.0714285714rem !important;
  }
  /* P */
  .g-pl-90 {
    padding-left: 6.4285714286rem !important;
  }
  /* P */
  .g-pl-95 {
    padding-left: 6.7857142857rem !important;
  }
  /* P */
  .g-pl-100 {
    padding-left: 7.1428571429rem !important;
  }
  /* P */
  .g-pl-105 {
    padding-left: 7.5rem !important;
  }
  /* P */
  .g-pl-110 {
    padding-left: 7.8571428571rem !important;
  }
  /* P */
  .g-pl-115 {
    padding-left: 8.2142857143rem !important;
  }
  /* P */
  .g-pl-120 {
    padding-left: 8.5714285714rem !important;
  }
  /* P */
  .g-pl-125 {
    padding-left: 8.9285714286rem !important;
  }
  /* P */
  .g-pl-130 {
    padding-left: 9.2857142857rem !important;
  }
  /* P */
  .g-pl-135 {
    padding-left: 9.6428571429rem !important;
  }
  /* P */
  .g-pl-140 {
    padding-left: 10rem !important;
  }
  /* P */
  .g-pl-145 {
    padding-left: 10.3571428571rem !important;
  }
  /* P */
  .g-pl-150 {
    padding-left: 10.7142857143rem !important;
  }
  /* P */
  .g-pl-155 {
    padding-left: 11.0714285714rem !important;
  }
  /* P */
  .g-pl-160 {
    padding-left: 11.4285714286rem !important;
  }
  /* P */
  .g-pl-165 {
    padding-left: 11.7857142857rem !important;
  }
  /* P */
  .g-pl-170 {
    padding-left: 12.1428571429rem !important;
  }
}

/* Padding Spaces (sm)
------------------------------------*/
@media (min-width: 576px) {
  .g-pa-0--sm {
    padding: 0 !important;
  }
  .g-px-0--sm {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .g-py-0--sm {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .g-pt-0--sm {
    padding-top: 0 !important;
  }
  .g-pr-0--sm {
    padding-right: 0 !important;
  }
  .g-pb-0--sm {
    padding-bottom: 0 !important;
  }
  .g-pl-0--sm {
    padding-left: 0 !important;
  }
  /* Padding Around */
  .g-pa-2--sm {
    padding: 0.1428571429rem !important;
  }
  .g-pa-3--sm {
    padding: 0.2142857143rem !important;
  }
  .g-pa-5--sm {
    padding: 0.3571428571rem !important;
  }
  .g-pa-7--sm {
    padding: 0.5rem !important;
  }
  .g-pa-10--sm {
    padding: 0.7142857143rem !important;
  }
  .g-pa-15--sm {
    padding: 1.0714285714rem !important;
  }
  .g-pa-20--sm {
    padding: 1.4285714286rem !important;
  }
  .g-pa-25--sm {
    padding: 1.7857142857rem !important;
  }
  .g-pa-30--sm {
    padding: 2.1428571429rem !important;
  }
  .g-pa-35--sm {
    padding: 2.5rem !important;
  }
  .g-pa-40--sm {
    padding: 2.8571428571rem !important;
  }
  .g-pa-45--sm {
    padding: 3.2142857143rem !important;
  }
  .g-pa-50--sm {
    padding: 3.5714285714rem !important;
  }
  .g-pa-55--sm {
    padding: 3.9285714286rem !important;
  }
  .g-pa-60--sm {
    padding: 4.2857142857rem !important;
  }
  .g-pa-65--sm {
    padding: 4.6428571429rem !important;
  }
  .g-pa-70--sm {
    padding: 5rem !important;
  }
  .g-pa-75--sm {
    padding: 5.3571428571rem !important;
  }
  .g-pa-80--sm {
    padding: 5.7142857143rem !important;
  }
  .g-pa-85--sm {
    padding: 6.0714285714rem !important;
  }
  .g-pa-90--sm {
    padding: 6.4285714286rem !important;
  }
  .g-pa-95--sm {
    padding: 6.7857142857rem !important;
  }
  .g-pa-100--sm {
    padding: 7.1428571429rem !important;
  }
  .g-pa-105--sm {
    padding: 7.5rem !important;
  }
  .g-pa-110--sm {
    padding: 7.8571428571rem !important;
  }
  .g-pa-115--sm {
    padding: 8.2142857143rem !important;
  }
  .g-pa-120--sm {
    padding: 8.5714285714rem !important;
  }
  .g-pa-125--sm {
    padding: 8.9285714286rem !important;
  }
  .g-pa-130--sm {
    padding: 9.2857142857rem !important;
  }
  .g-pa-135--sm {
    padding: 9.6428571429rem !important;
  }
  .g-pa-140--sm {
    padding: 10rem !important;
  }
  .g-pa-145--sm {
    padding: 10.3571428571rem !important;
  }
  .g-pa-150--sm {
    padding: 10.7142857143rem !important;
  }
  /* Padding X */
  .g-px-1--sm {
    padding-left: 0.0714285714rem !important;
    padding-right: 0.0714285714rem !important;
  }
  .g-px-2--sm {
    padding-left: 0.1428571429rem !important;
    padding-right: 0.1428571429rem !important;
  }
  .g-px-3--sm {
    padding-left: 0.2142857143rem !important;
    padding-right: 0.2142857143rem !important;
  }
  .g-px-4--sm {
    padding-left: 0.2857142857rem !important;
    padding-right: 0.2857142857rem !important;
  }
  .g-px-5--sm {
    padding-left: 0.3571428571rem !important;
    padding-right: 0.3571428571rem !important;
  }
  .g-px-6--sm {
    padding-left: 0.4285714286rem !important;
    padding-right: 0.4285714286rem !important;
  }
  .g-px-7--sm {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .g-px-8--sm {
    padding-left: 0.5714285714rem !important;
    padding-right: 0.5714285714rem !important;
  }
  .g-px-9--sm {
    padding-left: 0.6428571429rem !important;
    padding-right: 0.6428571429rem !important;
  }
  .g-px-10--sm {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }
  .g-px-11--sm {
    padding-left: 0.7857142857rem !important;
    padding-right: 0.7857142857rem !important;
  }
  .g-px-12--sm {
    padding-left: 0.8571428571rem !important;
    padding-right: 0.8571428571rem !important;
  }
  .g-px-13--sm {
    padding-left: 0.9285714286rem !important;
    padding-right: 0.9285714286rem !important;
  }
  .g-px-14--sm {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .g-px-15--sm {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }
  .g-px-16--sm {
    padding-left: 1.1428571429rem !important;
    padding-right: 1.1428571429rem !important;
  }
  .g-px-17--sm {
    padding-left: 1.2142857143rem !important;
    padding-right: 1.2142857143rem !important;
  }
  .g-px-18--sm {
    padding-left: 1.2857142857rem !important;
    padding-right: 1.2857142857rem !important;
  }
  .g-px-19--sm {
    padding-left: 1.3571428571rem !important;
    padding-right: 1.3571428571rem !important;
  }
  .g-px-10--sm {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }
  .g-px-15--sm {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }
  .g-px-20--sm {
    padding-left: 1.4285714286rem !important;
    padding-right: 1.4285714286rem !important;
  }
  .g-px-25--sm {
    padding-left: 1.7857142857rem !important;
    padding-right: 1.7857142857rem !important;
  }
  .g-px-30--sm {
    padding-left: 2.1428571429rem !important;
    padding-right: 2.1428571429rem !important;
  }
  .g-px-35--sm {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .g-px-40--sm {
    padding-left: 2.8571428571rem !important;
    padding-right: 2.8571428571rem !important;
  }
  .g-px-45--sm {
    padding-left: 3.2142857143rem !important;
    padding-right: 3.2142857143rem !important;
  }
  .g-px-50--sm {
    padding-left: 3.5714285714rem !important;
    padding-right: 3.5714285714rem !important;
  }
  .g-px-55--sm {
    padding-left: 3.9285714286rem !important;
    padding-right: 3.9285714286rem !important;
  }
  .g-px-60--sm {
    padding-left: 4.2857142857rem !important;
    padding-right: 4.2857142857rem !important;
  }
  .g-px-65--sm {
    padding-left: 4.6428571429rem !important;
    padding-right: 4.6428571429rem !important;
  }
  .g-px-70--sm {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .g-px-75--sm {
    padding-left: 5.3571428571rem !important;
    padding-right: 5.3571428571rem !important;
  }
  .g-px-80--sm {
    padding-left: 5.7142857143rem !important;
    padding-right: 5.7142857143rem !important;
  }
  .g-px-85--sm {
    padding-left: 6.0714285714rem !important;
    padding-right: 6.0714285714rem !important;
  }
  .g-px-90--sm {
    padding-left: 6.4285714286rem !important;
    padding-right: 6.4285714286rem !important;
  }
  .g-px-95--sm {
    padding-left: 6.7857142857rem !important;
    padding-right: 6.7857142857rem !important;
  }
  .g-px-100--sm {
    padding-left: 7.1428571429rem !important;
    padding-right: 7.1428571429rem !important;
  }
  .g-px-105--sm {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .g-px-110--sm {
    padding-left: 7.8571428571rem !important;
    padding-right: 7.8571428571rem !important;
  }
  .g-px-115--sm {
    padding-left: 8.2142857143rem !important;
    padding-right: 8.2142857143rem !important;
  }
  .g-px-120--sm {
    padding-left: 8.5714285714rem !important;
    padding-right: 8.5714285714rem !important;
  }
  .g-px-125--sm {
    padding-left: 8.9285714286rem !important;
    padding-right: 8.9285714286rem !important;
  }
  .g-px-130--sm {
    padding-left: 9.2857142857rem !important;
    padding-right: 9.2857142857rem !important;
  }
  .g-px-135--sm {
    padding-left: 9.6428571429rem !important;
    padding-right: 9.6428571429rem !important;
  }
  .g-px-140--sm {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .g-px-145--sm {
    padding-left: 10.3571428571rem !important;
    padding-right: 10.3571428571rem !important;
  }
  .g-px-150--sm {
    padding-left: 10.7142857143rem !important;
    padding-right: 10.7142857143rem !important;
  }
  /* Padding Y */
  .g-py-1--sm {
    padding-top: 0.0714285714rem !important;
    padding-bottom: 0.0714285714rem !important;
  }
  .g-py-2--sm {
    padding-top: 0.1428571429rem !important;
    padding-bottom: 0.1428571429rem !important;
  }
  .g-py-3--sm {
    padding-top: 0.2142857143rem !important;
    padding-bottom: 0.2142857143rem !important;
  }
  .g-py-4--sm {
    padding-top: 0.2857142857rem !important;
    padding-bottom: 0.2857142857rem !important;
  }
  .g-py-5--sm {
    padding-top: 0.3571428571rem !important;
    padding-bottom: 0.3571428571rem !important;
  }
  .g-py-6--sm {
    padding-top: 0.4285714286rem !important;
    padding-bottom: 0.4285714286rem !important;
  }
  .g-py-7--sm {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .g-py-8--sm {
    padding-top: 0.5714285714rem !important;
    padding-bottom: 0.5714285714rem !important;
  }
  .g-py-9--sm {
    padding-top: 0.6428571429rem !important;
    padding-bottom: 0.6428571429rem !important;
  }
  .g-py-10--sm {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }
  .g-py-11--sm {
    padding-top: 0.7857142857rem !important;
    padding-bottom: 0.7857142857rem !important;
  }
  .g-py-12--sm {
    padding-top: 0.8571428571rem !important;
    padding-bottom: 0.8571428571rem !important;
  }
  .g-py-13--sm {
    padding-top: 0.9285714286rem !important;
    padding-bottom: 0.9285714286rem !important;
  }
  .g-py-14--sm {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .g-py-15--sm {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }
  .g-py-16--sm {
    padding-top: 1.1428571429rem !important;
    padding-bottom: 1.1428571429rem !important;
  }
  .g-py-17--sm {
    padding-top: 1.2142857143rem !important;
    padding-bottom: 1.2142857143rem !important;
  }
  .g-py-18--sm {
    padding-top: 1.2857142857rem !important;
    padding-bottom: 1.2857142857rem !important;
  }
  .g-py-19--sm {
    padding-top: 1.3571428571rem !important;
    padding-bottom: 1.3571428571rem !important;
  }
  /* P */
  .g-py-10--sm {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }
  /* P */
  .g-py-15--sm {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }
  /* P */
  .g-py-20--sm {
    padding-top: 1.4285714286rem !important;
    padding-bottom: 1.4285714286rem !important;
  }
  /* P */
  .g-py-25--sm {
    padding-top: 1.7857142857rem !important;
    padding-bottom: 1.7857142857rem !important;
  }
  /* P */
  .g-py-30--sm {
    padding-top: 2.1428571429rem !important;
    padding-bottom: 2.1428571429rem !important;
  }
  /* P */
  .g-py-35--sm {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  /* P */
  .g-py-40--sm {
    padding-top: 2.8571428571rem !important;
    padding-bottom: 2.8571428571rem !important;
  }
  /* P */
  .g-py-45--sm {
    padding-top: 3.2142857143rem !important;
    padding-bottom: 3.2142857143rem !important;
  }
  /* P */
  .g-py-50--sm {
    padding-top: 3.5714285714rem !important;
    padding-bottom: 3.5714285714rem !important;
  }
  /* P */
  .g-py-55--sm {
    padding-top: 3.9285714286rem !important;
    padding-bottom: 3.9285714286rem !important;
  }
  /* P */
  .g-py-60--sm {
    padding-top: 4.2857142857rem !important;
    padding-bottom: 4.2857142857rem !important;
  }
  /* P */
  .g-py-65--sm {
    padding-top: 4.6428571429rem !important;
    padding-bottom: 4.6428571429rem !important;
  }
  /* P */
  .g-py-70--sm {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  /* P */
  .g-py-75--sm {
    padding-top: 5.3571428571rem !important;
    padding-bottom: 5.3571428571rem !important;
  }
  /* P */
  .g-py-80--sm {
    padding-top: 5.7142857143rem !important;
    padding-bottom: 5.7142857143rem !important;
  }
  /* P */
  .g-py-85--sm {
    padding-top: 6.0714285714rem !important;
    padding-bottom: 6.0714285714rem !important;
  }
  /* P */
  .g-py-90--sm {
    padding-top: 6.4285714286rem !important;
    padding-bottom: 6.4285714286rem !important;
  }
  /* P */
  .g-py-95--sm {
    padding-top: 6.7857142857rem !important;
    padding-bottom: 6.7857142857rem !important;
  }
  /* P */
  .g-py-100--sm {
    padding-top: 7.1428571429rem !important;
    padding-bottom: 7.1428571429rem !important;
  }
  /* P */
  .g-py-105--sm {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  /* P */
  .g-py-110--sm {
    padding-top: 7.8571428571rem !important;
    padding-bottom: 7.8571428571rem !important;
  }
  /* P */
  .g-py-115--sm {
    padding-top: 8.2142857143rem !important;
    padding-bottom: 8.2142857143rem !important;
  }
  /* P */
  .g-py-120--sm {
    padding-top: 8.5714285714rem !important;
    padding-bottom: 8.5714285714rem !important;
  }
  /* P */
  .g-py-125--sm {
    padding-top: 8.9285714286rem !important;
    padding-bottom: 8.9285714286rem !important;
  }
  /* P */
  .g-py-130--sm {
    padding-top: 9.2857142857rem !important;
    padding-bottom: 9.2857142857rem !important;
  }
  /* P */
  .g-py-135--sm {
    padding-top: 9.6428571429rem !important;
    padding-bottom: 9.6428571429rem !important;
  }
  /* P */
  .g-py-140--sm {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  /* P */
  .g-py-145--sm {
    padding-top: 10.3571428571rem !important;
    padding-bottom: 10.3571428571rem !important;
  }
  /* P */
  .g-py-150--sm {
    padding-top: 10.7142857143rem !important;
    padding-bottom: 10.7142857143rem !important;
  }
  /* P */
  .g-py-155--sm {
    padding-top: 11.0714285714rem !important;
    padding-bottom: 11.0714285714rem !important;
  }
  /* P */
  .g-py-160--sm {
    padding-top: 11.4285714286rem !important;
    padding-bottom: 11.4285714286rem !important;
  }
  /* P */
  .g-py-165--sm {
    padding-top: 11.7857142857rem !important;
    padding-bottom: 11.7857142857rem !important;
  }
  /* P */
  .g-py-170--sm {
    padding-top: 12.1428571429rem !important;
    padding-bottom: 12.1428571429rem !important;
  }
  /* P */
  .g-py-175--sm {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  /* P */
  .g-py-180--sm {
    padding-top: 12.8571428571rem !important;
    padding-bottom: 12.8571428571rem !important;
  }
  /* P */
  .g-py-185--sm {
    padding-top: 13.2142857143rem !important;
    padding-bottom: 13.2142857143rem !important;
  }
  /* P */
  .g-py-190--sm {
    padding-top: 13.5714285714rem !important;
    padding-bottom: 13.5714285714rem !important;
  }
  /* P */
  .g-py-195--sm {
    padding-top: 13.9285714286rem !important;
    padding-bottom: 13.9285714286rem !important;
  }
  /* P */
  .g-py-200--sm {
    padding-top: 14.2857142857rem !important;
    padding-bottom: 14.2857142857rem !important;
  }
  /* P */
  .g-py-205--sm {
    padding-top: 14.6428571429rem !important;
    padding-bottom: 14.6428571429rem !important;
  }
  /* P */
  .g-py-210--sm {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  /* Padding Top */
  .g-pt-0--sm {
    padding-top: 0px !important;
  }
  .g-pt-1--sm {
    padding-top: 0.0714285714rem !important;
  }
  .g-pt-2--sm {
    padding-top: 0.1428571429rem !important;
  }
  .g-pt-3--sm {
    padding-top: 0.2142857143rem !important;
  }
  .g-pt-4--sm {
    padding-top: 0.2857142857rem !important;
  }
  .g-pt-5--sm {
    padding-top: 0.3571428571rem !important;
  }
  .g-pt-6--sm {
    padding-top: 0.4285714286rem !important;
  }
  .g-pt-7--sm {
    padding-top: 0.5rem !important;
  }
  .g-pt-8--sm {
    padding-top: 0.5714285714rem !important;
  }
  .g-pt-9--sm {
    padding-top: 0.6428571429rem !important;
  }
  .g-pt-10--sm {
    padding-top: 0.7142857143rem !important;
  }
  .g-pt-11--sm {
    padding-top: 0.7857142857rem !important;
  }
  .g-pt-12--sm {
    padding-top: 0.8571428571rem !important;
  }
  .g-pt-13--sm {
    padding-top: 0.9285714286rem !important;
  }
  .g-pt-14--sm {
    padding-top: 1rem !important;
  }
  .g-pt-15--sm {
    padding-top: 1.0714285714rem !important;
  }
  .g-pt-16--sm {
    padding-top: 1.1428571429rem !important;
  }
  .g-pt-17--sm {
    padding-top: 1.2142857143rem !important;
  }
  .g-pt-18--sm {
    padding-top: 1.2857142857rem !important;
  }
  .g-pt-19--sm {
    padding-top: 1.3571428571rem !important;
  }
  .g-pt-10--sm {
    padding-top: 0.7142857143rem !important;
  }
  .g-pt-15--sm {
    padding-top: 1.0714285714rem !important;
  }
  .g-pt-20--sm {
    padding-top: 1.4285714286rem !important;
  }
  .g-pt-25--sm {
    padding-top: 1.7857142857rem !important;
  }
  .g-pt-30--sm {
    padding-top: 2.1428571429rem !important;
  }
  .g-pt-35--sm {
    padding-top: 2.5rem !important;
  }
  .g-pt-40--sm {
    padding-top: 2.8571428571rem !important;
  }
  .g-pt-45--sm {
    padding-top: 3.2142857143rem !important;
  }
  .g-pt-50--sm {
    padding-top: 3.5714285714rem !important;
  }
  .g-pt-55--sm {
    padding-top: 3.9285714286rem !important;
  }
  .g-pt-60--sm {
    padding-top: 4.2857142857rem !important;
  }
  .g-pt-65--sm {
    padding-top: 4.6428571429rem !important;
  }
  .g-pt-70--sm {
    padding-top: 5rem !important;
  }
  .g-pt-75--sm {
    padding-top: 5.3571428571rem !important;
  }
  .g-pt-80--sm {
    padding-top: 5.7142857143rem !important;
  }
  .g-pt-85--sm {
    padding-top: 6.0714285714rem !important;
  }
  .g-pt-90--sm {
    padding-top: 6.4285714286rem !important;
  }
  .g-pt-95--sm {
    padding-top: 6.7857142857rem !important;
  }
  .g-pt-100--sm {
    padding-top: 7.1428571429rem !important;
  }
  .g-pt-105--sm {
    padding-top: 7.5rem !important;
  }
  .g-pt-110--sm {
    padding-top: 7.8571428571rem !important;
  }
  .g-pt-115--sm {
    padding-top: 8.2142857143rem !important;
  }
  .g-pt-120--sm {
    padding-top: 8.5714285714rem !important;
  }
  .g-pt-125--sm {
    padding-top: 8.9285714286rem !important;
  }
  .g-pt-130--sm {
    padding-top: 9.2857142857rem !important;
  }
  .g-pt-135--sm {
    padding-top: 9.6428571429rem !important;
  }
  .g-pt-140--sm {
    padding-top: 10rem !important;
  }
  .g-pt-145--sm {
    padding-top: 10.3571428571rem !important;
  }
  .g-pt-150--sm {
    padding-top: 10.7142857143rem !important;
  }
  /* Padding Right */
  .g-pr-0--sm {
    padding-right: 0px !important;
  }
  .g-pr-1--sm {
    padding-right: 0.0714285714rem !important;
  }
  .g-pr-2--sm {
    padding-right: 0.1428571429rem !important;
  }
  .g-pr-3--sm {
    padding-right: 0.2142857143rem !important;
  }
  .g-pr-4--sm {
    padding-right: 0.2857142857rem !important;
  }
  .g-pr-5--sm {
    padding-right: 0.3571428571rem !important;
  }
  .g-pr-6--sm {
    padding-right: 0.4285714286rem !important;
  }
  .g-pr-7--sm {
    padding-right: 0.5rem !important;
  }
  .g-pr-8--sm {
    padding-right: 0.5714285714rem !important;
  }
  .g-pr-9--sm {
    padding-right: 0.6428571429rem !important;
  }
  /* P */
  .g-pr-10--sm {
    padding-right: 0.7142857143rem !important;
  }
  /* P */
  .g-pr-15--sm {
    padding-right: 1.0714285714rem !important;
  }
  /* P */
  .g-pr-20--sm {
    padding-right: 1.4285714286rem !important;
  }
  /* P */
  .g-pr-25--sm {
    padding-right: 1.7857142857rem !important;
  }
  /* P */
  .g-pr-30--sm {
    padding-right: 2.1428571429rem !important;
  }
  /* P */
  .g-pr-35--sm {
    padding-right: 2.5rem !important;
  }
  /* P */
  .g-pr-40--sm {
    padding-right: 2.8571428571rem !important;
  }
  /* P */
  .g-pr-45--sm {
    padding-right: 3.2142857143rem !important;
  }
  /* P */
  .g-pr-50--sm {
    padding-right: 3.5714285714rem !important;
  }
  /* P */
  .g-pr-55--sm {
    padding-right: 3.9285714286rem !important;
  }
  /* P */
  .g-pr-60--sm {
    padding-right: 4.2857142857rem !important;
  }
  /* P */
  .g-pr-65--sm {
    padding-right: 4.6428571429rem !important;
  }
  /* P */
  .g-pr-70--sm {
    padding-right: 5rem !important;
  }
  /* P */
  .g-pr-75--sm {
    padding-right: 5.3571428571rem !important;
  }
  /* P */
  .g-pr-80--sm {
    padding-right: 5.7142857143rem !important;
  }
  /* P */
  .g-pr-85--sm {
    padding-right: 6.0714285714rem !important;
  }
  /* P */
  .g-pr-90--sm {
    padding-right: 6.4285714286rem !important;
  }
  /* P */
  .g-pr-95--sm {
    padding-right: 6.7857142857rem !important;
  }
  /* P */
  .g-pr-100--sm {
    padding-right: 7.1428571429rem !important;
  }
  /* P */
  .g-pr-105--sm {
    padding-right: 7.5rem !important;
  }
  /* P */
  .g-pr-110--sm {
    padding-right: 7.8571428571rem !important;
  }
  /* P */
  .g-pr-115--sm {
    padding-right: 8.2142857143rem !important;
  }
  /* P */
  .g-pr-120--sm {
    padding-right: 8.5714285714rem !important;
  }
  /* P */
  .g-pr-125--sm {
    padding-right: 8.9285714286rem !important;
  }
  /* P */
  .g-pr-130--sm {
    padding-right: 9.2857142857rem !important;
  }
  /* P */
  .g-pr-135--sm {
    padding-right: 9.6428571429rem !important;
  }
  /* P */
  .g-pr-140--sm {
    padding-right: 10rem !important;
  }
  /* P */
  .g-pr-145--sm {
    padding-right: 10.3571428571rem !important;
  }
  /* P */
  .g-pr-150--sm {
    padding-right: 10.7142857143rem !important;
  }
  /* P */
  .g-pr-155--sm {
    padding-right: 11.0714285714rem !important;
  }
  /* P */
  .g-pr-160--sm {
    padding-right: 11.4285714286rem !important;
  }
  /* P */
  .g-pr-165--sm {
    padding-right: 11.7857142857rem !important;
  }
  /* P */
  .g-pr-170--sm {
    padding-right: 12.1428571429rem !important;
  }
  /* Padding Bottom */
  .g-pb-0--sm {
    padding-bottom: 0px !important;
  }
  .g-pb-1--sm {
    padding-bottom: 0.0714285714rem !important;
  }
  .g-pb-2--sm {
    padding-bottom: 0.1428571429rem !important;
  }
  .g-pb-3--sm {
    padding-bottom: 0.2142857143rem !important;
  }
  .g-pb-4--sm {
    padding-bottom: 0.2857142857rem !important;
  }
  .g-pb-5--sm {
    padding-bottom: 0.3571428571rem !important;
  }
  .g-pb-6--sm {
    padding-bottom: 0.4285714286rem !important;
  }
  .g-pb-7--sm {
    padding-bottom: 0.5rem !important;
  }
  .g-pb-8--sm {
    padding-bottom: 0.5714285714rem !important;
  }
  .g-pb-9--sm {
    padding-bottom: 0.6428571429rem !important;
  }
  .g-pb-10--sm {
    padding-bottom: 0.7142857143rem !important;
  }
  .g-pb-15--sm {
    padding-bottom: 1.0714285714rem !important;
  }
  .g-pb-20--sm {
    padding-bottom: 1.4285714286rem !important;
  }
  .g-pb-25--sm {
    padding-bottom: 1.7857142857rem !important;
  }
  .g-pb-30--sm {
    padding-bottom: 2.1428571429rem !important;
  }
  .g-pb-35--sm {
    padding-bottom: 2.5rem !important;
  }
  .g-pb-40--sm {
    padding-bottom: 2.8571428571rem !important;
  }
  .g-pb-45--sm {
    padding-bottom: 3.2142857143rem !important;
  }
  .g-pb-50--sm {
    padding-bottom: 3.5714285714rem !important;
  }
  .g-pb-55--sm {
    padding-bottom: 3.9285714286rem !important;
  }
  .g-pb-60--sm {
    padding-bottom: 4.2857142857rem !important;
  }
  .g-pb-65--sm {
    padding-bottom: 4.6428571429rem !important;
  }
  .g-pb-70--sm {
    padding-bottom: 5rem !important;
  }
  .g-pb-75--sm {
    padding-bottom: 5.3571428571rem !important;
  }
  .g-pb-80--sm {
    padding-bottom: 5.7142857143rem !important;
  }
  .g-pb-85--sm {
    padding-bottom: 6.0714285714rem !important;
  }
  .g-pb-90--sm {
    padding-bottom: 6.4285714286rem !important;
  }
  .g-pb-95--sm {
    padding-bottom: 6.7857142857rem !important;
  }
  .g-pb-100--sm {
    padding-bottom: 7.1428571429rem !important;
  }
  .g-pb-105--sm {
    padding-bottom: 7.5rem !important;
  }
  .g-pb-110--sm {
    padding-bottom: 7.8571428571rem !important;
  }
  .g-pb-115--sm {
    padding-bottom: 8.2142857143rem !important;
  }
  .g-pb-120--sm {
    padding-bottom: 8.5714285714rem !important;
  }
  .g-pb-125--sm {
    padding-bottom: 8.9285714286rem !important;
  }
  .g-pb-130--sm {
    padding-bottom: 9.2857142857rem !important;
  }
  .g-pb-135--sm {
    padding-bottom: 9.6428571429rem !important;
  }
  .g-pb-140--sm {
    padding-bottom: 10rem !important;
  }
  .g-pb-145--sm {
    padding-bottom: 10.3571428571rem !important;
  }
  .g-pb-150--sm {
    padding-bottom: 10.7142857143rem !important;
  }
  /* Padding Left */
  .g-pl-0--sm {
    padding-left: 0px !important;
  }
  .g-pl-1--sm {
    padding-left: 0.0714285714rem !important;
  }
  .g-pl-2--sm {
    padding-left: 0.1428571429rem !important;
  }
  .g-pl-3--sm {
    padding-left: 0.2142857143rem !important;
  }
  .g-pl-4--sm {
    padding-left: 0.2857142857rem !important;
  }
  .g-pl-5--sm {
    padding-left: 0.3571428571rem !important;
  }
  .g-pl-6--sm {
    padding-left: 0.4285714286rem !important;
  }
  .g-pl-7--sm {
    padding-left: 0.5rem !important;
  }
  .g-pl-8--sm {
    padding-left: 0.5714285714rem !important;
  }
  .g-pl-9--sm {
    padding-left: 0.6428571429rem !important;
  }
  /* P */
  .g-pl-10--sm {
    padding-left: 0.7142857143rem !important;
  }
  /* P */
  .g-pl-15--sm {
    padding-left: 1.0714285714rem !important;
  }
  /* P */
  .g-pl-20--sm {
    padding-left: 1.4285714286rem !important;
  }
  /* P */
  .g-pl-25--sm {
    padding-left: 1.7857142857rem !important;
  }
  /* P */
  .g-pl-30--sm {
    padding-left: 2.1428571429rem !important;
  }
  /* P */
  .g-pl-35--sm {
    padding-left: 2.5rem !important;
  }
  /* P */
  .g-pl-40--sm {
    padding-left: 2.8571428571rem !important;
  }
  /* P */
  .g-pl-45--sm {
    padding-left: 3.2142857143rem !important;
  }
  /* P */
  .g-pl-50--sm {
    padding-left: 3.5714285714rem !important;
  }
  /* P */
  .g-pl-55--sm {
    padding-left: 3.9285714286rem !important;
  }
  /* P */
  .g-pl-60--sm {
    padding-left: 4.2857142857rem !important;
  }
  /* P */
  .g-pl-65--sm {
    padding-left: 4.6428571429rem !important;
  }
  /* P */
  .g-pl-70--sm {
    padding-left: 5rem !important;
  }
  /* P */
  .g-pl-75--sm {
    padding-left: 5.3571428571rem !important;
  }
  /* P */
  .g-pl-80--sm {
    padding-left: 5.7142857143rem !important;
  }
  /* P */
  .g-pl-85--sm {
    padding-left: 6.0714285714rem !important;
  }
  /* P */
  .g-pl-90--sm {
    padding-left: 6.4285714286rem !important;
  }
  /* P */
  .g-pl-95--sm {
    padding-left: 6.7857142857rem !important;
  }
  /* P */
  .g-pl-100--sm {
    padding-left: 7.1428571429rem !important;
  }
  /* P */
  .g-pl-105--sm {
    padding-left: 7.5rem !important;
  }
  /* P */
  .g-pl-110--sm {
    padding-left: 7.8571428571rem !important;
  }
  /* P */
  .g-pl-115--sm {
    padding-left: 8.2142857143rem !important;
  }
  /* P */
  .g-pl-120--sm {
    padding-left: 8.5714285714rem !important;
  }
  /* P */
  .g-pl-125--sm {
    padding-left: 8.9285714286rem !important;
  }
  /* P */
  .g-pl-130--sm {
    padding-left: 9.2857142857rem !important;
  }
  /* P */
  .g-pl-135--sm {
    padding-left: 9.6428571429rem !important;
  }
  /* P */
  .g-pl-140--sm {
    padding-left: 10rem !important;
  }
  /* P */
  .g-pl-145--sm {
    padding-left: 10.3571428571rem !important;
  }
  /* P */
  .g-pl-150--sm {
    padding-left: 10.7142857143rem !important;
  }
  /* P */
  .g-pl-155--sm {
    padding-left: 11.0714285714rem !important;
  }
  /* P */
  .g-pl-160--sm {
    padding-left: 11.4285714286rem !important;
  }
  /* P */
  .g-pl-165--sm {
    padding-left: 11.7857142857rem !important;
  }
  /* P */
  .g-pl-170--sm {
    padding-left: 12.1428571429rem !important;
  }
}

/* Padding Spaces (md)
------------------------------------*/
/* P */
@media (min-width: 768px) {
  .g-pa-0--md {
    padding: 0 !important;
  }
  .g-px-0--md {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .g-py-0--md {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .g-pt-0--md {
    padding-top: 0 !important;
  }
  .g-pr-0--md {
    padding-right: 0 !important;
  }
  .g-pb-0--md {
    padding-bottom: 0 !important;
  }
  .g-pl-0--md {
    padding-left: 0 !important;
  }
  /* Padding Around */
  .g-pa-2--md {
    padding: 0.1428571429rem !important;
  }
  .g-pa-3--md {
    padding: 0.2142857143rem !important;
  }
  .g-pa-5--md {
    padding: 0.3571428571rem !important;
  }
  .g-pa-7--md {
    padding: 0.5rem !important;
  }
  .g-pa-10--md {
    padding: 0.7142857143rem !important;
  }
  .g-pa-15--md {
    padding: 1.0714285714rem !important;
  }
  .g-pa-20--md {
    padding: 1.4285714286rem !important;
  }
  .g-pa-25--md {
    padding: 1.7857142857rem !important;
  }
  .g-pa-30--md {
    padding: 2.1428571429rem !important;
  }
  .g-pa-35--md {
    padding: 2.5rem !important;
  }
  .g-pa-40--md {
    padding: 2.8571428571rem !important;
  }
  .g-pa-45--md {
    padding: 3.2142857143rem !important;
  }
  .g-pa-50--md {
    padding: 3.5714285714rem !important;
  }
  .g-pa-55--md {
    padding: 3.9285714286rem !important;
  }
  .g-pa-60--md {
    padding: 4.2857142857rem !important;
  }
  .g-pa-65--md {
    padding: 4.6428571429rem !important;
  }
  .g-pa-70--md {
    padding: 5rem !important;
  }
  .g-pa-75--md {
    padding: 5.3571428571rem !important;
  }
  .g-pa-80--md {
    padding: 5.7142857143rem !important;
  }
  .g-pa-85--md {
    padding: 6.0714285714rem !important;
  }
  .g-pa-90--md {
    padding: 6.4285714286rem !important;
  }
  .g-pa-95--md {
    padding: 6.7857142857rem !important;
  }
  .g-pa-100--md {
    padding: 7.1428571429rem !important;
  }
  .g-pa-105--md {
    padding: 7.5rem !important;
  }
  .g-pa-110--md {
    padding: 7.8571428571rem !important;
  }
  .g-pa-115--md {
    padding: 8.2142857143rem !important;
  }
  .g-pa-120--md {
    padding: 8.5714285714rem !important;
  }
  .g-pa-125--md {
    padding: 8.9285714286rem !important;
  }
  .g-pa-130--md {
    padding: 9.2857142857rem !important;
  }
  .g-pa-135--md {
    padding: 9.6428571429rem !important;
  }
  .g-pa-140--md {
    padding: 10rem !important;
  }
  .g-pa-145--md {
    padding: 10.3571428571rem !important;
  }
  .g-pa-150--md {
    padding: 10.7142857143rem !important;
  }
  /* Padding X */
  .g-px-1--md {
    padding-left: 0.0714285714rem !important;
    padding-right: 0.0714285714rem !important;
  }
  .g-px-2--md {
    padding-left: 0.1428571429rem !important;
    padding-right: 0.1428571429rem !important;
  }
  .g-px-3--md {
    padding-left: 0.2142857143rem !important;
    padding-right: 0.2142857143rem !important;
  }
  .g-px-4--md {
    padding-left: 0.2857142857rem !important;
    padding-right: 0.2857142857rem !important;
  }
  .g-px-5--md {
    padding-left: 0.3571428571rem !important;
    padding-right: 0.3571428571rem !important;
  }
  .g-px-6--md {
    padding-left: 0.4285714286rem !important;
    padding-right: 0.4285714286rem !important;
  }
  .g-px-7--md {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .g-px-8--md {
    padding-left: 0.5714285714rem !important;
    padding-right: 0.5714285714rem !important;
  }
  .g-px-9--md {
    padding-left: 0.6428571429rem !important;
    padding-right: 0.6428571429rem !important;
  }
  .g-px-10--md {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }
  .g-px-11--md {
    padding-left: 0.7857142857rem !important;
    padding-right: 0.7857142857rem !important;
  }
  .g-px-12--md {
    padding-left: 0.8571428571rem !important;
    padding-right: 0.8571428571rem !important;
  }
  .g-px-13--md {
    padding-left: 0.9285714286rem !important;
    padding-right: 0.9285714286rem !important;
  }
  .g-px-14--md {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .g-px-15--md {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }
  .g-px-16--md {
    padding-left: 1.1428571429rem !important;
    padding-right: 1.1428571429rem !important;
  }
  .g-px-17--md {
    padding-left: 1.2142857143rem !important;
    padding-right: 1.2142857143rem !important;
  }
  .g-px-18--md {
    padding-left: 1.2857142857rem !important;
    padding-right: 1.2857142857rem !important;
  }
  .g-px-19--md {
    padding-left: 1.3571428571rem !important;
    padding-right: 1.3571428571rem !important;
  }
  .g-px-10--md {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }
  .g-px-15--md {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }
  .g-px-20--md {
    padding-left: 1.4285714286rem !important;
    padding-right: 1.4285714286rem !important;
  }
  .g-px-25--md {
    padding-left: 1.7857142857rem !important;
    padding-right: 1.7857142857rem !important;
  }
  .g-px-30--md {
    padding-left: 2.1428571429rem !important;
    padding-right: 2.1428571429rem !important;
  }
  .g-px-35--md {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .g-px-40--md {
    padding-left: 2.8571428571rem !important;
    padding-right: 2.8571428571rem !important;
  }
  .g-px-45--md {
    padding-left: 3.2142857143rem !important;
    padding-right: 3.2142857143rem !important;
  }
  .g-px-50--md {
    padding-left: 3.5714285714rem !important;
    padding-right: 3.5714285714rem !important;
  }
  .g-px-55--md {
    padding-left: 3.9285714286rem !important;
    padding-right: 3.9285714286rem !important;
  }
  .g-px-60--md {
    padding-left: 4.2857142857rem !important;
    padding-right: 4.2857142857rem !important;
  }
  .g-px-65--md {
    padding-left: 4.6428571429rem !important;
    padding-right: 4.6428571429rem !important;
  }
  .g-px-70--md {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .g-px-75--md {
    padding-left: 5.3571428571rem !important;
    padding-right: 5.3571428571rem !important;
  }
  .g-px-80--md {
    padding-left: 5.7142857143rem !important;
    padding-right: 5.7142857143rem !important;
  }
  .g-px-85--md {
    padding-left: 6.0714285714rem !important;
    padding-right: 6.0714285714rem !important;
  }
  .g-px-90--md {
    padding-left: 6.4285714286rem !important;
    padding-right: 6.4285714286rem !important;
  }
  .g-px-95--md {
    padding-left: 6.7857142857rem !important;
    padding-right: 6.7857142857rem !important;
  }
  .g-px-100--md {
    padding-left: 7.1428571429rem !important;
    padding-right: 7.1428571429rem !important;
  }
  .g-px-105--md {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .g-px-110--md {
    padding-left: 7.8571428571rem !important;
    padding-right: 7.8571428571rem !important;
  }
  .g-px-115--md {
    padding-left: 8.2142857143rem !important;
    padding-right: 8.2142857143rem !important;
  }
  .g-px-120--md {
    padding-left: 8.5714285714rem !important;
    padding-right: 8.5714285714rem !important;
  }
  .g-px-125--md {
    padding-left: 8.9285714286rem !important;
    padding-right: 8.9285714286rem !important;
  }
  .g-px-130--md {
    padding-left: 9.2857142857rem !important;
    padding-right: 9.2857142857rem !important;
  }
  .g-px-135--md {
    padding-left: 9.6428571429rem !important;
    padding-right: 9.6428571429rem !important;
  }
  .g-px-140--md {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .g-px-145--md {
    padding-left: 10.3571428571rem !important;
    padding-right: 10.3571428571rem !important;
  }
  .g-px-150--md {
    padding-left: 10.7142857143rem !important;
    padding-right: 10.7142857143rem !important;
  }
  /* Padding Y */
  .g-py-1--md {
    padding-top: 0.0714285714rem !important;
    padding-bottom: 0.0714285714rem !important;
  }
  .g-py-2--md {
    padding-top: 0.1428571429rem !important;
    padding-bottom: 0.1428571429rem !important;
  }
  .g-py-3--md {
    padding-top: 0.2142857143rem !important;
    padding-bottom: 0.2142857143rem !important;
  }
  .g-py-4--md {
    padding-top: 0.2857142857rem !important;
    padding-bottom: 0.2857142857rem !important;
  }
  .g-py-5--md {
    padding-top: 0.3571428571rem !important;
    padding-bottom: 0.3571428571rem !important;
  }
  .g-py-6--md {
    padding-top: 0.4285714286rem !important;
    padding-bottom: 0.4285714286rem !important;
  }
  .g-py-7--md {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .g-py-8--md {
    padding-top: 0.5714285714rem !important;
    padding-bottom: 0.5714285714rem !important;
  }
  .g-py-9--md {
    padding-top: 0.6428571429rem !important;
    padding-bottom: 0.6428571429rem !important;
  }
  .g-py-10--md {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }
  .g-py-11--md {
    padding-top: 0.7857142857rem !important;
    padding-bottom: 0.7857142857rem !important;
  }
  .g-py-12--md {
    padding-top: 0.8571428571rem !important;
    padding-bottom: 0.8571428571rem !important;
  }
  .g-py-13--md {
    padding-top: 0.9285714286rem !important;
    padding-bottom: 0.9285714286rem !important;
  }
  .g-py-14--md {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .g-py-15--md {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }
  .g-py-16--md {
    padding-top: 1.1428571429rem !important;
    padding-bottom: 1.1428571429rem !important;
  }
  .g-py-17--md {
    padding-top: 1.2142857143rem !important;
    padding-bottom: 1.2142857143rem !important;
  }
  .g-py-18--md {
    padding-top: 1.2857142857rem !important;
    padding-bottom: 1.2857142857rem !important;
  }
  .g-py-19--md {
    padding-top: 1.3571428571rem !important;
    padding-bottom: 1.3571428571rem !important;
  }
  /* P */
  .g-py-10--md {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }
  /* P */
  .g-py-15--md {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }
  /* P */
  .g-py-20--md {
    padding-top: 1.4285714286rem !important;
    padding-bottom: 1.4285714286rem !important;
  }
  /* P */
  .g-py-25--md {
    padding-top: 1.7857142857rem !important;
    padding-bottom: 1.7857142857rem !important;
  }
  /* P */
  .g-py-30--md {
    padding-top: 2.1428571429rem !important;
    padding-bottom: 2.1428571429rem !important;
  }
  /* P */
  .g-py-35--md {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  /* P */
  .g-py-40--md {
    padding-top: 2.8571428571rem !important;
    padding-bottom: 2.8571428571rem !important;
  }
  /* P */
  .g-py-45--md {
    padding-top: 3.2142857143rem !important;
    padding-bottom: 3.2142857143rem !important;
  }
  /* P */
  .g-py-50--md {
    padding-top: 3.5714285714rem !important;
    padding-bottom: 3.5714285714rem !important;
  }
  /* P */
  .g-py-55--md {
    padding-top: 3.9285714286rem !important;
    padding-bottom: 3.9285714286rem !important;
  }
  /* P */
  .g-py-60--md {
    padding-top: 4.2857142857rem !important;
    padding-bottom: 4.2857142857rem !important;
  }
  /* P */
  .g-py-65--md {
    padding-top: 4.6428571429rem !important;
    padding-bottom: 4.6428571429rem !important;
  }
  /* P */
  .g-py-70--md {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  /* P */
  .g-py-75--md {
    padding-top: 5.3571428571rem !important;
    padding-bottom: 5.3571428571rem !important;
  }
  /* P */
  .g-py-80--md {
    padding-top: 5.7142857143rem !important;
    padding-bottom: 5.7142857143rem !important;
  }
  /* P */
  .g-py-85--md {
    padding-top: 6.0714285714rem !important;
    padding-bottom: 6.0714285714rem !important;
  }
  /* P */
  .g-py-90--md {
    padding-top: 6.4285714286rem !important;
    padding-bottom: 6.4285714286rem !important;
  }
  /* P */
  .g-py-95--md {
    padding-top: 6.7857142857rem !important;
    padding-bottom: 6.7857142857rem !important;
  }
  /* P */
  .g-py-100--md {
    padding-top: 7.1428571429rem !important;
    padding-bottom: 7.1428571429rem !important;
  }
  /* P */
  .g-py-105--md {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  /* P */
  .g-py-110--md {
    padding-top: 7.8571428571rem !important;
    padding-bottom: 7.8571428571rem !important;
  }
  /* P */
  .g-py-115--md {
    padding-top: 8.2142857143rem !important;
    padding-bottom: 8.2142857143rem !important;
  }
  /* P */
  .g-py-120--md {
    padding-top: 8.5714285714rem !important;
    padding-bottom: 8.5714285714rem !important;
  }
  /* P */
  .g-py-125--md {
    padding-top: 8.9285714286rem !important;
    padding-bottom: 8.9285714286rem !important;
  }
  /* P */
  .g-py-130--md {
    padding-top: 9.2857142857rem !important;
    padding-bottom: 9.2857142857rem !important;
  }
  /* P */
  .g-py-135--md {
    padding-top: 9.6428571429rem !important;
    padding-bottom: 9.6428571429rem !important;
  }
  /* P */
  .g-py-140--md {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  /* P */
  .g-py-145--md {
    padding-top: 10.3571428571rem !important;
    padding-bottom: 10.3571428571rem !important;
  }
  /* P */
  .g-py-150--md {
    padding-top: 10.7142857143rem !important;
    padding-bottom: 10.7142857143rem !important;
  }
  /* P */
  .g-py-155--md {
    padding-top: 11.0714285714rem !important;
    padding-bottom: 11.0714285714rem !important;
  }
  /* P */
  .g-py-160--md {
    padding-top: 11.4285714286rem !important;
    padding-bottom: 11.4285714286rem !important;
  }
  /* P */
  .g-py-165--md {
    padding-top: 11.7857142857rem !important;
    padding-bottom: 11.7857142857rem !important;
  }
  /* P */
  .g-py-170--md {
    padding-top: 12.1428571429rem !important;
    padding-bottom: 12.1428571429rem !important;
  }
  /* P */
  .g-py-175--md {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  /* P */
  .g-py-180--md {
    padding-top: 12.8571428571rem !important;
    padding-bottom: 12.8571428571rem !important;
  }
  /* P */
  .g-py-185--md {
    padding-top: 13.2142857143rem !important;
    padding-bottom: 13.2142857143rem !important;
  }
  /* P */
  .g-py-190--md {
    padding-top: 13.5714285714rem !important;
    padding-bottom: 13.5714285714rem !important;
  }
  /* P */
  .g-py-195--md {
    padding-top: 13.9285714286rem !important;
    padding-bottom: 13.9285714286rem !important;
  }
  /* P */
  .g-py-200--md {
    padding-top: 14.2857142857rem !important;
    padding-bottom: 14.2857142857rem !important;
  }
  /* P */
  .g-py-205--md {
    padding-top: 14.6428571429rem !important;
    padding-bottom: 14.6428571429rem !important;
  }
  /* P */
  .g-py-210--md {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  /* Padding Top */
  .g-pt-0--md {
    padding-top: 0px !important;
  }
  .g-pt-1--md {
    padding-top: 0.0714285714rem !important;
  }
  .g-pt-2--md {
    padding-top: 0.1428571429rem !important;
  }
  .g-pt-3--md {
    padding-top: 0.2142857143rem !important;
  }
  .g-pt-4--md {
    padding-top: 0.2857142857rem !important;
  }
  .g-pt-5--md {
    padding-top: 0.3571428571rem !important;
  }
  .g-pt-6--md {
    padding-top: 0.4285714286rem !important;
  }
  .g-pt-7--md {
    padding-top: 0.5rem !important;
  }
  .g-pt-8--md {
    padding-top: 0.5714285714rem !important;
  }
  .g-pt-9--md {
    padding-top: 0.6428571429rem !important;
  }
  .g-pt-10--md {
    padding-top: 0.7142857143rem !important;
  }
  .g-pt-11--md {
    padding-top: 0.7857142857rem !important;
  }
  .g-pt-12--md {
    padding-top: 0.8571428571rem !important;
  }
  .g-pt-13--md {
    padding-top: 0.9285714286rem !important;
  }
  .g-pt-14--md {
    padding-top: 1rem !important;
  }
  .g-pt-15--md {
    padding-top: 1.0714285714rem !important;
  }
  .g-pt-16--md {
    padding-top: 1.1428571429rem !important;
  }
  .g-pt-17--md {
    padding-top: 1.2142857143rem !important;
  }
  .g-pt-18--md {
    padding-top: 1.2857142857rem !important;
  }
  .g-pt-19--md {
    padding-top: 1.3571428571rem !important;
  }
  .g-pt-10--md {
    padding-top: 0.7142857143rem !important;
  }
  .g-pt-15--md {
    padding-top: 1.0714285714rem !important;
  }
  .g-pt-20--md {
    padding-top: 1.4285714286rem !important;
  }
  .g-pt-25--md {
    padding-top: 1.7857142857rem !important;
  }
  .g-pt-30--md {
    padding-top: 2.1428571429rem !important;
  }
  .g-pt-35--md {
    padding-top: 2.5rem !important;
  }
  .g-pt-40--md {
    padding-top: 2.8571428571rem !important;
  }
  .g-pt-45--md {
    padding-top: 3.2142857143rem !important;
  }
  .g-pt-50--md {
    padding-top: 3.5714285714rem !important;
  }
  .g-pt-55--md {
    padding-top: 3.9285714286rem !important;
  }
  .g-pt-60--md {
    padding-top: 4.2857142857rem !important;
  }
  .g-pt-65--md {
    padding-top: 4.6428571429rem !important;
  }
  .g-pt-70--md {
    padding-top: 5rem !important;
  }
  .g-pt-75--md {
    padding-top: 5.3571428571rem !important;
  }
  .g-pt-80--md {
    padding-top: 5.7142857143rem !important;
  }
  .g-pt-85--md {
    padding-top: 6.0714285714rem !important;
  }
  .g-pt-90--md {
    padding-top: 6.4285714286rem !important;
  }
  .g-pt-95--md {
    padding-top: 6.7857142857rem !important;
  }
  .g-pt-100--md {
    padding-top: 7.1428571429rem !important;
  }
  .g-pt-105--md {
    padding-top: 7.5rem !important;
  }
  .g-pt-110--md {
    padding-top: 7.8571428571rem !important;
  }
  .g-pt-115--md {
    padding-top: 8.2142857143rem !important;
  }
  .g-pt-120--md {
    padding-top: 8.5714285714rem !important;
  }
  .g-pt-125--md {
    padding-top: 8.9285714286rem !important;
  }
  .g-pt-130--md {
    padding-top: 9.2857142857rem !important;
  }
  .g-pt-135--md {
    padding-top: 9.6428571429rem !important;
  }
  .g-pt-140--md {
    padding-top: 10rem !important;
  }
  .g-pt-145--md {
    padding-top: 10.3571428571rem !important;
  }
  .g-pt-150--md {
    padding-top: 10.7142857143rem !important;
  }
  /* Padding Right */
  .g-pr-0--md {
    padding-right: 0px !important;
  }
  .g-pr-1--md {
    padding-right: 0.0714285714rem !important;
  }
  .g-pr-2--md {
    padding-right: 0.1428571429rem !important;
  }
  .g-pr-3--md {
    padding-right: 0.2142857143rem !important;
  }
  .g-pr-4--md {
    padding-right: 0.2857142857rem !important;
  }
  .g-pr-5--md {
    padding-right: 0.3571428571rem !important;
  }
  .g-pr-6--md {
    padding-right: 0.4285714286rem !important;
  }
  .g-pr-7--md {
    padding-right: 0.5rem !important;
  }
  .g-pr-8--md {
    padding-right: 0.5714285714rem !important;
  }
  .g-pr-9--md {
    padding-right: 0.6428571429rem !important;
  }
  /* P */
  .g-pr-10--md {
    padding-right: 0.7142857143rem !important;
  }
  /* P */
  .g-pr-15--md {
    padding-right: 1.0714285714rem !important;
  }
  /* P */
  .g-pr-20--md {
    padding-right: 1.4285714286rem !important;
  }
  /* P */
  .g-pr-25--md {
    padding-right: 1.7857142857rem !important;
  }
  /* P */
  .g-pr-30--md {
    padding-right: 2.1428571429rem !important;
  }
  /* P */
  .g-pr-35--md {
    padding-right: 2.5rem !important;
  }
  /* P */
  .g-pr-40--md {
    padding-right: 2.8571428571rem !important;
  }
  /* P */
  .g-pr-45--md {
    padding-right: 3.2142857143rem !important;
  }
  /* P */
  .g-pr-50--md {
    padding-right: 3.5714285714rem !important;
  }
  /* P */
  .g-pr-55--md {
    padding-right: 3.9285714286rem !important;
  }
  /* P */
  .g-pr-60--md {
    padding-right: 4.2857142857rem !important;
  }
  /* P */
  .g-pr-65--md {
    padding-right: 4.6428571429rem !important;
  }
  /* P */
  .g-pr-70--md {
    padding-right: 5rem !important;
  }
  /* P */
  .g-pr-75--md {
    padding-right: 5.3571428571rem !important;
  }
  /* P */
  .g-pr-80--md {
    padding-right: 5.7142857143rem !important;
  }
  /* P */
  .g-pr-85--md {
    padding-right: 6.0714285714rem !important;
  }
  /* P */
  .g-pr-90--md {
    padding-right: 6.4285714286rem !important;
  }
  /* P */
  .g-pr-95--md {
    padding-right: 6.7857142857rem !important;
  }
  /* P */
  .g-pr-100--md {
    padding-right: 7.1428571429rem !important;
  }
  /* P */
  .g-pr-105--md {
    padding-right: 7.5rem !important;
  }
  /* P */
  .g-pr-110--md {
    padding-right: 7.8571428571rem !important;
  }
  /* P */
  .g-pr-115--md {
    padding-right: 8.2142857143rem !important;
  }
  /* P */
  .g-pr-120--md {
    padding-right: 8.5714285714rem !important;
  }
  /* P */
  .g-pr-125--md {
    padding-right: 8.9285714286rem !important;
  }
  /* P */
  .g-pr-130--md {
    padding-right: 9.2857142857rem !important;
  }
  /* P */
  .g-pr-135--md {
    padding-right: 9.6428571429rem !important;
  }
  /* P */
  .g-pr-140--md {
    padding-right: 10rem !important;
  }
  /* P */
  .g-pr-145--md {
    padding-right: 10.3571428571rem !important;
  }
  /* P */
  .g-pr-150--md {
    padding-right: 10.7142857143rem !important;
  }
  /* P */
  .g-pr-155--md {
    padding-right: 11.0714285714rem !important;
  }
  /* P */
  .g-pr-160--md {
    padding-right: 11.4285714286rem !important;
  }
  /* P */
  .g-pr-165--md {
    padding-right: 11.7857142857rem !important;
  }
  /* P */
  .g-pr-170--md {
    padding-right: 12.1428571429rem !important;
  }
  /* Padding Bottom */
  .g-pb-0--md {
    padding-bottom: 0px !important;
  }
  .g-pb-1--md {
    padding-bottom: 0.0714285714rem !important;
  }
  .g-pb-2--md {
    padding-bottom: 0.1428571429rem !important;
  }
  .g-pb-3--md {
    padding-bottom: 0.2142857143rem !important;
  }
  .g-pb-4--md {
    padding-bottom: 0.2857142857rem !important;
  }
  .g-pb-5--md {
    padding-bottom: 0.3571428571rem !important;
  }
  .g-pb-6--md {
    padding-bottom: 0.4285714286rem !important;
  }
  .g-pb-7--md {
    padding-bottom: 0.5rem !important;
  }
  .g-pb-8--md {
    padding-bottom: 0.5714285714rem !important;
  }
  .g-pb-9--md {
    padding-bottom: 0.6428571429rem !important;
  }
  .g-pb-10--md {
    padding-bottom: 0.7142857143rem !important;
  }
  .g-pb-15--md {
    padding-bottom: 1.0714285714rem !important;
  }
  .g-pb-20--md {
    padding-bottom: 1.4285714286rem !important;
  }
  .g-pb-25--md {
    padding-bottom: 1.7857142857rem !important;
  }
  .g-pb-30--md {
    padding-bottom: 2.1428571429rem !important;
  }
  .g-pb-35--md {
    padding-bottom: 2.5rem !important;
  }
  .g-pb-40--md {
    padding-bottom: 2.8571428571rem !important;
  }
  .g-pb-45--md {
    padding-bottom: 3.2142857143rem !important;
  }
  .g-pb-50--md {
    padding-bottom: 3.5714285714rem !important;
  }
  .g-pb-55--md {
    padding-bottom: 3.9285714286rem !important;
  }
  .g-pb-60--md {
    padding-bottom: 4.2857142857rem !important;
  }
  .g-pb-65--md {
    padding-bottom: 4.6428571429rem !important;
  }
  .g-pb-70--md {
    padding-bottom: 5rem !important;
  }
  .g-pb-75--md {
    padding-bottom: 5.3571428571rem !important;
  }
  .g-pb-80--md {
    padding-bottom: 5.7142857143rem !important;
  }
  .g-pb-85--md {
    padding-bottom: 6.0714285714rem !important;
  }
  .g-pb-90--md {
    padding-bottom: 6.4285714286rem !important;
  }
  .g-pb-95--md {
    padding-bottom: 6.7857142857rem !important;
  }
  .g-pb-100--md {
    padding-bottom: 7.1428571429rem !important;
  }
  .g-pb-105--md {
    padding-bottom: 7.5rem !important;
  }
  .g-pb-110--md {
    padding-bottom: 7.8571428571rem !important;
  }
  .g-pb-115--md {
    padding-bottom: 8.2142857143rem !important;
  }
  .g-pb-120--md {
    padding-bottom: 8.5714285714rem !important;
  }
  .g-pb-125--md {
    padding-bottom: 8.9285714286rem !important;
  }
  .g-pb-130--md {
    padding-bottom: 9.2857142857rem !important;
  }
  .g-pb-135--md {
    padding-bottom: 9.6428571429rem !important;
  }
  .g-pb-140--md {
    padding-bottom: 10rem !important;
  }
  .g-pb-145--md {
    padding-bottom: 10.3571428571rem !important;
  }
  .g-pb-150--md {
    padding-bottom: 10.7142857143rem !important;
  }
  /* Padding Left */
  .g-pl-0--md {
    padding-left: 0px !important;
  }
  .g-pl-1--md {
    padding-left: 0.0714285714rem !important;
  }
  .g-pl-2--md {
    padding-left: 0.1428571429rem !important;
  }
  .g-pl-3--md {
    padding-left: 0.2142857143rem !important;
  }
  .g-pl-4--md {
    padding-left: 0.2857142857rem !important;
  }
  .g-pl-5--md {
    padding-left: 0.3571428571rem !important;
  }
  .g-pl-6--md {
    padding-left: 0.4285714286rem !important;
  }
  .g-pl-7--md {
    padding-left: 0.5rem !important;
  }
  .g-pl-8--md {
    padding-left: 0.5714285714rem !important;
  }
  .g-pl-9--md {
    padding-left: 0.6428571429rem !important;
  }
  /* P */
  .g-pl-10--md {
    padding-left: 0.7142857143rem !important;
  }
  /* P */
  .g-pl-15--md {
    padding-left: 1.0714285714rem !important;
  }
  /* P */
  .g-pl-20--md {
    padding-left: 1.4285714286rem !important;
  }
  /* P */
  .g-pl-25--md {
    padding-left: 1.7857142857rem !important;
  }
  /* P */
  .g-pl-30--md {
    padding-left: 2.1428571429rem !important;
  }
  /* P */
  .g-pl-35--md {
    padding-left: 2.5rem !important;
  }
  /* P */
  .g-pl-40--md {
    padding-left: 2.8571428571rem !important;
  }
  /* P */
  .g-pl-45--md {
    padding-left: 3.2142857143rem !important;
  }
  /* P */
  .g-pl-50--md {
    padding-left: 3.5714285714rem !important;
  }
  /* P */
  .g-pl-55--md {
    padding-left: 3.9285714286rem !important;
  }
  /* P */
  .g-pl-60--md {
    padding-left: 4.2857142857rem !important;
  }
  /* P */
  .g-pl-65--md {
    padding-left: 4.6428571429rem !important;
  }
  /* P */
  .g-pl-70--md {
    padding-left: 5rem !important;
  }
  /* P */
  .g-pl-75--md {
    padding-left: 5.3571428571rem !important;
  }
  /* P */
  .g-pl-80--md {
    padding-left: 5.7142857143rem !important;
  }
  /* P */
  .g-pl-85--md {
    padding-left: 6.0714285714rem !important;
  }
  /* P */
  .g-pl-90--md {
    padding-left: 6.4285714286rem !important;
  }
  /* P */
  .g-pl-95--md {
    padding-left: 6.7857142857rem !important;
  }
  /* P */
  .g-pl-100--md {
    padding-left: 7.1428571429rem !important;
  }
  /* P */
  .g-pl-105--md {
    padding-left: 7.5rem !important;
  }
  /* P */
  .g-pl-110--md {
    padding-left: 7.8571428571rem !important;
  }
  /* P */
  .g-pl-115--md {
    padding-left: 8.2142857143rem !important;
  }
  /* P */
  .g-pl-120--md {
    padding-left: 8.5714285714rem !important;
  }
  /* P */
  .g-pl-125--md {
    padding-left: 8.9285714286rem !important;
  }
  /* P */
  .g-pl-130--md {
    padding-left: 9.2857142857rem !important;
  }
  /* P */
  .g-pl-135--md {
    padding-left: 9.6428571429rem !important;
  }
  /* P */
  .g-pl-140--md {
    padding-left: 10rem !important;
  }
  /* P */
  .g-pl-145--md {
    padding-left: 10.3571428571rem !important;
  }
  /* P */
  .g-pl-150--md {
    padding-left: 10.7142857143rem !important;
  }
  /* P */
  .g-pl-155--md {
    padding-left: 11.0714285714rem !important;
  }
  /* P */
  .g-pl-160--md {
    padding-left: 11.4285714286rem !important;
  }
  /* P */
  .g-pl-165--md {
    padding-left: 11.7857142857rem !important;
  }
  /* P */
  .g-pl-170--md {
    padding-left: 12.1428571429rem !important;
  }
}

/* Padding Spaces (lg) P
------------------------------------*/
/* P */
@media (min-width: 992px) {
  .g-pa-0--lg {
    padding: 0 !important;
  }
  .g-px-0--lg {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .g-py-0--lg {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .g-pt-0--lg {
    padding-top: 0 !important;
  }
  .g-pr-0--lg {
    padding-right: 0 !important;
  }
  .g-pb-0--lg {
    padding-bottom: 0 !important;
  }
  .g-pl-0--lg {
    padding-left: 0 !important;
  }
  /* Padding Around */
  .g-pa-2--lg {
    padding: 0.1428571429rem !important;
  }
  .g-pa-3--lg {
    padding: 0.2142857143rem !important;
  }
  .g-pa-5--lg {
    padding: 0.3571428571rem !important;
  }
  .g-pa-7--lg {
    padding: 0.5rem !important;
  }
  .g-pa-10--lg {
    padding: 0.7142857143rem !important;
  }
  .g-pa-15--lg {
    padding: 1.0714285714rem !important;
  }
  .g-pa-20--lg {
    padding: 1.4285714286rem !important;
  }
  .g-pa-25--lg {
    padding: 1.7857142857rem !important;
  }
  .g-pa-30--lg {
    padding: 2.1428571429rem !important;
  }
  .g-pa-35--lg {
    padding: 2.5rem !important;
  }
  .g-pa-40--lg {
    padding: 2.8571428571rem !important;
  }
  .g-pa-45--lg {
    padding: 3.2142857143rem !important;
  }
  .g-pa-50--lg {
    padding: 3.5714285714rem !important;
  }
  .g-pa-55--lg {
    padding: 3.9285714286rem !important;
  }
  .g-pa-60--lg {
    padding: 4.2857142857rem !important;
  }
  .g-pa-65--lg {
    padding: 4.6428571429rem !important;
  }
  .g-pa-70--lg {
    padding: 5rem !important;
  }
  .g-pa-75--lg {
    padding: 5.3571428571rem !important;
  }
  .g-pa-80--lg {
    padding: 5.7142857143rem !important;
  }
  .g-pa-85--lg {
    padding: 6.0714285714rem !important;
  }
  .g-pa-90--lg {
    padding: 6.4285714286rem !important;
  }
  .g-pa-95--lg {
    padding: 6.7857142857rem !important;
  }
  .g-pa-100--lg {
    padding: 7.1428571429rem !important;
  }
  .g-pa-105--lg {
    padding: 7.5rem !important;
  }
  .g-pa-110--lg {
    padding: 7.8571428571rem !important;
  }
  .g-pa-115--lg {
    padding: 8.2142857143rem !important;
  }
  .g-pa-120--lg {
    padding: 8.5714285714rem !important;
  }
  .g-pa-125--lg {
    padding: 8.9285714286rem !important;
  }
  .g-pa-130--lg {
    padding: 9.2857142857rem !important;
  }
  .g-pa-135--lg {
    padding: 9.6428571429rem !important;
  }
  .g-pa-140--lg {
    padding: 10rem !important;
  }
  .g-pa-145--lg {
    padding: 10.3571428571rem !important;
  }
  .g-pa-150--lg {
    padding: 10.7142857143rem !important;
  }
  /* Padding X */
  .g-px-1--lg {
    padding-left: 0.0714285714rem !important;
    padding-right: 0.0714285714rem !important;
  }
  .g-px-2--lg {
    padding-left: 0.1428571429rem !important;
    padding-right: 0.1428571429rem !important;
  }
  .g-px-3--lg {
    padding-left: 0.2142857143rem !important;
    padding-right: 0.2142857143rem !important;
  }
  .g-px-4--lg {
    padding-left: 0.2857142857rem !important;
    padding-right: 0.2857142857rem !important;
  }
  .g-px-5--lg {
    padding-left: 0.3571428571rem !important;
    padding-right: 0.3571428571rem !important;
  }
  .g-px-6--lg {
    padding-left: 0.4285714286rem !important;
    padding-right: 0.4285714286rem !important;
  }
  .g-px-7--lg {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .g-px-8--lg {
    padding-left: 0.5714285714rem !important;
    padding-right: 0.5714285714rem !important;
  }
  .g-px-9--lg {
    padding-left: 0.6428571429rem !important;
    padding-right: 0.6428571429rem !important;
  }
  .g-px-10--lg {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }
  .g-px-11--lg {
    padding-left: 0.7857142857rem !important;
    padding-right: 0.7857142857rem !important;
  }
  .g-px-12--lg {
    padding-left: 0.8571428571rem !important;
    padding-right: 0.8571428571rem !important;
  }
  .g-px-13--lg {
    padding-left: 0.9285714286rem !important;
    padding-right: 0.9285714286rem !important;
  }
  .g-px-14--lg {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .g-px-15--lg {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }
  .g-px-16--lg {
    padding-left: 1.1428571429rem !important;
    padding-right: 1.1428571429rem !important;
  }
  .g-px-17--lg {
    padding-left: 1.2142857143rem !important;
    padding-right: 1.2142857143rem !important;
  }
  .g-px-18--lg {
    padding-left: 1.2857142857rem !important;
    padding-right: 1.2857142857rem !important;
  }
  .g-px-19--lg {
    padding-left: 1.3571428571rem !important;
    padding-right: 1.3571428571rem !important;
  }
  .g-px-10--lg {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }
  .g-px-15--lg {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }
  .g-px-20--lg {
    padding-left: 1.4285714286rem !important;
    padding-right: 1.4285714286rem !important;
  }
  .g-px-25--lg {
    padding-left: 1.7857142857rem !important;
    padding-right: 1.7857142857rem !important;
  }
  .g-px-30--lg {
    padding-left: 2.1428571429rem !important;
    padding-right: 2.1428571429rem !important;
  }
  .g-px-35--lg {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .g-px-40--lg {
    padding-left: 2.8571428571rem !important;
    padding-right: 2.8571428571rem !important;
  }
  .g-px-45--lg {
    padding-left: 3.2142857143rem !important;
    padding-right: 3.2142857143rem !important;
  }
  .g-px-50--lg {
    padding-left: 3.5714285714rem !important;
    padding-right: 3.5714285714rem !important;
  }
  .g-px-55--lg {
    padding-left: 3.9285714286rem !important;
    padding-right: 3.9285714286rem !important;
  }
  .g-px-60--lg {
    padding-left: 4.2857142857rem !important;
    padding-right: 4.2857142857rem !important;
  }
  .g-px-65--lg {
    padding-left: 4.6428571429rem !important;
    padding-right: 4.6428571429rem !important;
  }
  .g-px-70--lg {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .g-px-75--lg {
    padding-left: 5.3571428571rem !important;
    padding-right: 5.3571428571rem !important;
  }
  .g-px-80--lg {
    padding-left: 5.7142857143rem !important;
    padding-right: 5.7142857143rem !important;
  }
  .g-px-85--lg {
    padding-left: 6.0714285714rem !important;
    padding-right: 6.0714285714rem !important;
  }
  .g-px-90--lg {
    padding-left: 6.4285714286rem !important;
    padding-right: 6.4285714286rem !important;
  }
  .g-px-95--lg {
    padding-left: 6.7857142857rem !important;
    padding-right: 6.7857142857rem !important;
  }
  .g-px-100--lg {
    padding-left: 7.1428571429rem !important;
    padding-right: 7.1428571429rem !important;
  }
  .g-px-105--lg {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .g-px-110--lg {
    padding-left: 7.8571428571rem !important;
    padding-right: 7.8571428571rem !important;
  }
  .g-px-115--lg {
    padding-left: 8.2142857143rem !important;
    padding-right: 8.2142857143rem !important;
  }
  .g-px-120--lg {
    padding-left: 8.5714285714rem !important;
    padding-right: 8.5714285714rem !important;
  }
  .g-px-125--lg {
    padding-left: 8.9285714286rem !important;
    padding-right: 8.9285714286rem !important;
  }
  .g-px-130--lg {
    padding-left: 9.2857142857rem !important;
    padding-right: 9.2857142857rem !important;
  }
  .g-px-135--lg {
    padding-left: 9.6428571429rem !important;
    padding-right: 9.6428571429rem !important;
  }
  .g-px-140--lg {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .g-px-145--lg {
    padding-left: 10.3571428571rem !important;
    padding-right: 10.3571428571rem !important;
  }
  .g-px-150--lg {
    padding-left: 10.7142857143rem !important;
    padding-right: 10.7142857143rem !important;
  }
  /* Padding Y */
  .g-py-1--lg {
    padding-top: 0.0714285714rem !important;
    padding-bottom: 0.0714285714rem !important;
  }
  .g-py-2--lg {
    padding-top: 0.1428571429rem !important;
    padding-bottom: 0.1428571429rem !important;
  }
  .g-py-3--lg {
    padding-top: 0.2142857143rem !important;
    padding-bottom: 0.2142857143rem !important;
  }
  .g-py-4--lg {
    padding-top: 0.2857142857rem !important;
    padding-bottom: 0.2857142857rem !important;
  }
  .g-py-5--lg {
    padding-top: 0.3571428571rem !important;
    padding-bottom: 0.3571428571rem !important;
  }
  .g-py-6--lg {
    padding-top: 0.4285714286rem !important;
    padding-bottom: 0.4285714286rem !important;
  }
  .g-py-7--lg {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .g-py-8--lg {
    padding-top: 0.5714285714rem !important;
    padding-bottom: 0.5714285714rem !important;
  }
  .g-py-9--lg {
    padding-top: 0.6428571429rem !important;
    padding-bottom: 0.6428571429rem !important;
  }
  .g-py-10--lg {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }
  .g-py-11--lg {
    padding-top: 0.7857142857rem !important;
    padding-bottom: 0.7857142857rem !important;
  }
  .g-py-12--lg {
    padding-top: 0.8571428571rem !important;
    padding-bottom: 0.8571428571rem !important;
  }
  .g-py-13--lg {
    padding-top: 0.9285714286rem !important;
    padding-bottom: 0.9285714286rem !important;
  }
  .g-py-14--lg {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .g-py-15--lg {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }
  .g-py-16--lg {
    padding-top: 1.1428571429rem !important;
    padding-bottom: 1.1428571429rem !important;
  }
  .g-py-17--lg {
    padding-top: 1.2142857143rem !important;
    padding-bottom: 1.2142857143rem !important;
  }
  .g-py-18--lg {
    padding-top: 1.2857142857rem !important;
    padding-bottom: 1.2857142857rem !important;
  }
  .g-py-19--lg {
    padding-top: 1.3571428571rem !important;
    padding-bottom: 1.3571428571rem !important;
  }
  /* P */
  .g-py-10--lg {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }
  /* P */
  .g-py-15--lg {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }
  /* P */
  .g-py-20--lg {
    padding-top: 1.4285714286rem !important;
    padding-bottom: 1.4285714286rem !important;
  }
  /* P */
  .g-py-25--lg {
    padding-top: 1.7857142857rem !important;
    padding-bottom: 1.7857142857rem !important;
  }
  /* P */
  .g-py-30--lg {
    padding-top: 2.1428571429rem !important;
    padding-bottom: 2.1428571429rem !important;
  }
  /* P */
  .g-py-35--lg {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  /* P */
  .g-py-40--lg {
    padding-top: 2.8571428571rem !important;
    padding-bottom: 2.8571428571rem !important;
  }
  /* P */
  .g-py-45--lg {
    padding-top: 3.2142857143rem !important;
    padding-bottom: 3.2142857143rem !important;
  }
  /* P */
  .g-py-50--lg {
    padding-top: 3.5714285714rem !important;
    padding-bottom: 3.5714285714rem !important;
  }
  /* P */
  .g-py-55--lg {
    padding-top: 3.9285714286rem !important;
    padding-bottom: 3.9285714286rem !important;
  }
  /* P */
  .g-py-60--lg {
    padding-top: 4.2857142857rem !important;
    padding-bottom: 4.2857142857rem !important;
  }
  /* P */
  .g-py-65--lg {
    padding-top: 4.6428571429rem !important;
    padding-bottom: 4.6428571429rem !important;
  }
  /* P */
  .g-py-70--lg {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  /* P */
  .g-py-75--lg {
    padding-top: 5.3571428571rem !important;
    padding-bottom: 5.3571428571rem !important;
  }
  /* P */
  .g-py-80--lg {
    padding-top: 5.7142857143rem !important;
    padding-bottom: 5.7142857143rem !important;
  }
  /* P */
  .g-py-85--lg {
    padding-top: 6.0714285714rem !important;
    padding-bottom: 6.0714285714rem !important;
  }
  /* P */
  .g-py-90--lg {
    padding-top: 6.4285714286rem !important;
    padding-bottom: 6.4285714286rem !important;
  }
  /* P */
  .g-py-95--lg {
    padding-top: 6.7857142857rem !important;
    padding-bottom: 6.7857142857rem !important;
  }
  /* P */
  .g-py-100--lg {
    padding-top: 7.1428571429rem !important;
    padding-bottom: 7.1428571429rem !important;
  }
  /* P */
  .g-py-105--lg {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  /* P */
  .g-py-110--lg {
    padding-top: 7.8571428571rem !important;
    padding-bottom: 7.8571428571rem !important;
  }
  /* P */
  .g-py-115--lg {
    padding-top: 8.2142857143rem !important;
    padding-bottom: 8.2142857143rem !important;
  }
  /* P */
  .g-py-120--lg {
    padding-top: 8.5714285714rem !important;
    padding-bottom: 8.5714285714rem !important;
  }
  /* P */
  .g-py-125--lg {
    padding-top: 8.9285714286rem !important;
    padding-bottom: 8.9285714286rem !important;
  }
  /* P */
  .g-py-130--lg {
    padding-top: 9.2857142857rem !important;
    padding-bottom: 9.2857142857rem !important;
  }
  /* P */
  .g-py-135--lg {
    padding-top: 9.6428571429rem !important;
    padding-bottom: 9.6428571429rem !important;
  }
  /* P */
  .g-py-140--lg {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  /* P */
  .g-py-145--lg {
    padding-top: 10.3571428571rem !important;
    padding-bottom: 10.3571428571rem !important;
  }
  /* P */
  .g-py-150--lg {
    padding-top: 10.7142857143rem !important;
    padding-bottom: 10.7142857143rem !important;
  }
  /* P */
  .g-py-155--lg {
    padding-top: 11.0714285714rem !important;
    padding-bottom: 11.0714285714rem !important;
  }
  /* P */
  .g-py-160--lg {
    padding-top: 11.4285714286rem !important;
    padding-bottom: 11.4285714286rem !important;
  }
  /* P */
  .g-py-165--lg {
    padding-top: 11.7857142857rem !important;
    padding-bottom: 11.7857142857rem !important;
  }
  /* P */
  .g-py-170--lg {
    padding-top: 12.1428571429rem !important;
    padding-bottom: 12.1428571429rem !important;
  }
  /* P */
  .g-py-175--lg {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  /* P */
  .g-py-180--lg {
    padding-top: 12.8571428571rem !important;
    padding-bottom: 12.8571428571rem !important;
  }
  /* P */
  .g-py-185--lg {
    padding-top: 13.2142857143rem !important;
    padding-bottom: 13.2142857143rem !important;
  }
  /* P */
  .g-py-190--lg {
    padding-top: 13.5714285714rem !important;
    padding-bottom: 13.5714285714rem !important;
  }
  /* P */
  .g-py-195--lg {
    padding-top: 13.9285714286rem !important;
    padding-bottom: 13.9285714286rem !important;
  }
  /* P */
  .g-py-200--lg {
    padding-top: 14.2857142857rem !important;
    padding-bottom: 14.2857142857rem !important;
  }
  /* P */
  .g-py-205--lg {
    padding-top: 14.6428571429rem !important;
    padding-bottom: 14.6428571429rem !important;
  }
  /* P */
  .g-py-210--lg {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  /* Padding Top */
  .g-pt-0--lg {
    padding-top: 0px !important;
  }
  .g-pt-1--lg {
    padding-top: 0.0714285714rem !important;
  }
  .g-pt-2--lg {
    padding-top: 0.1428571429rem !important;
  }
  .g-pt-3--lg {
    padding-top: 0.2142857143rem !important;
  }
  .g-pt-4--lg {
    padding-top: 0.2857142857rem !important;
  }
  .g-pt-5--lg {
    padding-top: 0.3571428571rem !important;
  }
  .g-pt-6--lg {
    padding-top: 0.4285714286rem !important;
  }
  .g-pt-7--lg {
    padding-top: 0.5rem !important;
  }
  .g-pt-8--lg {
    padding-top: 0.5714285714rem !important;
  }
  .g-pt-9--lg {
    padding-top: 0.6428571429rem !important;
  }
  .g-pt-10--lg {
    padding-top: 0.7142857143rem !important;
  }
  .g-pt-11--lg {
    padding-top: 0.7857142857rem !important;
  }
  .g-pt-12--lg {
    padding-top: 0.8571428571rem !important;
  }
  .g-pt-13--lg {
    padding-top: 0.9285714286rem !important;
  }
  .g-pt-14--lg {
    padding-top: 1rem !important;
  }
  .g-pt-15--lg {
    padding-top: 1.0714285714rem !important;
  }
  .g-pt-16--lg {
    padding-top: 1.1428571429rem !important;
  }
  .g-pt-17--lg {
    padding-top: 1.2142857143rem !important;
  }
  .g-pt-18--lg {
    padding-top: 1.2857142857rem !important;
  }
  .g-pt-19--lg {
    padding-top: 1.3571428571rem !important;
  }
  .g-pt-10--lg {
    padding-top: 0.7142857143rem !important;
  }
  .g-pt-15--lg {
    padding-top: 1.0714285714rem !important;
  }
  .g-pt-20--lg {
    padding-top: 1.4285714286rem !important;
  }
  .g-pt-25--lg {
    padding-top: 1.7857142857rem !important;
  }
  .g-pt-30--lg {
    padding-top: 2.1428571429rem !important;
  }
  .g-pt-35--lg {
    padding-top: 2.5rem !important;
  }
  .g-pt-40--lg {
    padding-top: 2.8571428571rem !important;
  }
  .g-pt-45--lg {
    padding-top: 3.2142857143rem !important;
  }
  .g-pt-50--lg {
    padding-top: 3.5714285714rem !important;
  }
  .g-pt-55--lg {
    padding-top: 3.9285714286rem !important;
  }
  .g-pt-60--lg {
    padding-top: 4.2857142857rem !important;
  }
  .g-pt-65--lg {
    padding-top: 4.6428571429rem !important;
  }
  .g-pt-70--lg {
    padding-top: 5rem !important;
  }
  .g-pt-75--lg {
    padding-top: 5.3571428571rem !important;
  }
  .g-pt-80--lg {
    padding-top: 5.7142857143rem !important;
  }
  .g-pt-85--lg {
    padding-top: 6.0714285714rem !important;
  }
  .g-pt-90--lg {
    padding-top: 6.4285714286rem !important;
  }
  .g-pt-95--lg {
    padding-top: 6.7857142857rem !important;
  }
  .g-pt-100--lg {
    padding-top: 7.1428571429rem !important;
  }
  .g-pt-105--lg {
    padding-top: 7.5rem !important;
  }
  .g-pt-110--lg {
    padding-top: 7.8571428571rem !important;
  }
  .g-pt-115--lg {
    padding-top: 8.2142857143rem !important;
  }
  .g-pt-120--lg {
    padding-top: 8.5714285714rem !important;
  }
  .g-pt-125--lg {
    padding-top: 8.9285714286rem !important;
  }
  .g-pt-130--lg {
    padding-top: 9.2857142857rem !important;
  }
  .g-pt-135--lg {
    padding-top: 9.6428571429rem !important;
  }
  .g-pt-140--lg {
    padding-top: 10rem !important;
  }
  .g-pt-145--lg {
    padding-top: 10.3571428571rem !important;
  }
  .g-pt-150--lg {
    padding-top: 10.7142857143rem !important;
  }
  /* Padding Right */
  .g-pr-0--lg {
    padding-right: 0px !important;
  }
  .g-pr-1--lg {
    padding-right: 0.0714285714rem !important;
  }
  .g-pr-2--lg {
    padding-right: 0.1428571429rem !important;
  }
  .g-pr-3--lg {
    padding-right: 0.2142857143rem !important;
  }
  .g-pr-4--lg {
    padding-right: 0.2857142857rem !important;
  }
  .g-pr-5--lg {
    padding-right: 0.3571428571rem !important;
  }
  .g-pr-6--lg {
    padding-right: 0.4285714286rem !important;
  }
  .g-pr-7--lg {
    padding-right: 0.5rem !important;
  }
  .g-pr-8--lg {
    padding-right: 0.5714285714rem !important;
  }
  .g-pr-9--lg {
    padding-right: 0.6428571429rem !important;
  }
  /* P */
  .g-pr-10--lg {
    padding-right: 0.7142857143rem !important;
  }
  /* P */
  .g-pr-15--lg {
    padding-right: 1.0714285714rem !important;
  }
  /* P */
  .g-pr-20--lg {
    padding-right: 1.4285714286rem !important;
  }
  /* P */
  .g-pr-25--lg {
    padding-right: 1.7857142857rem !important;
  }
  /* P */
  .g-pr-30--lg {
    padding-right: 2.1428571429rem !important;
  }
  /* P */
  .g-pr-35--lg {
    padding-right: 2.5rem !important;
  }
  /* P */
  .g-pr-40--lg {
    padding-right: 2.8571428571rem !important;
  }
  /* P */
  .g-pr-45--lg {
    padding-right: 3.2142857143rem !important;
  }
  /* P */
  .g-pr-50--lg {
    padding-right: 3.5714285714rem !important;
  }
  /* P */
  .g-pr-55--lg {
    padding-right: 3.9285714286rem !important;
  }
  /* P */
  .g-pr-60--lg {
    padding-right: 4.2857142857rem !important;
  }
  /* P */
  .g-pr-65--lg {
    padding-right: 4.6428571429rem !important;
  }
  /* P */
  .g-pr-70--lg {
    padding-right: 5rem !important;
  }
  /* P */
  .g-pr-75--lg {
    padding-right: 5.3571428571rem !important;
  }
  /* P */
  .g-pr-80--lg {
    padding-right: 5.7142857143rem !important;
  }
  /* P */
  .g-pr-85--lg {
    padding-right: 6.0714285714rem !important;
  }
  /* P */
  .g-pr-90--lg {
    padding-right: 6.4285714286rem !important;
  }
  /* P */
  .g-pr-95--lg {
    padding-right: 6.7857142857rem !important;
  }
  /* P */
  .g-pr-100--lg {
    padding-right: 7.1428571429rem !important;
  }
  /* P */
  .g-pr-105--lg {
    padding-right: 7.5rem !important;
  }
  /* P */
  .g-pr-110--lg {
    padding-right: 7.8571428571rem !important;
  }
  /* P */
  .g-pr-115--lg {
    padding-right: 8.2142857143rem !important;
  }
  /* P */
  .g-pr-120--lg {
    padding-right: 8.5714285714rem !important;
  }
  /* P */
  .g-pr-125--lg {
    padding-right: 8.9285714286rem !important;
  }
  /* P */
  .g-pr-130--lg {
    padding-right: 9.2857142857rem !important;
  }
  /* P */
  .g-pr-135--lg {
    padding-right: 9.6428571429rem !important;
  }
  /* P */
  .g-pr-140--lg {
    padding-right: 10rem !important;
  }
  /* P */
  .g-pr-145--lg {
    padding-right: 10.3571428571rem !important;
  }
  /* P */
  .g-pr-150--lg {
    padding-right: 10.7142857143rem !important;
  }
  /* P */
  .g-pr-155--lg {
    padding-right: 11.0714285714rem !important;
  }
  /* P */
  .g-pr-160--lg {
    padding-right: 11.4285714286rem !important;
  }
  /* P */
  .g-pr-165--lg {
    padding-right: 11.7857142857rem !important;
  }
  /* P */
  .g-pr-170--lg {
    padding-right: 12.1428571429rem !important;
  }
  /* Padding Bottom */
  .g-pb-0--lg {
    padding-bottom: 0px !important;
  }
  .g-pb-1--lg {
    padding-bottom: 0.0714285714rem !important;
  }
  .g-pb-2--lg {
    padding-bottom: 0.1428571429rem !important;
  }
  .g-pb-3--lg {
    padding-bottom: 0.2142857143rem !important;
  }
  .g-pb-4--lg {
    padding-bottom: 0.2857142857rem !important;
  }
  .g-pb-5--lg {
    padding-bottom: 0.3571428571rem !important;
  }
  .g-pb-6--lg {
    padding-bottom: 0.4285714286rem !important;
  }
  .g-pb-7--lg {
    padding-bottom: 0.5rem !important;
  }
  .g-pb-8--lg {
    padding-bottom: 0.5714285714rem !important;
  }
  .g-pb-9--lg {
    padding-bottom: 0.6428571429rem !important;
  }
  .g-pb-10--lg {
    padding-bottom: 0.7142857143rem !important;
  }
  .g-pb-15--lg {
    padding-bottom: 1.0714285714rem !important;
  }
  .g-pb-20--lg {
    padding-bottom: 1.4285714286rem !important;
  }
  .g-pb-25--lg {
    padding-bottom: 1.7857142857rem !important;
  }
  .g-pb-30--lg {
    padding-bottom: 2.1428571429rem !important;
  }
  .g-pb-35--lg {
    padding-bottom: 2.5rem !important;
  }
  .g-pb-40--lg {
    padding-bottom: 2.8571428571rem !important;
  }
  .g-pb-45--lg {
    padding-bottom: 3.2142857143rem !important;
  }
  .g-pb-50--lg {
    padding-bottom: 3.5714285714rem !important;
  }
  .g-pb-55--lg {
    padding-bottom: 3.9285714286rem !important;
  }
  .g-pb-60--lg {
    padding-bottom: 4.2857142857rem !important;
  }
  .g-pb-65--lg {
    padding-bottom: 4.6428571429rem !important;
  }
  .g-pb-70--lg {
    padding-bottom: 5rem !important;
  }
  .g-pb-75--lg {
    padding-bottom: 5.3571428571rem !important;
  }
  .g-pb-80--lg {
    padding-bottom: 5.7142857143rem !important;
  }
  .g-pb-85--lg {
    padding-bottom: 6.0714285714rem !important;
  }
  .g-pb-90--lg {
    padding-bottom: 6.4285714286rem !important;
  }
  .g-pb-95--lg {
    padding-bottom: 6.7857142857rem !important;
  }
  .g-pb-100--lg {
    padding-bottom: 7.1428571429rem !important;
  }
  .g-pb-105--lg {
    padding-bottom: 7.5rem !important;
  }
  .g-pb-110--lg {
    padding-bottom: 7.8571428571rem !important;
  }
  .g-pb-115--lg {
    padding-bottom: 8.2142857143rem !important;
  }
  .g-pb-120--lg {
    padding-bottom: 8.5714285714rem !important;
  }
  .g-pb-125--lg {
    padding-bottom: 8.9285714286rem !important;
  }
  .g-pb-130--lg {
    padding-bottom: 9.2857142857rem !important;
  }
  .g-pb-135--lg {
    padding-bottom: 9.6428571429rem !important;
  }
  .g-pb-140--lg {
    padding-bottom: 10rem !important;
  }
  .g-pb-145--lg {
    padding-bottom: 10.3571428571rem !important;
  }
  .g-pb-150--lg {
    padding-bottom: 10.7142857143rem !important;
  }
  /* Padding Left */
  .g-pl-0--lg {
    padding-left: 0px !important;
  }
  .g-pl-1--lg {
    padding-left: 0.0714285714rem !important;
  }
  .g-pl-2--lg {
    padding-left: 0.1428571429rem !important;
  }
  .g-pl-3--lg {
    padding-left: 0.2142857143rem !important;
  }
  .g-pl-4--lg {
    padding-left: 0.2857142857rem !important;
  }
  .g-pl-5--lg {
    padding-left: 0.3571428571rem !important;
  }
  .g-pl-6--lg {
    padding-left: 0.4285714286rem !important;
  }
  .g-pl-7--lg {
    padding-left: 0.5rem !important;
  }
  .g-pl-8--lg {
    padding-left: 0.5714285714rem !important;
  }
  .g-pl-9--lg {
    padding-left: 0.6428571429rem !important;
  }
  /* P */
  .g-pl-10--lg {
    padding-left: 0.7142857143rem !important;
  }
  /* P */
  .g-pl-15--lg {
    padding-left: 1.0714285714rem !important;
  }
  /* P */
  .g-pl-20--lg {
    padding-left: 1.4285714286rem !important;
  }
  /* P */
  .g-pl-25--lg {
    padding-left: 1.7857142857rem !important;
  }
  /* P */
  .g-pl-30--lg {
    padding-left: 2.1428571429rem !important;
  }
  /* P */
  .g-pl-35--lg {
    padding-left: 2.5rem !important;
  }
  /* P */
  .g-pl-40--lg {
    padding-left: 2.8571428571rem !important;
  }
  /* P */
  .g-pl-45--lg {
    padding-left: 3.2142857143rem !important;
  }
  /* P */
  .g-pl-50--lg {
    padding-left: 3.5714285714rem !important;
  }
  /* P */
  .g-pl-55--lg {
    padding-left: 3.9285714286rem !important;
  }
  /* P */
  .g-pl-60--lg {
    padding-left: 4.2857142857rem !important;
  }
  /* P */
  .g-pl-65--lg {
    padding-left: 4.6428571429rem !important;
  }
  /* P */
  .g-pl-70--lg {
    padding-left: 5rem !important;
  }
  /* P */
  .g-pl-75--lg {
    padding-left: 5.3571428571rem !important;
  }
  /* P */
  .g-pl-80--lg {
    padding-left: 5.7142857143rem !important;
  }
  /* P */
  .g-pl-85--lg {
    padding-left: 6.0714285714rem !important;
  }
  /* P */
  .g-pl-90--lg {
    padding-left: 6.4285714286rem !important;
  }
  /* P */
  .g-pl-95--lg {
    padding-left: 6.7857142857rem !important;
  }
  /* P */
  .g-pl-100--lg {
    padding-left: 7.1428571429rem !important;
  }
  /* P */
  .g-pl-105--lg {
    padding-left: 7.5rem !important;
  }
  /* P */
  .g-pl-110--lg {
    padding-left: 7.8571428571rem !important;
  }
  /* P */
  .g-pl-115--lg {
    padding-left: 8.2142857143rem !important;
  }
  /* P */
  .g-pl-120--lg {
    padding-left: 8.5714285714rem !important;
  }
  /* P */
  .g-pl-125--lg {
    padding-left: 8.9285714286rem !important;
  }
  /* P */
  .g-pl-130--lg {
    padding-left: 9.2857142857rem !important;
  }
  /* P */
  .g-pl-135--lg {
    padding-left: 9.6428571429rem !important;
  }
  /* P */
  .g-pl-140--lg {
    padding-left: 10rem !important;
  }
  /* P */
  .g-pl-145--lg {
    padding-left: 10.3571428571rem !important;
  }
  /* P */
  .g-pl-150--lg {
    padding-left: 10.7142857143rem !important;
  }
  /* P */
  .g-pl-155--lg {
    padding-left: 11.0714285714rem !important;
  }
  /* P */
  .g-pl-160--lg {
    padding-left: 11.4285714286rem !important;
  }
  /* P */
  .g-pl-165--lg {
    padding-left: 11.7857142857rem !important;
  }
  /* P */
  .g-pl-170--lg {
    padding-left: 12.1428571429rem !important;
  }
}

/* Padding Spaces (xl) P
------------------------------------*/
@media (min-width: 1200px) {
  .g-pa-0--xl {
    padding: 0 !important;
  }
  .g-px-0--xl {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .g-py-0--xl {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .g-pt-0--xl {
    padding-top: 0 !important;
  }
  .g-pr-0--xl {
    padding-right: 0 !important;
  }
  .g-pb-0--xl {
    padding-bottom: 0 !important;
  }
  .g-pl-0--xl {
    padding-left: 0 !important;
  }
  /* Padding Around */
  .g-pa-2--xl {
    padding: 0.1428571429rem !important;
  }
  .g-pa-3--xl {
    padding: 0.2142857143rem !important;
  }
  .g-pa-5--xl {
    padding: 0.3571428571rem !important;
  }
  .g-pa-7--xl {
    padding: 0.5rem !important;
  }
  .g-pa-10--xl {
    padding: 0.7142857143rem !important;
  }
  .g-pa-15--xl {
    padding: 1.0714285714rem !important;
  }
  .g-pa-20--xl {
    padding: 1.4285714286rem !important;
  }
  .g-pa-25--xl {
    padding: 1.7857142857rem !important;
  }
  .g-pa-30--xl {
    padding: 2.1428571429rem !important;
  }
  .g-pa-35--xl {
    padding: 2.5rem !important;
  }
  .g-pa-40--xl {
    padding: 2.8571428571rem !important;
  }
  .g-pa-45--xl {
    padding: 3.2142857143rem !important;
  }
  .g-pa-50--xl {
    padding: 3.5714285714rem !important;
  }
  .g-pa-55--xl {
    padding: 3.9285714286rem !important;
  }
  .g-pa-60--xl {
    padding: 4.2857142857rem !important;
  }
  .g-pa-65--xl {
    padding: 4.6428571429rem !important;
  }
  .g-pa-70--xl {
    padding: 5rem !important;
  }
  .g-pa-75--xl {
    padding: 5.3571428571rem !important;
  }
  .g-pa-80--xl {
    padding: 5.7142857143rem !important;
  }
  .g-pa-85--xl {
    padding: 6.0714285714rem !important;
  }
  .g-pa-90--xl {
    padding: 6.4285714286rem !important;
  }
  .g-pa-95--xl {
    padding: 6.7857142857rem !important;
  }
  .g-pa-100--xl {
    padding: 7.1428571429rem !important;
  }
  .g-pa-105--xl {
    padding: 7.5rem !important;
  }
  .g-pa-110--xl {
    padding: 7.8571428571rem !important;
  }
  .g-pa-115--xl {
    padding: 8.2142857143rem !important;
  }
  .g-pa-120--xl {
    padding: 8.5714285714rem !important;
  }
  .g-pa-125--xl {
    padding: 8.9285714286rem !important;
  }
  .g-pa-130--xl {
    padding: 9.2857142857rem !important;
  }
  .g-pa-135--xl {
    padding: 9.6428571429rem !important;
  }
  .g-pa-140--xl {
    padding: 10rem !important;
  }
  .g-pa-145--xl {
    padding: 10.3571428571rem !important;
  }
  .g-pa-150--xl {
    padding: 10.7142857143rem !important;
  }
  /* Padding X */
  .g-px-1--xl {
    padding-left: 0.0714285714rem !important;
    padding-right: 0.0714285714rem !important;
  }
  .g-px-2--xl {
    padding-left: 0.1428571429rem !important;
    padding-right: 0.1428571429rem !important;
  }
  .g-px-3--xl {
    padding-left: 0.2142857143rem !important;
    padding-right: 0.2142857143rem !important;
  }
  .g-px-4--xl {
    padding-left: 0.2857142857rem !important;
    padding-right: 0.2857142857rem !important;
  }
  .g-px-5--xl {
    padding-left: 0.3571428571rem !important;
    padding-right: 0.3571428571rem !important;
  }
  .g-px-6--xl {
    padding-left: 0.4285714286rem !important;
    padding-right: 0.4285714286rem !important;
  }
  .g-px-7--xl {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .g-px-8--xl {
    padding-left: 0.5714285714rem !important;
    padding-right: 0.5714285714rem !important;
  }
  .g-px-9--xl {
    padding-left: 0.6428571429rem !important;
    padding-right: 0.6428571429rem !important;
  }
  .g-px-10--xl {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }
  .g-px-11--xl {
    padding-left: 0.7857142857rem !important;
    padding-right: 0.7857142857rem !important;
  }
  .g-px-12--xl {
    padding-left: 0.8571428571rem !important;
    padding-right: 0.8571428571rem !important;
  }
  .g-px-13--xl {
    padding-left: 0.9285714286rem !important;
    padding-right: 0.9285714286rem !important;
  }
  .g-px-14--xl {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .g-px-15--xl {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }
  .g-px-16--xl {
    padding-left: 1.1428571429rem !important;
    padding-right: 1.1428571429rem !important;
  }
  .g-px-17--xl {
    padding-left: 1.2142857143rem !important;
    padding-right: 1.2142857143rem !important;
  }
  .g-px-18--xl {
    padding-left: 1.2857142857rem !important;
    padding-right: 1.2857142857rem !important;
  }
  .g-px-19--xl {
    padding-left: 1.3571428571rem !important;
    padding-right: 1.3571428571rem !important;
  }
  .g-px-10--xl {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }
  .g-px-15--xl {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }
  .g-px-20--xl {
    padding-left: 1.4285714286rem !important;
    padding-right: 1.4285714286rem !important;
  }
  .g-px-25--xl {
    padding-left: 1.7857142857rem !important;
    padding-right: 1.7857142857rem !important;
  }
  .g-px-30--xl {
    padding-left: 2.1428571429rem !important;
    padding-right: 2.1428571429rem !important;
  }
  .g-px-35--xl {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .g-px-40--xl {
    padding-left: 2.8571428571rem !important;
    padding-right: 2.8571428571rem !important;
  }
  .g-px-45--xl {
    padding-left: 3.2142857143rem !important;
    padding-right: 3.2142857143rem !important;
  }
  .g-px-50--xl {
    padding-left: 3.5714285714rem !important;
    padding-right: 3.5714285714rem !important;
  }
  .g-px-55--xl {
    padding-left: 3.9285714286rem !important;
    padding-right: 3.9285714286rem !important;
  }
  .g-px-60--xl {
    padding-left: 4.2857142857rem !important;
    padding-right: 4.2857142857rem !important;
  }
  .g-px-65--xl {
    padding-left: 4.6428571429rem !important;
    padding-right: 4.6428571429rem !important;
  }
  .g-px-70--xl {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .g-px-75--xl {
    padding-left: 5.3571428571rem !important;
    padding-right: 5.3571428571rem !important;
  }
  .g-px-80--xl {
    padding-left: 5.7142857143rem !important;
    padding-right: 5.7142857143rem !important;
  }
  .g-px-85--xl {
    padding-left: 6.0714285714rem !important;
    padding-right: 6.0714285714rem !important;
  }
  .g-px-90--xl {
    padding-left: 6.4285714286rem !important;
    padding-right: 6.4285714286rem !important;
  }
  .g-px-95--xl {
    padding-left: 6.7857142857rem !important;
    padding-right: 6.7857142857rem !important;
  }
  .g-px-100--xl {
    padding-left: 7.1428571429rem !important;
    padding-right: 7.1428571429rem !important;
  }
  .g-px-105--xl {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .g-px-110--xl {
    padding-left: 7.8571428571rem !important;
    padding-right: 7.8571428571rem !important;
  }
  .g-px-115--xl {
    padding-left: 8.2142857143rem !important;
    padding-right: 8.2142857143rem !important;
  }
  .g-px-120--xl {
    padding-left: 8.5714285714rem !important;
    padding-right: 8.5714285714rem !important;
  }
  .g-px-125--xl {
    padding-left: 8.9285714286rem !important;
    padding-right: 8.9285714286rem !important;
  }
  .g-px-130--xl {
    padding-left: 9.2857142857rem !important;
    padding-right: 9.2857142857rem !important;
  }
  .g-px-135--xl {
    padding-left: 9.6428571429rem !important;
    padding-right: 9.6428571429rem !important;
  }
  .g-px-140--xl {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .g-px-145--xl {
    padding-left: 10.3571428571rem !important;
    padding-right: 10.3571428571rem !important;
  }
  .g-px-150--xl {
    padding-left: 10.7142857143rem !important;
    padding-right: 10.7142857143rem !important;
  }
  /* Padding Y */
  .g-py-1--xl {
    padding-top: 0.0714285714rem !important;
    padding-bottom: 0.0714285714rem !important;
  }
  .g-py-2--xl {
    padding-top: 0.1428571429rem !important;
    padding-bottom: 0.1428571429rem !important;
  }
  .g-py-3--xl {
    padding-top: 0.2142857143rem !important;
    padding-bottom: 0.2142857143rem !important;
  }
  .g-py-4--xl {
    padding-top: 0.2857142857rem !important;
    padding-bottom: 0.2857142857rem !important;
  }
  .g-py-5--xl {
    padding-top: 0.3571428571rem !important;
    padding-bottom: 0.3571428571rem !important;
  }
  .g-py-6--xl {
    padding-top: 0.4285714286rem !important;
    padding-bottom: 0.4285714286rem !important;
  }
  .g-py-7--xl {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .g-py-8--xl {
    padding-top: 0.5714285714rem !important;
    padding-bottom: 0.5714285714rem !important;
  }
  .g-py-9--xl {
    padding-top: 0.6428571429rem !important;
    padding-bottom: 0.6428571429rem !important;
  }
  .g-py-10--xl {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }
  .g-py-11--xl {
    padding-top: 0.7857142857rem !important;
    padding-bottom: 0.7857142857rem !important;
  }
  .g-py-12--xl {
    padding-top: 0.8571428571rem !important;
    padding-bottom: 0.8571428571rem !important;
  }
  .g-py-13--xl {
    padding-top: 0.9285714286rem !important;
    padding-bottom: 0.9285714286rem !important;
  }
  .g-py-14--xl {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .g-py-15--xl {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }
  .g-py-16--xl {
    padding-top: 1.1428571429rem !important;
    padding-bottom: 1.1428571429rem !important;
  }
  .g-py-17--xl {
    padding-top: 1.2142857143rem !important;
    padding-bottom: 1.2142857143rem !important;
  }
  .g-py-18--xl {
    padding-top: 1.2857142857rem !important;
    padding-bottom: 1.2857142857rem !important;
  }
  .g-py-19--xl {
    padding-top: 1.3571428571rem !important;
    padding-bottom: 1.3571428571rem !important;
  }
  /* P */
  .g-py-10--xl {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }
  /* P */
  .g-py-15--xl {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }
  /* P */
  .g-py-20--xl {
    padding-top: 1.4285714286rem !important;
    padding-bottom: 1.4285714286rem !important;
  }
  /* P */
  .g-py-25--xl {
    padding-top: 1.7857142857rem !important;
    padding-bottom: 1.7857142857rem !important;
  }
  /* P */
  .g-py-30--xl {
    padding-top: 2.1428571429rem !important;
    padding-bottom: 2.1428571429rem !important;
  }
  /* P */
  .g-py-35--xl {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  /* P */
  .g-py-40--xl {
    padding-top: 2.8571428571rem !important;
    padding-bottom: 2.8571428571rem !important;
  }
  /* P */
  .g-py-45--xl {
    padding-top: 3.2142857143rem !important;
    padding-bottom: 3.2142857143rem !important;
  }
  /* P */
  .g-py-50--xl {
    padding-top: 3.5714285714rem !important;
    padding-bottom: 3.5714285714rem !important;
  }
  /* P */
  .g-py-55--xl {
    padding-top: 3.9285714286rem !important;
    padding-bottom: 3.9285714286rem !important;
  }
  /* P */
  .g-py-60--xl {
    padding-top: 4.2857142857rem !important;
    padding-bottom: 4.2857142857rem !important;
  }
  /* P */
  .g-py-65--xl {
    padding-top: 4.6428571429rem !important;
    padding-bottom: 4.6428571429rem !important;
  }
  /* P */
  .g-py-70--xl {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  /* P */
  .g-py-75--xl {
    padding-top: 5.3571428571rem !important;
    padding-bottom: 5.3571428571rem !important;
  }
  /* P */
  .g-py-80--xl {
    padding-top: 5.7142857143rem !important;
    padding-bottom: 5.7142857143rem !important;
  }
  /* P */
  .g-py-85--xl {
    padding-top: 6.0714285714rem !important;
    padding-bottom: 6.0714285714rem !important;
  }
  /* P */
  .g-py-90--xl {
    padding-top: 6.4285714286rem !important;
    padding-bottom: 6.4285714286rem !important;
  }
  /* P */
  .g-py-95--xl {
    padding-top: 6.7857142857rem !important;
    padding-bottom: 6.7857142857rem !important;
  }
  /* P */
  .g-py-100--xl {
    padding-top: 7.1428571429rem !important;
    padding-bottom: 7.1428571429rem !important;
  }
  /* P */
  .g-py-105--xl {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  /* P */
  .g-py-110--xl {
    padding-top: 7.8571428571rem !important;
    padding-bottom: 7.8571428571rem !important;
  }
  /* P */
  .g-py-115--xl {
    padding-top: 8.2142857143rem !important;
    padding-bottom: 8.2142857143rem !important;
  }
  /* P */
  .g-py-120--xl {
    padding-top: 8.5714285714rem !important;
    padding-bottom: 8.5714285714rem !important;
  }
  /* P */
  .g-py-125--xl {
    padding-top: 8.9285714286rem !important;
    padding-bottom: 8.9285714286rem !important;
  }
  /* P */
  .g-py-130--xl {
    padding-top: 9.2857142857rem !important;
    padding-bottom: 9.2857142857rem !important;
  }
  /* P */
  .g-py-135--xl {
    padding-top: 9.6428571429rem !important;
    padding-bottom: 9.6428571429rem !important;
  }
  /* P */
  .g-py-140--xl {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  /* P */
  .g-py-145--xl {
    padding-top: 10.3571428571rem !important;
    padding-bottom: 10.3571428571rem !important;
  }
  /* P */
  .g-py-150--xl {
    padding-top: 10.7142857143rem !important;
    padding-bottom: 10.7142857143rem !important;
  }
  /* P */
  .g-py-155--xl {
    padding-top: 11.0714285714rem !important;
    padding-bottom: 11.0714285714rem !important;
  }
  /* P */
  .g-py-160--xl {
    padding-top: 11.4285714286rem !important;
    padding-bottom: 11.4285714286rem !important;
  }
  /* P */
  .g-py-165--xl {
    padding-top: 11.7857142857rem !important;
    padding-bottom: 11.7857142857rem !important;
  }
  /* P */
  .g-py-170--xl {
    padding-top: 12.1428571429rem !important;
    padding-bottom: 12.1428571429rem !important;
  }
  /* P */
  .g-py-175--xl {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  /* P */
  .g-py-180--xl {
    padding-top: 12.8571428571rem !important;
    padding-bottom: 12.8571428571rem !important;
  }
  /* P */
  .g-py-185--xl {
    padding-top: 13.2142857143rem !important;
    padding-bottom: 13.2142857143rem !important;
  }
  /* P */
  .g-py-190--xl {
    padding-top: 13.5714285714rem !important;
    padding-bottom: 13.5714285714rem !important;
  }
  /* P */
  .g-py-195--xl {
    padding-top: 13.9285714286rem !important;
    padding-bottom: 13.9285714286rem !important;
  }
  /* P */
  .g-py-200--xl {
    padding-top: 14.2857142857rem !important;
    padding-bottom: 14.2857142857rem !important;
  }
  /* P */
  .g-py-205--xl {
    padding-top: 14.6428571429rem !important;
    padding-bottom: 14.6428571429rem !important;
  }
  /* P */
  .g-py-210--xl {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  /* Padding Top */
  .g-pt-0--xl {
    padding-top: 0px !important;
  }
  .g-pt-1--xl {
    padding-top: 0.0714285714rem !important;
  }
  .g-pt-2--xl {
    padding-top: 0.1428571429rem !important;
  }
  .g-pt-3--xl {
    padding-top: 0.2142857143rem !important;
  }
  .g-pt-4--xl {
    padding-top: 0.2857142857rem !important;
  }
  .g-pt-5--xl {
    padding-top: 0.3571428571rem !important;
  }
  .g-pt-6--xl {
    padding-top: 0.4285714286rem !important;
  }
  .g-pt-7--xl {
    padding-top: 0.5rem !important;
  }
  .g-pt-8--xl {
    padding-top: 0.5714285714rem !important;
  }
  .g-pt-9--xl {
    padding-top: 0.6428571429rem !important;
  }
  .g-pt-10--xl {
    padding-top: 0.7142857143rem !important;
  }
  .g-pt-11--xl {
    padding-top: 0.7857142857rem !important;
  }
  .g-pt-12--xl {
    padding-top: 0.8571428571rem !important;
  }
  .g-pt-13--xl {
    padding-top: 0.9285714286rem !important;
  }
  .g-pt-14--xl {
    padding-top: 1rem !important;
  }
  .g-pt-15--xl {
    padding-top: 1.0714285714rem !important;
  }
  .g-pt-16--xl {
    padding-top: 1.1428571429rem !important;
  }
  .g-pt-17--xl {
    padding-top: 1.2142857143rem !important;
  }
  .g-pt-18--xl {
    padding-top: 1.2857142857rem !important;
  }
  .g-pt-19--xl {
    padding-top: 1.3571428571rem !important;
  }
  .g-pt-10--xl {
    padding-top: 0.7142857143rem !important;
  }
  .g-pt-15--xl {
    padding-top: 1.0714285714rem !important;
  }
  .g-pt-20--xl {
    padding-top: 1.4285714286rem !important;
  }
  .g-pt-25--xl {
    padding-top: 1.7857142857rem !important;
  }
  .g-pt-30--xl {
    padding-top: 2.1428571429rem !important;
  }
  .g-pt-35--xl {
    padding-top: 2.5rem !important;
  }
  .g-pt-40--xl {
    padding-top: 2.8571428571rem !important;
  }
  .g-pt-45--xl {
    padding-top: 3.2142857143rem !important;
  }
  .g-pt-50--xl {
    padding-top: 3.5714285714rem !important;
  }
  .g-pt-55--xl {
    padding-top: 3.9285714286rem !important;
  }
  .g-pt-60--xl {
    padding-top: 4.2857142857rem !important;
  }
  .g-pt-65--xl {
    padding-top: 4.6428571429rem !important;
  }
  .g-pt-70--xl {
    padding-top: 5rem !important;
  }
  .g-pt-75--xl {
    padding-top: 5.3571428571rem !important;
  }
  .g-pt-80--xl {
    padding-top: 5.7142857143rem !important;
  }
  .g-pt-85--xl {
    padding-top: 6.0714285714rem !important;
  }
  .g-pt-90--xl {
    padding-top: 6.4285714286rem !important;
  }
  .g-pt-95--xl {
    padding-top: 6.7857142857rem !important;
  }
  .g-pt-100--xl {
    padding-top: 7.1428571429rem !important;
  }
  .g-pt-105--xl {
    padding-top: 7.5rem !important;
  }
  .g-pt-110--xl {
    padding-top: 7.8571428571rem !important;
  }
  .g-pt-115--xl {
    padding-top: 8.2142857143rem !important;
  }
  .g-pt-120--xl {
    padding-top: 8.5714285714rem !important;
  }
  .g-pt-125--xl {
    padding-top: 8.9285714286rem !important;
  }
  .g-pt-130--xl {
    padding-top: 9.2857142857rem !important;
  }
  .g-pt-135--xl {
    padding-top: 9.6428571429rem !important;
  }
  .g-pt-140--xl {
    padding-top: 10rem !important;
  }
  .g-pt-145--xl {
    padding-top: 10.3571428571rem !important;
  }
  .g-pt-150--xl {
    padding-top: 10.7142857143rem !important;
  }
  /* Padding Right */
  .g-pr-0--xl {
    padding-right: 0px !important;
  }
  .g-pr-1--xl {
    padding-right: 0.0714285714rem !important;
  }
  .g-pr-2--xl {
    padding-right: 0.1428571429rem !important;
  }
  .g-pr-3--xl {
    padding-right: 0.2142857143rem !important;
  }
  .g-pr-4--xl {
    padding-right: 0.2857142857rem !important;
  }
  .g-pr-5--xl {
    padding-right: 0.3571428571rem !important;
  }
  .g-pr-6--xl {
    padding-right: 0.4285714286rem !important;
  }
  .g-pr-7--xl {
    padding-right: 0.5rem !important;
  }
  .g-pr-8--xl {
    padding-right: 0.5714285714rem !important;
  }
  .g-pr-9--xl {
    padding-right: 0.6428571429rem !important;
  }
  /* P */
  .g-pr-10--xl {
    padding-right: 0.7142857143rem !important;
  }
  /* P */
  .g-pr-15--xl {
    padding-right: 1.0714285714rem !important;
  }
  /* P */
  .g-pr-20--xl {
    padding-right: 1.4285714286rem !important;
  }
  /* P */
  .g-pr-25--xl {
    padding-right: 1.7857142857rem !important;
  }
  /* P */
  .g-pr-30--xl {
    padding-right: 2.1428571429rem !important;
  }
  /* P */
  .g-pr-35--xl {
    padding-right: 2.5rem !important;
  }
  /* P */
  .g-pr-40--xl {
    padding-right: 2.8571428571rem !important;
  }
  /* P */
  .g-pr-45--xl {
    padding-right: 3.2142857143rem !important;
  }
  /* P */
  .g-pr-50--xl {
    padding-right: 3.5714285714rem !important;
  }
  /* P */
  .g-pr-55--xl {
    padding-right: 3.9285714286rem !important;
  }
  /* P */
  .g-pr-60--xl {
    padding-right: 4.2857142857rem !important;
  }
  /* P */
  .g-pr-65--xl {
    padding-right: 4.6428571429rem !important;
  }
  /* P */
  .g-pr-70--xl {
    padding-right: 5rem !important;
  }
  /* P */
  .g-pr-75--xl {
    padding-right: 5.3571428571rem !important;
  }
  /* P */
  .g-pr-80--xl {
    padding-right: 5.7142857143rem !important;
  }
  /* P */
  .g-pr-85--xl {
    padding-right: 6.0714285714rem !important;
  }
  /* P */
  .g-pr-90--xl {
    padding-right: 6.4285714286rem !important;
  }
  /* P */
  .g-pr-95--xl {
    padding-right: 6.7857142857rem !important;
  }
  /* P */
  .g-pr-100--xl {
    padding-right: 7.1428571429rem !important;
  }
  /* P */
  .g-pr-105--xl {
    padding-right: 7.5rem !important;
  }
  /* P */
  .g-pr-110--xl {
    padding-right: 7.8571428571rem !important;
  }
  /* P */
  .g-pr-115--xl {
    padding-right: 8.2142857143rem !important;
  }
  /* P */
  .g-pr-120--xl {
    padding-right: 8.5714285714rem !important;
  }
  /* P */
  .g-pr-125--xl {
    padding-right: 8.9285714286rem !important;
  }
  /* P */
  .g-pr-130--xl {
    padding-right: 9.2857142857rem !important;
  }
  /* P */
  .g-pr-135--xl {
    padding-right: 9.6428571429rem !important;
  }
  /* P */
  .g-pr-140--xl {
    padding-right: 10rem !important;
  }
  /* P */
  .g-pr-145--xl {
    padding-right: 10.3571428571rem !important;
  }
  /* P */
  .g-pr-150--xl {
    padding-right: 10.7142857143rem !important;
  }
  /* P */
  .g-pr-155--xl {
    padding-right: 11.0714285714rem !important;
  }
  /* P */
  .g-pr-160--xl {
    padding-right: 11.4285714286rem !important;
  }
  /* P */
  .g-pr-165--xl {
    padding-right: 11.7857142857rem !important;
  }
  /* P */
  .g-pr-170--xl {
    padding-right: 12.1428571429rem !important;
  }
  /* Padding Bottom */
  .g-pb-0--xl {
    padding-bottom: 0px !important;
  }
  .g-pb-1--xl {
    padding-bottom: 0.0714285714rem !important;
  }
  .g-pb-2--xl {
    padding-bottom: 0.1428571429rem !important;
  }
  .g-pb-3--xl {
    padding-bottom: 0.2142857143rem !important;
  }
  .g-pb-4--xl {
    padding-bottom: 0.2857142857rem !important;
  }
  .g-pb-5--xl {
    padding-bottom: 0.3571428571rem !important;
  }
  .g-pb-6--xl {
    padding-bottom: 0.4285714286rem !important;
  }
  .g-pb-7--xl {
    padding-bottom: 0.5rem !important;
  }
  .g-pb-8--xl {
    padding-bottom: 0.5714285714rem !important;
  }
  .g-pb-9--xl {
    padding-bottom: 0.6428571429rem !important;
  }
  .g-pb-10--xl {
    padding-bottom: 0.7142857143rem !important;
  }
  .g-pb-15--xl {
    padding-bottom: 1.0714285714rem !important;
  }
  .g-pb-20--xl {
    padding-bottom: 1.4285714286rem !important;
  }
  .g-pb-25--xl {
    padding-bottom: 1.7857142857rem !important;
  }
  .g-pb-30--xl {
    padding-bottom: 2.1428571429rem !important;
  }
  .g-pb-35--xl {
    padding-bottom: 2.5rem !important;
  }
  .g-pb-40--xl {
    padding-bottom: 2.8571428571rem !important;
  }
  .g-pb-45--xl {
    padding-bottom: 3.2142857143rem !important;
  }
  .g-pb-50--xl {
    padding-bottom: 3.5714285714rem !important;
  }
  .g-pb-55--xl {
    padding-bottom: 3.9285714286rem !important;
  }
  .g-pb-60--xl {
    padding-bottom: 4.2857142857rem !important;
  }
  .g-pb-65--xl {
    padding-bottom: 4.6428571429rem !important;
  }
  .g-pb-70--xl {
    padding-bottom: 5rem !important;
  }
  .g-pb-75--xl {
    padding-bottom: 5.3571428571rem !important;
  }
  .g-pb-80--xl {
    padding-bottom: 5.7142857143rem !important;
  }
  .g-pb-85--xl {
    padding-bottom: 6.0714285714rem !important;
  }
  .g-pb-90--xl {
    padding-bottom: 6.4285714286rem !important;
  }
  .g-pb-95--xl {
    padding-bottom: 6.7857142857rem !important;
  }
  .g-pb-100--xl {
    padding-bottom: 7.1428571429rem !important;
  }
  .g-pb-105--xl {
    padding-bottom: 7.5rem !important;
  }
  .g-pb-110--xl {
    padding-bottom: 7.8571428571rem !important;
  }
  .g-pb-115--xl {
    padding-bottom: 8.2142857143rem !important;
  }
  .g-pb-120--xl {
    padding-bottom: 8.5714285714rem !important;
  }
  .g-pb-125--xl {
    padding-bottom: 8.9285714286rem !important;
  }
  .g-pb-130--xl {
    padding-bottom: 9.2857142857rem !important;
  }
  .g-pb-135--xl {
    padding-bottom: 9.6428571429rem !important;
  }
  .g-pb-140--xl {
    padding-bottom: 10rem !important;
  }
  .g-pb-145--xl {
    padding-bottom: 10.3571428571rem !important;
  }
  .g-pb-150--xl {
    padding-bottom: 10.7142857143rem !important;
  }
  /* Padding Left */
  .g-pl-0--xl {
    padding-left: 0px !important;
  }
  .g-pl-1--xl {
    padding-left: 0.0714285714rem !important;
  }
  .g-pl-2--xl {
    padding-left: 0.1428571429rem !important;
  }
  .g-pl-3--xl {
    padding-left: 0.2142857143rem !important;
  }
  .g-pl-4--xl {
    padding-left: 0.2857142857rem !important;
  }
  .g-pl-5--xl {
    padding-left: 0.3571428571rem !important;
  }
  .g-pl-6--xl {
    padding-left: 0.4285714286rem !important;
  }
  .g-pl-7--xl {
    padding-left: 0.5rem !important;
  }
  .g-pl-8--xl {
    padding-left: 0.5714285714rem !important;
  }
  .g-pl-9--xl {
    padding-left: 0.6428571429rem !important;
  }
  /* P */
  .g-pl-10--xl {
    padding-left: 0.7142857143rem !important;
  }
  /* P */
  .g-pl-15--xl {
    padding-left: 1.0714285714rem !important;
  }
  /* P */
  .g-pl-20--xl {
    padding-left: 1.4285714286rem !important;
  }
  /* P */
  .g-pl-25--xl {
    padding-left: 1.7857142857rem !important;
  }
  /* P */
  .g-pl-30--xl {
    padding-left: 2.1428571429rem !important;
  }
  /* P */
  .g-pl-35--xl {
    padding-left: 2.5rem !important;
  }
  /* P */
  .g-pl-40--xl {
    padding-left: 2.8571428571rem !important;
  }
  /* P */
  .g-pl-45--xl {
    padding-left: 3.2142857143rem !important;
  }
  /* P */
  .g-pl-50--xl {
    padding-left: 3.5714285714rem !important;
  }
  /* P */
  .g-pl-55--xl {
    padding-left: 3.9285714286rem !important;
  }
  /* P */
  .g-pl-60--xl {
    padding-left: 4.2857142857rem !important;
  }
  /* P */
  .g-pl-65--xl {
    padding-left: 4.6428571429rem !important;
  }
  /* P */
  .g-pl-70--xl {
    padding-left: 5rem !important;
  }
  /* P */
  .g-pl-75--xl {
    padding-left: 5.3571428571rem !important;
  }
  /* P */
  .g-pl-80--xl {
    padding-left: 5.7142857143rem !important;
  }
  /* P */
  .g-pl-85--xl {
    padding-left: 6.0714285714rem !important;
  }
  /* P */
  .g-pl-90--xl {
    padding-left: 6.4285714286rem !important;
  }
  /* P */
  .g-pl-95--xl {
    padding-left: 6.7857142857rem !important;
  }
  /* P */
  .g-pl-100--xl {
    padding-left: 7.1428571429rem !important;
  }
  /* P */
  .g-pl-105--xl {
    padding-left: 7.5rem !important;
  }
  /* P */
  .g-pl-110--xl {
    padding-left: 7.8571428571rem !important;
  }
  /* P */
  .g-pl-115--xl {
    padding-left: 8.2142857143rem !important;
  }
  /* P */
  .g-pl-120--xl {
    padding-left: 8.5714285714rem !important;
  }
  /* P */
  .g-pl-125--xl {
    padding-left: 8.9285714286rem !important;
  }
  /* P */
  .g-pl-130--xl {
    padding-left: 9.2857142857rem !important;
  }
  /* P */
  .g-pl-135--xl {
    padding-left: 9.6428571429rem !important;
  }
  /* P */
  .g-pl-140--xl {
    padding-left: 10rem !important;
  }
  /* P */
  .g-pl-145--xl {
    padding-left: 10.3571428571rem !important;
  }
  /* P */
  .g-pl-150--xl {
    padding-left: 10.7142857143rem !important;
  }
  /* P */
  .g-pl-155--xl {
    padding-left: 11.0714285714rem !important;
  }
  /* P */
  .g-pl-160--xl {
    padding-left: 11.4285714286rem !important;
  }
  /* P */
  .g-pl-165--xl {
    padding-left: 11.7857142857rem !important;
  }
  /* P */
  .g-pl-170--xl {
    padding-left: 12.1428571429rem !important;
  }
}

/* Custom Paddings (xs)
------------------------------------*/
.g-pa-0-20-20 {
  padding: 0 1.4285714286rem 1.4285714286rem !important;
}

.g-pa-4-11 {
  padding: 0.2857142857rem 0.7857142857rem !important;
}

.g-pa-4-13 {
  padding: 0.2857142857rem 0.9285714286rem !important;
}

.g-pa-5-10 {
  padding: 0.3571428571rem 0.7142857143rem !important;
}

.g-pa-5-15 {
  padding: 0.3571428571rem 1.0714285714rem !important;
}

.g-pa-6-8 {
  padding: 0.4285714286rem 0.5714285714rem !important;
}

.g-pa-7-14 {
  padding: 0.5rem 1rem !important;
}

.g-pa-7-16 {
  padding: 0.5rem 1.1428571429rem !important;
}

.g-pa-10-5-5 {
  padding: 0.7142857143rem 0.3571428571rem 0.3571428571rem !important;
}

.g-pa-10-15 {
  padding: 0.7142857143rem 1.0714285714rem !important;
}

.g-pa-10-16 {
  padding: 0.7142857143rem 1.1428571429rem !important;
}

.g-pa-10-20 {
  padding: 0.7142857143rem 1.4285714286rem !important;
}

.g-pa-10-30 {
  padding: 0.7142857143rem 2.1428571429rem !important;
}

.g-pa-12 {
  padding: 0.8571428571rem !important;
}

.g-pa-12-19 {
  padding: 0.8571428571rem 1.3571428571rem !important;
}

.g-pa-12-21 {
  padding: 0.8571428571rem 1.5rem !important;
}

.g-pa-12-5-7 {
  padding: 0.8571428571rem 0.3571428571rem 0.5rem !important;
}

.g-pa-14-18 {
  padding: 1rem 1.2857142857rem !important;
}

.g-pa-15-0 {
  padding: 1.0714285714rem 0 !important;
}

.g-pa-15-5 {
  padding: 1.0714285714rem 0.3571428571rem !important;
}

.g-pa-15-20-10 {
  padding: 1.0714285714rem 1.4285714286rem 0.7142857143rem !important;
}

.g-pa-15-20 {
  padding: 1.0714285714rem 1.4285714286rem !important;
}

.g-pa-15-30 {
  padding: 1.0714285714rem 2.1428571429rem !important;
}

.g-pa-17-23 {
  padding: 1.2142857143rem 1.6428571429rem !important;
}

.g-pa-20-10 {
  padding: 1.4285714286rem 0.7142857143rem !important;
}

.g-pa-20-20-15 {
  padding: 1.4285714286rem 1.4285714286rem 1.0714285714rem !important;
}

.g-pa-20-20-20-55 {
  padding: 1.4285714286rem 1.4285714286rem 1.4285714286rem 3.9285714286rem !important;
}

.g-pa-20-30-0 {
  padding: 1.4285714286rem 2.1428571429rem 0 !important;
}

.g-pa-20-40-25 {
  padding: 1.4285714286rem 2.8571428571rem 1.7857142857rem !important;
}

.g-pa-24 {
  padding: 1.7142857143rem !important;
}

.g-pa-25-20-30 {
  padding: 1.7857142857rem 1.4285714286rem 2.1428571429rem !important;
}

.g-pa-25-30-20 {
  padding: 1.7857142857rem 2.1428571429rem 1.4285714286rem !important;
}

.g-pa-25-30 {
  padding: 1.7857142857rem 2.1428571429rem !important;
}

.g-pa-30-10 {
  padding: 2.1428571429rem 0.7142857143rem !important;
}

.g-pa-30-15 {
  padding: 2.1428571429rem 1.0714285714rem !important;
}

.g-pa-30-20 {
  padding: 2.1428571429rem 1.4285714286rem !important;
}

.g-pa-30-30-20 {
  padding: 2.1428571429rem 2.1428571429rem 1.4285714286rem !important;
}

.g-pa-40 {
  padding: 2.8571428571rem !important;
}

.g-pa-40-20 {
  padding: 2.8571428571rem 1.4285714286rem !important;
}

.g-pa-40-30-30 {
  padding: 2.8571428571rem 2.1428571429rem 2.1428571429rem !important;
}

.g-pa-50-15-0 {
  padding: 3.5714285714rem 1.0714285714rem 0 !important;
}

.g-pa-15x {
  padding: 15% !important;
}

.g-px-17 {
  padding-left: 1.2142857143rem !important;
  padding-right: 1.2142857143rem !important;
}

.g-px-18 {
  padding-left: 1.2857142857rem !important;
  /* O */
  padding-right: 1.2857142857rem !important;
}

.g-py-23 {
  padding-top: 1.6428571429rem !important;
  /* O */
  padding-bottom: 1.6428571429rem !important;
}

.g-py-235 {
  padding-top: 16.7857142857rem !important;
  /* O */
  padding-bottom: 16.7857142857rem !important;
}

.g-pl-12 {
  padding-left: 0.8571428571rem !important;
}

.g-pl-17 {
  padding-left: 1.2142857143rem !important;
}

.g-pl-7--hover:hover {
  padding-left: 0.5rem !important;
}

.g-pl-25--hover:hover {
  padding-left: 1.7857142857rem !important;
}

.active.g-pl-25--active {
  padding-left: 1.7857142857rem !important;
}

.g-pl-60--xs {
  padding-left: 4.2857142857rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.g-pt-11 {
  padding-top: 0.7857142857rem !important;
}

.g-pt-12 {
  padding-top: 0.8571428571rem !important;
}

.g-parent:hover .g-pt-25--parent-hover {
  padding-top: 1.7857142857rem !important;
}

.g-pt-130 {
  padding-top: 9.2857142857rem !important;
}

.g-pt-150 {
  padding-top: 10.7142857143rem !important;
}

.g-pt-170 {
  padding-top: 12.1428571429rem !important;
}

.g-pt-195 {
  padding-top: 13.9285714286rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.g-pb-170 {
  padding-bottom: 12.1428571429rem !important;
}

.g-pb-180 {
  padding-bottom: 12.8571428571rem !important;
}

.g-pb-200 {
  padding-bottom: 14.2857142857rem !important;
}

.g-pb-250 {
  padding-bottom: 17.8571428571rem !important;
}

.g-pb-13 {
  padding-bottom: 0.9285714286rem !important;
}

.g-pb-16 {
  padding-bottom: 1.1428571429rem !important;
}

.g-pa-9 {
  padding: 0.6428571429rem !important;
}

.g-pa-11 {
  padding: 0.7857142857rem !important;
}

.g-pa-12 {
  padding: 0.8571428571rem !important;
}

.g-pl-30--hover:hover {
  padding-left: 2.1428571429rem !important;
}

/* Custom Paddings (sm)
------------------------------------*/
@media (min-width: 576px) {
  .g-px-10x--sm {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .g-px-0--sm {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .g-py-0--sm {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .g-py-5--sm {
    padding-top: 0.3571428571rem !important;
    padding-bottom: 0.3571428571rem !important;
  }
  .g-pa-0-10x--sm {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .g-pa-0-35-35--sm {
    padding: 0 2.5rem 2.5rem !important;
  }
  .g-pa-5-10--sm {
    padding: 0.3571428571rem 0.7142857143rem !important;
  }
  .g-pa-8-16--sm {
    padding: 0.5714285714rem 1.1428571429rem !important;
  }
  .g-pa-10-20--sm {
    padding: 0.7142857143rem 1.4285714286rem !important;
  }
  .g-pa-15-20-10--sm {
    padding: 1.0714285714rem 1.4285714286rem 0.7142857143rem !important;
  }
  .g-pa-20-20-0--sm {
    padding: 1.4285714286rem 1.4285714286rem 0 !important;
  }
  .g-pa-20-20-10--sm {
    padding: 1.4285714286rem 1.4285714286rem 0.7142857143rem !important;
  }
  .g-pa-20-30-0--sm {
    padding: 1.4285714286rem 2.1428571429rem 0 !important;
  }
  .g-pa-20-30--sm {
    padding: 1.4285714286rem 2.1428571429rem !important;
  }
  .g-pa-30-20--sm {
    padding: 2.1428571429rem 1.4285714286rem !important;
  }
  .g-pa-30-25-20--sm {
    padding: 2.1428571429rem 1.7857142857rem 1.4285714286rem !important;
  }
  .g-pa-30-30-20--sm {
    padding: 2.1428571429rem 2.1428571429rem 1.4285714286rem !important;
  }
  .g-pa-30-50-40--sm {
    padding: 2.1428571429rem 3.5714285714rem 2.8571428571rem !important;
  }
  .g-pa-30-80--sm {
    padding: 2.1428571429rem 5.7142857143rem !important;
  }
  .g-pa-40-30-30--sm {
    padding: 2.8571428571rem 2.1428571429rem 2.1428571429rem !important;
  }
  .g-pa-60-30--sm {
    padding: 4.2857142857rem 2.1428571429rem !important;
  }
  .g-pa-70-50-50-30--sm {
    padding: 5rem 3.5714285714rem 3.5714285714rem 2.1428571429rem !important;
  }
  .g-pa-80-40--sm {
    padding: 5.7142857143rem 2.8571428571rem !important;
  }
  .g-pa-85-0-40--sm {
    padding: 6.0714285714rem 0 2.8571428571rem !important;
  }
  .g-pa-100-30--sm {
    padding: 7.1428571429rem 2.1428571429rem !important;
  }
  .g-pa-170-0-150--sm {
    padding: 12.1428571429rem 0 10.7142857143rem !important;
  }
  .g-pl-300--sm {
    padding-left: 21.4285714286rem !important;
  }
}

/* Custom Paddings (md)
------------------------------------*/
@media (min-width: 768px) {
  .g-pa-25-30--md {
    padding: 1.7857142857rem 2.1428571429rem !important;
  }
  .g-pa-30-10--md {
    padding: 2.1428571429rem 0.7142857143rem !important;
  }
  .g-pa-30-35--md {
    padding: 2.1428571429rem 2.5rem !important;
  }
  .g-py-0--md {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .g-py-23--md {
    padding-top: 1.6428571429rem !important;
    padding-bottom: 1.6428571429rem !important;
  }
  .g-px-18--md {
    padding-left: 1.2857142857rem !important;
    padding-right: 1.2857142857rem !important;
  }
  .g-pt-135--md {
    padding-top: 9.6428571429rem !important;
  }
  .g-pt-145--md {
    padding-top: 10.3571428571rem !important;
  }
  .g-pt-150--md {
    padding-top: 10.7142857143rem !important;
  }
  .g-pt-170--md {
    padding-top: 12.1428571429rem !important;
  }
  .g-pt-175--md {
    padding-top: 12.5rem !important;
  }
  .g-pb-70--md {
    padding-bottom: 5rem !important;
  }
  .g-pb-170--md {
    padding-bottom: 12.1428571429rem !important;
  }
  .g-pb-250--md {
    padding-bottom: 17.8571428571rem !important;
  }
  .g-pl-300--md {
    padding-left: 21.4285714286rem !important;
  }
}

/* Custom Paddings (lg)
------------------------------------*/
@media (min-width: 992px) {
  .g-py-0--lg {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .g-py-100--lg {
    padding-top: 7.1428571429rem !important;
    padding-bottom: 7.1428571429rem !important;
  }
  .g-px-200--lg {
    padding-left: 14.2857142857rem !important;
    padding-right: 14.2857142857rem !important;
  }
  .g-pt-170--lg {
    padding-top: 12.1428571429rem !important;
  }
  .g-pb-200--lg {
    padding-bottom: 14.2857142857rem !important;
  }
}

/* Custom Paddings (xl)
------------------------------------*/
@media (min-width: 1200px) {
  .g-py-0--xl {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .g-px-23--xl {
    padding-left: 1.6428571429rem !important;
    padding-right: 1.6428571429rem !important;
  }
}

/*------------------------------------
  Block Hidden
------------------------------------*/
.g-hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .g-hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .g-hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .g-hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .g-hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .g-hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .g-hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .g-hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .g-hidden-xl-up {
    display: none !important;
  }
}

.g-hidden-xl-down {
  display: none !important;
}
