/*------------------------------------
  Carousel indicators v30
------------------------------------*/

.u-carousel-indicators-v30 {
  display: block;
  width: 100%;

  > li {
    display: table-cell;
    width: 1%;
    text-align: center;
    float: none;
  }

  span {
    display: inline-block;
  }
}

@media (min-width: $g-sm) {
  .u-carousel-indicators-v30 {
    display: block;
    width: 100%;

    > li {
      display: table-cell;
      width: 1%;
      text-align: center;
      float: none;
    }

    span {
      display: inline-block;
    }
  }
}
