/*------------------------------------
  Navigation Style v3
------------------------------------*/

.navbar .u-main-nav-v3 {
	.nav-link {
		@extend %u-main-nav-base-link;

		color: $g-color-gray-dark-v2;

		@include px-to-rem(padding, 40px 36px 36px);
		border-bottom: 4px solid transparent;
	}

	.nav-item {

		&.hs-has-sub-menu,
		&.hs-has-mega-menu,
		&.dropdown {
			@extend %u-main-nav-has-sub-menu;

			> a {
				&::after {
					position: static;

					display: inline-block;
					vertical-align: middle;
					@include px-to-rem(margin-left, 10px);

					transform: none;
				}
			}
		}

		&.active > .nav-link,
		&:hover > .nav-link,
		&:focus > .nav-link,
		&.show > .nav-link,
		&.hs-sub-menu-opened > .nav-link,
		.nav-link:focus {
			border-color: $g-color-primary;
		}
	}
}

@media all and (max-width: #{$g-xl}-1) {
	.navbar .u-main-nav-v3 {
		.nav-link {
			@include px-to-rem(padding, 30px 25px);
		}
	}
}

@media all and (max-width: #{$g-lg}-1) {
	.navbar .u-main-nav-v3 {
		.nav-link {
			@include px-to-rem(padding, 10px 20px);
		}
	}
}