/*------------------------------------
  Heading-v7
------------------------------------*/
%u-heading-v7-divider {
  content: "";
  position: absolute;
  top: 52%;
  display: inline-block;
  @include px-to-rem(width, 40px);
  border-top-width: 1px;
  border-top-style: solid;
  border-color: inherit;
}
.u-heading-v7-divider {
  position: relative;
  display: inline-block;

  &::after {
    @extend %u-heading-v7-divider;
    @include px-to-rem(right, -55px);
  }
  .text-center &::before {
    @extend %u-heading-v7-divider;
    @include px-to-rem(left, -55px);
  }
  .text-right &::before {
    @extend %u-heading-v7-divider;
    @include px-to-rem(left, -55px);
  }
  .text-right &::after {
    display: none;
  }
  &__icon {
    display: inline-block;
    font-size: 1.4rem;
  }
}



//
// Imports
//

@import "heading-v7-1";
@import "heading-v7-3";