//
// Dividers Icon Type
//

.u-divider {
  position: relative;
  border-top-width: 1px;

  // Icon Style
  &__icon {
    position: absolute;
    @include px-to-rem(top, -20px);
    @include px-to-rem(width, 40px);
    @include px-to-rem(height, 40px);
    @include px-to-rem(font-size, 18px);
    @include px-to-rem(line-height, 40px);
    text-align: center;
    font-style: normal;
  }

  // Icon Indented
  &__icon--indented {
    box-shadow: 0 0 0 15px $g-color-white;
  }

  // Alignment Positions
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }

  &-center &__icon {
    left: auto;
    right: auto;
    @include px-to-rem(margin-left, -20px);
  }

  &-right &__icon {
    left: auto;
    right: 0;
  }
}