/*------------------------------------
  Credit cards
------------------------------------*/

.u-card {
  &--v1 {
    position: relative;

    &-front {
      position: relative;
      margin-bottom: 220px;
      z-index: 2;
    }

    &-back {
      position: absolute;
      bottom: -70%;
      right: 0;
      z-index: 1;
      width: 100%;
      height: 80%;
      padding-left: 0;
    }
  }
}

@media (min-width: 768px) {
  .u-card {
    &--v1 {
      &-front {
        margin-bottom: 30px;
      }

      &-back {
        bottom: auto;
        top: 30px;
        right: -30%;
        height: 100%;
        padding-left: 70%;
      }
    }
  }
}