/*------------------------------------
  Icon-v4 Additional BG colors
------------------------------------*/
.u-icon-v4 {
  &-bg-red {
    border-color: $g-color-red;

    .u-icon-v4-inner {
      background: $g-color-red;
    }
  }

  &-bg-lightred {
    border-color: $g-color-lightred;

    .u-icon-v4-inner {
      background: $g-color-lightred;
    }
  }

  &-bg-darkred {
    border-color: $g-color-darkred;

    .u-icon-v4-inner {
      background: $g-color-darkred;
    }
  }

  &-bg-blue {
    border-color: $g-color-blue;

    .u-icon-v4-inner {
      background: $g-color-blue;
    }
  }

  &-bg-indigo {
    border-color: $g-color-indigo;

    .u-icon-v4-inner {
      background: $g-color-indigo;
    }
  }

  &-bg-purple {
    border-color: $g-color-purple;

    .u-icon-v4-inner {
      background: $g-color-purple;
    }
  }

  &-bg-darkpurple {
    border-color: $g-color-darkpurple;

    .u-icon-v4-inner {
      background: $g-color-darkpurple;
    }
  }

  &-bg-pink {
    border-color: $g-color-pink;

    .u-icon-v4-inner {
      background: $g-color-pink;
    }
  }

  &-bg-orange {
    border-color: $g-color-orange;

    .u-icon-v4-inner {
      background: $g-color-orange;
    }
  }

  &-bg-deeporange {
    border-color: $g-color-deeporange;

    .u-icon-v4-inner {
      background: $g-color-deeporange;
    }
  }

  &-bg-aqua {
    border-color: $g-color-aqua;

    .u-icon-v4-inner {
      background: $g-color-aqua;
    }
  }

  &-bg-yellow {
    border-color: $g-color-yellow;

    .u-icon-v4-inner {
      background: $g-color-yellow;
    }
  }

  &-bg-cyan {
    border-color: $g-color-cyan;

    .u-icon-v4-inner {
      background: $g-color-cyan;
    }
  }

  &-bg-teal {
    border-color: $g-color-teal;

    .u-icon-v4-inner {
      background: $g-color-teal;
    }
  }

  &-bg-brown {
    border-color: $g-color-brown;

    .u-icon-v4-inner {
      background: $g-color-brown;
    }
  }

  &-bg-bluegray {
    border-color: $g-color-bluegray;

    .u-icon-v4-inner {
      background: $g-color-bluegray;
    }
  }



  //
  // Hover Effects
  //

  &-bg-red--hover:hover,
  .u-icon-block--hover:hover &-bg-red--hover {
    border-color: $g-color-red;

    & .u-icon-v4-inner {
      background: $g-color-red;
    }
  }

  &-bg-lightred--hover:hover,
  .u-icon-block--hover:hover &-bg-lightred--hover {
    border-color: $g-color-lightred;

    & .u-icon-v4-inner {
      background: $g-color-lightred;
    }
  }

  &-bg-darkred--hover:hover,
  .u-icon-block--hover:hover &-bg-darkred--hover {
    border-color: $g-color-darkred;

    & .u-icon-v4-inner {
      background: $g-color-darkred;
    }
  }

  &-bg-blue--hover:hover,
  .u-icon-block--hover:hover &-bg-blue--hover {
    border-color: $g-color-blue;

    & .u-icon-v4-inner {
      background: $g-color-blue;
    }
  }

  &-bg-indigo--hover:hover,
  .u-icon-block--hover:hover &-bg-indigo--hover {
    border-color: $g-color-indigo;

    & .u-icon-v4-inner {
      background: $g-color-indigo;
    }
  }

  &-bg-purple--hover:hover,
  .u-icon-block--hover:hover &-bg-purple--hover {
    border-color: $g-color-purple;

    & .u-icon-v4-inner {
      background: $g-color-purple;
    }
  }

  &-bg-darkpurple--hover:hover,
  .u-icon-block--hover:hover &-bg-darkpurple--hover {
    border-color: $g-color-darkpurple;

    & .u-icon-v4-inner {
      background: $g-color-darkpurple;
    }
  }

  &-bg-pink--hover:hover,
  .u-icon-block--hover:hover &-bg-pink--hover {
    border-color: $g-color-pink;

    & .u-icon-v4-inner {
      background: $g-color-pink;
    }
  }

  &-bg-orange--hover:hover,
  .u-icon-block--hover:hover &-bg-orange--hover {
    border-color: $g-color-orange;

    & .u-icon-v4-inner {
      background: $g-color-orange;
    }
  }

  &-bg-deeporange--hover:hover,
  .u-icon-block--hover:hover &-bg-deeporange--hover {
    border-color: $g-color-deeporange;

    & .u-icon-v4-inner {
      background: $g-color-deeporange;
    }
  }

  &-bg-aqua--hover:hover,
  .u-icon-block--hover:hover &-bg-aqua--hover {
    border-color: $g-color-aqua;

    & .u-icon-v4-inner {
      background: $g-color-aqua;
    }
  }

  &-bg-yellow--hover:hover,
  .u-icon-block--hover:hover &-bg-yellow--hover {
    border-color: $g-color-yellow;

    & .u-icon-v4-inner {
      background: $g-color-yellow;
    }
  }

  &-bg-cyan--hover:hover,
  .u-icon-block--hover:hover &-bg-cyan--hover {
    border-color: $g-color-cyan;

    & .u-icon-v4-inner {
      background: $g-color-cyan;
    }
  }

  &-bg-teal--hover:hover,
  .u-icon-block--hover:hover &-bg-teal--hover {
    border-color: $g-color-teal;

    & .u-icon-v4-inner {
      background: $g-color-teal;
    }
  }

  &-bg-brown--hover:hover,
  .u-icon-block--hover:hover &-bg-brown--hover {
    border-color: $g-color-brown;

    & .u-icon-v4-inner {
      background: $g-color-brown;
    }
  }

  &-bg-bluegray--hover:hover,
  .u-icon-block--hover:hover &-bg-bluegray--hover {
    border-color: $g-color-bluegray;

    & .u-icon-v4-inner {
      background: $g-color-bluegray;
    }
  }
}