/*------------------------------------
  Tabs v4
------------------------------------*/

@media (min-width: $g-md) {
  .u-nav-v4-1 {
    border-bottom: {
      style: solid;
      width: 1px;
      color: $g-color-gray-light-v4;
    }

    & .nav-item {
      margin-bottom: -1px;
    }

    & .nav-link {
      border: {
        style: solid;
        width: 2px 1px 1px;
        color: transparent;
      }
    }

    & .nav-link.active {
      border-color: $g-color-gray-light-v2 $g-color-gray-light-v4 $g-color-white;
    }

    &.u-nav-light {
      & .nav-link {
        color: $g-color-white;
      }

      & .nav-link.active {
        color: $g-color-white;
        border-color: $g-color-gray-light-v2 $g-color-gray-light-v4 transparent;
        background-color: $g-color-gray-dark-v2;
      }

      &.flex-column {
        & .nav-link.active {
          border-color: $g-color-gray-light-v2 transparent $g-color-gray-light-v2 $g-color-gray-light-v4;
          border-width: 1px 0 1px 1px;
          background-color: $g-color-gray-dark-v2;
        }
      }
    }

    &.u-nav-primary .nav-link.active {
      border-color: $g-color-primary $g-color-gray-light-v4 $g-color-white;
    }

    &.u-nav-dark .nav-link.active {
      border-color: $g-color-gray-dark-v2 $g-color-gray-light-v4 $g-color-white;
    }
  }
  @media (min-width: $g-md) {
    .u-nav-v4-1-column {
      display: flex;

      .u-nav-v4-1 {
        border-bottom: none;

        & .nav-item {
          margin: 0 -1px 0 0;
          z-index: 2;
        }

        & .nav-link {
          border: {
            style: solid;
            width: 1px 1px 1px 2px;
            color: transparent;
          }
        }

        & .nav-link.active {
          border-color: $g-color-gray-light-v4 $g-color-white $g-color-gray-light-v4 $g-color-gray-light-v2;
        }

        &.u-nav-primary .nav-link.active {
          border-color: $g-color-gray-light-v4 $g-color-white $g-color-gray-light-v4 $g-color-primary;
        }

        &.u-nav-dark .nav-link.active {
          border-color: $g-color-gray-light-v4 $g-color-white $g-color-gray-light-v4 $g-color-gray-dark-v2;
        }
      }

      & .nav {
        width: 30%;
      }
      & .tab-content {
        width: 70%;
      }
    }
  }
}
