/*------------------------------------
  Searchform v1
------------------------------------*/

.u-searchform-v1,
.u-searchform-v1--align-left {
	min-width: initial;
	@include px-to-rem(width, 300px);
	@include absolute(right -15px);
	top: 100%;

	box-shadow: 0 1px 3px #ddd;

	.form-control {
		font-family: $g-font-primary;
		@include px-to-rem(font-size, 12px);
		// text-transform: uppercase;
		// @include px-to-rem(height, 50px);
		// @include px-to-rem(padding, 12px);

		// &::placeholder {
		// 	color: g-color-gray-dark-v4;
		// 	opacity: 1;
		// }

		// &::-webkit-input-placeholder {
		// 	color: g-color-gray-dark-v4;
		// 	opacity: 1;
		// }

		// &::-moz-placeholder {
		// 	color: g-color-gray-dark-v4;
		// 	opacity: 1;
		// }
	}

	&--align-left {
		@include absolute(left -15px);
	}

	.input-group-addon {
		border: 0;

		button[type="submit"] {
			height: 100%;
		}
	}
}