/*------------------------------------
  Ribbons
------------------------------------*/
%u-ribbon {
  position: absolute;
  font-size: .9rem;
}

.u-ribbon {
  // Ribbon Position
  &-center {
    left: 50%;
    transform: translateX(-50%);
  }

  // Ribbon Size
  &--sm {
    font-size: .7rem;
  }
  &--lg {
    font-size: 1.1rem;
  }
}

/* Ribbon Style Type 1
------------------------------------*/
.u-ribbon-v1 {
  @extend %u-ribbon;
  display: inline-block;
  padding: .35rem .7rem;

  /* Ribbon Type 1 With icon */
  &.u-ribbon-with-icon {
    padding: .5rem .85rem;
  }
  & .u-ribbon-icon--left {
    margin-right: .5rem;
  }
  & .u-ribbon-icon--right {
    margin-left: .5rem;
  }
}

/* Ribbon Style Type 2
------------------------------------*/
.u-ribbon-v2 {
  @extend %u-ribbon;
  display: inline-block;
  padding: 1rem 1.1rem;

  &.u-ribbon--sm {
    padding: 1rem 1.2rem;
  }
  &.u-ribbon--lg {
    padding: 1.2rem 1.1rem;
  }
}

/* Bookmarked Ribbon
------------------------------------*/
.u-ribbon-bookmark::after {
  content: "";
  position: absolute;
  bottom: -1.1rem;
  left: 0;
  width: 100%;
  height: 0;
  border-style: solid;
  border-left-width: 1.5rem;
  border-right-width: 1.5rem;
  border-bottom: 1rem solid transparent !important;
}
.u-ribbon--lg.u-ribbon-bookmark::after {
  border-right-width: 1.4rem;
}

/* Ribbon Colors
------------------------------------*/
.u-ribbon-bookmark {
  &.g-bg-primary::after {
    border-color: $g-color-primary;
  }

  &.g-bg-black::after {
    border-color: $g-color-black;
  }
  &.g-bg-white::after {
    border-color: $g-color-white;
  }

  &.g-bg-light-opacity::after {
    border-color: rgba(255,255,255, .7);
  }
  &.g-bg-dark-opacity::after {
    border-color: rgba(30,30,30, .7);
  }

  &.g-color-gray-light-v3::after {
    border-color: $g-color-gray-light-v3;
  }
  &.g-color-gray-light-v4::after {
    border-color: $g-color-gray-light-v4;
  }
  &.g-color-gray-dark-v5::after {
    border-color: $g-color-gray-dark-v5;
  }

  &.g-bg-green::after {
    border-color: $g-color-green;
  }
  &.g-bg-blue::after {
    border-color: $g-color-blue;
  }
  &.g-bg-lightblue::after {
    border-color: $g-color-lightblue;
  }
  &.g-bg-lightblue-v1::after {
    border-color: $g-color-lightblue-v1;
  }
  &.g-bg-darkblue::after {
    border-color: $g-color-darkblue;
  }
  &.g-bg-indigo::after {
    border-color: $g-color-indigo;
  }
  &.g-bg-red::after {
    border-color: $g-color-red;
  }
  &.g-bg-lightred::after {
    border-color: $g-color-lightred;
  }
  &.g-bg-darkred::after {
    border-color: $g-color-darkred;
  }
  &.g-bg-purple::after {
    border-color: $g-color-purple;
  }
  &.g-bg-darkpurple::after {
    border-color: $g-color-darkpurple;
  }
  &.g-bg-pink::after {
    border-color: $g-color-pink;
  }
  &.g-bg-orange::after {
    border-color: $g-color-darkred;
  }
  &.g-bg-deeporange::after {
    border-color: $g-color-deeporange;
  }
  &.g-bg-yellow::after {
    border-color: $g-color-darkred;
  }
  &.g-bg-aqua::after {
    border-color: $g-color-aqua;
  }
  &.g-bg-cyan::after {
    border-color: $g-color-cyan;
  }
  &.g-bg-teal::after {
    border-color: $g-color-teal;
  }
  &.g-bg-brown::after {
    border-color: $g-color-darkred;
  }
  &.g-bg-bluegray::after {
    border-color: $g-color-bluegray;
  }
}

/* Clipped-v1 Ribbon
------------------------------------*/
.u-ribbon-clip-v1::before,
.u-ribbon-clip-v2::before {
  content: "";
  position: absolute;
  @include px-to-rem(bottom, -10px);
  border-style: solid;
}
.u-ribbon--left.u-ribbon-clip-v1::before,
.u-ribbon--left.u-ribbon-clip-v2::before {
  left: 0;
  @include px-to-rem(border-width, 0 10px 10px 0);
  border-right-color: $g-color-gray-dark-v5;
}
.u-ribbon--right.u-ribbon-clip-v1::before,
.u-ribbon--right.u-ribbon-clip-v2::before {
  right: 0;
  @include px-to-rem(border-width, 0 0 10px 10px);
  border-left-color: $g-color-gray-dark-v5;
}

/* Clipped-v2 Ribbon
------------------------------------*/
.u-ribbon-clip-v2 {
  padding: .35rem 1.35rem;
  transform: skewX(-10deg) translateZ(1px);
}
.u-ribbon-clip-v2.u-ribbon-with-icon {
  padding: .5rem 1.35rem;
}
.u-ribbon-clip-v2__inner {
  display: inline-block;
  transform: skewX(10deg) translateZ(1px);
}
.u-ribbon-clip-v2::before {
  transform: skewX(10deg);
}
.u-ribbon--left.u-ribbon-clip-v2::before {
  @include px-to-rem(left, 1px);
}
.u-ribbon--right.u-ribbon-clip-v2::before {
  @include px-to-rem(right, -1px);
}

/* Clipped-v3 Ribbon
------------------------------------*/
.u-ribbon-clip-v3 {
  width: calc(100% + 20px);
  text-align: center;
}
.u-ribbon-clip-v3::before,
.u-ribbon-clip-v3::after {
  content: "";
  position: absolute;
}
.u-ribbon-clip-v3::before {
  left: 0;
  @include px-to-rem(bottom, -10px);
  width: 0;
  height: 0;
  border-top: 10px solid $g-color-gray-dark-v5;
  border-left: 10px solid transparent;
}
.u-ribbon-clip-v3::after {
  right: 0;
  @include px-to-rem(bottom, -10px);
  width: 0;
  height: 0;
  border-top: 10px solid $g-color-gray-dark-v5;
  border-right: 10px solid transparent;
}

/* Clipped-v4 Ribbon
------------------------------------*/
.u-ribbon-clip-v4 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.u-ribbon-clip-v4::before,
.u-ribbon-clip-v4::after {
  content: "";
  position: absolute;
}
.u-ribbon-clip-v4::before {
  top: 0;
  @include px-to-rem(width, 40px);
  @include px-to-rem(height, 6px);
  background: $g-color-gray-dark-v5;
  @include px-to-rem(border-radius, 8px 8px 0 0);
}
.u-ribbon-clip-v4.u-ribbon--left::before {
  @include px-to-rem(left, 100px);
}
.u-ribbon-clip-v4.u-ribbon--right::before {
  @include px-to-rem(right, 100px);
}
.u-ribbon-clip-v4::after {
  @include px-to-rem(top, 100px);
  @include px-to-rem(width, 6px);
  @include px-to-rem(height, 40px);
  background: $g-color-gray-dark-v5;
}
.u-ribbon-clip-v4.u-ribbon--left::after {
  left: 0;
  @include px-to-rem(border-radius, 8px 0 0 8px);
}
.u-ribbon-clip-v4.u-ribbon--right::after {
  right: 0;
  @include px-to-rem(border-radius, 0 8px 8px 0);
}
.u-ribbon-clip-v4__inner {
  position: absolute;
  @include px-to-rem(top, 30px);
  @include px-to-rem(width, 200px);
  @include px-to-rem(height, 40px);
  @include px-to-rem(line-height, 40px);
  overflow: hidden;
  transform: rotate(45deg);
  text-align: center;
  z-index: 2;

  .u-ribbon--left & {
    @include px-to-rem(left, -50px);
    transform: rotate(-45deg);
  }
  .u-ribbon--right & {
    @include px-to-rem(right, -50px);
    transform: rotate(45deg);
  }

  &--bordered {
    border: 1px dashed rgba(255,255,255, .7);
  }

  &.g-bg-primary {
    box-shadow: 0 0 0 3px $g-color-primary, 0 21px 5px -18px rgba(0,0,0, .6);
  }

  &.g-bg-black {
    box-shadow: 0 0 0 3px $g-color-black, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-white {
    box-shadow: 0 0 0 3px $g-color-white, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-light-opacity {
    box-shadow: 0 0 0 3px rgba(255,255,255,.7), 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-dark-opacity {
    box-shadow: 0 0 0 3px rgba(30,30,30,.7), 0 21px 5px -18px rgba(0,0,0, .6);
  }

  &.g-color-gray-light-v3 {
    box-shadow: 0 0 0 3px $g-color-gray-light-v3, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-color-gray-light-v4 {
    box-shadow: 0 0 0 3px $g-color-gray-light-v4, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-color-gray-dark-v5 {
    box-shadow: 0 0 0 3px $g-color-gray-dark-v5, 0 21px 5px -18px rgba(0,0,0, .6);
  }

  &.g-bg-green {
    box-shadow: 0 0 0 3px $g-color-green, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-blue {
    box-shadow: 0 0 0 3px $g-color-blue, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-lightblue {
    box-shadow: 0 0 0 3px $g-color-lightblue, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-lightblue-v1 {
    box-shadow: 0 0 0 3px $g-color-lightblue-v1, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-darkblue {
    box-shadow: 0 0 0 3px $g-color-darkblue, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-indigo {
    box-shadow: 0 0 0 3px $g-color-indigo, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-red {
    box-shadow: 0 0 0 3px $g-color-red, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-lightred {
    box-shadow: 0 0 0 3px $g-color-lightred, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-darkred {
    box-shadow: 0 0 0 3px $g-color-darkred, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-purple {
    box-shadow: 0 0 0 3px $g-color-purple, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-darkpurple {
    box-shadow: 0 0 0 3px $g-color-darkpurple, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-pink {
    box-shadow: 0 0 0 3px $g-color-pink, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-orange {
    box-shadow: 0 0 0 3px $g-color-darkred, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-deeporange {
    box-shadow: 0 0 0 3px $g-color-deeporange, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-yellow {
    box-shadow: 0 0 0 3px $g-color-darkred, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-aqua {
    box-shadow: 0 0 0 3px $g-color-aqua, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-cyan {
    box-shadow: 0 0 0 3px $g-color-cyan, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-teal {
    box-shadow: 0 0 0 3px $g-color-teal, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-brown {
    box-shadow: 0 0 0 3px $g-color-darkred, 0 21px 5px -18px rgba(0,0,0, .6);
  }
  &.g-bg-bluegray {
    box-shadow: 0 0 0 3px $g-color-bluegray, 0 21px 5px -18px rgba(0,0,0, .6);
  }
}