/*------------------------------------
  Selects v1
------------------------------------*/

.u-select-v1 {
  position: relative;
  max-width: 100%;
  font-size: 1rem;
  color: rgba($g-color-main, .3);
  cursor: pointer;
  border: {
    width: 1px;
    style: solid;
    color: $g-color-gray-light-v2;
  }
  padding: .4rem 1rem;

  .chosen-single {
    position: static;
    height: auto;
    color: inherit;
    background: {
      image: none;
      color: transparent;
    }
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    line-height: inherit;

    span {
      margin-right: 0;

      img {
        position: relative;
        top: -2px;
        margin-right: 4px;
      }
    }

    div {
      width: 40px;

      b {
        background: none !important;

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          &:first-child {
            display: inline-block;
          }

          &:last-child {
            display: none;
          }
        }
      }
    }

    &:focus {
      outline: 0 none;
    }
  }

  .chosen-drop {
    width: calc(100% + 2px);
    box-sizing: border-box;
    border: {
      width: 1px;
      style: solid;
      color: inherit;
      radius: 0;
      top-width: 1px;
    }
    margin-left: -1px;
  }

  .chosen-results {
    padding: 0;
    margin: 0;

    > li {
      position: relative;
      font-size: inherit;
      color: $g-color-gray-dark-v5;
      border-bottom: {
        width: 1px;
        style: solid;
        color: $g-color-gray-light-v2;
      }
      padding: 12px 16px;

      img {
        position: relative;
        top: -2px;
        margin-right: 4px;
      }

      div {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 100%;

        b {
          display: block;
          width: 100%;
          height: 100%;

          i {
            position: absolute;
            top: 50%;
            left: 50%;
            display: none;
            transform: translate(-50%, -50%);
          }
        }
      }

      &:last-child {
        border-bottom-width: 0;
      }

      &.highlighted {
        color: $g-color-gray-dark-v5;
        background: {
          image: none;
          color: $g-color-primary-light-v1;
        }
        border-bottom-color: $g-color-primary-light-v1;

        &.g-color-white--active {
          color: $g-color-white !important;
        }

        &.g-bg-primary--active {
          background-color: $g-color-primary !important;
        }
      }

      &.result-selected {
        color: $g-color-gray-dark-v5;
        background-color: $g-color-primary-light-v1;
        border-bottom-color: $g-color-primary-light-v1;

        div {
          b {
            i {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  &.chosen-container-active {
    .chosen-single {
      background-image: none;
      box-shadow: none;
    }
  }

  &.chosen-with-drop {
    .chosen-single {
      border: none;
      border-radius: 0;

      div {
        b {
          i {
            &:first-child {
              display: none;
            }

            &:last-child {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  &.chosen-container-multi:not(.u-select-multiple-custom) {
    .chosen-choices {
      background-image: none;
      border: none;
      box-shadow: none;
      padding: 0;

      .search-choice {
        font-size: 12px;
        color: $g-color-gray-dark-v5;
        background: {
          image: none;
          color: $g-color-white;
        }
        border: {
          color: $g-color-gray-light-v2;
          radius: 0;
        }
        padding: 5px 20px 5px 5px;

        &-close {
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .search-field {
        height: 22px;

        input[type="text"] {
          height: 22px;
          margin: 0;
        }
      }
    }
  }

  &.u-select-multiple-custom {
    .chosen-choices {
      display: none;
    }

    .chosen-drop {
      width: 100%;
      position: static;
      top: auto;
      left: auto;
      z-index: 3;
      border: none;
      box-shadow: none;
      margin-left: 0;
    }
  }

  &.u-dropdown {
    &-sm {
      padding: .1rem .5rem;
      font-size: .875rem;

      .chosen-results {
        > li {
          padding: 6px 8px;
        }
      }
    }

    &-lg {
      padding: .55rem 1.5rem;
      font-size: 1.25rem;

      .chosen-results {
        > li {
          padding: 14px 18px;
        }
      }
    }
  }
}
