/*------------------------------------
  Typography Font Style
------------------------------------*/
.g-font-style {
  &-normal {
    font-style: normal;
  }
  &-italic {
    font-style: italic;
  }
}