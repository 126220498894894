/*------------------------------------
  Heading-v8
------------------------------------*/
%u-heading-v8__title {
  display: inline-block;
  font-weight: inherit;
}



//
// Imports
//

@import "heading-v8-1";
@import "heading-v8-2";