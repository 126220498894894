// donate

//
// Variables and Mixins
//
@import "settings/variables.donate";
@import "unify";

/* Typography */
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: $g-color-gray-dark-v1;
}

a {
  &:hover,
  &:active,
  &:focus {
    color: $g-color-primary;
  }
}

/* Hamburger */
.hamburger {
  padding: 0;
}

.hamburger-box,
.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
  width: 25px;
}

.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
  .light-theme & {
    background-color: $g-color-white;
  }
}

.hamburger-box {
  height: 17px;
}

.hamburger--slider .hamburger-inner {
  top: 0;
}

.hamburger--slider .hamburger-inner:before {
  top: 9px;
}

.hamburger--slider .hamburger-inner:after {
  top: 17px;
}

.hamburger--slider.is-active .hamburger-inner:after {
  transform: translate3d(0, -17px, 0) rotate(-90deg);
}

/* Main navigation */
.light-theme {
  .nav-link {
    color: $g-color-white;
  }

  &:hover {
    color: $g-color-primary;
  }

  li.active {
    .nav-link {
      color: $g-color-primary;
    }
  }
}

.dark-theme {
  .nav-link {
    color: $g-color-gray-dark-v4;
  }

  &:hover {
    color: $g-color-primary;
  }

  li.active {
    .nav-link {
      color: $g-color-primary;
    }
  }
}

/* Background */
.g-theme-bg-gray-light-v3 {
  background-color: $g-theme-color-gray-light-v3 !important;
}

/* Border */
.g-theme-brd-color-gray-light-v2 {
  border-color: $g-theme-color-gray-light-v2 !important;
}

.g-theme-brd-color-dark-light-v1 {
  border-color: $g-theme-color-gray-dark-v1 !important;
}

/* Carousel */
.u-carousel-v5 .js-slide {
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($g-color-white, .7);
    opacity: 1;
    transition: opacity .2s ease-in;
  }

  &.slick-active {
    &::after {
      opacity: 0;
    }
  }
}

/* Carousel indicators */
.u-carousel-indicators-v1 li span {
  background-color: $g-theme-color-gray-light-v1;
}

.u-carousel-indicators-v1 li.slick-active span {
  background-color: $g-color-primary;
}

/* Pin map */
.point,
.point::after {
  background-color: rgba($g-color-black, .2);
}

.point::before {
  background-color: $g-color-primary;
}

/* Progress bar */
.u-progress__pointer-v2::after {
  border-color: $g-color-primary transparent;
}

@media (max-width: $g-lg - 1) {
  .u-header__section:not(.js-header-change-moment) .navbar-collapse {
    padding: 0 20px 10px;
    background-color: $g-color-white;

    .nav-item .nav-link {
      color: $g-color-gray-dark-v4;
    }

    .active.nav-item .nav-link {
      color: $g-color-primary;
    }
  }
}