/*------------------------------------
  Searchform v5
------------------------------------*/

.u-searchform-v5 {

	.input-group {
		border: solid 1px $g-color-gray-light-v4;
	}

	.form-control {
		font-family: $g-font-primary;
		@include px-to-rem(font-size, 12px);
		text-transform: uppercase;
		color: $g-color-gray-dark-v4;

		@include px-to-rem(height, 43px);
		@include px-to-rem(padding, 5px 5px 5px 28px);
		border-color: transparent;

		&::placeholder {
			color: g-color-gray-dark-v4;
			opacity: 1;
		}

		&::-webkit-input-placeholder {
			color: g-color-gray-dark-v4;
			opacity: 1;
		}

		&::-moz-placeholder {
			color: g-color-gray-dark-v4;
			opacity: 1;
		}

		// &:not(:last-child) {
		// 	border-right: none;
		// }

		&:focus {
			border-color: transparent;
		}
	}

	.input-group-addon {
		border-color: transparent;

		button[type="submit"] {
			@include px-to-rem(font-size, 19px);
			height: 100%;
			cursor: pointer;
			outline: none;
			border: none;
			background-color: transparent;
		}
	}
}