/*------------------------------------
  Nonstandard Background v3
------------------------------------*/

[class*="u-ns-bg-v3"] {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: 3;
    display: block;
    width: 0;
    height: 0;
    border: {
      style: solid;
      color: transparent;
    }
  }
}

.u-ns-bg-v3 {
  &-top,
  &-bottom {
    &::before {
      left: 80px;
    }
  }

  &-left,
  &-right {
    &::before {
      top: 23px;
    }
  }

  &-top,
  &-left {
    &.g-bg-primary {
      &::before {
        border-bottom-color: $g-color-primary;
      }
    }

    &.g-bg-white {
      &::before {
        border-bottom-color: $g-color-white;
      }
    }

    &.g-bg-gray-light-v2 {
      &::before {
        border-bottom-color: $g-color-gray-light-v2;
      }
    }

    &.g-bg-black {
      &::before {
        border-bottom-color: $g-color-black;
      }
    }

    &.g-bg-facebook {
      &::before {
        border-bottom-color: $g-color-facebook;
      }
    }
  }

  &-bottom,
  &-right {
    &.g-bg-primary {
      &::before {
        border-top-color: $g-color-primary;
      }
    }

    &.g-bg-white {
      &::before {
        border-top-color: $g-color-white;
      }
    }

    &.g-bg-gray-light-v2 {
      &::before {
        border-top-color: $g-color-gray-light-v2;
      }
    }

    &.g-bg-black {
      &::before {
        border-top-color: $g-color-black;
      }
    }

    &.g-bg-lightred {
      &::before {
        border-top-color: $g-color-lightred;
      }
    }

    &.g-bg-blue {
      &::before {
        border-top-color: $g-color-blue;
      }
    }

    &.g-bg-purple {
      &::before {
        border-top-color: $g-color-purple;
      }
    }

    &.g-bg-cyan {
      &::before {
        border-top-color: $g-color-cyan;
      }
    }

    &.g-bg-teal {
      &::before {
        border-top-color: $g-color-teal;
      }
    }

    &.g-bg-pink {
      &::before {
        border-top-color: $g-color-pink;
      }
    }

    &.g-bg-red {
      &::before {
        border-top-color: $g-color-red;
      }
    }

    &.g-bg-facebook {
      &::before {
        border-top-color: $g-color-facebook;
      }
    }
  }

  &-top {
    &::before {
      top: -22px;
      border-width: 22px 0 0 22px;
    }
  }

  &-bottom {
    &::before {
      bottom: -22px;
      border-width: 22px 22px 0 0;
    }
  }

  &-left {
    &::before {
      left: -22px;
      border-width: 0 22px 22px 0;
    }
  }

  &-right {
    &::before {
      right: -22px;
      border-width: 22px 22px 0 0;
    }
  }
}
