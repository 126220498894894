/*------------------------------------
  Go To v3
------------------------------------*/

[class*="u-go-to"] {
  display: none;
}

.u-go-to-v3 {
  padding: 5px;
  display: block;
  background-color: rgba($g-color-white, .7);
  color: $g-color-main;
  border-radius: 50%;
  transition: .3s ease-out;
  z-index: 11;

  i {
    @include px-to-rem(width, 50px);
    @include px-to-rem(height, 50px);
    display: block;
    text-align: center;
    border-radius: 50%;
    @include px-to-rem(font-size, 16px);
    @include px-to-rem(line-height, 46px);
    border: solid 1px $g-color-main;
    transition: .3s ease-out;
  }

  &:hover,
  &:focus:hover {
    text-decoration: none;
    color: $g-color-primary;
    background-color: rgba($g-color-white, 1);

    & i {
      border-color: $g-color-primary;
    }
  }

  &:focus {
    text-decoration: none;
    color: $g-color-main;
    background-color: rgba($g-color-white, .7);

    & i {
      border-color: $g-color-main;
    }
  }
}

@media (max-width: #{$g-sm}-1) {
  .u-go-to-v3 {
    transform: scale(.8, .8);
  }
}