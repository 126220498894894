/*------------------------------------
  Checked state colors
------------------------------------*/

/* White Colors */
.g-color-white {
  &--checked {
    input[type="checkbox"]:checked + &,
    input[type="radio"]:checked + &,
    input[type="checkbox"]:checked + * &,
    input[type="radio"]:checked + * & {
      color: $g-color-white !important;
    }

    &.g-checked,
    .g-checked & {
      color: $g-color-white !important;
    }
  }
}
